import { createSlice } from "@reduxjs/toolkit";

const initialValue = {
  campaignInfo: {}, // Corrected typo here
  customerInfo: {},
  queueStatus: false,
  radioCheck: "",
  scriptCheck: "",
  dispositionCheck: "",
  subDispositionCheck: "",
  callScore: 100,
};

export const callSessionSlice = createSlice({
  name: "callsession",
  initialState: initialValue,
  reducers: {
    setCampaignInfo: (state, action) => {
      state.campaignInfo = action.payload; // Corrected action assignment
    },
    setCustomerInfo: (state, action) => {
      state.customerInfo = action.payload;
    },
    setQueueInfo: (state, action) => {
      state.queueStatus = action.payload;
    },
    setRadioCheck: (state, action) => {
      state.radioCheck = action.payload;
    },
    setScriptCheck: (state, action) => {
      state.scriptCheck = action.payload;
    },
    setDispositionCheck: (state, action) => {
      state.dispositionCheck = action.payload;
    },
    setsubDispositionCheck: (state, action) => {
      state.subDispositionCheck = action.payload;
    },
    setCallScore: (state, action) => {
      state.callScore = action.payload;
    },
    removeCallSession: (state) => {
      state.customerInfo = {};
      state.campaignInfo = {};
      state.radioCheck = "";
      state.scriptCheck = "";
      state.dispositionCheck = "";
      state.subDispositionCheck = "";
      state.callScore = 100;
    },
  },
});

export const {
  setCampaignInfo,
  setCustomerInfo,
  removeCallSession,
  setQueueInfo,
  setRadioCheck,
  setScriptCheck,
  setDispositionCheck,
  setsubDispositionCheck,
  setCallScore,
} = callSessionSlice.actions;
export default callSessionSlice.reducer;
