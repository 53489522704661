import { createSlice } from "@reduxjs/toolkit";
const initialValue = {
    crmwebphone: {
        "queueStatus": "",
        "callstatus": "",
        "message_text": "",
        "pauseStatus": "",
        "setdialeCall": "",
        "dialerHangupStatus": true,
        "dialerHangupCuid":"",
        "dialerCallHangup" : false,
        "callStartTime":"",
        "callEndTime":"",
        "confStatus": "",
    }
}

export const webphoneSlice = createSlice({
    name: "webphone",
    initialState: initialValue,
    reducers: {
        setCrmWebphone: (state, action) => {
            state.crmwebphone = {...state.crmwebphone , ...action.payload};
        },
        removeCrmWebphone: (state, action) => {
            state.crmwebphone = initialValue.crmwebphone;
        },
    }
})

export const { setCrmWebphone, removeCrmWebphone } = webphoneSlice.actions;
export default webphoneSlice.reducer;
