import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Table } from 'react-bootstrap'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { MultiSelect } from '../../components/formValidation/MultiSelect'
import { TextField } from '../../components/formValidation/TextField'
import DaynmicApicall from '../../utils/function'


const ManageBotDialerData = (props) => {
    const { userInfo } = useSelector((state) => state?.user?.value);
  const Header = { headers: { Authorization: userInfo.data[0].UserToken } };
    const [operator,setoperator] = useState("");
    const [dataprep, setDataprep] = useState(false);

    const [serach,setSearch] = useState("");

    const SearchBar =() =>{
        return setSearch(! serach); 
    }


    async function getOperator() {
        await DaynmicApicall(
          `prm/getParamdata/ALL/OPERATOR/${props.activeMenu.keypointer}`,
          "",
          "get",
          userInfo.data[0].UserToken
        )
          .then(async (res) => {
           
            let bData = [];
            bData = await res.map((item) => {
              return {
                value: item.paravalue,
                label: item.paracode,
              };
            });
            setoperator(bData);
          })
          .catch((error) => {
            toast.error(
              error.response.message ??
                error.message ??
                "OOPs, something went wrong."
            );
          });
      }

      async function dataPreparation() {
        await DaynmicApicall(
          `prm/getParamdata/ALL/DATA_PREPRATION_B_D/${props.activeMenu.keypointer}`,
          "",
          "get",
          userInfo.data[0].UserToken
        )
          .then(async (res) => {
        
            let bData = [];
            bData = await res.map((item) => {
              return {
                value: item.paravalue,
                label: item.paracode,
              };
            });
            setDataprep(bData);
          })
          .catch((error) => {
            toast.error(
              error.response.message ??
                error.message ??
                "OOPs, something went wrong."
            );
          });
      }

      useEffect(() =>{
        getOperator();
        dataPreparation();

      },[])

      const initial = {
        dataprep: "",
        policno: "",
        operator: "",
        valuerow:"",
       
        
      };

      const onSubmit =() =>{


      }
    


     
  return (

    <>
    <div className="container-fluid py-4 bg ">
    <div className="row">
      <div className="card col-xl-12 bg-dark">
        <div className="row row-cols-md-2 m-2 p-2 row-cols-lg-2 row-cols-xl-2 font-weight-bold">
          <span className="text-left text-light ">Dialer / Bot Data Preparation</span>
          
        </div>
      </div>
    </div>
  </div>  
    
  
    <div className='card container'>
    <Formik
     initialValues={initial}

     onSubmit={onSubmit}
  >
    {(formik) => (
      <Form>
      {serach && (
        <div class="input-group mt-3">
        <div class="form-outline">
          <input type="search" id="form1" class="form-control"  placeholder='Search....'/>
        </div>
        <button type="button" class="btn btn-primary " style={{height:"43px",width:"30px"}}>
          <i class="fas fa-search"></i>
        </button>
      </div>
      )}
        <div className="row mt-3 tex-left">
        <MultiSelect
            label="Search Filter"
             value={formik.values.dataprep}
            name="dataprep"
            isMulti={false}
            formik={formik}
             options={dataprep}
            onChange={(value) => formik.setFieldValue("dataprep", value)}
          />
          <TextField
            label="Policy Number"
            placeholder="Enter Policy Number"
            name="policno"
            type="text"
            
          />

         
          <MultiSelect
            label="Operator"
            // value={formik.values.campid}
            name="operator"
            isMulti={false}
            formik={formik}
             options={operator}
            onChange={(value) => formik.setFieldValue("operator", value)}
          />

          <MultiSelect
          label="Value"
          // value={formik.values.campid}
          name="operator"
          isMulti={true}
          formik={formik}
           options="{operator}"
          onChange={(value) => formik.setFieldValue("operator", value)}
        />
         <button  className = 'btn btn-primary ' style={{marginTop:"33px",width:"70px",height:"35px"}} type='submit'>Add</button>
        </div>

        <div className='card mt-3'>
        <h6>Total Data Count</h6>
        <Table table-responsive>
        <thead>
        <tr>
        <th>Search Filter</th>
        <th>Policy Number</th>
        <th>Operator</th>
        <th>Value</th>
        <th>View Details</th>
        </tr> 
        <tr>
        <td>Bot Data</td>
        <td>12345</td>
        <td>=</td>
        <td>20</td>
        <td><a href='#/'>View Data</a></td>
        </tr>
        </thead>
        </Table>
        <div className="d-flex justify-content-end w-100">
        <button type="submit" className="btn btn-primary btn-sm m-2" onClick={SearchBar}>
          Search
        </button>
        <button type="reset" className="btn btn-primary btn-sm m-2">
          Cancel
        </button>
        <button type="reset" className="btn btn-primary btn-sm m-2">
      Push
      </button>
      </div>
        </div>
      </Form>
    )}
  </Formik>

    
    
    </div>

    </>
  )
}

export default ManageBotDialerData