import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import AgentDashTable from "./AgentDashTable";
import "./App.css";
import AgentDashTable2 from "./AgentDashTable2";
import toast from "react-hot-toast";
import DaynmicApicall from "../../utils/function";
import RefreshIcon from "@mui/icons-material/Refresh";
import Skeleton from "react-loading-skeleton";
import Tooltip from "@mui/material/Tooltip";
import "react-loading-skeleton/dist/skeleton.css";
import AgentNotification from "./AgentNotification";

function TabPanel(props) {
  const { value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{props.children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function AgetDashboard() {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state?.user?.value);
  const [getCardData, setCardData] = useState([]);
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [leadUpdate, setLeadUpdate] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dashboard = {
    headerStyle: {
      background: "#262B40",
      color: "#eaedf2",
    },
    LevelOneCards: {
      cardImage: {
        url: "/images/text-editor.png",
        style: {
          height: "30px",
        },
      },
      style: {
        background: "#fff",
        color: "#262B40",
      },
      label: {
        style: {
          fontWeight: "bold",
          color: "#F0526F",
        },
      },
    },
    tableStyle: {
      style: {
        background: "#fff",
        color: "#fff",
      },
    },
    dashboardChart: {
      style: {
        background: "#333",
        color: "#fff",
      },
    },
  };

  let getAgentData = async () => {
    const userid = userInfo.data[0].dlrAgentId;
    await DaynmicApicall(
      `mcrmdlr/getagentcalldata/${userid}`,
      "",
      "get",
      userInfo.data[0].UserToken
    )
      .then(async (res) => {
        setCardData(res);
      })
      .catch((error) => {
        toast.error(
          error.response.message ??
            error.message ??
            "OOPs, something went wrong ."
        );
      });
  };

  useEffect(async () => {
    await getAgentData();
  }, []);

  return (
    <div style={{ paddingRight: "15px" }}>
      <div className="row mt-3" style={{ width: "100.5%" }}>
        <div className="card pb-3" style={{ position: "sticky" }}>
          <div className="justify-content-end">
            <RefreshIcon
              onClick={getAgentData}
              className="text-success float-right btn-outline-dark rounded"
              style={{
                cursor: "pointer",
              }}
            />
          </div>

          <div className="row">
            {(getCardData &&
              getCardData.map((data, index) => (
                <div
                  className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 m-0"
                  key={index}
                  style={{ width: "20%" }}
                >
                  <div className="card p-2 mb-2 border-right-4 secondary m-0 border-2 border-secondary">
                    <Tooltip title={data.tooltip} arrow>
                      <div>
                        <div
                          style={{
                            color: "#262B40",
                            fontWeight: "bold",
                            fontSize: "10px",
                          }}
                        >
                          <span>{data.label}</span>
                        </div>
                        <div>
                          <div>
                            <span
                              style={{ fontWeight: "bold", fontSize: "15px" }}
                              className={
                                data > 5 ? "text-success" : "text-danger"
                              }
                            >
                              {data.count}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              ))) || <Skeleton />}
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12" id="cardagent">
          <div className="card" style={{ height: "100%" }}>
            <div
              className="agent-form"
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingRight: "10px",
              }}
            >
              <div id="agent-table" className="p-1 bg-dark">
                Performance
              </div>

              <RefreshIcon
                onClick={() => {
                  setLoading(true);
                  setLoading1(true);
                }}
                className="text-success btn-outline-dark rounded"
                style={{
                  cursor: "pointer",
                }}
              />
            </div>

            <div style={{ height: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    style={{ width: "150px" }}
                    label="Top 10 Agents"
                    {...a11yProps(0)}
                  >
                    {" "}
                  </Tab>
                  <Tab
                    style={{ width: "180px" }}
                    label="Bottom 10 Agents"
                    {...a11yProps(1)}
                  />
                </Tabs>
              </Box>
              <TabPanel style={{ height: "100%" }} value={value} index={0}>
                <AgentDashTable loading={loading} setLoading={setLoading} />
              </TabPanel>
              <TabPanel style={{ height: "100%" }} value={value} index={1}>
                <AgentDashTable2
                  loading1={loading1}
                  setLoading1={setLoading1}
                />
              </TabPanel>
            </div>
          </div>
        </div>
        <div
          className="mt-3 mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12 "
          style={{ padding: 0 }}
        >
          <div className="card" style={{ height: "100%" }}>
            <div
              className="agent-form2"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div id="agent-card3" className="p-1 bg-dark">
                Notification
              </div>
              <div style={{ padding: "4px 12px" }}>
                <RefreshIcon
                  onClick={async () => {
                    setLeadUpdate(true);
                  }}
                  className="text-success float-right btn-outline-dark rounded"
                  style={{
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
            <div
              className="d-flex mt-4"
              style={{
                fontSize: "14px",
                paddingLeft: "15px",
                paddingRight: "10px",
              }}
            >
              <AgentNotification
                leadUpdate={leadUpdate}
                setLeadUpdate={setLeadUpdate}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
