import React from "react";
import { DataGrid } from "@mui/x-data-grid";

const Emitable = ({ columns, formik, emiData }) => {
  const handleCellChange = (id, field, value) => {
    const updatedRows = formik.values.data.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: value };
      }
      return row;
    });

    const isInvalid = updatedRows.some(
      (row) => !row.partDate || !row.partAmount
    );

    formik.setValues((prevValues) => ({
      ...prevValues,
      data: updatedRows,
      isInvalid: isInvalid,
    }));
  };

  const renderEditableCell = (params) => {
    const { id, field, value, colDef } = params;
    return (
      <div style={{ padding: "7px", width: "100%", height: "100%" }}>
        {colDef.type === "date" ? (
          <input
            type={colDef.type}
            value={value}
            onChange={(e) => handleCellChange(id, field, e.target.value)}
            min={new Date().toLocaleDateString("en-CA")}
            style={{
              height: "100%",
              width: "100%",
              borderRadius: "10px",
              borderColor: "#9da0aa",
              padding: "5px",
            }}
          />
        ) : (
          <input
            type={colDef.type}
            value={value}
            min="1"
            onChange={(e) => handleCellChange(id, field, e.target.value)}
            style={{
              height: "100%",
              width: "100%",
              borderRadius: "10px",
              borderColor: "#9da0aa",
              padding: "5px",
            }}
          />
        )}
      </div>
    );
  };

  return (
    <div style={{ height: 400, width: "100%" }}>
      {emiData ? (
        <DataGrid
          getRowId={(row) => row?.id}
          rows={emiData && emiData.length > 0 ? emiData : []}
          columns={columns}
          pageSize={5}
          disableColumnMenu
        />
      ) : (
        <DataGrid
          getRowId={(row) => row?.id}
          rows={formik.values?.data}
          columns={columns.map((col) => ({
            ...col,
            renderCell: (params) => renderEditableCell(params),
          }))}
          pageSize={5}
          disableColumnMenu
        />
      )}
    </div>
  );
};

export default Emitable;
