import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Grid, TextField, FormHelperText, FormControl } from "@mui/material";
import Select from "react-select";
import DaynmicApicall from "../../utils/function";
import api from "../../utils/api";
import toast from "react-hot-toast";

export default function LeadDesposition({
  componentid,
  customer,
  campinfo,
  onClose,
  setLBagent,
}) {
  const { userInfo } = useSelector((state) => state?.user?.value);
  let { crmsaveandexit } = useSelector((state) => state?.dialer);
  let { customerInfo } = useSelector((state) => state?.dialer);
  const { UserToken } = userInfo.data[0];
  const [pageData, setpageData] = useState([]);
  const [initial, setInitial] = useState({});
  const [filteredCities, setFilteredCities] = useState([]);

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  useEffect(() => {
    pageInfo();
  }, []);

  const validationSchema = Yup.object().shape(
    pageData?.reduce((schema, field) => {
      if (field.type === "text") {
        schema[field.name] = field.required
          ? Yup.string().required(`${field.label} is required`)
          : Yup.string();
      } else if (field.type === "number") {
        schema[field.name] = field.required
          ? Yup.string().required(`${field.label} is required`)
          : Yup.string();
      } else if (field.type === "select") {
        schema[field.name] = field.required
          ? Yup.string().required(`${field.label} is required`)
          : Yup.string();
      } else if (field.type === "date") {
        if (field.previous === false) {
          const minDate = new Date();
          minDate.setDate(minDate.getDate() - 1);
          schema[field.name] = field.required
            ? Yup.date()
                .min(minDate, "*Date cannot be in the past")
                .required(`${field.label} is required`)
            : Yup.date();
        } else {
          schema[field.name] = field.required
            ? Yup.date().required(`${field.label} is required`)
            : Yup.date();
        }
      }

      return schema;
    }, {})
  );

  const formik = useFormik({
    initialValues: initial,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  async function handleSubmit(values) {
    let defaultValue = {
      custid: customer.custid,
      call_date: new Date().toISOString(),
      agentid: userInfo.data[0]?.userid,
      resp_code: customer.resp_code,
      sub_resp_code:
        crmsaveandexit.sub_resp_code === "NA"
          ? customer?.resp_code
          : crmsaveandexit.sub_resp_code,
      campid: campinfo?.campid,
      compid: componentid,
      pol_loan_no: customer.policy,
      keypointer: campinfo.keypointer,
      call_id: 0,
      sessionid: crmsaveandexit?.dialer_session_id,
      action_name: "INSERT",
    };

    const combinedData = {
      ...values,
      ...defaultValue,
      firstName:
        customerInfo?.customer_name?.split(" ").length > 0
          ? customerInfo?.customer_name?.split(" ")[0]
          : customerInfo?.customer_name,
      lastName:
        customerInfo?.customer_name?.split(" ").length > 1
          ? customerInfo?.customer_name?.split(" ")[1]
          : "",
      email: customerInfo?.email_id,
      requestid: customerInfo?.requestid,
      leadid: customerInfo?.leadid,
      mobile_no: customerInfo?.mobile_no,
      gender: customerInfo?.gender,
      dob: customerInfo?.customer_dob,
    };
    await api
      .post("/mcrmdlr/managedispcomp", combinedData, {
        headers: { Authorization: userInfo.data[0].UserToken },
      })
      .then((Info) => {
        onClose();
        toast.success(Info.data.message);
        formik.resetForm();
      })
      .catch((error) => {
        console.log("Error", error.message);
        toast.error(error.response?.data?.errors);
      });
  }

  async function pageInfo() {
    await DaynmicApicall(
      `/appuser/getcomponetbyid/${campinfo.keypointer}/${componentid}`,
      "",
      "get",
      UserToken
    )
      .then((data) => {
        const pageFields = data[0] ? data[0]?.DATA : [];
        setpageData(pageFields);
        const initialValues = pageFields.reduce((values, field) => {
          values[field.name] = "";
          return values;
        }, {});
        setInitial(initialValues);
      })
      .catch((err) => {
        console.log("ERROR", err);
        setpageData([]);
      });
  }

  useEffect(() => {
    const selectedState = formik.values.branchState;
    if (selectedState) {
      const cityField = pageData.find((field) => field.name === "branchCity");
      if (cityField) {
        const filteredCities = cityField.options.filter(
          (city) => city.state === selectedState
        );
        setFilteredCities(filteredCities);
      }
    }
  }, [formik.values.branchState]);

  const renderField = (field) => {
    switch (field.type) {
      case "date":
        return (
          <Grid item xs={12} sm={4} key={field.name}>
            <TextField
              id={field.name}
              fullWidth
              type="date"
              size="small"
              label={field.label}
              InputLabelProps={{ shrink: true }}
              value={formik.values[field.name]}
              onChange={(event) =>
                formik.setFieldValue(field.name, event.target.value)
              }
              onBlur={formik.handleBlur(field.name)}
              error={formik.touched[field.name] && !!formik.errors[field.name]}
              helperText={
                formik.touched[field.name] && formik.errors[field.name]
              }
            />
          </Grid>
        );
      case "text":
        return (
          <Grid item xs={12} sm={4} key={field.name}>
            <TextField
              id={field.name}
              fullWidth
              type="text"
              size="small"
              label={field.label}
              value={formik.values[field.name]}
              onChange={(event) =>
                formik.setFieldValue(field.name, event.target.value)
              }
              onBlur={formik.handleBlur(field.name)}
              error={formik.touched[field.name] && !!formik.errors[field.name]}
              helperText={
                formik.touched[field.name] && formik.errors[field.name]
              }
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        );
      case "number":
        return (
          <Grid item xs={12} sm={4} key={field.name}>
            <TextField
              id={field.name}
              fullWidth
              type="number"
              size="small"
              label={field.label}
              value={formik.values[field.name]}
              onChange={(event) => {
                const value = event.target.value;
                if (
                  value === "" ||
                  (Number(value) >= 0 && /^\d*\.?\d*$/.test(value))
                ) {
                  formik.setFieldValue(field.name, event.target.value);
                }
              }}
              onBlur={formik.handleBlur(field.name)}
              error={formik.touched[field.name] && !!formik.errors[field.name]}
              helperText={
                formik.touched[field.name] && formik.errors[field.name]
              }
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        );
      case "select":
        return (
          <Grid item xs={12} sm={4} key={field.name}>
            <FormControl fullWidth>
              <Select
                styles={{
                  ...customStyles,
                  control: (provided, state) => ({
                    ...provided,
                    borderColor:
                      formik.touched[field.name] && formik.errors[field.name]
                        ? "#d32f2f"
                        : "#ccc",
                    "&:hover": {
                      borderColor:
                        formik.touched[field.name] && formik.errors[field.name]
                          ? "#d32f2f"
                          : "#ccc",
                    },
                  }),
                }}
                classNamePrefix="react-select"
                value={field.options.find(
                  (option) => option.value === formik.values[field.name]
                )}
                onChange={(selectedOption) => {
                  formik.setFieldValue(field.name, selectedOption.value);
                  formik.setFieldTouched(field.name, true);
                }}
                options={
                  field.name === "branchCity" ? filteredCities : field.options
                }
                onBlur={() => formik.setFieldTouched(field.name, true)}
                placeholder={field.label}
              />
              {formik.touched[field.name] && formik.errors[field.name] && (
                <FormHelperText error>
                  {formik.errors[field.name]}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        );
      default:
        return null;
    }
  };

  return (
    <div className="card p-4">
      {pageData?.length > 0 && (
        <>
          <form className="p-2" onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              {pageData.map((field) => renderField(field))}
            </Grid>
            <div className="d-flex justify-content-end w-100 pt-2">
              <button type="submit" className="btn btn-primary btn-sm m-2">
                Submit
              </button>
              <button type="reset" className="btn btn-primary btn-sm m-2">
                Cancel
              </button>
            </div>
          </form>
        </>
      )}
    </div>
  );
}
