import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Grid from "@mui/material/Grid";
import {
  Button,
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
} from "@mui/material";
import Select from "react-select";

const DyanmicTabForm = ({
  fields,
  submitfunction,
  initialValues,
  setActionName,
}) => {
  const validationSchema = Yup.object().shape(
    fields &&
      fields.reduce((schema, field) => {
        switch (field.type) {
          case "date":
            return { ...schema, [field.name]: Yup.date().required() };
          case "text":
            return { ...schema, [field.name]: Yup.string().required() };
          case "number":
            return { ...schema, [field.name]: Yup.string().required() };
          case "select":
            return { ...schema, [field.name]: Yup.string().required() };
          case "radio":
            return { ...schema, [field.name]: Yup.string().required() };
          default:
            return schema;
        }
      }, {})
  );

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      submitfunction(values);
    },
  });

  useEffect(() => {
    if (initialValues.action_name === "UPDATE") {
      const updatedValues = {};
      fields.forEach((field) => {
        updatedValues[field.name] = initialValues[field.name];
      });
      formik.setValues((values) => ({
        ...values,
        ...initialValues,
        ...updatedValues,
      }));
    }
  }, [initialValues, fields, formik.setValues]);

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  const renderField = (field) => {
    switch (field.type) {
      case "date":
        return (
          <Grid item xs={12} sm={4} key={field.name}>
            <TextField
              id={field.id ? field.id : "outlined-size-small"}
              variant={field.variant ? field.variant : ""}
              size="small"
              fullWidth
              type="date"
              label={field.label}
              value={formik.values[field.name]}
              onChange={(event) =>
                formik.setFieldValue(field.name, event.target.value)
              }
              onBlur={formik.handleBlur(field.name)}
              error={formik.touched[field.name] && !!formik.errors[field.name]}
              helperText={
                formik.touched[field.name] && formik.errors[field.name]
              }
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                readOnly: field.disabled ? field.disabled : false,
              }}
            />
          </Grid>
        );

      case "text":
        return (
          <Grid item xs={12} sm={4} key={field.name}>
            <TextField
              id={field.id ? field.id : "outlined-size-small"}
              variant={field.variant ? field.variant : "outlined"}
              size="small"
              fullWidth
              type="text"
              label={field.label}
              value={formik.values[field.name]}
              onChange={(event) =>
                formik.setFieldValue(field.name, event.target.value)
              }
              onBlur={formik.handleBlur(field.name)}
              error={formik.touched[field.name] && !!formik.errors[field.name]}
              helperText={
                formik.touched[field.name] && formik.errors[field.name]
              }
              InputLabelProps={
                field.disabled
                  ? { shrink: field.disabled ? field.disabled : false }
                  : formik.values[field.name]
                  ? { shrink: true }
                  : { shrink: false }
              }
              InputProps={
                field.disabled
                  ? { readOnly: field.disabled ? field.disabled : false }
                  : ""
              }
            />
          </Grid>
        );

      case "number":
        return (
          <Grid item xs={12} sm={4} key={field.name}>
            <TextField
              id={field.id ? field.id : "outlined-size-small"}
              variant={field.variant ? field.variant : "outlined"}
              size="small"
              fullWidth
              type="number"
              label={field.label}
              value={formik.values[field.name]}
              onChange={(event) => {
                const value = event.target.value;
                if (
                  value === "" ||
                  (Number(value) >= 0 && /^\d*\.?\d*$/.test(value))
                ) {
                  formik.setFieldValue(field.name, value);
                }
              }}
              onBlur={formik.handleBlur(field.name)}
              error={formik.touched[field.name] && !!formik.errors[field.name]}
              helperText={
                formik.touched[field.name] && formik.errors[field.name]
              }
              InputLabelProps={
                field.disabled
                  ? { shrink: field.disabled ? field.disabled : false }
                  : formik.values[field.name]
                  ? { shrink: true }
                  : { shrink: false }
              }
              InputProps={
                field.disabled
                  ? { readOnly: field.disabled ? field.disabled : false }
                  : ""
              }
            />
          </Grid>
        );

      case "select":
        return (
          <Grid item xs={12} sm={4} key={field.name}>
            <Select
              styles={customStyles}
              label={field.label}
              options={field.options}
              value={
                field &&
                field.options.find(
                  (option) => option.value === formik.values[field.name]
                )
              }
              onChange={(selectedOption) =>
                formik.setFieldValue(field.name, selectedOption.value)
              }
              onBlur={formik.handleBlur(field.name)}
              isSearchable={false}
              placeholder={field.placeholder}
            />
          </Grid>
        );

      case "radio":
        return (
          <Grid item xs={12} sm={4} key={field.name}>
            <FormControl component="fieldset">
              <FormLabel component="legend">{field.label}</FormLabel>

              <RadioGroup
                row
                aria-label={field.name}
                name={field.name}
                value={formik.values[field.name]}
                onChange={(event) =>
                  formik.setFieldValue(field.name, event.target.value)
                }
              >
                {field.options.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={<Radio />}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        );

      default:
        return null;
    }
  };

  const handleReset = () => {
    formik.resetForm();

    setActionName({ action_name: "Add" });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        {fields.map((field) => renderField(field))}

        <Grid item container justifyContent="flex-end">
          <button
            type="submit"
            className="btn btn-primary btn-sm m-2"
            disabled={formik.isSubmitting}
          >
            Submit
          </button>

          <button
            type="reset"
            className="btn btn-primary btn-sm m-2"
            onClick={handleReset}
          >
            Reset
          </button>
        </Grid>
      </Grid>
    </form>
  );
};

export default DyanmicTabForm;
