import React, { useState } from "react";
import BasicTabs from "./BasicTabs";
import "../CrmServices/Style.css";

function Cardpopup(props) {
  const closeModal = () => {
    const modalId = `cardtoggel${props.data.sno}`;
    const disableMOdal = document.getElementById(modalId);

    if (disableMOdal) {
      disableMOdal.classList.remove("show");
      disableMOdal.style.display = "none";
      disableMOdal.removeAttribute("aria-modal");
      disableMOdal.removeAttribute("role");
      disableMOdal.setAttribute("aria-hidden", "true");

      const disableModal1 = document.querySelector(".modal-backdrop.show");
      disableModal1?.remove();

      const disableModal2 = document.getElementsByClassName("modal-open");

      if (disableModal2.length > 0) {
        const firstElement = disableModal2[0];

        firstElement.removeAttribute("class");
        firstElement.removeAttribute("style");
      }
    } else {
      console.error(`Modal element with ID ${modalId} not found.`);
    }
  };
  return (
    <div
      className="modal"
      data-bs-backdrop="static"
      id={`cardtoggel${props.data.sno}`}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalTitleNotify"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered ml-1" role="document">
        <div className="modal-content " style={{ marginTop: "250px" }}>
          <div className="modal-header">
            <BasicTabs
              onClose={closeModal}
              subscript={{
                subscript: props.data.subscript,
                script: props.data.script_temp_body,
                name: props.data.script_name,
              }}
              customer={props.customer}
              besicInfo={{ ...props.data, ...props.camp, ...props.customer }}
              campinfo={props.campinfo}
              setLBagent={props.setLBagent}
              setTLId={props.setTLId}
              setsubDispositon={props.setsubDispositon}
            />
            <button
              id="closepopup"
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
            ></button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cardpopup;
