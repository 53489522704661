import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { styled } from "@mui/system";
import OtherCustomerGrid from "./OtherCustomerGrid";
import { useSelector } from "react-redux";
import DaynmicApicall, { ManageEventLog } from "../../../utils/function";
import "./tabStyle.css";

const StyledTabs = styled(Tabs)({
  backgroundColor: "#ffffff",
  display: "flex",
  justifyContent: "flex-start",
  marginBottom: "1rem",
  minHeight: "36px",
});

const StyledTab = styled(Tab)({
  textTransform: "none",
  fontSize: "10px",
  fontWeight: "bold",
  color: "#181b28",
  minHeight: "36px",
  padding: "6px 12px",
  "&.Mui-selected": {
    color: "#ffffff",
    backgroundColor: "#181b28",
    borderRadius: "20px",
  },
  "&.MuiTabs-indicator": {
    display: "none",
  },
});

const DynamicTab = ({ campinfo }) => {
  let { customerInfo } = useSelector((state) => state?.dialer);
  const [value, setValue] = useState(0);
  const { userInfo } = useSelector((state) => state?.user?.value);
  const { UserToken } = userInfo.data[0];
  const [getcrmtabcomp, setCrmtabcomp] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  async function fn_crmtabcomp_data() {
    try {
      const data = await DaynmicApicall(
        `mcrmdlr/crmtabcomp/${campinfo?.keypointer}`,
        "",
        "get",
        UserToken
      );
      setCrmtabcomp(data);
    } catch (error) {
      console.log("ERROR", error.message);
    }
  }

  useEffect(() => {
    fn_crmtabcomp_data();
  }, []);

  const handleTabClick = async (tab) => {
    let actionlog = {
      userid: userInfo.data[0].userid,
      custid: customerInfo?.cuid,
      campid: campinfo?.campid,
      createdby: userInfo.data[0].userid,
      eventname: "OnClick",
      actionname: `${tab.menu_name} TAB Called`,
      actioncate: "TABS",
      action_name: "INSERT",
      resp_code: null,
      sub_resp_code: null,
    };
    await ManageEventLog(actionlog);
  };

  return (
    <div>
      <StyledTabs
        value={value}
        onChange={handleChange}
        centered
        TabIndicatorProps={{ style: { display: "none" } }}
        className="tabStyling"
      >
        {getcrmtabcomp &&
          getcrmtabcomp.map((tab, index) => (
            <StyledTab
              key={index}
              label={tab.menu_name}
              style={{ padding: "10px", marginRight: "5px" }}
              onClick={() => handleTabClick(tab)}
            />
          ))}
      </StyledTabs>
      {getcrmtabcomp &&
        getcrmtabcomp.map((tab, index) => {
          return (
            <TabPanel key={index} value={value} index={index}>
              {tab}
            </TabPanel>
          );
        })}
    </div>
  );
};

const TabPanel = ({ children, value, index }) => {
  return (
    <div hidden={value !== index}>
      {children.camp_id && children.comp_id ? (
        <OtherCustomerGrid
          keypointer={children?.camp_id}
          componentid={children?.comp_id}
          rest={children}
        />
      ) : (
        <div className="card card-body border-light shadow mb-4">
          <div className="py-4 text-center">
            No such form contact to your team lead
          </div>
        </div>
      )}
    </div>
  );
};

export default DynamicTab;
