import React, { useState, useEffect } from "react";
import { Form, Formik } from "formik";
import PageHeader from "../../components/PageHeader";
import DaynmicApicall from "../../utils/function";
import { TextField } from "../../components/formValidation/TextField";
import { MultiSelect } from "../../components/formValidation/MultiSelect";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import CustomerTab from "../../components/CrmServices/CustomerData/CustomerTab";
import axios from "axios";
import { BASE_URL } from "../../utils/constent";

export default function ManageCustomerData(props) {
  let allCamps = [];
  const { userInfo } = useSelector((state) => state?.user?.value);
  const { campdetail } = useSelector((state) => state.campaign);
  const [pageData, setpageData] = useState("");
  const [searchField, setSearchField] = useState("");
  const [initialTableData, setInitialTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    await pageInfo();
  }, []);

  if (campdetail && campdetail.length > 0) {
    allCamps = campdetail.map((item) => {
      return {
        value: item.campid,
        label: item.campname,
        keypointer: item.keypointer,
      };
    });
  }

  async function pageInfo() {
    await DaynmicApicall(
      `/appuser/getcomponetbyid/${props.activemenu.keypointer}/155`,
      "",
      "get",
      userInfo.data[0].UserToken
    ).then((data) => {
      setpageData(data[0]?.DATA);
    });
  }

  let initial = {
    history: "",
    camp_id: "",
    filter: "",
    textvalue: "",
  };

  const validate = Yup.object({
    history: Yup.object().required("History Type is required"),
    camp_id: Yup.object().required("Campaign Name is required"),
    filter: Yup.object().required("Filter is required"),
    textvalue: Yup.string().required("This Field is required"),
  });

  const onSearch = async (values, { resetForm }) => {
    setLoading(true);
    let payload;
    setInitialTableData([]);
    setSearchField(values);
    if (values?.history?.value === "recordinghistory") {
      payload = {
        key: values?.filter?.value,
        value: values?.textvalue,
        camp_id: values?.camp_id?.value,
        view: values?.history?.view,
      };
    } else if (values?.history?.value === "vochistory") {
      payload = {
        key: values?.filter?.value,
        value: values?.textvalue,
        keypointer: values?.camp_id?.keypointer,
        view: values?.history?.view,
        search: "VOC",
      };
    } else {
      payload = {
        key: values?.filter?.value,
        value: values?.textvalue,
        keypointer: values?.camp_id?.keypointer,
        view: values?.history?.view,
      };
    }

    try {
      const response = await axios.post(
        `${BASE_URL}mcrmdlr/getCustomerCallHistory`,
        payload,
        {
          headers: {
            Authorization: `${userInfo.data[0].UserToken}`,
          },
        }
      );
      setInitialTableData(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      {pageData && (
        <>
          <PageHeader heading={pageData.mainHeader} />
          <div className="card pl-2 pr-2">
            <Formik
              initialValues={initial}
              validationSchema={validate}
              onSubmit={onSearch}
            >
              {(formik) => (
                <Form>
                  <div className="row mt-3">
                    <MultiSelect
                      label={pageData.form.history.label}
                      value={formik.values.history}
                      name={pageData.form.history.name}
                      isMulti={false}
                      formik={formik}
                      options={pageData.form.history.options}
                      onChange={(value) =>
                        formik.setFieldValue("history", value)
                      }
                    />
                    <MultiSelect
                      label={pageData.form.campaign.label}
                      value={formik.values.camp_id}
                      name={pageData.form.campaign.name}
                      isMulti={false}
                      formik={formik}
                      options={allCamps}
                      onChange={(value) =>
                        formik.setFieldValue("camp_id", value)
                      }
                    />
                    <MultiSelect
                      label={pageData.form.filter.label}
                      value={formik.values.filter}
                      name={pageData.form.filter.name}
                      isMulti={false}
                      formik={formik}
                      options={formik.values.history.option}
                      onChange={(value) =>
                        formik.setFieldValue("filter", value)
                      }
                    />
                    <div
                      style={{
                        textAlign: "-webkit-right",
                        marginTop: "-40px",
                      }}
                    >
                      {formik.values.filter && (
                        <TextField
                          data-toggle="tooltip"
                          type="text"
                          name={pageData.form.textfield.name}
                          placeholder={pageData.form.textfield.placeholder}
                          format={true}
                        />
                      )}
                    </div>
                  </div>

                  <div className="d-flex justify-content-end w-100">
                    <button
                      type="submit"
                      className="btn btn-primary btn-sm m-2"
                    >
                      {pageData.form.button.label1}
                    </button>

                    <button type="reset" className="btn btn-primary btn-sm m-2">
                      {pageData.form.button.label2}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
            {searchField?.history?.label && (
              <div className="table-settings mb-4 mt-1">
                <div className="my-2">
                  <div className="card card-body border-light shadow">
                    {loading ? (
                      <div
                        style={{ textAlignLast: "center", color: "#1acc61" }}
                      >
                        <span>Fetching Data...</span>
                      </div>
                    ) : (
                      <CustomerTab
                        SearchField={searchField}
                        InitialTableData={initialTableData}
                        ActiveMenu={props?.activemenu?.keypointer}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}
