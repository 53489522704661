import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import CustomerCallHistory from "./CustomerCallHistory";
import CutomerCallRecording from "./CutomerCallRecording";
import VOCHistory from "./VOCHsitory";
import MemberHistory from "./MemberHistory";
import PolicyCoverHistory from "./PolicyCoverHistory";

function CustomerTab(props) {
  const { SearchField, InitialTableData, ActiveMenu } = props;
  let selectedComponent;

  switch (SearchField.history.value) {
    case "callhistory":
      selectedComponent = (
        <CustomerCallHistory
          SearchField={SearchField}
          InitialTableData={InitialTableData}
        />
      );
      break;
    case "recordinghistory":
      selectedComponent = (
        <CutomerCallRecording
          SearchField={SearchField}
          InitialTableData={InitialTableData}
          ActiveMenu={ActiveMenu}
        />
      );
      break;
    case "vochistory":
      selectedComponent = (
        <VOCHistory
          SearchField={SearchField}
          InitialTableData={InitialTableData}
        />
      );
      break;
    case "memberhistory":
      selectedComponent = (
        <MemberHistory
          SearchField={SearchField}
          InitialTableData={InitialTableData}
        />
      );
      break;
    case "policycoverhistory":
      selectedComponent = (
        <PolicyCoverHistory
          SearchField={SearchField}
          InitialTableData={InitialTableData}
        />
      );
      break;
    default:
      selectedComponent = <div>No Selected</div>;
  }
  return (
    <Tabs defaultActiveKey="home" className="mb-1 mt-0" tabSize="5">
      <Tab eventKey="home" title={SearchField.history.label}>
        {selectedComponent}
      </Tab>
    </Tabs>
  );
}

export default CustomerTab;
