import { React, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./Style.css";
import DaynmicApicall from "../../utils/function";
import { Form, Formik } from "formik";
import { TextField } from "../../components/formValidation/TextField";
import { MultiSelect } from "../../components/formValidation/MultiSelect";
import PageHeader from "../../components/PageHeader";
import * as Yup from "yup";
import PolicyInfoDND from "./policyInfo";

export default function Managecomplain(props) {
  let { userInfo } = useSelector((state) => state?.user?.value);
  const [campList, setCampList] = useState([]);
  const [campKey, setCampKey] = useState("");
  const [campname, setcampname] = useState("");
  const [pageData, setpageData] = useState("");
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);

  useEffect(() => {
    const fetchCampaigns = async () => {
      await pageInfo();
      await getCampaign();
    };

    fetchCampaigns();
  }, []);

  async function pageInfo() {
    await DaynmicApicall(
      `/appuser/getcomponetbyid/${props.activemenu.keypointer}/183`,
      "",
      "get",
      userInfo.data[0].UserToken
    )
      .then((data) => {
        setpageData(data[0]?.DATA);
      })
      .catch((err) => {
        console.log("ERROR", err);
      });
  }

  async function getCampaign() {
    await DaynmicApicall(
      `appuser/getcamp/ALL`,
      "",
      "get",
      userInfo.data[0].UserToken
    ).then((res) => {
      let uniqueCamp = [
        ...new Map(res?.map((item) => [item["campname"], item])).values(),
      ];
      let camp = uniqueCamp.map((item) => ({
        value: item.campname,
        label: item.campname,
        ...item,
      }));
      setCampList(camp);
    });
  }

  const validate = Yup.object({
    camp_id: Yup.object().required("Campaign Name is required"),
    custId: Yup.string().required("Cust Id is required"),
    phonenumber: Yup.string().required("Phone Number is required"),
  });

  const onSearch = async (values, { resetForm }) => {
    try {
      setLoading(true);
      setHasSearched(true);
      const payload = {
        custid: values?.custId,
        phonenumber: values?.phonenumber,
        keypointer: campKey,
      };
      await DaynmicApicall(
        `/mcrmdlr/getcustomerdnd`,
        payload,
        "post",
        userInfo.data[0].UserToken
      )
        .then((data) => {
          setcampname(values?.camp_id?.campname);
          setTableData(data?.data);
        })
        .catch((err) => {
          setTableData([]);
          console.log("ERROR", err);
        });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error", error);
    }
  };

  return (
    <>
      {pageData && (
        <>
          <PageHeader heading={pageData.mainHeader} />
          <div className="card pl-2 pr-2 mb-2">
            <Formik
              initialValues={{
                camp_id: "",
                custId: "",
                phonenumber: "",
              }}
              validationSchema={validate}
              onSubmit={onSearch}
            >
              {(formik) => (
                <Form>
                  <div className="row mt-3">
                    <MultiSelect
                      label={pageData?.form?.campaign.label}
                      value={formik?.values?.camp_id}
                      name={pageData?.form?.campaign?.name}
                      isMulti={false}
                      formik={formik}
                      options={campList}
                      onChange={(value) => {
                        formik.setFieldValue("camp_id", value);
                        setCampKey(value?.keypointer);
                      }}
                    />

                    <TextField
                      title={pageData.form.custId.title}
                      label={pageData.form.custId.label}
                      type="text"
                      name={pageData.form.custId.name}
                      placeholder={pageData.form.custId.placeholder}
                    />

                    <TextField
                      title={pageData.form.phonenumber.title}
                      label={pageData.form.phonenumber.label}
                      type="text"
                      name={pageData.form.phonenumber.name}
                      placeholder={pageData.form.phonenumber.placeholder}
                    />
                  </div>

                  <div className="d-flex justify-content-end w-100">
                    <button
                      type="submit"
                      className="btn btn-primary btn-sm m-2"
                    >
                      {pageData.form.button.label1}
                    </button>

                    <button type="reset" className="btn btn-primary btn-sm m-2">
                      {pageData.form.button.label2}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>

          {loading ? (
            <div style={{ textAlign: "center", color: "#1acc61" }}>
              <span>Fetching Data...</span>
            </div>
          ) : tableData?.length > 0 && !loading ? (
            <div className="container-fluid">
              {/* Data section */}
              <div
                className="row bg-dark mt-2 font-weight-bold rounded sticky-navbar"
                style={{ color: "white" }}
              >
                <span className="mt-3 col-xs-12 col-sm-5 col-md-4 col-lg-2">
                  {campname}
                </span>
                <span
                  id="wlcmtext1"
                  className="mt-3 col-xs-12 col-sm-7 col-md-8 col-lg-5"
                >
                  Welcome {pageData?.userName}
                  <span className="text-success ml-1">
                    ({pageData?.userId})
                  </span>
                </span>
                <span
                  id="wlcmtext"
                  className="mt-3 col-xs-12 col-sm-10 col-md-10 col-lg-4"
                >
                  {new Date().toLocaleDateString(undefined, {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </span>
                <div
                  id="profileimg"
                  className="mt-2 col-xs-12 col-sm-2 col-md-2 col-lg-1 col-xl-1"
                >
                  <div className="media d-flex align-items-center">
                    <img
                      className="user-avatar md-avatar rounded-circle"
                      alt="placeholder"
                      src="https://cdnlib.a10s.in/cdndata/user.png"
                    />
                  </div>
                </div>
              </div>

              {/* Scrollable content */}
              <div className="scrollable-content">
                <div className="row">
                  <div className="col-sm-6 col-md-12 col-lg-12">
                    <div className="row">
                      <div>
                        <div className="table-settings mb-4 mt-2">
                          <div className="modal-header">
                            <p className="modal-title" id="modalTitleNotify">
                              {pageData?.crm?.policybutton?.header}
                            </p>
                          </div>
                          <PolicyInfoDND tableData={tableData} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : hasSearched && !loading && tableData?.length === 0 ? (
            <div style={{ textAlign: "center", color: "red" }}>
              <span>No Data Found !!!!!</span>
            </div>
          ) : null}
        </>
      )}
    </>
  );
}
