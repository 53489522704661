import React, { useState, useCallback } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
  gridClasses,
} from "@mui/x-data-grid";
import { Stack } from "@mui/material";

export default function MemberHistory({ InitialTableData }) {
  const [state, setState] = useState({
    filterValue: "",
    filterColumn: "",
    pageSize: 10,
    open: false,
    customerInfo: "",
  });

  const handleClickOpen = () => {
    setState((prevState) => ({ ...prevState, open: true }));
  };

  const handleClose = () => {
    setState((prevState) => ({ ...prevState, open: false }));
  };

  const handlePageSizeChange = (newPageSize) => {
    setState((prevState) => ({ ...prevState, pageSize: newPageSize }));
  };

  const onFilterChange = useCallback((filterModel) => {
    if (
      filterModel?.items?.[0]?.value &&
      filterModel?.items?.[0]?.value.length > 0
    ) {
      setState((prevState) => ({
        ...prevState,
        filterColumn: filterModel.items[0].columnField,
        filterValue: filterModel.items[0].value,
      }));
    }
  }, []);

  const CustomToolbar = () => (
    <Stack direction="row" justifyContent="flex-end">
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport />
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    </Stack>
  );

  const columns = [
    {
      field: "customerid",
      headerName: "Customer Id",
      minWidth: 100,
      flex: 1,
      valueGetter: (params) => params.row.customerid,
    },
    {
      field: "policy_no",
      headerName: "Policy Number",
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => params.row.policy_no,
    },
    {
      field: "member_code",
      headerName: "Member Code",
      minWidth: 120,
      flex: 1,
      valueGetter: (params) => params.row.member_code,
    },
    {
      field: "member_name",
      headerName: "Member Name",
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => params.row.member_name,
    },
    {
      field: "member_dob",
      headerName: "Member DOB",
      minWidth: 130,
      flex: 1,
      valueGetter: (params) =>
        params?.row?.member_dob?.split("T").length > 0
          ? params?.row?.member_dob?.split("T")[0]
          : params?.row?.member_dob,
    },
    {
      field: "gender",
      headerName: "Gender",
      minWidth: 80,
      flex: 1,
      valueGetter: (params) => params.row.gender,
    },
    {
      field: "sessionid",
      headerName: "sessionid",
      minWidth: 80,
      flex: 1,
      hide: true,
      valueGetter: (params) => params.row.sessionid,
    },
  ];

  return (
    <>
      {InitialTableData?.length === 0 ? (
        <div style={{ textAlign: "center" }}>No data found</div>
      ) : (
        <DataGrid
          getRowId={(r) => r.sessionid}
          rows={InitialTableData}
          columns={columns}
          pageSize={state.pageSize}
          onPageSizeChange={handlePageSizeChange}
          rowsPerPageOptions={[10, 25, 50, 100]}
          onFilterModelChange={onFilterChange}
          autoHeight={true}
          className="bg-white"
          components={{
            Toolbar: CustomToolbar,
          }}
          density="compact"
        />
      )}
    </>
  );
}
