import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Form, Formik } from "formik";
import { MultiSelect } from "../../components/formValidation/MultiSelect";
import * as Yup from "yup";
import api from "../../utils/api";
import toast from "react-hot-toast";
import DaynmicApicall from "../../utils/function";

export default function BulkDataUploadCSV(props) {
  const [excelurl, setexcelurl] = useState([]);
  const [success, setSuccess] = useState("");
  const [failedInserts, setFailedInserts] = useState([]);
  const [failedUpdates, setFailedUpdates] = useState([]);
  const [updatedCount, setUpdatedCount] = useState(0);
  const [insertedCount, setInsertedCount] = useState(0);
  const [recordCount, setRecordCount] = useState(0);
  const [dataUploadCamp, setDataUploadCamp] = useState("");

  const { userInfo } = useSelector((state) => state?.user?.value);
  const { campdetail } = useSelector((state) => state.campaign);

  let allCamps = [];
  if (campdetail && campdetail.length > 0) {
    allCamps = campdetail.map((item) => {
      return {
        value: item.campid,
        label: item.campname,
        keypointer: item.keypointer,
      };
    });
  }

  async function getsampleexcelpath() {
    await DaynmicApicall(
      `prm/getParamdata/ALL/EXCEL-URL/${props.activemenu?.keypointer}`,
      "",
      "get",
      userInfo.data[0].UserToken
    )
      .then(async (res) => {
        let bData = [];
        bData = await res.map((item) => {
          return {
            value: item.paravalue,
            label: item.paracode,
          };
        });
        setexcelurl(bData);
      })
      .catch((error) => {
        toast.error(
          error.response.message ??
            error.message ??
            "OOPs, something went wrong."
        );
      });
  }

  // useEffect(() => {
  //   getsampleexcelpath();
  // }, []);

  const validate = Yup.object().shape({
    CAMP_ID: Yup.object().required("Camp Id is required"),
    file: Yup.mixed().required("File is required"),
  });

  const initialValues = {
    file: "",
    CAMP_ID: "",
  };

  const dataupload = async (values) => {
    const data = new FormData();
    data.append("file", values.file);
    data.append("CAMP_ID", values.CAMP_ID.value);
    data.append("keypointer", props.activemenu.keypointer);

    try {
      const response = await api.post(`/managedata/dynamicdataupload`, data);
      const result = response.data;
      setSuccess(result.message);
      setFailedInserts(result.failedInserts);
      setFailedUpdates(result.failedUpdates);
      setUpdatedCount(result.updatedCount);
      setInsertedCount(result.insertedCount);
      setRecordCount(result.recordCount);

      toast.success(result.message);
    } catch (error) {
      toast.error(
        error?.response?.data?.errors ??
          error?.response?.data?.message ??
          error?.message ??
          "OOPs something went wrong."
      );
    }
  };

  const downloadSampleFile = async () => {
    try {
      if (!dataUploadCamp) toast.error("Please select Campigin");
      const response = await api.get(
        `managedata/download-excel/${dataUploadCamp.keypointer}`,
        {
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "sample-data.xlsx"); // Set the file name
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      toast.error(
        error?.response?.data?.errors ??
          error?.response?.data?.message ??
          error?.message ??
          "OOPs something went wrong while downloading the file."
      );
    }
  };

  return (
    <>
      <div>
        <div className="container-fluid py-4 bg">
          <div className="row">
            <div className="card col-xl-12 bg-dark">
              <div className="row row-cols-md-2 m-2 p-2 row-cols-lg-2 row-cols-xl-2 font-weight-bold">
                <span className="text-left text-light">
                  Manage Data Upload (CSV/Excel)
                </span>
              </div>
            </div>
          </div>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validate}
          onSubmit={dataupload}
        >
          {(formik) => {
            const imageChange = (e) => {
              const file = e.target.files[0];
              formik.setFieldValue("file", file);
            };

            return (
              <Form>
                <div className="card">
                  <div className="row mt-1">
                    <div className="col-7">
                      <MultiSelect
                        label="Select Campaign"
                        name="CAMP_ID"
                        options={allCamps}
                        isMulti={false}
                        onChange={(value) => {
                          formik.setFieldValue("CAMP_ID", value);
                          setDataUploadCamp(value);
                        }}
                      />
                    </div>
                    <div
                      className="col-1 mt-4 mr-4"
                      data-toggle="tooltip"
                      title="Please Choose file only csv format"
                    >
                      <input
                        style={{
                          marginLeft: "-300px",
                          height: "42px",
                          width: "270px",
                        }}
                        type="file"
                        onChange={imageChange}
                        id="myDropify"
                        className="border"
                      />
                    </div>
                    <div
                      className="col-1 mt-4"
                      style={{ marginLeft: "-100px" }}
                      data-toggle="tooltip"
                      title="Data Upload"
                    >
                      <button className="btn-sm btn-primary p-1" type="submit">
                        Upload
                      </button>
                    </div>
                    <div
                      className="col-1 mt-4"
                      style={{ marginLeft: "-20px" }}
                      data-toggle="tooltip"
                      title="Please Download sample data file"
                    >
                      <button
                        className="dropdown-item"
                        style={{ width: "200px" }}
                        type="button"
                        onClick={downloadSampleFile}
                      >
                        Sample file download
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
        {success && (
          <div className="card mt-4 p-4 border-primary shadow-lg">
            <div className="card-body">
              <h5 className="card-title text-success">
                <strong>Success!</strong>
              </h5>
              <p className="card-text">
                <strong>Message:</strong> {success}
              </p>
              <hr className="my-3" />
              <div className="mb-3">
                <h6 className="text-dark">
                  <strong>Total Record:</strong> {recordCount}
                </h6>
              </div>
              <div className="mb-3">
                <h6 className="text-info">
                  <strong>Updated Records:</strong> {updatedCount}
                </h6>
              </div>
              <div className="mb-3">
                <h6 className="text-success">
                  <strong>Inserted Records:</strong> {insertedCount}
                </h6>
              </div>
              <hr className="my-3" />
              <div className="mb-3">
                <h6 className="text-danger">
                  <strong>Failed Inserts: {failedInserts.length}</strong>
                </h6>
                {failedInserts.length > 0 ? (
                  <ul className="list-unstyled">
                    {failedInserts.map((item, index) => (
                      <li key={index} className="text-danger">
                        {item.reason}{" "}
                        <span className="text-dark">
                          (LOAN_NUMBER: {item.LOAN_NUMBER})
                        </span>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="text-muted">No failed inserts.</p>
                )}
              </div>
              <div>
                <h6 className="text-danger">
                  <strong>Failed Updates: {failedUpdates.length}</strong>
                </h6>
                {failedUpdates.length > 0 ? (
                  <ul className="list-unstyled">
                    {failedUpdates.map((item, index) => (
                      <li key={index} className="text-danger">
                        {item.reason}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="text-muted">No failed updates.</p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
