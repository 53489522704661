import React from "react";
import { useSelector } from "react-redux";
const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "70vh",
    backgroundColor: "#f0f2f5",
    fontFamily: "Arial, sans-serif",
  },
  welcomeContainer: {
    textAlign: "center",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
  },
  welcomeTitle: {
    fontSize: "2.5rem",
    color: "#333",
  },
  welcomeMessage: {
    fontSize: "1.2rem",
    color: "#666",
  },
};

function GuestDashboard() {
  const { userInfo } = useSelector((state) => state?.user?.value);

  return (
    <>
      <div>
        {/* {rowdata && <ManageServerConfig rowdata={rowdata} />} */}
        <div className="container-fluid py-4">
          <div className="row">
            <div className="card col-xl-12 bg-dark text-white">
              <div className="row row-cols-md-2 m-2 p-2 row-cols-lg-2 row-cols-xl-2 font-weight-bold">
                <span
                  className="text-left"
                  title="Hello Admin"
                  data-toggle="tooltip"
                >
                  Hello, {userInfo.data[0].username}
                  <span className="text-success">
                    ({userInfo.data[0].userid})
                  </span>
                </span>
                <span
                  className="text-right"
                  title="Current Show Day and Date"
                  data-toggle="tooltip"
                >
                  {new Date().toLocaleDateString(undefined, {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-1">
          <div style={styles.container}>
            <div style={styles.welcomeContainer}>
              <h1 style={styles.welcomeTitle}>Welcome to Guest Profile</h1>
              <p style={styles.welcomeMessage}>
                We are glad to have you here! Explore our features and enjoy
                your stay.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GuestDashboard;
