import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import DaynmicApicall from "../../../utils/function";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { Stack } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import TableDetails from "./TableDetails";

export default function AgelntIneraction(props) {
  const { userInfo } = useSelector((state) => state?.user?.value);
  const { userData } = props;
  const [tableData, setTableData] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [filterColumn, setFilterColumn] = useState("");
  const [pageSize, setPageSize] = useState(5);
  async function AgentIneractionData() {
    await DaynmicApicall(
      `err/getactionlog/${userData.data.session_id}/${userData.data.campinfo.keypointer}`,
      "",
      "get",
      userInfo.data[0].UserToken
    ).then((data) => {
      const modifiedData = data.map((row, index) => ({
        ...row,
        sno: index + 1,
      }));
      setTableData(modifiedData);
    });
  }

  const columns = [
    {
      field: "sno",
      headerName: "SNO",
      minWidth: 20,
      flex: 0.5,
    },
    {
      field: "resp_code",
      headerName: "Response Code",
      minWidth: 130,
      flex: 1,
    },
    {
      field: "sub_resp_code",
      headerName: "Sub-Response Code",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "created_on",
      headerName: "Entry Date",
      minWidth: 150,
      flex: 1,
    },
  ];

  const onFilterChange = React.useCallback(async (filterModel) => {
    if (
      filterModel?.items?.[0]?.value &&
      filterModel?.items?.[0]?.value.length > 0
    ) {
      setFilterColumn(filterModel?.items?.[0]?.columnField);
      setFilterValue(filterModel?.items?.[0]?.value);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await AgentIneractionData();
    })();
  }, []);

  function generateRandom() {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  return (
    <>
      <div className="table-settings mb-4">
        <div className="my-2">
          <DataGrid
            getRowId={(row) => generateRandom()}
            rows={tableData}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 15]}
            onFilterModelChange={onFilterChange}
            autoHeight={true}
            className="bg-white"
            density="compact"
          />
        </div>
      </div>
    </>
  );
}
