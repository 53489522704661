import React from "react";
import { useState } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  gridClasses,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import TableDetails from "../EngageHistory/TableDetails";
import PolicyInfoModel from "../PolicyInfo/PolicyInfoModel";
import { Button } from "react-bootstrap";

export default function CustomerCallHistory(props) {
  const { InitialTableData, SearchField } = props;
  const [filterValue, setFilterValue] = useState("");
  const [filterColumn, setFilterColumn] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [open, setOpen] = useState(false);
  const [customerInfo, setcustomerInfo] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    {
      field: "contactdate",
      headerName: "Contact Date",
      minWidth: 130,
      flex: 1,
    },
    {
      field: "callid",
      headerName: "Call Id",
      minWidth: 90,
      flex: 1,
    },
    { field: "contactby", headerName: "Contact By", minWidth: 110, flex: 1 },
    {
      field: "customerid",
      headerName: "Customer Id",
      minWidth: 150,
      flex: 1,
      headerClassName: "table-header",
      renderCell: (params) => {
        const object = {
          data: {
            custinfo: {
              cuid: `${params?.row?.campid}${params?.row?.customerid}`,
            },
            campinfo: { keypointer: SearchField?.camp_id?.keypointer },
          },
        };
        return (
          <span
            style={{
              color: "#1976d2",
              cursor: "pointer",
            }}
            onClick={async (e) => {
              await setcustomerInfo(object);
              await handleClickOpen();
            }}
          >
            {params?.row?.customerid}
          </span>
        );
      },
    },
    {
      field: "customer_name",
      headerName: "Customer Name",
      minWidth: 150,
      flex: 1,
      disableExport: true,
    },
    {
      field: "responsecode",
      headerName: "Response Code",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "component_data",
      headerName: "Show Details",
      minWidth: 150,
      flex: 1,
      headerClassName: "table-header",
      renderCell: (params) => {
        const value =
          params.value && params.value[0] && params.value[0].response_component;
        let content;
        let ptpdate, ptpAmount, remarks, sessionid, surveyData;

        switch (value && value.resp_code) {
          case "PTP":
            ({ ptpdate, ptpAmount, remarks, sessionid } = value || {});
            const selectedData = { ptpdate, ptpAmount, remarks, sessionid };
            content = (
              <Tooltip
                title={<TableDetails data={selectedData} category="PTP" />}
                color="inherit"
                placement="left-start"
                arrow
                style={{ backgroundColor: "#ffffff", maxWidth: "300px" }}
              >
                <span
                  style={{
                    color: "#1976d2",
                    cursor: "pointer",
                  }}
                >
                  Show PTP Details
                </span>
              </Tooltip>
            );
            break;
          case "PTR-UPSELL":
            ({ ptpdate, ptpAmount, remarks, sessionid } = value || {});
            const selectedPTRData = { ptpdate, ptpAmount, remarks, sessionid };
            content = (
              <Tooltip
                title={<TableDetails data={selectedPTRData} category="PTR" />}
                color="inherit"
                placement="left-start"
                arrow
                style={{ backgroundColor: "#ffffff", maxWidth: "300px" }}
              >
                <span
                  style={{
                    color: "#1976d2",
                    cursor: "pointer",
                  }}
                >
                  Show PTR Details
                </span>
              </Tooltip>
            );
            break;
          case "CMI":
            ({ surveyData } = value || []);
            const selectedCMIData = { surveyData };
            content = (
              <Tooltip
                title={<TableDetails data={selectedCMIData} category="CMI" />}
                color="inherit"
                placement="left-start"
                arrow
                style={{
                  backgroundColor: "#ffffff",
                  maxWidth: "1000",
                }}
              >
                <span
                  style={{
                    color: "#1976d2",
                    cursor: "pointer",
                  }}
                >
                  Show CMI Details
                </span>
              </Tooltip>
            );
            break;
          default:
            const remark = value?.remarks ? value?.remarks : [];
            const remarksData = { remark };
            content = (
              <Tooltip
                title={<TableDetails data={remarksData} category="DEFAULT" />}
                color="inherit"
                placement="left-start"
                arrow
                style={{
                  backgroundColor: "#ffffff",
                  maxWidth: "1000",
                }}
              >
                <span
                  style={{
                    color: "#1976d2",
                    cursor: "pointer",
                  }}
                >
                  Show Remarks
                </span>
              </Tooltip>
            );
        }

        return content;
      },
    },
    { field: "revertdate", headerName: "Revert Date", minWidth: 130, flex: 1 },
    { field: "reverttime", headerName: "Revert Time", minWidth: 130, flex: 1 },
    {
      field: "followupdate",
      headerName: "Followup Date ",
      minWidth: 130,
      flex: 1,
    },
    {
      field: "subresponsecode",
      headerName: "Sub Response Code",
      minWidth: 170,
      flex: 1,
    },
    {
      field: "dialer_session_id",
      headerName: "Dialer Session Id",
      minWidth: 150,
      flex: 1,
    },
  ];

  function CustomToolbar() {
    return (
      <Stack direction="row" justifyContent="flex-end">
        <GridToolbarContainer className={gridClasses.toolbarContainer}>
          <GridToolbarFilterButton />
        </GridToolbarContainer>
      </Stack>
    );
  }

  const onFilterChange = React.useCallback(async (filterModel) => {
    if (
      filterModel?.items?.[0]?.value &&
      filterModel?.items?.[0]?.value.length > 0
    ) {
      setFilterColumn(filterModel?.items?.[0]?.columnField);
      setFilterValue(filterModel?.items?.[0]?.value);
    }
  }, []);

  const renderDataGrid = () => {
    if (InitialTableData.length === 0) {
      return <div>No data found</div>;
    }
  };

  return (
    <>
      {InitialTableData.length === 0 ? (
        <div style={{ textAlign: "center" }}>No data found</div>
      ) : (
        <DataGrid
          getRowId={(r) => r.dialer_session_id}
          rows={InitialTableData}
          columns={columns}
          initialState={{
            sorting: {
              sortModel: [{ field: "callid", sort: "desc" }],
            },
          }}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 25, 50, 100]}
          onFilterModelChange={onFilterChange}
          autoHeight={true}
          className="bg-white"
          components={{
            Toolbar: CustomToolbar,
          }}
          density="compact"
        />
      )}
      <Dialog fullWidth={true} maxWidth="lg" open={open} onClose={handleClose}>
        <DialogTitle>Customer Information</DialogTitle>
        <DialogContent>
          <PolicyInfoModel userData={customerInfo} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
