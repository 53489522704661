import React, { useState } from "react";
import api from "../../utils/api";
import { useEffect } from "react";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { TextField } from "../formValidation/TextField";
import { Form, Formik } from "formik";
import { BsPencilSquare } from "react-icons/bs";
import { toast } from "react-hot-toast";
import DaynmicApicall, { ManageEventLog } from "../../utils/function";

export default function UpdateInformation(props) {
  let { userInfo } = useSelector((state) => state?.user?.value);
  let { crmsaveandexit } = useSelector((state) => state?.dialer);
  const [showDatas, setShowDatas] = useState("");
  const [userDatas, setUserDatas] = useState({});
  const [action, setAction] = useState(false);
  const [customerbesicinfo, setCustomerbesicinfo] = useState(false);
  const [pageData, setpageData] = useState("");
  let actionlog = {
    Rowed: "NEW",
    eventname: ``,
    actionname: "",
    custid: props.userInfo?.data?.custinfo?.cust_id,
    campid: props.userInfo?.data?.campinfo?.campid,
    createdby: props.userInfo?.data?.campinfo?.userid,
  };
  async function getcustomer(cuid, campid) {
    await api
      .get(
        `mcrmdlr/getcustdata/${props?.userInfo?.data?.custinfo?.cuid}/${props.userInfo.data.campinfo.campid}/${props?.userInfo?.data?.campinfo?.keypointer}`,
        {
          headers: {
            Authorization: userInfo.data[0].UserToken,
          },
        }
      )
      .then(async (data) => {
        const policy_data =
          data?.data?.data[0]?.policy_no || data?.data?.data[0]?.loan_no;
        actionlog.eventname = `OnClick`;
        actionlog.actionname = "Show Customer Data";
        ManageEventLog(actionlog);
        setShowDatas(data.data.data);
        setCustomerbesicinfo({
          process_id: data?.data?.data[0]?.process_id,
          cust_id: data?.data?.data[0]?.cust_id,
          client_id: data?.data?.data[0]?.client_id
            ? data?.data?.data[0]?.client_id
            : "0",
          policy_no: policy_data,
        });
        await setUserDatas(data?.data?.data[0]);
      })
      .catch((err) => {
        console.log("ERROR", err);
      });
  }

  async function pageInfo(keypointer) {
    await DaynmicApicall(
      `appuser/getcomponetbyid/${props?.userInfo?.data?.campinfo?.keypointer}/40`,
      "",
      "get",
      userInfo.data[0].UserToken
    )
      .then((res) => {
        setpageData(res[0]?.DATA);
      })
      .catch((err) => {
        console.log("ERROR", err);
        setpageData([]);
      });
  }

  useEffect(async () => {
    await getcustomer();
    await pageInfo();
  }, []);

  let initial = {
    sno: userDatas?.sno ?? "0",
    process_id: customerbesicinfo?.process_id,
    cust_id: customerbesicinfo?.cust_id,
    client_id: customerbesicinfo?.client_id,
    policy_no: customerbesicinfo?.policy_no,
    // email: userDatas?.email,
    // phone: userDatas?.phone,
    address_type: userDatas?.address_type,
    address1: userDatas?.address1,
    address2: userDatas?.address2,
    address3: userDatas?.address3,
    address4: userDatas?.address4,
    address5: userDatas?.address5,
    country: userDatas?.country,
    c_state: userDatas?.c_state,
    pin_code: userDatas?.pin_code,
    is_active: action === false ? userDatas?.is_active : "Y",
    created_by: userInfo?.data[0]?.userid,
    sessionid: userDatas?.dialer_session_id,
    action_name: userDatas ? "UPDATE" : "INSERT",
  };

  const validate = Yup.object({
    address_type: Yup.string().required(
      "Address Type is required, Please Mention in Capital Letter"
    ),
    email: Yup.string()
      .matches(
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
        "Email Id is in wrong format"
      )
      .nullable(),
    phone: Yup.string()
      .matches(/^\d{10}$/, "Phone number must have exactly 10 digits")
      .nullable(),
  });

  async function submit(values, { resetForm }) {
    const payload = {
      ...values,
      sessionid: crmsaveandexit?.dialer_session_id,
      policy_no: customerbesicinfo.policy_no,
      client_id: customerbesicinfo.client_id
        ? customerbesicinfo.client_id
        : "0",
      keypointer: props?.userInfo?.data?.campinfo?.keypointer,
    };
    const Info = await DaynmicApicall(
      `mcrmdlr/managemodificustinfo`,
      payload,
      "post",
      userInfo.data[0].UserToken
    );
    toast.success(Info.message);
    getcustomer();
  }

  const maskPhone = (phone) => {
    return phone ? phone.replace(/\d(?=\d{4})/g, "*") : "";
  };

  const maskEmail = (email) => {
    if (!email) return "";
    const [localPart, domain] = email.split("@");
    const maskedLocalPart = localPart.slice(0, 2) + "***";
    return `${maskedLocalPart}@${domain}`;
  };

  return (
    <>
      {pageData && (
        <div>
          <div className="row">
            <div className="row row-cols-md-2  row-cols-lg-2 row-cols-xl-2 font-weight-bold">
              {showDatas &&
                showDatas?.map((data, index) => {
                  return (
                    <div className="col-12 col-sm-2 mt-2" key={index}>
                      {action === false && (
                        <div className="card border-light shadow">
                          <div className="card-body row row-cols-md-2 m-2 p-2 row-cols-lg-2 row-cols-xl-2">
                            <span
                              className="d-flex fw-bold justify-content-start"
                              title={data.address_type}
                              data-toggle="tooltip"
                            >
                              {data.address_type}
                            </span>
                            <span className="d-flex justify-content-end">
                              <BsPencilSquare
                                title="Update information"
                                data-toggle="tooltip"
                                onClick={(e) => {
                                  setAction(action === true ? false : true);
                                  setUserDatas(data);
                                }}
                                size={20}
                              />
                            </span>
                            <ul className="list" style={{ width: "100%" }}>
                              <li
                                className="list-group-item"
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                              >
                                <label>{pageData.text1}</label>: {data.address1}
                              </li>
                              <li
                                className="list-group-item"
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                              >
                                <label>{pageData.text2}</label>: {data.address2}
                              </li>
                              <li
                                className="list-group-item"
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                              >
                                <label>{pageData.text4}</label>:-{data.address4}
                              </li>
                              <li
                                className="list-group-item"
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                              >
                                <label>{pageData.text5}</label>:-{" "}
                                {data.pin_code}
                              </li>
                              <li
                                className="list-group-item"
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                              >
                                <label>{pageData.text8}</label> :-{" "}
                                {userDatas.c_state}
                              </li>
                              <li
                                className="list-group-item w-100"
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                              >
                                <label>{pageData.text9}</label> :-
                                {/* {data.phone} */}
                                {maskPhone(data.phone)}
                              </li>
                              <li
                                className="list-group-item w-100"
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                              >
                                <label>{pageData.text10}</label>:-
                                {/* {data.email} */}
                                {maskEmail(data.email)}
                              </li>
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              {userDatas && (
                <div className="col-12 mt-2 ml-1">
                  <button
                    className="ml-2 btn-sm btn-primary"
                    title={pageData.button.title}
                    data-toggle="tooltip"
                    onClick={(e) => {
                      setAction(true);
                      setUserDatas("");
                    }}
                  >
                    {pageData.button.label}
                  </button>
                </div>
              )}
            </div>
          </div>
          {action === true && (
            <div className="card card-body border-light shadow-sm mb-4">
              <Formik
                initialValues={initial}
                validationSchema={validate}
                onSubmit={submit}
              >
                {(formik) => (
                  <div className="">
                    <Form>
                      <div className="row mt-2">
                        <TextField
                          data-toggle="tooltip"
                          title={pageData.addrestype.title}
                          label={pageData.addrestype.label}
                          placeholder={pageData.addrestype.placeholder}
                          type={pageData.addrestype.type}
                          defaultvalues={showDatas.address_type}
                          name={pageData.addrestype.name}
                        />
                        <TextField
                          data-toggle="tooltip"
                          title={pageData.house.title}
                          label={pageData.house.label}
                          placeholder={pageData.house.placeholder}
                          type={pageData.house.type}
                          name={pageData.house.name}
                          defaultvalues={showDatas.address1}
                        />
                        <TextField
                          data-toggle="tooltip"
                          title={pageData.village.title}
                          label={pageData.village.label}
                          placeholder={pageData.village.placeholder}
                          type={pageData.village.type}
                          name={pageData.village.name}
                          defaultvalues={showDatas.address2}
                        />
                        <TextField
                          data-toggle="tooltip"
                          title={pageData.post.title}
                          label={pageData.post.label}
                          placeholder={pageData.post.placeholder}
                          type={pageData.post.type}
                          name={pageData.post.name}
                          defaultvalues={showDatas.address3}
                        />
                        <TextField
                          data-toggle="tooltip"
                          title={pageData.district.title}
                          label={pageData.district.label}
                          placeholder={pageData.district.placeholder}
                          type={pageData.district.type}
                          name={pageData.district.name}
                          defaultvalues={showDatas.address4}
                        />
                        <TextField
                          data-toggle="tooltip"
                          title={pageData.other.title}
                          label={pageData.other.label}
                          placeholder={pageData.other.placeholder}
                          type={pageData.other.type}
                          name={pageData.other.name}
                          defaultvalues={showDatas.address5}
                        />
                        <TextField
                          data-toggle="tooltip"
                          title={pageData.state.title}
                          label={pageData.state.label}
                          placeholder={pageData.state.placeholder}
                          type={pageData.state.type}
                          defaultvalues={showDatas.c_state}
                          name={pageData.state.name}
                        />
                        <TextField
                          data-toggle="tooltip"
                          title={pageData.pncode.title}
                          label={pageData.pncode.label}
                          placeholder={pageData.pncode.placeholder}
                          type={pageData.pncode.type}
                          name={pageData.pncode.name}
                          defaultvalues={showDatas.pin_code}
                        />
                        <TextField
                          data-toggle="tooltip"
                          title={pageData.country.title}
                          label={pageData.country.label}
                          placeholder={pageData.country.placeholder}
                          type={pageData.country.type}
                          defaultvalues={showDatas.country}
                          name={pageData.country.name}
                        />
                        <TextField
                          data-toggle="tooltip"
                          title={pageData.email.title}
                          label={pageData.email.label}
                          placeholder={pageData.email.placeholder}
                          type={pageData.email.type}
                          name={pageData.email.name}
                          defaultvalues={showDatas.email}
                        />
                        <TextField
                          data-toggle="tooltip"
                          title={pageData.phone.title}
                          label={pageData.phone.label}
                          placeholder={pageData.phone.placeholder}
                          type={pageData.phone.type}
                          name={pageData.phone.name}
                          defaultvalues={showDatas.phone}
                        />
                        <div className="d-flex justify-content-end">
                          <button
                            className="btn btn-primary p-2 m-2"
                            data-toggle="tooltip"
                            title={pageData.button1.title}
                            type={pageData.button1.type}
                          >
                            {pageData.button1.label}
                          </button>
                          <button
                            className="btn btn-primary p-2 m-2"
                            data-toggle="tooltip"
                            title={pageData.button2.title}
                            type={pageData.button2.type}
                            onClick={(e) => setAction(false)}
                          >
                            {pageData.button2.label}
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                )}
              </Formik>
            </div>
          )}
        </div>
      )}
    </>
  );
}
