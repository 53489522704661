import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { Chip, Stack } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import DaynmicApicall from "../../utils/function";
import { useSelector } from "react-redux";
import "./AgentDashboard.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function AgentDashTable(props) {
  const { userInfo } = useSelector((state) => state?.user?.value);
  const [filterColumn, setFilterColumn] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [tableData, setTableData] = useState("");
  let order;
  let agentid;
  async function getbottom10Agentcount() {
    order = "bottom10";
    agentid = userInfo.data[0].userid;
    await DaynmicApicall(
      `/mcrmdlr/dailyagentperformance/${agentid}/${order}`,
      "",
      "get",
      userInfo.data[0].UserToken
    )
      .then((res) => {
        setTableData(res);
        props.setLoading1(false);
      })
      .catch((err) => {
        console.log("ERROR", err);
        setTableData([]);
        props.setLoading1(false);
      });
  }

  useEffect(async () => {
    if (props.loading1 === true) await getbottom10Agentcount();
  }, [props]);
  const columns = [
    { field: "dlragentid", headerName: "DLR Agent Id", minWidth: 100, flex: 1 },
    { field: "userid", headerName: "Agent Id", minWidth: 150, flex: 1 },
    { field: "username", headerName: "Agent Name", minWidth: 150, flex: 1 },
    {
      field: "agent_call_count",
      headerName: "Total Calls",
      minWidth: 150,
      flex: 1,
    },
    { field: "positive", headerName: "Positive", minWidth: 150, flex: 1 },
    { field: "negative", headerName: "Negative", minWidth: 150, flex: 1 },
    { field: "neutral", headerName: "Netural", minWidth: 150, flex: 1 },
  ];

  return (
    <div className="overflow-auto" style={{ height: "100%" }}>
      {(tableData && (
        <DataGrid
          getRowId={(r) => r.userid}
          rows={tableData}
          columns={columns}
          rowHeight={25}
          pageSize={10}
          autoHeight={true}
          className="bg-white"
          density="compact"
        />
      )) || <Skeleton className="skeleton" />}
    </div>
  );
}
