import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import PolicyInfo from "./PolicyInfo";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react"; // Import useEffect
import DaynmicApicall from "../../../utils/function";

function PolicyInfoModel(props) {
  const { userInfo } = useSelector((state) => state?.user?.value);
  const { userData } = props;
  const [tableData, setTableData] = useState([]);
  const [activeKey, setActiveKey] = useState(0);

  useEffect(() => {
    if (tableData && tableData?.length > 0) {
      setActiveKey(0);
    }
  }, []);

  async function productInformation(cuid) {
    await DaynmicApicall(
      `/mcrmdlr/getcustomerproduct/${userData.data.custinfo.cuid}/${userData.data.campinfo.keypointer}`,
      "",
      "get",
      userInfo.data[0].UserToken
    )
      .then((data) => {
        setTableData(data);
      })
      .catch((err) => {
        setTableData([]);
        console.log("ERROR", err);
      });
  }

  useEffect(() => {
    if (
      userData?.data?.custinfo?.cuid &&
      userData?.data?.campinfo?.keypointer
    ) {
      productInformation();
    }
  }, [userData?.data?.custinfo?.cuid]);

  return (
    <Tabs
      activeKey={activeKey}
      id="uncontrolled-tab-example"
      className="mb-1 mt-0"
      onSelect={(key) => setActiveKey(key)}
    >
      {tableData &&
        tableData?.map((data, index) => (
          <Tab
            eventKey={index}
            title={
              Object.entries(data)[0].join("").charAt(0).toUpperCase() +
              Object.entries(data)[0].join(" ").slice(1)
            }
            key={index}
          >
            <PolicyInfo productInfo={data} />
          </Tab>
        ))}
    </Tabs>
  );
}

export default PolicyInfoModel;
