import socketIOClient from "socket.io-client";
import api from "./api";
import toast from "react-hot-toast";
import { speak } from "./textToSpeech";
import { WEBSOCKETIO } from "./constent";

const endpoint = WEBSOCKETIO;
// const endpoint = 'http://localhost:5001/'

const socketOptions = {
  reconnection: true, // Enable reconnection
  reconnectionAttempts: 3, // Number of attempts before giving up
  reconnectionDelay: 1000, // Delay between reconnection attempts (in milliseconds)
  reconnectionDelayMax: 5000, // Maximum delay between reconnection attempts (in milliseconds)
  path: "/socket.io",
  // transports: ['websocket'],
};

const socket = socketIOClient(endpoint, socketOptions);

socket.on("connect", () => {
  console.log("Connected to  server");
});

socket.on("message", (msg) => {
  console.log("socket message:: ", msg);
});

socket.on("reconnect_attempt", () => {
  console.log("Attempting to reconnect...");
});

socket.on("reconnect", (attemptNumber) => {
  console.log(`Reconnected after ${attemptNumber} attempts.`);
});

socket.on("reconnect_failed", () => {
  console.error("Reconnection failed. Please  check your server or network.");
});

socket.on("error", (error) => {
  console.error("Socket error::", error);
});

socket.on("disconnect", (reason) => {
  console.log("Disconnected from server:", reason);
});

export async function getDialerServer(userinfo) {
  let dlrserver = await f_getConnectedCamp(
    "ALL",
    userinfo.userid,
    userinfo.UserToken
  );
  const queueNames = dlrserver.map((item) => item.queue_name);
  console.log("USERINFO::: ", userinfo);
  let allqueue;
  allqueue = dlrserver?.map((data) => {
    return {
      queue_name: data.campname,
      queue_number: data.queue_name,
    };
  });
  let agentSIPConfig = {
    agent_token: userinfo.agentid,
    wp_config: {
      auto_answer: "true",
      queue_login_code: "*40",
      queue_logout_code: "*41",
    },
    wp_sip_config: {
      sip_username: userinfo.agentid,
      wss_server: dlrserver[0]?.wss_server_path, // dialer server websocket address/port
      sip_password: userinfo.sippassword,
      sip_server: dlrserver[0]?.dlr_server_ip,
    },
    wp_queue_list: [],
  };
  socket.emit("crmdialerdata", agentSIPConfig, (data) => {
    console.log("Agent configraction event: ", data);
  });

  // await api
  //     .post(
  //         "/ami/queue/login",
  //         {
  //             actionid: "crmuuid",
  //             queues: queueNames,
  //             interface: userinfo.dlrAgentId,
  //             membername: userinfo.dlrAgentId,
  //         },
  //     )
  //     .then((response) => {
  //         response.data.results.map((resp) => {
  //             // toast.success(`${resp.agentid} ${resp.message} ${resp.queueid}`);
  //             toast.success(`${resp.Message}`);
  //         });
  //         console.log("AMI LOgin response:: ", response);
  //     })
  //     .catch((error) => {
  //         // toast.error(error.message)
  //         console.log("Error from queue/logout::: ", error);
  //     });
}

export const f_getConnectedCamp = async (campid, userid, UserToken) => {
  try {
    const camp = await api.get(`appuser/getcampbycampid/${campid}/${userid}`, {
      headers: {
        Authorization: UserToken,
      },
    });
    return camp.data.data;
  } catch (error) {
    console.log("Error from f_getConnectedCamp:: ", error);
  }
};

export async function queueLogin(dlrAgentId, userid) {
  try {
    let dlrserver = await f_getConnectedCamp("ALL", userid);
    // const queueNames = dlrserver.map((item) => item.queue_name !== '100078');
    const queueNames = dlrserver
      .filter((item) => item.queue_name !== "100078") // Filter out items with queue_name '100078'
      .map((item) => item.queue_name);

    console.log("queueNames:: ", queueNames);
    const response = await api.post("/ami/queue/login", {
      queues: queueNames,
      interface: dlrAgentId,
      membername: dlrAgentId,
    });

    speak(response.data.Message);
    toast.success(`${response.data.Message}`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.Message) {
      toast.error(error.response.data.Message);
    } else {
      toast.error("An error occurred");
    }
    console.error("Error from queueLogin:: ", error);
    throw error; // Rethrow the error to let the caller handle it if needed
  }
}

export default socket;

// cmr: c1002, roomid: 1002, webphone: w1002
