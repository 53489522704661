export default function DashboardFooter() {
  const PageData = {
    title: "Copyright © 2023-2024 payZorro | All rights reserved",
  };
  const localData = JSON.parse(localStorage.getItem("support_user"));
  return (
    <footer className="position-auto bg-dark text-white  text-md-center mt-2rem p-1">
      <p>{localData.copyRight}</p>
    </footer>
  );
}
