import React from "react";
import { ErrorMessage } from "formik";
import Select from "react-select";

export const MultiSelect = ({
  label,
  name,
  formik,
  onChange,
  isMulti,
  options,
  value,
  format,
  ...rest
}) => {
  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { isDisabled }) => {
      return {
        ...styles,
        cursor: isDisabled ? "not-allowed" : "pointer",
      };
    },
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#727cf5",
        borderColor: "#727cf5",
        borderRadius: "3px",
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: "white",
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: "#ffffff",
      cursor: "pointer",
      ":hover": {
        backgroundColor: "red",
        color: "white",
      },
    }),
  };
  return (
    <div className={format ? "col-md-12 mb-3" : "col-md-4 mb-3"}>
      <div className={format ? "form-control-sm row" : "form-control-sm"}>
        <div
          className={format ? "col-sm-12 col-md-2 col-lg-2 col-xl-2 pt-2" : ""}
        >
          <label htmlFor={name}>{label}</label>
        </div>
        <div className={format ? "col-sm-12 col-md-5 col-lg-5 col-xl-5" : ""}>
          <Select
            name={name}
            styles={colourStyles}
            value={value}
            options={options}
            onChange={onChange}
            isMulti={isMulti}
            {...rest}
          />
          <ErrorMessage
            component="div"
            name={name}
            className="error text-danger"
          />
        </div>
      </div>
    </div>
  );
};
