import React from "react";

const TableDetails = ({ data, category }) => {
  if (!data) {
    return null;
  }

  const dataArray = Array.isArray(data) ? data : [data];

  return (
    <>
      {category === "PTP" && (
        <div style={{ width: "250px", maxHeight: "300px", overflowY: "auto" }}>
          <ul style={{ listStyleType: "none", padding: 0 }}>
            {dataArray.map((item, index) => (
              <li
                key={index}
                style={{ marginBottom: "8px", borderBottom: "1px solid #ddd" }}
              >
                <strong>PTP Date :</strong> {item.ptpdate}
                <br />
                <strong>PTP Amount :</strong> {item.ptpAmount}
                <br />
                <strong>Remarks :</strong> {item.remarks}
                <br />
              </li>
            ))}
          </ul>
        </div>
      )}
      {category === "PTR" && (
        <div style={{ width: "250px", maxHeight: "300px", overflowY: "auto" }}>
          <ul style={{ listStyleType: "none", padding: 0 }}>
            {dataArray.map((item, index) => (
              <li
                key={index}
                style={{ marginBottom: "8px", borderBottom: "1px solid #ddd" }}
              >
                <strong>PTR Date :</strong> {item.ptpdate}
                <br />
                <strong>PTR Amount :</strong> {item.ptpAmount}
                <br />
                <strong>Remarks :</strong> {item.remarks}
                <br />
              </li>
            ))}
          </ul>
        </div>
      )}
      {category === "CMI" && (
        <div
          style={{
            maxWidth: "1000",
            overflow: "scroll",
          }}
        >
          <table style={{ border: "1px solid white" }}>
            <thead>
              <tr>
                <th
                  className="col-1 pl-1 pb-1 m-0"
                  style={{ border: "0.5px solid white" }}
                >
                  S.No.
                </th>
                <th
                  className="col-6  pl-2 pb-1 m-0"
                  style={{ border: "0.5px solid white" }}
                >
                  Survey Questions
                </th>
                <th
                  className="col-4 pl-1 pb-1 m-0"
                  style={{ border: "0.5px solid white" }}
                >
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.surveyData?.map((row, index) => (
                <tr key={row.id}>
                  <td
                    className="col-1 pl-1 pb-1 m-0"
                    style={{ border: "1px solid white" }}
                  >
                    {row.id + 1}
                  </td>
                  <td
                    className="col-6 pl-1 pb-1 m-0 text-left text-wrap"
                    style={{ border: "1px solid white" }}
                  >
                    {row.question
                      ? row.question.length > 32
                        ? row.question.slice(0, 32) + "..."
                        : row.question
                      : ""}
                  </td>
                  <td
                    className="col-4 pl-1 pb-1 m-0"
                    style={{ border: "1px solid white" }}
                  >
                    {row.status}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {category === "DEFAULT" && (
        <div style={{ width: "250px", maxHeight: "300px", overflowY: "auto" }}>
          <ul style={{ listStyleType: "none", padding: 0 }}>
            {dataArray.map((item, index) => (
              <li
                key={index}
                style={{ marginBottom: "8px", borderBottom: "1px solid #ddd" }}
              >
                <strong>REMARKS :</strong> {item.remark}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default TableDetails;
