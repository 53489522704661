import React, { useState, useEffect, useCallback } from "react";
import TableCell from "@mui/material/TableCell";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  gridClasses,
} from "@mui/x-data-grid";
import { Chip, Stack, Tooltip } from "@mui/material";
import DaynmicApicall from "../../utils/function";
import { useSelector } from "react-redux";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./AgentDashboard.css";

export default function AgentNotification({ leadUpdate, setLeadUpdate }) {
  const { userInfo } = useSelector((state) => state?.user?.value);
  const { UserToken, userid, campaignid } = userInfo.data[0];
  const [filterColumn, setFilterColumn] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [tableData, setTableData] = useState([]);
  const [pageSize, setPageSize] = useState(10);

  async function getLeadStatus() {
    try {
      let body = {
        agentid: userid,
        userid: "ALL",
        campid: campaignid,
      };

      const res = await DaynmicApicall(
        `/mcrmdlr/getagentlead`,
        body,
        "post",
        UserToken
      );
      setTableData(res?.data);
      setLeadUpdate(false);
    } catch (err) {
      console.error("ERROR", err);
      setTableData([]);
      setLeadUpdate(false);
    }
  }

  useEffect(() => {
    getLeadStatus();
  }, [userInfo, leadUpdate]);

  useEffect(() => {
    if (leadUpdate === true) getLeadStatus();
  }, [leadUpdate]);

  const columns = [
    {
      field: "flag_status",
      headerName: "Flag Status",
      minWidth: 110,
      flex: 1,
      renderCell: (params) => (
        <TableCell>
          {params?.value === "Y" ? (
            <Tooltip title="Flagged" arrow>
              <Chip
                label="Y"
                style={{
                  backgroundColor: "#5dd350",
                  border: "1px solid black",
                }}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Not Flagged" arrow>
              <Chip
                label="N"
                style={{
                  backgroundColor: "#d32f2f",
                  border: "1px solid black",
                }}
              />
            </Tooltip>
          )}
        </TableCell>
      ),
    },
    {
      field: "campid",
      headerName: "Camp Id",
      minWidth: 90,
      flex: 1,
    },
    {
      field: "cust_id",
      headerName: "Cust Id",
      minWidth: 110,
      flex: 1,
    },
    {
      field: "agent_remarks",
      headerName: "Agent Remarks",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params?.value} arrow>
          <span>{params?.value?.length > 30
            ? `${params?.value?.substring(0, 15)}...`
            : params?.value}</span>
        </Tooltip>
      ),
    },
    {
      field: "super_visor_id",
      headerName: "Supervisor Id",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "super_visor_response",
      headerName: "Supervisor Response",
      minWidth: 180,
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params?.value} arrow>
         <span>
         {params?.value?.length > 30
            ? `${params?.value?.substring(0, 15)}...`
            : params?.value}
         </span>
        </Tooltip>
      ),
    },
    {
      field: "super_visor_remarks",
      headerName: "Supervisor Remarks",
      minWidth: 180,
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params?.value} arrow>
          <span>
          {params?.value?.length > 30
            ? `${params?.value?.substring(0, 15)}...`
            : params?.value}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "contact_date",
      headerName: "Contact Date",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <TableCell>{params?.value?.split("T")[0]}</TableCell>
      ),
    },
  ];

  function CustomToolbar() {
    return (
      <Stack direction="row" justifyContent="flex-end">
        <GridToolbarContainer className={gridClasses.toolbarContainer}>
          <GridToolbarFilterButton />
        </GridToolbarContainer>
      </Stack>
    );
  }

  const onFilterChange = useCallback((filterModel) => {
    if (
      filterModel?.items?.[0]?.value &&
      filterModel?.items?.[0]?.value?.length > 0
    ) {
      setFilterColumn(filterModel?.items?.[0]?.columnField);
      setFilterValue(filterModel?.items?.[0]?.value);
    }
  }, []);

  return (
    <div style={{ height: "100%", width: "100%" }}>
      {tableData.length > 0 ? (
        <DataGrid
          getRowId={(r) => r.sno}
          rows={tableData}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 25, 50]}
          autoHeight
          className="bg-white"
          density="compact"
          onFilterModelChange={onFilterChange}
          components={{ Toolbar: CustomToolbar }}
        />
      ) : (
        <SkeletonTheme height="20">
          <div style={{ height: "100%" }}>
            <Skeleton className="skeleton" />
          </div>
        </SkeletonTheme>
      )}
    </div>
  );
}
