import React, { useState, useCallback } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
  gridClasses,
} from "@mui/x-data-grid";
import { Stack } from "@mui/material";

export default function VOCHistory({ InitialTableData }) {
  const [state, setState] = useState({
    filterValue: "",
    filterColumn: "",
    pageSize: 10,
    open: false,
    customerInfo: "",
  });

  const handleClickOpen = () => {
    setState((prevState) => ({ ...prevState, open: true }));
  };

  const handleClose = () => {
    setState((prevState) => ({ ...prevState, open: false }));
  };

  const handlePageSizeChange = (newPageSize) => {
    setState((prevState) => ({ ...prevState, pageSize: newPageSize }));
  };

  const onFilterChange = useCallback((filterModel) => {
    if (
      filterModel?.items?.[0]?.value &&
      filterModel?.items?.[0]?.value.length > 0
    ) {
      setState((prevState) => ({
        ...prevState,
        filterColumn: filterModel.items[0].columnField,
        filterValue: filterModel.items[0].value,
      }));
    }
  }, []);

  const CustomToolbar = () => (
    <Stack direction="row" justifyContent="flex-end">
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport />
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    </Stack>
  );

  const columns = [
    {
      field: "sessionid",
      headerName: "Session Id",
      minWidth: 100,
      flex: 1,
      valueGetter: (params) => params.row.input_json?.sessionid,
    },
    {
      field: "custid",
      headerName: "Custid",
      minWidth: 80,
      flex: 1,
      valueGetter: (params) => params.row.input_json?.custid,
    },
    {
      field: "call_id",
      headerName: "Callid",
      minWidth: 80,
      flex: 1,
      valueGetter: (params) => params.row.input_json?.call_id,
    },
    {
      field: "reasonL1",
      headerName: "Reason L1",
      minWidth: 100,
      flex: 1,
      valueGetter: (params) => params.row.input_json?.reasonL1,
    },
    {
      field: "descriptionL2",
      headerName: "Description L2",
      minWidth: 130,
      flex: 1,
      valueGetter: (params) => params.row.input_json?.descriptionL2,
    },
    {
      field: "remarksL3",
      headerName: "Remarks L3",
      minWidth: 110,
      flex: 1,
      valueGetter: (params) => params.row.input_json?.remarksL3,
    },
    {
      field: "dhaL7",
      headerName: "DHA L7",
      minWidth: 80,
      flex: 1,
      valueGetter: (params) => params.row.input_json?.dhaL7,
    },
    {
      field: "agentname",
      headerName: "Agent Name",
      minWidth: 120,
      flex: 1,
      valueGetter: (params) => params.row.input_json?.agentname,
    },
    {
      field: "contactperson",
      headerName: "Contact Person",
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => params.row.input_json?.contactperson,
    },
    {
      field: "multiyear",
      headerName: "Multi Year",
      minWidth: 100,
      flex: 1,
      valueGetter: (params) => params.row.input_json?.multiyear,
    },
    {
      field: "upselling",
      headerName: "Upselling",
      minWidth: 100,
      flex: 1,
      valueGetter: (params) => params.row.input_json?.upselling,
    },
    {
      field: "earlysalary",
      headerName: "Early Salary",
      minWidth: 120,
      flex: 1,
      valueGetter: (params) => params.row.input_json?.earlysalary,
    },
    {
      field: "pol_loan_no",
      headerName: "Policy/Loan",
      minWidth: 120,
      flex: 1,
      valueGetter: (params) => params.row.input_json?.pol_loan_no,
    },
    {
      field: "agentid",
      headerName: "Agentid",
      minWidth: 90,
      flex: 1,
      valueGetter: (params) => params.row.input_json?.agentid,
    },
    {
      field: "call_date",
      headerName: "Call Date",
      minWidth: 100,
      flex: 1,
      valueGetter: (params) => params.row.input_json?.call_date,
    },
  ];

  return (
    <>
      {InitialTableData?.length === 0 ? (
        <div style={{ textAlign: "center" }}>No data found</div>
      ) : (
        <DataGrid
          getRowId={(r) => r.session_id}
          rows={InitialTableData}
          columns={columns}
          pageSize={state.pageSize}
          onPageSizeChange={handlePageSizeChange}
          rowsPerPageOptions={[10, 25, 50, 100]}
          onFilterModelChange={onFilterChange}
          autoHeight={true}
          className="bg-white"
          components={{
            Toolbar: CustomToolbar,
          }}
          density="compact"
        />
      )}
    </>
  );
}
