import React, { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useSelector } from "react-redux";
import DaynmicApicall from "../../../utils/function";

export default function OtherInfoTab({ tabInfo, getfunction }) {
  let { crmsaveandexit } = useSelector((state) => state?.dialer);
  const { userInfo } = useSelector((state) => state?.user?.value);
  const { UserToken } = userInfo.data[0];
  let { campid, cust_id } = crmsaveandexit;
  const [pageData, setpageData] = useState([]);

  useEffect(() => {
    pageInfo();
  }, []);

  async function pageInfo() {
    await DaynmicApicall(
      `/appuser/getcomponetbyid/${tabInfo.camp_id}/${tabInfo.comp_id}`,
      "",
      "get",
      UserToken
    )
      .then((data) => {
        setpageData(data[0] ? data[0]?.DATA : []);
      })
      .catch((err) => {
        console.log("ERROR", err);
        setpageData([]);
      });
  }

  return (
    <>
      {pageData?.length > 0 && (
        <div className="card p-4">
          <FormControl>
            <RadioGroup
              className="mt-2 p-1"
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              style={{ alignItems: "start" }}
            >
              {pageData?.map((pageData, index) => (
                <FormControlLabel
                  key={index}
                  className="pr-2"
                  onChange={(e) => {
                    getfunction(e.target.value, `${campid}${cust_id}`);
                  }}
                  value={pageData?.value}
                  control={<Radio />}
                  label={pageData?.lable}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </div>
      )}
    </>
  );
}
