import React from "react";
import { useSelector } from "react-redux";

import DialerDashboard from "./Dialer/DialerDashboard";
import GuestDashboard from "./GuestDashboard";

export default function DashboardHome(props) {
  const { userInfo } = useSelector((state) => state?.user?.value);

  return (
    <>
      {userInfo?.data[0]?.usergroup !== "GUEST" ? (
        <DialerDashboard
          handleSnackbarOpen={props.handleSnackbarOpen}
          snackbarOpen={props.snackbarOpen}
          setSnackbarOpen={props.setSnackbarOpen}
          setSnackbarContent={props.setSnackbarContent}
        />
      ) : (
        <GuestDashboard />
      )}
    </>
  );
}
