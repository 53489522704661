import React, { useState, useEffect } from "react";
import PageHeader from "../../components/PageHeader";
import DaynmicApicall from "../../utils/function";
import {
  DataGrid,
  GridToolbarContainer,
  gridClasses,
  GridToolbarFilterButton,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Chip,
  Tooltip,
  TextField,
} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { useSelector } from "react-redux";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import PendingActionsOutlinedIcon from "@mui/icons-material/PendingActionsOutlined";
import toast from "react-hot-toast";

export default function ManageLeadNotification(props) {
  const { userInfo } = useSelector((state) => state?.user?.value);
  const { UserToken, userid, campaignid } = userInfo.data[0];
  const { campdetail } = useSelector((state) => state.campaign);
  const [filterColumn, setFilterColumn] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [pageData, setpageData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = useState(false);
  const [actionType, setActionType] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [remarks, setRemarks] = useState("");

  useEffect(async () => {
    await pageInfo();
    await getLeadStatus();
  }, [userInfo]);

  async function pageInfo() {
    await DaynmicApicall(
      `/appuser/getcomponetbyid/${props.activemenu.keypointer}/172`,
      "",
      "get",
      userInfo.data[0].UserToken
    )
      .then((data) => {
        setpageData(data[0]?.DATA);
      })
      .catch((err) => {
        console.log("ERROR", err);
        setpageData([]);
      });
  }

  async function getLeadStatus() {
    try {
      let body = {
        agentid: userid === "ADMIN" ? "" : "ALL",
        userid: userid === "ADMIN" ? "" : userid,
        campid: campaignid,
      };
      const res = await DaynmicApicall(
        `/mcrmdlr/getagentlead`,
        body,
        "post",
        UserToken
      );
      setTableData(res?.data);
    } catch (err) {
      console.error("ERROR", err);
      setTableData([]);
    }
  }

  const handleClickOpen = (type, row) => {
    setActionType(type);
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setRemarks("");
    setSelectedRow(null);
  };

  const handleSubmit = async () => {
    if (!remarks) {
      alert("Please provide remarks");
      return;
    }
    try {
      const campKeypointer = campdetail?.filter(
        (e) => e.campid === selectedRow.campid
      );

      let payload = {
        campid: selectedRow.campid,
        custid: selectedRow.cust_id,
        callid: selectedRow.call_id,
        contactdate: selectedRow.contact_date,
        contacttime: selectedRow.contact_time,
        contactby: selectedRow.contact_by,
        respcode: selectedRow.resp_code,
        subrespcode: selectedRow.sub_resp_code,
        dialerconnectedph: selectedRow.dialer_connected_ph,
        reqamount: selectedRow.req_amount,
        agentremarks: selectedRow.agent_remarks,
        paymentoption: selectedRow.payment_option,
        supervisorid: selectedRow.super_visor_id,
        supervisorresponse: actionType,
        supervisorremarks: remarks,
        callsessionid: selectedRow.dialer_session_id,
        leadid: selectedRow.leadid,
        flagstatus:
          actionType === "Approve" || actionType === "Reject" ? "Y" : "N",
        active: "Y",
        createdby: selectedRow.contact_by,
        action_name: "UPDATE",
        update_date: new Date().toISOString(),
        update_by: userid,
        keypointer: campKeypointer[0]?.keypointer,
      };

      await DaynmicApicall(
        `mcrmdlr/managelead`,
        payload,
        "post",
        userInfo?.data?.[0]?.UserToken
      )
        .then((data) => {
          toast.success(data.message);
        })
        .catch((err) => {
          console.log("ERROR", err);
          toast.error(
            err.response.message ?? err.message ?? "OOPs, something went wrong."
          );
        });
      getLeadStatus();
      handleClose();
    } catch (err) {
      console.error("ERROR", err);
    }
  };

  const columns = [
    {
      field: "actions",
      headerName: "ACTION",
      width: 90,
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          label="Approve"
          icon={<TaskAltOutlinedIcon className="text-success" />}
          className=""
          onClick={() => handleClickOpen("Approve", params.row)}
          showInMenu
        />,
        <GridActionsCellItem
          label="Reject"
          icon={<CancelOutlinedIcon className="text-danger" />}
          onClick={() => handleClickOpen("Reject", params.row)}
          showInMenu
        />,
        <GridActionsCellItem
          label="Hold"
          icon={<PendingActionsOutlinedIcon className="text-warning" />}
          onClick={() => handleClickOpen("Hold", params.row)}
          showInMenu
        />,
      ],
    },
    {
      field: "campid",
      headerName: "Camp Id",
      minWidth: 90,
      flex: 1,
    },
    {
      field: "cust_id",
      headerName: "Cust Id",
      minWidth: 110,
      flex: 1,
    },
    {
      field: "agent_remarks",
      headerName: "Agent Remarks",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params?.value} arrow>
       <span>
       {params?.value?.length > 30
            ? `${params?.value?.substring(0, 15)}...`
            : params?.value}
       </span>
        </Tooltip>
      ),
    },
    {
      field: "contact_by",
      headerName: "Contact By",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "req_amount",
      headerName: "Request Amount",
      minWidth: 150,
      flex: 1,
      hide: true,
    },
    {
      field: "super_visor_response",
      headerName: "Supervisor Response",
      minWidth: 180,
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params?.value} arrow>
          <span>
            {params?.value?.length > 30
              ? `${params?.value?.substring(0, 15)}...`
              : params?.value}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "super_visor_remarks",
      headerName: "Supervisor Remarks",
      minWidth: 180,
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params?.value} arrow>
          <span>
            {params?.value?.length > 30
              ? `${params?.value?.substring(0, 15)}...`
              : params?.value}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "contact_date",
      headerName: "Contact Date",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <TableCell>{params?.value?.split("T")[0]}</TableCell>
      ),
    },
  ];

  function CustomToolbar() {
    return (
      <Stack direction="row" justifyContent="flex-end">
        <GridToolbarContainer className={gridClasses.toolbarContainer}>
          <GridToolbarFilterButton />
        </GridToolbarContainer>
      </Stack>
    );
  }

  const onFilterChange = React.useCallback(async (filterModel) => {
    if (
      filterModel?.items?.[0]?.value &&
      filterModel?.items?.[0]?.value?.length > 0
    ) {
      setFilterColumn(filterModel?.items?.[0]?.columnField);
      setFilterValue(filterModel?.items?.[0]?.value);
    }
  }, []);

  return (
    <>
      {pageData && (
        <>
          <PageHeader heading={pageData?.mainHeader} />

          <div className="table-settings mb-4 mt-1 pl-2">
            <div className="my-2">
              <div className="card card-body border-light shadow">
                <DataGrid
                  getRowId={(r) => r.sno}
                  rows={tableData.length > 0 ? tableData : []}
                  columns={columns}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[10, 25, 50]}
                  autoHeight
                  className="bg-white"
                  density="compact"
                  onFilterModelChange={onFilterChange}
                  components={{ Toolbar: CustomToolbar }}
                />
              </div>
            </div>
          </div>

          <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={open}
            onClose={handleClose}
          >
            <DialogTitle>
              <strong>
                {actionType} {pageData?.dialogHeader}
              </strong>
            </DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="Remarks"
                type="text"
                fullWidth
                multiline
                rows={4}
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <div className="d-flex justify-content-end w-100 pt-2">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm m-2"
                  onClick={handleSubmit}
                >
                  {pageData?.button?.label1}
                </button>
                <button
                  type="reset"
                  className="btn btn-primary btn-sm m-2"
                  onClick={handleClose}
                >
                  {pageData?.button?.label2}
                </button>
              </div>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}
