import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import DaynmicApicall from "../../../utils/function";
import { Col, Row } from "react-bootstrap";

function getDate(date) {
  let newDate =
    date !== null && date !== undefined && date.length > 8
      ? new Date(date)
      : "";
  if (newDate) {
    return `${newDate.getFullYear()}-${(newDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${newDate.getDate().toString().padStart(2, "0")}`;
  } else {
    return date;
  }
}

const PolicyInfo = (props) => {
  return (
    <div className="row" style={{ width: "100%" }}>
      <div className="col p-2">
        <div className="card-responsive">
          <div className="row">
            <Row className="ml-2">
              {props.productInfo &&
                Object.entries(props.productInfo).map((data, i) => {
                  if (data[0].toLowerCase().includes("date")) {
                    const formattedDate = getDate(data[1]);
                    return (
                      <Col
                        className="p-1 m-0"
                        index={i}
                        key={i}
                        xs={6}
                        sm={6}
                        md={4}
                        lg={4}
                        xl={4}
                      >
                        <ul className="list-group">
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            {data[0]
                              .replaceAll("_", " ")
                              .charAt(0)
                              .toUpperCase() +
                              data[0].replaceAll("_", " ").slice(1)}
                            :
                            <span className="badge text-dark text-wrap col-4">
                              {formattedDate}
                            </span>
                          </li>
                        </ul>
                      </Col>
                    );
                  } else {
                    return (
                      <Col
                        className="p-1 m-0"
                        index={i}
                        key={i}
                        xs={6}
                        sm={2}
                        md={4}
                        lg={4}
                        xl={4}
                      >
                        <ul className="list-group">
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            {data[0]
                              .replaceAll("_", " ")
                              .charAt(0)
                              .toUpperCase() +
                              data[0].replaceAll("_", " ").slice(1)}
                            :
                            <span
                              className="badge text-dark text-wrap col-4"
                              style={{
                                wordWrap: "break-word",
                                whiteSpace: "normal",
                              }}
                            >
                              {data[1]}
                            </span>
                          </li>
                        </ul>
                      </Col>
                    );
                  }
                })}
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PolicyInfo;
