import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import DaynmicApicall from "../../../utils/function";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { Stack } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

function getDate(date) {
  let newDate =
    date !== null && date !== undefined && date?.length > 8
      ? new Date(date)
      : "";
  if (newDate) {
    return `${newDate.getFullYear()}-${(newDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${newDate.getDate().toString().padStart(2, "0")}`;
  } else {
    return date;
  }
}

export default function OldHistory(props) {
  const { userInfo } = useSelector((state) => state?.user?.value);
  const {
    userInfo: { userInfo: callHistoryDetails },
  } = props;
  const [tableData, setTableData] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [filterColumn, setFilterColumn] = useState("");
  // const [pageSize, setPageSize] = useState(10);
  async function CallHistoryData() {
    await DaynmicApicall(
      `mcrmdlr/oldcrmcallhistory/${callHistoryDetails?.data?.campinfo?.campid}/${callHistoryDetails?.data?.custinfo?.cuid}`,
      "",
      "get",
      userInfo.data[0].UserToken
    )
      .then((data) => {
        const formattedData = data.map((item) => ({
          ...item,
          CONTACT_DATE: getDate(item.CONTACT_DATE),
          REVERTTO_DATE: getDate(item.REVERTTO_DATE),
          FOLLOWUP_DATE: getDate(item.FOLLOWUP_DATE),
        }));
        setTableData(formattedData);
      })
      .catch((err) => {
        console.log("ERROR", err);
      });
  }

  const columns = [
    {
      field: "CONTACT_DATE",
      headerName: "Contact Date",
      minWidth: 130,
      flex: 1,
    },
    { field: "CUST_ID", headerName: "Coustomer Id", minWidth: 150, flex: 1 },
    {
      field: "customer_name",
      headerName: "Customer Name",
      minWidth: 150,
      flex: 1,
    },
    { field: "CONTACT_BY", headerName: "Contact By", minWidth: 110, flex: 1 },
    {
      field: "RESP_CODE",
      headerName: "Response Code",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "SUB_RESP_CODE",
      headerName: "Sub Response Code",
      minWidth: 170,
      flex: 1,
    },
    {
      field: "REVERTTO_DATE",
      headerName: "Revert Date",
      minWidth: 130,
      flex: 1,
    },
    {
      field: "REVERTTO_TIME",
      headerName: "Revert Time",
      minWidth: 130,
      flex: 1,
    },
    { field: "CALL_ID", headerName: "Call Id", minWidth: 90, flex: 1 },
    {
      field: "FOLLOWUP_DATE",
      headerName: "Followup Date ",
      minWidth: 130,
      flex: 1,
    },
    {
      field: "REMARK",
      headerName: "REMARK",
      minWidth: 150,
      flex: 1,

      headerClassName: "table-header",
      renderCell: (params) =>
        params.value && (
          <Tooltip
            title={params.value}
            color="inherit"
            placement="bottom-start"
          >
            <span> {params.value} </span>
          </Tooltip>
        ),
    },
  ];

  function CustomToolbar() {
    return (
      <Stack direction="row" justifyContent="flex-end">
        <GridToolbarContainer className={gridClasses.toolbarContainer}>
          {/* <GridToolbarExport /> */}
          <GridToolbarFilterButton />
        </GridToolbarContainer>
      </Stack>
    );
  }

  const onFilterChange = React.useCallback(async (filterModel) => {
    if (
      filterModel?.items?.[0]?.value &&
      filterModel?.items?.[0]?.value.length > 0
    ) {
      setFilterColumn(filterModel?.items?.[0]?.columnField);
      setFilterValue(filterModel?.items?.[0]?.value);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await CallHistoryData();
    })();
  }, []);

  return (
    <>
      <div className="card card-body border-light shadow">
        <div className="table-settings mb-4">
          <div className="my-2">
            <DataGrid
              getRowId={(r) => r.CALL_ID}
              rows={tableData.length > 0 ? tableData : []}
              columns={columns}
              // pageSize={pageSize}
              // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              // rowsPerPageOptions={[10, 20, 50, 100]}
              hideFooter={true}
              onFilterModelChange={onFilterChange}
              autoHeight={true}
              className="bg-white"
              components={{
                Toolbar: CustomToolbar,
              }}
              density="compact"
            />
          </div>
        </div>
      </div>
    </>
  );
}
