import { Form, Formik } from "formik";
import React from "react";
import { TextField } from "../../components/formValidation/TextField";
import { useState } from "react";
import { useSelector } from "react-redux";
import api from "../../utils/api";
import toast from "react-hot-toast";
import { useEffect } from "react";
import { MultiSelect } from "../../components/formValidation/MultiSelect";
import DaynmicApicall from "../../utils/function";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { Button, Divider, Stack } from "@mui/material";
import * as Yup from "yup";
import { Toggle } from "../../components/formValidation/Toggle";

export default function DataAllocation(props) {
  const { userInfo } = useSelector((state) => state?.user?.value);
  const { campdetail } = useSelector((state) => state.campaign);
  const [searchdata, setSearchdata] = useState([]);
  const [filterColumn, setFilterColumn] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [Transferdata, settransferdata] = useState("");
  const [searchfount, setSearchfount] = useState(false);
  const [botAction, setBotAction] = useState("");
  const [botscripttemp, setBotscripttemp] = useState([]);
  const [agentlistdata, setagentlistdata] = useState([]);
  const [apiprocess, setApiprocess] = useState(false);
  const [allocFilter, setAllocFilter] = useState("");
  const [pageData, setPageData] = useState("");
  const [data, setData] = useState("");
  const [scripttext, setScripttext] = useState("");
  const [getDrugData, setDrugData] = useState("");

  let allCamps = [];
  if (campdetail && campdetail.length > 0) {
    allCamps = campdetail.map((item) => {
      return {
        value: item.campid,
        label: item.campname,
      };
    });
  }
  async function pageInfo() {
    await DaynmicApicall(
      `/appuser/getcomponetbyid/${props.activemenu.keypointer}/${props.activemenu.componentid}`,
      "",
      "get",
      userInfo.data[0].UserToken
    ).then((data) => {
      setPageData(data[0].DATA);
    });
  }
  let priority = [
    { value: "9", label: "High" },
    { value: "5", label: "Medium" },
    { value: "2", label: "Low" },
  ];
  let agentFliterList = [
    { value: "age", label: "Age" },
    { value: "campid", label: "Campiagn" },
    { value: "alloc_to", label: "Allocation" },
    { value: "bmi", label: "BMI" },
    { value: "nexteligibledate", label: "Last  Eligible Date" },
    { value: "weight", label: "Weight" },
    { value: "literacyconsent", label: "Literacy Consent" },
    { value: "food_habit", label: "Food Habit" },
    { value: "gender", label: "Gender" },
    { value: "consentlanguage", label: "Consent Language" },
    { value: "localcity", label: "Local City" },
    { value: "contactnumber", label: "Contact Number" },
    { value: "lastresponse", label: "Last Response" },
    { value: "lotdate", label: "Data Upload Date" },
  ];
  let Genderlist = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ];
  let foodhabit = [
    { value: "Vegetarian", label: "Vegetarian" },
    { value: "Non-Vegetarian", label: "Non-Vegetarian" },
  ];
  let Language = [
    { value: "English", label: "English" },
    { value: "Hindi", label: "Hindi" },
    { value: "Gujrati", label: "Gujrati" },
  ];
  const initial = {
    age: "NA",
    minvalueage: "NA",
    maxvalueage: "NA",
    campid: "NA",
    alloc_to: "NA",
    bmi: "NA",
    bmimin: "NA",
    bmimax: "NA",
    nexteligibledate: "NA",
    startdate: "NA",
    end_date: "NA",
    weight: "NA",
    weightmin: "NA",
    weightmax: "NA",
    food_habit: "NA",
    gender: "NA",
    literacyconsent: "NA",
    consentlanguage: "NA",
    localcity: "NA",
    contactnumber: "NA",
    lastresponse: "NA",
    lotdate: "NA",
  };
  async function getbotscripttemp() {
    await DaynmicApicall(
      `mcrmdlr/getbotscriptmaster/`,
      "",
      "get",
      userInfo.data[0].UserToken
    ).then(async (res) => {
      setData(res);
      let botdata = await res.map((item) => {
        return {
          value: `${item.sub_camp_id}~${item.dgltext}`,
          label: item.dgl,
        };
      });
      setBotscripttemp(botdata);
    });
  }
  async function getAgentList() {
    api
      .get(`appuser/userlist/101/ALL`, {
        headers: {
          Authorization: userInfo.data[0].UserToken, //the token is a variable which holds the token
        },
      })
      .then(async (res) => {
        let bData = [];
        let getAgents = res.data.data.filter((v) => v.usergroup === "AGENT");
        bData = await getAgents.map((item) => {
          return {
            value: item.username,
            label: item.username,
          };
        });
        setagentlistdata(bData);
      })
      .catch((error) => {
        toast.error(
          error.response.data.message ??
            error.message ??
            "OOPs, something went wrong."
        );
      });
  }
  async function fn_getDrugDataInfo() {
    api
      .get(`prm/getParamdata/ALL/DRUG_TESTING/${props.activeMenu.keypointer}`, {
        headers: {
          Authorization: userInfo.data[0].UserToken, //the token is a variable which holds the token
        },
      })
      .then(async (res) => {
        let bData = [];
        bData = await res.data.data.map((item) => {
          return {
            value: item.paracode,
            label: item.paravalue,
          };
        });
        setDrugData(bData);
      })
      .catch((error) => {
        toast.error(
          error.response.data.message ??
            error.message ??
            "OOPs, something went wrong."
        );
      });
  }
  useEffect(async () => {
    await pageInfo();
    await getbotscripttemp();
    await fn_getDrugDataInfo();
  }, []);

  async function getDataTransfer() {
    api
      .get(
        `prm/getParamdata/ALL/DATA_TRANSFER/${props.activeMenu.keypointer}`,
        {
          headers: {
            Authorization: userInfo.data[0].UserToken, //the token is a variable which holds the token
          },
        }
      )
      .then(async (res) => {
        let bData = [];
        bData = await res.data.data.map((item) => {
          return {
            value: item.paracode,
            label: item.paravalue,
          };
        });
        settransferdata(bData);
      })
      .catch((error) => {
        toast.error(
          error.response.data.message ??
            error.message ??
            "OOPs, something went wrong."
        );
      });
  }
  useEffect(() => {
    getAgentList();
    getDataTransfer();
  }, []);
  function CustomToolbar() {
    return (
      <Stack direction="row" spacing={0.5} m={1}>
        <GridToolbarContainer className={gridClasses.toolbarContainer}>
          <GridToolbarExport />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
        </GridToolbarContainer>
      </Stack>
    );
  }
  const onFilterChange = React.useCallback(async (filterModel) => {
    if (
      filterModel?.items?.[0]?.value &&
      filterModel?.items?.[0]?.value.length > 0
    ) {
      setFilterColumn(filterModel?.items?.[0]?.columnField);
      setFilterValue(filterModel?.items?.[0]?.value);
    }
  }, []);

  const onSubmit = async (values, { resetForm }) => {
    values.age =
      values.age !== "NA"
        ? { min: values.minvalueage, max: values.maxvalueage }
        : "NA";
    values.bmi =
      values.bmi !== "NA" ? { min: values.bmimin, max: values.bmimax } : "NA";
    values.weight =
      values.weight !== "NA"
        ? { min: values.weightmin, max: values.weightmax }
        : "NA";
    values.nexteligibledate =
      values.nexteligibledate !== "NA"
        ? { start: values.startdate, end: values.end_date }
        : "NA";
    values.consentlanguage =
      values.consentlanguage !== "NA"
        ? values.consentlanguage.map((v) => v.value)
        : "NA";
    values.campid = values.campid !== "NA" ? values.campid.value : "NA";
    values.gender =
      values.gender !== "NA" ? values.gender.map((v) => v.value) : "NA";
    values.food_habit =
      values.food_habit !== "NA" ? values.food_habit.map((v) => v.value) : "NA";
    // delete values.bmimax; delete values.bmimin; delete values.minvalueage;
    // delete values.maxvalueage; delete values.startdate; delete values.end_date; delete values.weightmin; delete values.weightmax;
    // delete values.agentaction;
    setAllocFilter(values);
    let Info = api.post(`mcrmdlr/getdataallocation`, values, {
      headers: { Authorization: userInfo.data[0].UserToken },
    });
    toast
      .promise(Info, {
        success: (Info) => {
          return Info.data.message;
        },
        error: (err) => {
          return (
            err?.response?.data?.errors ??
            err?.response?.data?.message ??
            err?.message ??
            "OOPs something went wrong."
          );
        },
      })
      .then((data) => {
        setSearchfount(true);
        setSearchdata(data.data.data);
      });
  };

  const columns = [
    {
      field: "cust_id",
      headerName: "Customer ID",
      width: 150,
      className: "text-center",
    },
    { field: "volunteer_no", headerName: "Volunteer No", width: 150 },
    { field: "volunteer_name", hbmieaderName: "Volunteer Name", width: 150 },
    { field: "age", headerName: "Age", width: 100 },
    { field: "bmi", headerName: "BMI", width: 100 },
    { field: "height", headerName: "Height", width: 100 },
    { field: "weight", headerName: "Weight", width: 100 },
    { field: "cust_language", headerName: "Language", width: 150 },
    { field: "contact_no", headerName: "Phone Number", width: 150 },
    { field: "gender", headerName: "Gender", width: 150 },
  ];

  const postFroBotRecording = async (values) => {
    // values.recordingData = searchdata
    values.userid = userInfo.data[0].userid;
    let dgl = values.dgl.value.split("~")[1];
    let sub_camp_id = parseInt(values.dgl.value.split("~")[0]);
    values.query = searchdata.query;
    values.dgl = { value: sub_camp_id, label: dgl };
    if (botAction === "POST-TO-BOT-RECORDING") {
      let scriptval = {
        dgl: values.dgl,
        userid: values.userid,
        query: values.query,
      };
      // console.log("scriptval", scriptval)
      // let Info = await DaynmicApicall(`services/managebotscriptcreation/`,
      //     scriptval,
      //     "post",
      //     userInfo.data[0].UserToken
      // );
      // toast.success(Info.message);
      // setApiprocess(false)
      const Info = api.post(
        "services/managebotscriptcreation/",
        scriptval,
        userInfo.data[0].UserToken
      );
      toast
        .promise(Info, {
          loading: "Data posting from recording...",
          success: (Info) => {
            return Info.data.message;
          },
          info: (Info) => {
            return Info.data.message;
          },
          error: (err) => {
            return (
              err?.response?.data?.errors ??
              err?.response?.data?.message ??
              err?.message ??
              "OOPs something went wrong."
            );
          },
        })
        .then((data) => {
          setApiprocess(false);
        });
    }
    if (botAction === "POST-TO-BOT") {
      values.campid = values.campid.value;
      values.retry = values.retry === true ? 1 : 0;
      values.priority = values.priority.value;
      let scriptval = { dgl: values.dgl, userid: values.userid, ...values };
      let Info = await DaynmicApicall(
        `services/managebotrecordingdata/`,
        scriptval,
        "post",
        userInfo.data[0].UserToken
      );
      toast.success(Info.message);
      setApiprocess(false);
    }
  };

  const alloc_Dealloca = async (values) => {
    let userids = values.userid.map((v) => v.value);
    values.allocation = searchdata.finalresult.slice(0, values.alloc);
    let distribut = parseInt(values.alloc / userids.length);
    let userval = 0;
    let arr = values.allocation.map((value, index) => {
      if (
        index !== 0 &&
        index % distribut === 0 &&
        userval < userids.length - 1
      ) {
        userval = userval + 1;
      }
      let data = {
        cuid: value.cuid,
        custid: value.cust_id,
        campid: values.campid.value,
        agentid: userids[userval],
        action_name: botAction,
        createdby: userInfo.data[0].userid,
      };
      return data;
    });
    values.allocationdata = arr;
    let Info = await DaynmicApicall(
      `services/managedataallocation`,
      { allocationdata: values.allocationdata },
      "post",
      userInfo.data[0].UserToken
    );
    toast.success(Info.message);
  };

  const postToDialer = async (values) => {
    values.userid = userInfo.data[0].userid;
    values.query = searchdata.query;
    let scriptval = { ...values };
    let Info = await DaynmicApicall(
      `services/managedialerdatatransf/`,
      scriptval,
      "post",
      userInfo.data[0].UserToken
    );
    toast.success(Info.message);
    setApiprocess(false);
  };

  const allocationValidation = Yup.object({
    // alloc: Yup.number().max(searchdata.length, 'Allocation data count should be less then total search data'),
  });

  // console.log("searchdata ? searchdata.finalresult :", searchdata.finalresult)
  return (
    <>
      {pageData && (
        <>
          <div>
            <div className="row">
              <div className="card col-xl-12 bg-dark">
                <div className="row row-cols-md-2 m-2 p-2 row-cols-lg-2 row-cols-xl-2 font-weight-bold">
                  <span className="text-left text-light">
                    {pageData.pagetitle}
                  </span>
                  <span className="text-right"></span>
                </div>
              </div>
            </div>
            <div className="card  card-body border-light shadow mt-2">
              {searchfount && (
                <>
                  <div
                    className="justify-content-end"
                    style={{ marginTop: "-10px" }}
                  >
                    <button
                      // className="btn btn-success btn-rounded"
                      className="btn btn-outline-success btn-rounded"
                      data-mdb-ripple-color="dark"
                      title="POST-TO-BOT-RECORDING"
                      data-toggle="tooltip"
                      data-bs-toggle="modal"
                      data-bs-target="#cardtoggel1"
                      size="sm"
                      variant="white"
                      onClick={(e) => {
                        setBotAction("POST-TO-BOT-RECORDING");
                      }}
                    >
                      Prepare Recording
                    </button>
                    <button
                      className={`border-1 btn-sm bg-success text-white fw-bold rounded-left-3 p-2`}
                      title="POST-TO-BOT"
                      data-toggle="tooltip"
                      data-bs-toggle="modal"
                      data-bs-target="#cardtoggel1"
                      size="sm"
                      variant="white"
                      onClick={(e) => setBotAction("POST-TO-BOT")}
                    >
                      Post to BOT
                    </button>
                    <button
                      className={`border-1 btn-sm bg-success text-white fw-bold rounded-left-3 p-2`}
                      title="Data Allocation"
                      data-toggle="tooltip"
                      data-bs-toggle="modal"
                      data-bs-target="#cardtoggel1"
                      size="sm"
                      variant="white"
                      onClick={(e) => setBotAction("ALLOCATE")}
                    >
                      Data Allocation
                    </button>
                    <button
                      className={`border-1 btn-sm bg-success text-white fw-bold rounded-left-3 p-2`}
                      title="DE-ALLOCATE"
                      data-toggle="tooltip"
                      data-bs-toggle="modal"
                      data-bs-target="#cardtoggel1"
                      size="sm"
                      variant="white"
                      onClick={(e) => setBotAction("DE-ALLOCATE")}
                    >
                      De-Allocation
                    </button>
                    {/* <button
                                            className={`border-1 btn-sm bg-success text-white fw-bold rounded-left-3 p-2`}
                                            title="POST-TO-DIALER"
                                            data-toggle="tooltip"
                                            data-bs-toggle="modal"
                                            data-bs-target="#cardtoggel1"
                                            size="sm" variant="white"
                                            onClick={(e) => {
                                                setBotAction("POST-TO-DIALER")
                                            }}
                                        >
                                            Post To Dialer
                                        </button> */}
                  </div>
                </>
              )}
              <Formik
                initialValues={initial}
                // validationSchema={validate}
                onSubmit={onSubmit}
              >
                {(formik) => (
                  <Form>
                    <div className="row mt-2 p-1">
                      <div className="mt-2" style={{ marginTop: "-38px" }}>
                        <MultiSelect
                          title="POST-TO-BOT-RECORDING"
                          data-toggle="tooltip"
                          label={pageData.datafilter.lable}
                          value={formik.values.agentaction}
                          name={pageData.datafilter.name}
                          isMulti={true}
                          formik={formik}
                          options={agentFliterList}
                          onChange={(value) => {
                            formik.setFieldValue("agentaction", value);
                            value.map((data) => {
                              formik.setFieldValue(`${data.value}`, "");
                            });
                          }}
                        />
                      </div>
                      {formik.values.alloc_to !== "NA" && (
                        <>
                          <TextField
                            title="Please Enter Allocation"
                            data-toggle="tooltip"
                            label={pageData.allocadata.label}
                            name={pageData.allocadata.name}
                            placeholder={pageData.allocadata.placeholder}
                            type={pageData.allocadata.type}
                          />
                        </>
                      )}
                      {formik.values.campid !== "NA" && (
                        <>
                          <MultiSelect
                            data-toggle="tooltip"
                            label={pageData.campaigndata.label}
                            name={pageData.campaigndata.name}
                            value={formik.values.campid}
                            isMulti={false}
                            options={allCamps}
                            onChange={(value) =>
                              formik.setFieldValue("campid", value)
                            }
                          />
                        </>
                      )}
                      {formik.values.age !== "NA" && (
                        <>
                          <h4 style={{ borderBottom: "0.5px solid black" }}>
                            {pageData.agetitle}
                          </h4>
                          <TextField
                            data-toggle="tooltip"
                            title="Please Enter Minimum Age for Data Search"
                            label={pageData.agemin.label}
                            name={pageData.agemin.name}
                            placeholder={pageData.agemin.placeholder}
                            type={pageData.agemin.type}
                          />
                          <TextField
                            data-toggle="tooltip"
                            title="Please Enter Max Age for Data Search"
                            label={pageData.agemax.label}
                            name={pageData.agemax.name}
                            placeholder={pageData.agemax.placeholder}
                            type={pageData.agemax.type}
                          />
                        </>
                      )}
                      {formik.values.bmi !== "NA" && (
                        <>
                          <h4 style={{ borderBottom: "0.5px solid black" }}>
                            {pageData.btmtitle}BMI
                          </h4>
                          <TextField
                            data-toggle="tooltip"
                            title="Please Enter Minimum BMI for Data Search"
                            label={pageData.minbmi.label}
                            name={pageData.minbmi.name}
                            placeholder={pageData.minbmi.placeholder}
                            type={pageData.minbmi.type}
                          />
                          <TextField
                            data-toggle="tooltip"
                            title="Please Enter Max BMI for Data Search"
                            label={pageData.mixbmi.label}
                            name={pageData.mixbmi.name}
                            placeholder={pageData.mixbmi.placeholder}
                            type={pageData.mixbmi.type}
                          />
                        </>
                      )}
                      {formik.values.weight !== "NA" && (
                        <>
                          <h4 style={{ borderBottom: "0.5px solid black" }}>
                            {pageData.weighttitle}
                          </h4>
                          <TextField
                            data-toggle="tooltip"
                            title="Please Enter Min Weight for Data Search"
                            label={pageData.minweight.label}
                            name={pageData.minweight.name}
                            placeholder={pageData.minweight.placeholder}
                            type={pageData.minweight.type}
                          />
                          <TextField
                            data-toggle="tooltip"
                            title="Please Enter Max Weight for Data Search"
                            label={pageData.maxweight.label}
                            name={pageData.maxweight.name}
                            placeholder={pageData.maxweight.placeholder}
                            maxweight
                          />
                        </>
                      )}
                      {formik.values.nexteligibledate !== "NA" && (
                        <>
                          <h4 style={{ borderBottom: "0.5px solid black" }}>
                            {pageData.seligibletitle}
                          </h4>
                          <TextField
                            data-toggle="tooltip"
                            title="Please Enter Start Eligible date"
                            label={pageData.seligible.label}
                            name={pageData.seligible.name}
                            placeholder={pageData.seligible.placeholder}
                            type={pageData.seligible.type}
                          />
                          <TextField
                            data-toggle="tooltip"
                            title="Please Enter End Eligible date"
                            label={pageData.endeligible.label}
                            name={pageData.endeligible.label}
                            placeholder={pageData.endeligible.label}
                            type={pageData.endeligible.label}
                          />
                        </>
                      )}
                      {formik.values.literacyconsent !== "NA" && (
                        <>
                          <h4 style={{ borderBottom: "0.5px solid black" }}>
                            {pageData.leteracyconttitle}
                          </h4>
                          <TextField
                            data-toggle="tooltip"
                            title="Please Enter Start Eligible date"
                            label={pageData.leteracycont.label}
                            name={pageData.leteracycont.name}
                            defaultValue="4TH"
                            placeholder={pageData.leteracycont.placeholder}
                            type={pageData.leteracycont.type}
                          />
                        </>
                      )}
                      {formik.values.contactnumber !== "NA" && (
                        <>
                          <h4 style={{ borderBottom: "0.5px solid black" }}>
                            {pageData.numbertitle}
                          </h4>
                          <TextField
                            data-toggle="tooltip"
                            title="Please Enter Contact Number"
                            label={pageData.numbercontact.label}
                            name={pageData.numbercontact.name}
                            placeholder={pageData.numbercontact.placeholder}
                            type={pageData.numbercontact.type}
                          />
                        </>
                      )}
                      {formik.values.localcity !== "NA" && (
                        <>
                          <h4 style={{ borderBottom: "0.5px solid black" }}>
                            {pageData.citytitle}
                          </h4>
                          <TextField
                            data-toggle="tooltip"
                            title="Please Enter Local City"
                            label={pageData.citylocal.label}
                            name={pageData.citylocal.name}
                            placeholder={pageData.citylocal.placeholder}
                            type={pageData.citylocal.type}
                          />
                        </>
                      )}
                      {formik.values.consentlanguage !== "NA" && (
                        <>
                          <h4 style={{ borderBottom: "0.5px solid black" }}>
                            {pageData.langconsenttitle}
                          </h4>
                          <MultiSelect
                            label={pageData.langconsent.label}
                            value={formik.values.consentlanguage}
                            name={pageData.langconsent.name}
                            isMulti={true}
                            formik={formik}
                            options={Language}
                            onChange={(value) => {
                              formik.setFieldValue("consentlanguage", value);
                            }}
                          />
                        </>
                      )}
                      {formik.values.gender !== "NA" && (
                        <>
                          <MultiSelect
                            label={pageData.genderlist.label}
                            value={formik.values.gender}
                            name={pageData.genderlist.name}
                            isMulti={true}
                            formik={formik}
                            options={Genderlist}
                            onChange={(value) => {
                              formik.setFieldValue("gender", value);
                            }}
                          />
                        </>
                      )}
                      {formik.values.food_habit !== "NA" && (
                        <>
                          <MultiSelect
                            label={pageData.habitfood.label}
                            value={formik.values.food_habit}
                            name={pageData.habitfood.label}
                            isMulti={true}
                            formik={formik}
                            options={foodhabit}
                            onChange={(value) => {
                              formik.setFieldValue("food_habit", value);
                            }}
                          />
                        </>
                      )}
                      {formik.values.lastresponse !== "NA" && (
                        <>
                          <TextField
                            data-toggle="tooltip"
                            title="Please Enter Last Response"
                            label={pageData.respolast.label}
                            name={pageData.respolast.name}
                            placeholder={pageData.respolast.placeholder}
                            type={pageData.respolast.type}
                          />
                        </>
                      )}
                      {formik.values.lotdate !== "NA" && (
                        <>
                          <TextField
                            data-toggle="tooltip"
                            label="Data upload Date"
                            name="lotdate"
                            placeholder={pageData.respolast.placeholder}
                            type="date"
                          />
                        </>
                      )}
                      <div className="d-flex " style={{ marginLeft: "500px" }}>
                        <button
                          type="submit"
                          className="btn btn-primary btn-sm m-2"
                        >
                          {pageData.dataserchbtn}
                        </button>
                        <button
                          type="reset"
                          className="btn btn-primary btn-sm m-2"
                        >
                          {pageData.dataclearbtn}
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
              {searchfount && searchdata.finalresult && (
                <div>
                  <div className="card card-body border-light shadow">
                    <div className="table-settings mb-4">
                      <p>
                        {pageData.tablecount} : {searchdata.finalresult.length}
                      </p>
                      <div className="my-2">
                        <DataGrid
                          //loading={loadingData}
                          getRowId={(r) => r.cuid}
                          rows={searchdata ? searchdata.finalresult : []}
                          columns={columns}
                          //rowCount={totalUsers}
                          pageSize={10}
                          rowsPerPageOptions={[10, 25, 25, 50, 100]}
                          // checkboxSelection
                          //paginationMode="server"
                          onFilterModelChange={onFilterChange}
                          // onPageChange={handlePageChange}
                          autoHeight={true}
                          className="bg-white"
                          components={{
                            Toolbar: CustomToolbar,
                          }}
                          density="compact"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div
              className="modal fade col-6"
              id="cardtoggel1"
              tabindex="-1"
              role="dialog"
              aria-labelledby="modalTitleNotify"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered ml-1"
                role="document"
              >
                <div className="modal-content " style={{ marginTop: "250px" }}>
                  {apiprocess === true && (
                    <div className="justify-content-center">
                      <div class="spinner-grow text-info m-2" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  )}
                  {apiprocess === false && (
                    <>
                      <div className="modal-header">
                        <h6 className="">
                          {pageData.dailerdata} {botAction.replaceAll("-", " ")}
                        </h6>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                        ></button>
                      </div>
                      <div className="modal-body">
                        {searchfount && searchdata.finalresult && (
                          <div>
                            <span>
                              Total Data:{" "}
                              {searchdata ? searchdata.finalresult.length : 0}
                            </span>
                          </div>
                        )}
                        {/* */}
                        {(botAction === "POST-TO-BOT" ||
                          botAction === "POST-TO-BOT-RECORDING") && (
                          <Formik
                            initialValues={{
                              dgl: "",
                              campid: "",
                              priority: "",
                              retry_interval: 0,
                              retry: "",
                              use_master_recording: false,
                            }}
                            onSubmit={postFroBotRecording}
                          >
                            {(formik) => (
                              <div className="">
                                <Form>
                                  <div className="row mt-3 m-1 p-1">
                                    <div className="d-flex">
                                      <MultiSelect
                                        label="Templete Script"
                                        name={pageData.templetedata.name}
                                        placeholder={
                                          pageData.templetedata.placeholder
                                        }
                                        isMulti={false}
                                        options={botscripttemp}
                                        formik={formik}
                                        value={formik.values.dgl}
                                        onChange={async (value) => {
                                          formik.setFieldValue("dgl", value);
                                          let dgl = value.value.split("~")[1];
                                          let bb = await data.find(
                                            (a) => a.dgltext == dgl
                                          );
                                          await setScripttext(bb.voice_txt);
                                        }}
                                      />
                                      <div
                                        className="overflow-auto ml-5 mt-3"
                                        style={{
                                          width: "600px",
                                          height: "50px",
                                          border: "1px solid #ccc",
                                        }}
                                      >
                                        {scripttext && (
                                          <span className="text-muted ">
                                            "
                                            {scripttext.length > 0
                                              ? scripttext
                                              : "No Script Avilable"}
                                            "
                                          </span>
                                        )}
                                      </div>
                                    </div>

                                    {botAction === "POST-TO-BOT" && (
                                      <>
                                        <MultiSelect
                                          label={pageData.camp2.label}
                                          name={pageData.camp2.name}
                                          placeholder={
                                            pageData.camp2.placeholder
                                          }
                                          isMulti={false}
                                          options={allCamps}
                                          formik={formik}
                                          value={formik.values.campid}
                                          onChange={async (value) => {
                                            formik.setFieldValue(
                                              "campid",
                                              value
                                            );
                                          }}
                                        />
                                        <MultiSelect
                                          label={pageData.prioritydata.label}
                                          name={pageData.prioritydata.name}
                                          placeholder={
                                            pageData.prioritydata.placeholder
                                          }
                                          isMulti={false}
                                          options={priority}
                                          formik={formik}
                                          value={formik.values.priority}
                                          onChange={async (value) => {
                                            formik.setFieldValue(
                                              "priority",
                                              value
                                            );
                                          }}
                                        />
                                        <MultiSelect
                                          label="Select Drug Name"
                                          name="drug"
                                          isMulti={false}
                                          options={getDrugData}
                                          formik={formik}
                                          value={formik.values.drug}
                                          onChange={async (value) => {
                                            formik.setFieldValue(
                                              "drugcheck",
                                              value.value
                                            );
                                          }}
                                        />

                                        <Toggle
                                          // className="ml-3"
                                          data-toggle="tooltip"
                                          title="Data Retry"
                                          label={pageData.retrytogle.label}
                                          name={pageData.retrytogle.name}
                                          type={pageData.retrytogle.type}
                                          defaultChecked={formik.values.retry}
                                          onChange={(e) =>
                                            formik.setFieldValue(
                                              "retry",
                                              e.target.checked
                                            )
                                          }
                                        />
                                        <Toggle
                                          className="ml-3"
                                          title="Use Master Recording"
                                          data-toggle="tooltip"
                                          label="Recording"
                                          name="use_master_recording"
                                          type="switch"
                                          defaultChecked={
                                            formik.values.use_master_recording
                                          }
                                          onChange={(e) =>
                                            formik.setFieldValue(
                                              "use_master_recording",
                                              e.target.checked
                                            )
                                          }
                                        />

                                        {formik.values.retry === true && (
                                          <TextField
                                            data-toggle="tooltip"
                                            title="Please Enter Retry Interval Number"
                                            label={pageData.interval.label}
                                            name={pageData.interval.name}
                                            type={pageData.interval.type}
                                            placeholder={
                                              pageData.interval.placeholder
                                            }
                                          />
                                        )}
                                      </>
                                    )}
                                  </div>
                                  <div className="d-flex justify-content-end mt-1">
                                    <button
                                      type="submit"
                                      className="btn btn-primary btn-sm m-2"
                                    >
                                      {pageData.subbtn}
                                    </button>
                                  </div>
                                </Form>
                              </div>
                            )}
                          </Formik>
                        )}

                        {botAction === "POST-TO-DIALER" && (
                          <Formik
                            initialValues={{
                              campaign_id: "",
                              priority: "",
                              retry: 0,
                              retry_interval: 0,
                              Agent_id: { value: "NA", label: "NA" },
                              Extra1: "",
                              CallType: "",
                              Pacing: "",
                              pacing_value: 0,
                              Queue_id: "",
                            }}
                            onSubmit={postToDialer}
                          >
                            {(formik) => (
                              <div className="">
                                <Form>
                                  <div className="row mt-3 m-1 p-1">
                                    {/* <div className=''> */}
                                    <MultiSelect
                                      label="Campaign"
                                      name="camp"
                                      placeholder={
                                        pageData.templetedata.placeholder
                                      }
                                      isMulti={false}
                                      options={allCamps}
                                      formik={formik}
                                      value={formik.values.camp}
                                      onChange={async (value) => {
                                        formik.setFieldValue(
                                          "campaign_id",
                                          value.value
                                        );
                                        // let dgl = value.value.split("~")[1]
                                        // let bb = await data.find((a) => a.dgltext == dgl)
                                        // await setScripttext(bb.voice_txt)
                                      }}
                                    />
                                    {/* <div className='overflow-auto ml-5 mt-3' style={{ width: "600px", height: "50px", border: "1px solid #ccc" }}>

                                                                                {
                                                                                    scripttext && <span className='text-muted '>

                                                                                        "{scripttext.length > 0 ? scripttext : "No Script Avilable"}"
                                                                                    </span>
                                                                                }</div> */}

                                    <MultiSelect
                                      label="Select Drug Name"
                                      name="drug"
                                      isMulti={false}
                                      options={getDrugData}
                                      formik={formik}
                                      value={formik.values.drug}
                                      onChange={async (value) => {
                                        formik.setFieldValue(
                                          "drugcheck",
                                          value.value
                                        );
                                      }}
                                    />
                                    <MultiSelect
                                      label="priority"
                                      name="priori"
                                      placeholder="priori"
                                      isMulti={false}
                                      options={priority}
                                      formik={formik}
                                      value={formik.values.priori}
                                      onChange={async (value) => {
                                        formik.setFieldValue(
                                          "priority",
                                          value.value
                                        );
                                      }}
                                    />
                                    <MultiSelect
                                      label="User Name"
                                      name="Agent_id"
                                      placeholder="Select Tepmlete"
                                      isMulti={false}
                                      options={agentlistdata}
                                      formik={formik}
                                      value={formik.values.Agent_id}
                                      onChange={async (value) => {
                                        formik.setFieldValue("Agent_id", value);
                                      }}
                                    />
                                    {/* <TextField
                                                                                title="Lot Id"
                                                                                data-toggle="tooltip"
                                                                                label="loadid"
                                                                                name="lot_id"
                                                                                placeholder="Lot id"
                                                                                type="text"
                                                                            /> */}
                                    <Toggle
                                      // className="ml-3"
                                      data-toggle="tooltip"
                                      title="Data Retry"
                                      label={pageData.retrytogle.label}
                                      name={pageData.retrytogle.name}
                                      type={pageData.retrytogle.type}
                                      defaultChecked={formik.values.retry}
                                      onChange={(e) =>
                                        formik.setFieldValue(
                                          "retry",
                                          e.target.checked
                                        )
                                      }
                                    />
                                    {formik.values.retry === true && (
                                      <TextField
                                        data-toggle="tooltip"
                                        title="Please Enter Retry Interval Number"
                                        label={pageData.interval.label}
                                        name={pageData.interval.name}
                                        type="number"
                                        placeholder={
                                          pageData.interval.placeholder
                                        }
                                      />
                                    )}
                                    {/* </div> */}
                                  </div>
                                  <div className="d-flex justify-content-end mt-1">
                                    <button
                                      type="submit"
                                      className="btn btn-primary btn-sm m-2"
                                    >
                                      {pageData.subbtn}
                                    </button>
                                  </div>
                                </Form>
                              </div>
                            )}
                          </Formik>
                        )}

                        {(botAction === "DE-ALLOCATE" ||
                          botAction === "ALLOCATE") && (
                          <div>
                            <Formik
                              initialValues={{ userid: "" }}
                              validationSchema={allocationValidation}
                              onSubmit={alloc_Dealloca}
                            >
                              {(formik) => (
                                <div className="">
                                  <Form>
                                    <div className="row mt-3 m-1 p-1">
                                      <MultiSelect
                                        title="Campiagn"
                                        data-toggle="tooltip"
                                        label="Campiagn"
                                        name="campid"
                                        value={formik.values.campid}
                                        isMulti={false}
                                        options={allCamps}
                                        onChange={(value) =>
                                          formik.setFieldValue("campid", value)
                                        }
                                      />
                                      <MultiSelect
                                        label="User Name"
                                        name="dgl"
                                        placeholder="Select Tepmlete"
                                        isMulti={true}
                                        options={agentlistdata}
                                        formik={formik}
                                        value={formik.values.userid}
                                        onChange={async (value) => {
                                          formik.setFieldValue("userid", value);
                                        }}
                                      />
                                      <TextField
                                        title="Total Count Data"
                                        data-toggle="tooltip"
                                        label={pageData.allocount.label}
                                        name={pageData.allocount.name}
                                        placeholder={
                                          pageData.allocount.placeholder
                                        }
                                        type={pageData.allocount.type}
                                      />
                                    </div>
                                    <div className="d-flex justify-content-end mt-1">
                                      <button
                                        type="submit"
                                        className="btn btn-primary btn-sm m-2"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </Form>
                                </div>
                              )}
                            </Formik>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
