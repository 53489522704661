import React, { useEffect, useState, useRef } from "react";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import DaynmicApicall from "../../../utils/function";
import {
  DataGrid,
  GridToolbarContainer,
  gridClasses,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { Button, Stack } from "@mui/material";
import api from "../../../utils/api";
import EditIcon from "@mui/icons-material/Edit";
import DyanmicTabForm from "./DyanmicTabForm";
import toast from "react-hot-toast";
import ErrorBoundary from "../../../ErrorBoundary";
import VocTab from "./VocTab";
import OtherInfoTab from "./OtherInfoTab";

export default function OtherCustomerGrid({ keypointer, componentid, rest }) {
  const { userInfo } = useSelector((state) => state?.user?.value);
  const { UserToken, userid, usergroup, dlrAgentId } = userInfo.data[0];
  let { crmsaveandexit, customerInfo } = useSelector((state) => state?.dialer);
  const [initial, setInitial] = useState(null);
  const [pageData, setpageData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [columns, setColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [updatedValues, setUpdatedValues] = useState("");
  let DialerCrmData = localStorage.getItem("currentcall")
    ? JSON.parse(JSON.parse(localStorage.currentcall).crm_data)
    : "";

  async function getOtherData() {
    try {
      const data = await DaynmicApicall(
        `mcrmdlr/getothercustinfo/${keypointer}/${componentid}/${crmsaveandexit.cust_id}`,
        "",
        "get",
        UserToken
      );

      const col = Object.keys(data[0]?.input_json).map((item) => ({
        field: item,
        headerName: item.toUpperCase(),
        editable: true,
        minWidth: item === "date" ? 200 : 100,
        flex: 1,
      }));

      col.unshift({
        field: "Action",
        headerName: "Edit",
        type: "actions",
        width: 60,
        renderCell: (params) => (
          <EditIcon
            onClick={(event) => {
              params.row.action_name = "UPDATE";
              setUpdatedValues(params.row);
              event.stopPropagation();
            }}
          />
        ),
      });
      setColumns(col);

      const arr = data.map((item) => item?.input_json);
      setTableData(arr);
    } catch (error) {
      console.log("ERROR", error.message);
      setTableData([]);
    }
  }

  async function pageInfo() {
    await DaynmicApicall(
      `/appuser/getcomponetbyid/${keypointer}/${componentid}`,
      "",
      "get",
      UserToken
    )
      .then((data) => {
        setpageData(data[0] ? data[0]?.DATA : []);
      })
      .catch((err) => {
        console.log("ERROR", err);
        setpageData([]);
      });
  }

  useEffect(() => {
    pageInfo();
    getOtherData();

    let init = pageData?.reduce((acc, field) => {
      acc[field.name] = field.type === "select" && field.isMulti ? [] : "";

      return acc;
    }, {});
    init.action_name = "INSERT";
    setInitial(init);
  }, []);

  function CustomToolbar() {
    return (
      <Stack direction="row" justifyContent="flex-end">
        <GridToolbarContainer className={gridClasses.toolbarContainer}>
          <GridToolbarFilterButton />
        </GridToolbarContainer>
      </Stack>
    );
  }
  const onFilterChange = React.useCallback(async (filterModel) => {
    if (
      filterModel?.items?.[0]?.value &&
      filterModel?.items?.[0]?.value.length > 0
    ) {
      // setFilterColumn(filterModel?.items?.[0]?.columnField);
      // setFilterValue(filterModel?.items?.[0]?.value);
    }
  }, []);

  function generateRandom() {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  async function formsubmit(values) {
    try {
      Object.entries(values).map((data) => {
        if (typeof data[1] === "object") {
          values[data[0]] = data[1].value;
        }
      });

      let defaultValue = {
        custid: crmsaveandexit?.cust_id,
        call_date: new Date().toISOString().split("T")[0],
        agentid: userid,
        campid: crmsaveandexit?.campid,
        compid: componentid,
        pol_loan_no: customerInfo?.policy_no,
        keypointer: keypointer,
        call_id: 0,
        tab_name: rest?.menu_name,
        resp_code: "",
        sub_resp_code: "",
        sessionid: crmsaveandexit?.dialer_session_id,
        crmuuid: DialerCrmData?.dialeruuid,
        action_name: "INSERT",
      };

      const Info = api.post(
        "/mcrmdlr/custothersinfo",
        { ...values, ...defaultValue },
        {
          headers: { Authorization: UserToken },
        }
      );
      toast
        .promise(Info, {
          success: (Info) => {
            return Info.data.message;
          },
          error: (err) => {
            return (
              err?.response?.data?.errors ??
              "Please contact to dialer admin & Save the call" ??
              err?.message ??
              "OOPs something went wrong."
            );
          },
        })
        .then(async (Info) => {
          await getOtherData();
        })
        .catch((error) => {
          console.log("Error", error.message);
          toast.error(error.response?.data?.errors);
        });
    } catch (error) {
      console.error("API Error:", error);
    }
  }

  async function otherInfo(view, custid) {
    try {
      const data = await DaynmicApicall(
        `mcrmdlr/getotherreporting/${view}/${custid}/${keypointer}`,
        "",
        "get",
        UserToken
      );
      const col = Object.keys(data[0]).map((item) => ({
        field: item,
        headerName: item.toUpperCase(),
        editable: true,
        minWidth: item === "date" ? 200 : 150,
      }));
      setColumns(col);

      setTableData(data);
    } catch (error) {
      console.log("ERROR", error.message);
      setTableData([]);
    }
  }

  return (
    <>
      {(() => {
        switch (componentid) {
          case 167:
            return (
              <ErrorBoundary>
                <VocTab
                  tabInfo={rest}
                  initialValues={updatedValues}
                  submitfunction={formsubmit}
                />
              </ErrorBoundary>
            );
          case 169:
            return (
              <ErrorBoundary>
                <OtherInfoTab tabInfo={rest} getfunction={otherInfo} />
              </ErrorBoundary>
            );
          case 171:
            return (
              <ErrorBoundary>
                <OtherInfoTab tabInfo={rest} getfunction={otherInfo} />
              </ErrorBoundary>
            );
          default:
            return (
              <div className="card p-4">
                {initial && (
                  <ErrorBoundary>
                    <DyanmicTabForm
                      submitfunction={formsubmit}
                      initialValues={updatedValues ? updatedValues : initial}
                      fields={pageData}
                      setActionName={setUpdatedValues}
                    />
                  </ErrorBoundary>
                )}
              </div>
            );
        }
      })()}
      {tableData.length > 0 && (
        <div className="my-2 card">
          <DataGrid
            getRowId={(row) => row.sessionid}
            rows={tableData.length > 0 ? tableData : []}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 20, 25]}
            onFilterModelChange={onFilterChange}
            autoHeight={true}
            className="bg-white"
            components={{
              Toolbar: CustomToolbar,
            }}
            density="compact"
          />
        </div>
      )}
    </>
  );
}
