import React, { useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { Button, Stack, Tab, Tabs } from "react-bootstrap";
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  gridClasses,
} from "@mui/material";
import DaynmicApicall from "../../../utils/function";
import socket from "../../../utils/socket";
import CallEndIcon from "@mui/icons-material/CallEnd";
import HearingIcon from "@mui/icons-material/Hearing";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import CallMergeIcon from "@mui/icons-material/CallMerge";
import PhoneDisabledIcon from "@mui/icons-material/PhoneDisabled";
import RefreshIcon from "@mui/icons-material/Refresh";
import SanitizerIcon from "@mui/icons-material/Sanitizer";
import DialerSipIcon from "@mui/icons-material/DialerSip";
import ManageServerConfig from "../../ManageCampaigns/ManageServerConfig";
import { MultiSelect } from "../../../components/formValidation/MultiSelect";
import { Form, Formik } from "formik";
import api from "../../../utils/api";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";
import "react-loading-skeleton/dist/skeleton.css";
import PolicyInfoModel from "../../../components/CrmServices/PolicyInfo/PolicyInfoModel";
import CallHistoryTL from "../../../components/CrmServices/EngageHistory/CallHistoryTL";
import AgentIneraction from "../../../components/CrmServices/EngageHistory/AgentInteraction";

export default function DialerDashboard(props) {
  const { userInfo } = useSelector((state) => state?.user?.value);
  const { UserToken, userid, usergroup, dlrAgentId } = userInfo.data[0];
  const [getUserPhoneList, setuserphone] = useState([]);
  const [queue, setQueue] = useState([]);
  const [getqueue, setqueueStatus] = useState([]);
  const [queueReload, setQueueReload] = useState(false);
  const [getreacltimedata, setreacltimedata] = useState([]);
  const [showUnique, setShowUnique] = useState(false);
  const [getCardData, setCardData] = useState({});
  const [rowdata, setRowdata] = useState("");
  const [getCallConnectEvent, setcallConnectevent] = useState(false);
  const [isReferesh, setIsReferesh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [pageSize, setPageSize] = useState(50);
  const [customerinfo, setcustomerinfo] = useState({});
  const [filterColumn, setFilterColumn] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(async () => {
    await getQueue();
    await getuserphonelist();
    setIsReferesh(false);
    setqueueStatus([]);
  }, []);

  useEffect(() => {
    async function fetchData() {
      setIsReferesh(false);
      try {
        setIsLoading(true);
        setIsLoading1(true);
        if (queue.length > 0) {
          await getrealtimedata(queue);
          setIsLoading(false);
        }
        if (getqueue.length > 0) {
          await getDashboardCardData(getqueue);
          setIsLoading1(false);
        } else {
          const arr = [];
          queue.map((value) => {
            arr.push(value.value);
          });
          await getDashboardCardData(arr);
          setIsLoading1(false);
        }
        setIsLoading(false);
      } catch (error) {
        console.log("Error from getrealtimedata", error.message);
        setIsLoading(false);
        setIsLoading1(false);
      } finally {
        setIsReferesh(true);
      }
    }
    fetchData();
  }, [getqueue, queue.length > 0]);

  useEffect(() => {
    async function fetchData() {
      try {
        if (queue.length > 0 && isReferesh === true) {
          const arr = getqueue.length > 0 ? getqueue : queue;
          await getrealtimedata(arr);
        }
      } catch (error) {
        console.log("Error from getrealtimedata", error.message);
      }
    }
    fetchData();
    const interval = setInterval(fetchData, 5000);
    return () => clearInterval(interval);
  }, [getqueue, queue, isReferesh]);

  useEffect(() => {
    async function fetchData() {
      try {
        if (getqueue.length > 0) {
          await getDashboardCardData(getqueue);
        } else {
          const arr = [];
          queue.map((value) => {
            arr.push(value.value);
          });
          await getDashboardCardData(arr);
        }
      } catch (error) {
        console.log("Error from getrealtimedata", error.message);
      }
    }

    if (isReferesh === true) fetchData();

    const interval = setInterval(fetchData, 5000);

    return () => clearInterval(interval);
  }, [getqueue.length > 0, queue.length > 0, isReferesh]);

  useEffect(() => {
    async function fetchData() {
      setIsReferesh(false);
      try {
        setIsLoading(true);
        setIsLoading1(true);

        if (getqueue.length > 0) {
          await getrealtimedata(getqueue);
          await getDashboardCardData(getqueue);
          setIsLoading(false);
          setIsLoading1(false);
        } else {
          const arr = [];
          queue.map((value) => {
            arr.push(value.value);
          });
          await getrealtimedata(queue);
          await getDashboardCardData(arr);
          setIsLoading(false);
          setIsLoading1(false);
        }
        setQueueReload(false);
      } catch (error) {
        console.log("Error from getrealtimedata", error.message);
        setIsLoading(false);
        setIsLoading1(false);
      } finally {
        setIsReferesh(true);
      }
    }

    if (queueReload) {
      fetchData();
    }
  }, [queueReload]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseDialog = (event, reason) => {
    if (reason !== "backdropClick") {
      handleClose(event, reason);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function getQueue() {
    await DaynmicApicall(
      `appuser/getcampbycampid/ALL/${userInfo.data[0].userid}`,
      "",
      "get"
    )
      .then(async (res) => {
        let bData = [];
        bData = res?.map((item) => {
          return {
            label: item.campname,
            value: item.queue_name,
          };
        });
        setQueue(bData);
      })
      .catch((err) => {
        console.log("ERROR", err);
      });
  }

  async function getuserphonelist() {
    await DaynmicApicall(
      `appuser/getuserphonelist/`,
      "",
      "get",
      userInfo.data[0].UserToken
    )
      .then((data) => {
        setuserphone(data);
      })
      .catch((err) => {
        console.log("ERROR", err);
      });
  }

  async function getDashboardCardData(queue) {
    const payload = {
      queueid: queue,
    };
    await DaynmicApicall(
      `dialer/getmanagedashboarddata`,
      payload,
      "post",
      userInfo.data[0].UserToken
    )
      .then((data) => {
        setCardData(data?.data);
      })
      .catch((err) => {
        console.log("ERROR", err);
      });
  }

  async function getrealtimedata(Queue) {
    try {
      let str = "";
      Queue.forEach((data, index) => {
        str = str + (data.value ? `'${data.value}'` : `'${data}'`);
        if (index < Queue.length - 1) {
          str = str + ", ";
        }
      });
      const body = {
        queueid: str,
      };
      await DaynmicApicall(
        `dialer/getagentlivestatus`,
        body,
        "post",
        userInfo.data[0].UserToken
      )
        .then((data) => {
          setreacltimedata(data.data);
        })
        .catch((err) => {
          console.log("ERROR", err);
        });
    } catch (error) {
      console.log("Error from getrealtimedata", error.message);
    }
  }

  const statusColors = {
    INCALL: "#42A5F5",
    READY: "#66BB6A",
    RINGING: "#EF5350",
    PAUSE: "#ae6313",
    UNAVLBL: "#D13028",
    WRAPUP: "#ef9862",
    UNKNOWN: "#ef9862",
    BUSY: "#ef9862",
    INVALID: "#ef9862",
    BOTCALL: "#3d497c",
  };

  // Function to filter data based on unique user IDs
  const getUniqueData = (data) => {
    const uniqueUsersMap = new Map();

    data.forEach((item) => {
      if (item.status !== "BUSY" && !uniqueUsersMap.has(item.userid)) {
        uniqueUsersMap.set(item.userid, item);
      }
    });

    return Array.from(uniqueUsersMap.values());
  };

  const handleToggleChange = (event) => {
    setShowUnique(event.target.checked);
  };

  const filteredData = showUnique
    ? getUniqueData(getreacltimedata)
    : getreacltimedata;

  const columns = [
    { field: "campaign", headerName: "CAMPAIGN", width: 200 },
    { field: "userid", headerName: "CRM ID", width: 100 },
    { field: "username", headerName: "USER NAME", width: 250 },
    {
      field: "agentid",
      headerName: "STATION",
      width: 100,
      renderCell: (params) => {
        const chipColor = statusColors[params.row.STATUS] || "#262B40";
        const chipTextColor = "#fff";
        return (
          <Chip
            label={params.value}
            style={{ backgroundColor: chipColor, color: chipTextColor }}
            size="small"
            variant="outlined"
            sx={{ width: "100%" }}
          />
        );
      },
    },
    {
      field: "status",
      headerName: "STATUS/CALL TYPE",
      width: 180,
      renderCell: (params) => {
        const chipColor = statusColors[params.value] || "#262B40";
        const chipTextColor = "#fff";

        return (
          <Chip
            label={params.value}
            style={{ backgroundColor: chipColor, color: chipTextColor }}
            size="small"
            variant="outlined"
            sx={{ width: "60%" }}
          />
        );
      },
    },
    {
      field: "time",
      headerName: "HH:MM:SS",
      width: 120,
      renderCell: (params) => {
        return params.value.split(":")[1] > 2 ? (
          <Chip
            label={params.value}
            style={{ backgroundColor: "#FFCDD2", color: "#C62828" }}
            size="small"
            variant="outlined"
            sx={{ width: "90%" }}
          />
        ) : (
          <Chip
            label={params.value}
            style={{ backgroundColor: "#C8E6C9", color: "#2E7D32" }}
            size="small"
            variant="outlined"
            sx={{ width: "90%" }}
          />
        );
      },
    },
    { field: "total_calls", headerName: "TOTAL CALLS", width: 150 },
    {
      field: "Action",
      headerName: "ACTION",
      width: 100,
      type: "actions",
      flex: 1,
      getActions: (params) => {
        const actions = [
          <GridActionsCellItem
            disabled={
              getCallConnectEvent === true || props.snackbarOpen === true
                ? true
                : false
            }
            label="Call Listen"
            icon={<HearingIcon className="text-info" />}
            className=""
            onClick={async (e) => {
              await calltransfer(
                "11",
                params.row.agentid,
                `You are listening ${params.row.agentid} call.`
              );
              const parameter = {
                userid: params.row.userid,
                agentid: params.row.agentid,
                queueid: params.row.queue,
              };
              const Info = await api.post(
                "/mcrmdlr/callinformation",
                parameter,
                {
                  headers: { Authorization: userInfo.data[0].UserToken },
                }
              );
              setcustomerinfo(Info.data);
              handleClickOpen();
            }}
            showInMenu
          />,
          <GridActionsCellItem
            disabled={
              getCallConnectEvent === true || props.snackbarOpen === true
                ? true
                : false
            }
            label="Call Whisper"
            icon={<HeadphonesIcon className="text-info" />}
            onClick={async (e) => {
              await calltransfer(
                "12",
                params.row.agentid,
                `You are whispering ${params.row.agentid} call.`
              );
              const parameter = {
                userid: params.row.userid,
                agentid: params.row.agentid,
                queueid: params.row.queue,
              };
              const Info = await api.post(
                "/mcrmdlr/callinformation",
                parameter,
                {
                  headers: { Authorization: userInfo.data[0].UserToken },
                }
              );
              setcustomerinfo(Info.data);
              handleClickOpen();
            }}
            showInMenu
          />,
          <GridActionsCellItem
            disabled={
              getCallConnectEvent === true || props.snackbarOpen === true
                ? true
                : false
            }
            label="Call Barge"
            icon={<CallMergeIcon className="text-success" />}
            onClick={async (e) => {
              await calltransfer(
                "13",
                params.row.agentid,
                `You are barging ${params.row.agentid} call.`
              );
              const parameter = {
                userid: params.row.userid,
                agentid: params.row.agentid,
                queueid: params.row.queue,
              };
              const Info = await api.post(
                "/mcrmdlr/callinformation",
                parameter,
                {
                  headers: { Authorization: userInfo.data[0].UserToken },
                }
              );
              setcustomerinfo(Info.data);
              handleClickOpen();
            }}
            showInMenu
          />,
          <GridActionsCellItem
            label="Call End"
            icon={<CallEndIcon className="text-danger" />}
            onClick={async (e) => {
              setcallConnectevent(false);
              let endcall = {
                agent_token: userInfo.data[0]?.dlrAgentId,
                action: "end_call",
                event_data: {
                  call_session_id: JSON?.parse(localStorage?.currentListening)
                    ?.call_session_id,
                },
              };
              socket.emit("crmdialerdata", endcall);
              props.setSnackbarOpen(false);
              props.setSnackbarContent("");
            }}
            showInMenu
          />,
          <GridActionsCellItem
            label="Agent free from call"
            icon={<PhoneDisabledIcon className="text-danger" />}
            onClick={(e) => {
              const parameter = {
                userid: params.row.userid,
                agentid: params.row.agentid,
                queueid: params.row.queue,
              };
              api
                .post("mcrmdlr/callend", parameter, {
                  headers: { Authorization: userInfo.data[0].UserToken },
                })
                .then((data) => {
                  toast.success(data.message);
                })
                .catch((error) => {
                  console.log("Error", error?.message);
                  toast.error(
                    error?.response.data.message ??
                      error.message ??
                      "OOPs, something went wrong."
                  );
                });
            }}
            showInMenu
          />,
        ];
        if (usergroup === "ADMIN" || usergroup === "CRM-ADMIN") {
          actions.push(
            <GridActionsCellItem
              label="Free user from dialer"
              icon={<DialerSipIcon className="text-danger" />}
              onClick={(e) => {
                releaseUserFromCalls(params.row);
              }}
              showInMenu
            />,
            <GridActionsCellItem
              label="Clean agent previous status"
              icon={<SanitizerIcon className="text-danger" />}
              onClick={async (e) => {
                const hangupdata = {
                  agent: params.row.agentid,
                };
                let Info = await DaynmicApicall(
                  `ami/superhangup`,
                  hangupdata,
                  "post",
                  userInfo.data[0].UserToken
                );
                let responsedata = Info.find(
                  (data) => data.Event === "ChannelsHungupListComplete"
                );
                toast.success(responsedata.Event);
              }}
              showInMenu
            />
          );
        }
        return actions;
      },
    },
  ];

  async function releaseUserFromCalls(row) {
    const dialeruuid = await DaynmicApicall(
      `dialer/getsessionbyagent/${row.agentid}/${row.queue}`,
      "",
      "get",
      userInfo.data[0].UserToken
    );
    if (dialeruuid.length > 0) {
      const hangupdata = {
        crmuuid: dialeruuid[0]?.dialeruuid,
        external_hangup: "1",
        external_status: `${userInfo?.data[0]?.userid}${userInfo?.data[0]?.usergroup}`,
        agentid: row.agentid,
      };
      const info = await DaynmicApicall(
        `dialer/dialerhangup`,
        hangupdata,
        "post",
        userInfo.data[0].UserToken
      );
      toast.success("Agent free from call");
    }
  }

  async function auditTrails(taskName, errMessage, errRemarks) {
    const parameter = {
      sNo: "404",
      taskName: taskName,
      errMessage: errMessage,
      errRemarks: errRemarks,
    };
    const Info = await DaynmicApicall(
      `err/setError/`,
      parameter,
      "post",
      UserToken
    );
  }

  const handleRowClick = (param, event) => {};

  async function calltransfer(sipcode, phone_extension, data) {
    setcallConnectevent(true);
    const calltranfer = {
      agent_token: userInfo.data[0]?.dlrAgentId,
      action: "dial_call",
      event_data: {
        dial_number: `*${sipcode + phone_extension}`, // 11 is code , 2010 agent extenction
      },
    };
    try {
      await socket.emit("crmdialerdata", calltranfer);
      props.handleSnackbarOpen({}, data);
    } catch (error) {
      console.error("Dial Call Error:", error);
      props.handleSnackbarOpen({}, "Dial Call Error");
    }
  }

  function CustomToolbar() {
    return (
      <>
        <Stack direction="row" justifycontent="flex-end">
          <GridToolbarContainer
            className={gridClasses.toolbarContainer}
            style={{ justifyContent: "space-between" }}
          >
            {/* <GridToolbarExport /> */}
            <div>
              <GridToolbarColumnsButton />
              <GridToolbarDensitySelector />
              <GridToolbarFilterButton />
            </div>
            {getqueue.length == 0 && (
              <div>
                <label>Show Unique Agent ID's</label>
                <Switch checked={showUnique} onChange={handleToggleChange} />
              </div>
            )}
          </GridToolbarContainer>
        </Stack>
      </>
    );
  }

  const onFilterChange = React.useCallback(async (filterModel) => {
    if (
      filterModel?.items?.[0]?.value &&
      filterModel?.items?.[0]?.value.length > 0
    ) {
      setFilterColumn(filterModel?.items?.[0]?.columnField);
      setFilterValue(filterModel?.items?.[0]?.value);
    }
  }, []);

  function generateRandom() {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  return (
    <>
      <div>
        {rowdata && <ManageServerConfig rowdata={rowdata} />}
        <div className="container-fluid py-4">
          <div className="row">
            <div className="card col-xl-12 bg-dark text-white">
              <div className="row row-cols-md-2 m-2 p-2 row-cols-lg-2 row-cols-xl-2 font-weight-bold">
                <span
                  className="text-left"
                  title="Hello Admin"
                  data-toggle="tooltip"
                >
                  Hello, {userInfo.data[0].username}
                  <span className="text-success">
                    ({userInfo.data[0].userid})
                  </span>
                </span>
                <span
                  className="text-right"
                  title="Current Show Day and Date"
                  data-toggle="tooltip"
                >
                  {new Date().toLocaleDateString(undefined, {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-1">
          <div className="card p-1">
            <div className="row">
              <div className="col-sm-12 col-md-10 col-lg-10 col-xl-10">
                <Formik
                  initialValues={{}}
                  // validationSchema={validate}
                  // onSubmit={onSubmit}
                >
                  {(formik) => (
                    <div>
                      <Form>
                        <MultiSelect
                          label="Select Campaign"
                          name="queeu"
                          isMulti={true}
                          formik={formik}
                          options={queue}
                          format={true}
                          onChange={(value) => {
                            formik.setFieldValue("queeu", value);
                            const arr = [];
                            value?.map((value) => {
                              arr.push(value.value);
                            });
                            setqueueStatus(arr);
                            setShowUnique(false);
                          }}
                        />
                      </Form>
                    </div>
                  )}
                </Formik>
              </div>
              <div className="col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <div className="justify-content-end">
                  <RefreshIcon
                    onClick={() => setQueueReload(true)}
                    className="text-success float-right btn-outline-dark rounded"
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="row mt-1 pr-1 pl-1">
              {isLoading1 ? (
                <div className="loading-overlay" style={{ height: "100px" }}>
                  <SkeletonTheme
                    color="#f3f3f3"
                    highlightColor="#ecebeb"
                    style={{ height: "100%" }}
                  >
                    <Skeleton style={{ height: "80%" }} />
                  </SkeletonTheme>
                </div>
              ) : (
                getCardData &&
                Object.entries(getCardData).map((data, index) => (
                  <div
                    className="col-sm-6 col-md-4 col-lg-2 col-xl-2 m-0"
                    key={index}
                  >
                    <div className="card mb-3 p-2 border-right-4 secondary m-0 border-2 border-secondary">
                      <Tooltip title={data[1]?.split(",")[1]} arrow>
                        <div className="row">
                          <div
                            className="col-7"
                            style={{
                              color: "#262B40",
                              fontWeight: "bold",
                              fontSize: "10px",
                            }}
                          >
                            <span className="">{data[0]}</span>
                          </div>
                          <div className="col-5">
                            <span
                              style={{ fontWeight: "bold", fontSize: "15px" }}
                              className={
                                data > 5 ? "text-success" : "text-danger"
                              }
                            >
                              {data[1]?.split(",")[0]}
                            </span>
                          </div>
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
          <div className="row card mt-4">
            {isLoading ? (
              <div className="loading-overlay pt-5" style={{ height: "200px" }}>
                <SkeletonTheme
                  color="#f3f3f3"
                  highlightColor="#ecebeb"
                  style={{ height: "100%" }}
                >
                  <Skeleton style={{ height: "80%" }} />
                </SkeletonTheme>
              </div>
            ) : (
              getreacltimedata && (
                <div className="table-settings mb-4">
                  <div className="my-2">
                    <div
                      className="text-right"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Refresh Table Data"
                    ></div>
                    <DataGrid
                      getRowId={(row) => generateRandom()}
                      rows={filteredData}
                      columns={columns}
                      rowCount={filteredData.length}
                      rowHeight={40}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      rowsPerPageOptions={[50, 70, 100]}
                      onFilterModelChange={onFilterChange}
                      onRowClick={handleRowClick}
                      autoHeight={true}
                      className="bg-white"
                      getSelectedRows={(data) => console.log("DATAA", data)}
                      density="compact"
                      components={{
                        Toolbar: CustomToolbar,
                      }}
                    />
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>

      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={open}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Call Information</DialogTitle>
        <DialogContent>
          <Tabs
            className="tabLI"
            defaultActiveKey="agentinteraction"
            style={{ paddingBottom: "10px" }}
          >
            <Tab
              eventKey="agentinteraction"
              title="Agent Interaction"
              style={{ overflowX: "clip" }}
            >
              <AgentIneraction userData={customerinfo} />
            </Tab>
            <Tab
              eventKey="history"
              title="History"
              style={{ overflowX: "clip" }}
            >
              <CallHistoryTL userData={customerinfo} />
            </Tab>
            <Tab
              eventKey="information"
              title="Customer Information"
              style={{ overflowX: "clip" }}
            >
              <PolicyInfoModel userData={customerinfo} />
            </Tab>
          </Tabs>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
