import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { Chip, Stack } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import DaynmicApicall from "../../utils/function";
import { useSelector } from "react-redux";
import "./AgentDashboard.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function AgentDashTable(props) {
  const { userInfo } = useSelector((state) => state?.user?.value);
  const [filterColumn, setFilterColumn] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [tableData, setTableData] = useState("");
  let order;
  let agentid;
  let action = props?.data?.paremsData.action === "action";
  async function gettop10Agentcount() {
    order = "top10";
    agentid = userInfo.data[0].userid;
    await DaynmicApicall(
      `/mcrmdlr/dailyagentperformance/${agentid}/${order}`,
      "",
      "get",
      userInfo.data[0].UserToken
    )
      .then((res) => {
        setTableData(res);
        props.setLoading(false);
      })
      .catch((err) => {
        console.log("ERROR", err);
        setTableData([]);
        props.setLoading(false);
      });
  }
  useEffect(async () => {
    if (props.loading === true) await gettop10Agentcount();
  }, [props]);
  const columns = [
    {
      field: "dlragentid",
      headerName: "Dialer Agent Id",
      minWidth: 100,
      flex: 1,
    },
    { field: "userid", headerName: "Agent Id", minWidth: 150, flex: 1 },
    { field: "username", headerName: "Agent Name", minWidth: 150, flex: 1 },
    {
      field: "agent_call_count",
      headerName: "Total Calls",
      minWidth: 150,
      flex: 1,
    },
    { field: "positive", headerName: "Positive", minWidth: 150, flex: 1 },
    { field: "negative", headerName: "Negative", minWidth: 150, flex: 1 },
    { field: "neutral", headerName: "Netural", minWidth: 150, flex: 1 },
  ];
  function CustomToolbar() {
    return (
      <Stack direction="row" justifyContent="flex-end">
        <GridToolbarContainer className={gridClasses.toolbarContainer}>
          <GridToolbarExport />
          <GridToolbarFilterButton />
        </GridToolbarContainer>
      </Stack>
    );
  }
  const onFilterChange = React.useCallback(async (filterModel) => {
    if (
      filterModel?.items?.[0]?.value &&
      filterModel?.items?.[0]?.value.length > 0
    ) {
      setFilterColumn(filterModel?.items?.[0]?.columnField);
      setFilterValue(filterModel?.items?.[0]?.value);
    }
  }, []);
  return (
    <div style={{ height: "100%" }}>
      {(tableData && (
        <DataGrid
          getRowId={(r) => r.userid}
          rows={tableData}
          columns={columns}
          rowHeight={25}
          pageSize={10}
          autoHeight={true}
          className="bg-white"
          density="compact"
        />
      )) || (
        <SkeletonTheme height="10">
          <div style={{ height: "100%" }}>
            <Skeleton className="skeleton" />
          </div>
        </SkeletonTheme>
      )}
    </div>
  );
}
