import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { getFormData } from "../../helpers/helpers";
import api from "../../utils/api";
import { Formik, Form } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { TextField } from "../../components/formValidation/TextField";
import DaynmicApicall, { ManageEventLog } from "../../utils/function";


export default function ChangePassword(props) {
  const navigate = useNavigate();
  const { isLoggedIn, userInfo } = useSelector((state) => state?.user?.value);


  const changPass = {

    cardstyle: {
      backgroundColor: " ",
      width: "50rem",
      marginbottom: "5rem",
    },
    userid: {

      label: "User Id",
      name: "userid",
      placeholder: "Enter User Id",
      type: "text",
    },
    oldpassword: {

      label: "Old password",
      name: "userpwd",
      placeholder: "Enter your old password",
      type: "password",
      controlType: "input",
      style: {
        color: "#70657b",
      },
      tooltip: "Enter your old password",
    },
    newpassword: {

      label: "New password",
      name: "usernewpwd",
      placeholder: "User New Password",
      type: "password",

      controlType: "input",
      style: {
        color: "#70657b",
      },

    },
    confermpassword: {

      label: "Confirm Password",
      placeholder: "User confirm password",
      name: 'confermpassword',
      type: "password",
      style: {
        color: "#70657b",
      },
    },
    ChangePasswordbtn: {
      value: "Change Password",

      type: "submit",
      style: {
        backgroundColor: "#39",
        width: "17rem",
      },

    },
    backlogingbtn: {
      label: "Back Login Page",

    }
  }
  let validate = Yup.object({
    usernewpwd: Yup.string()
      .required("No password provided.")
      .min(8, "Password is too short - should be 8 chars minimum.")
      .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),

    confermpassword: Yup.string()
      .oneOf([Yup.ref("usernewpwd"), null], "Password Must Match")
      .required("Confermpassword is must be same as New Password"),
  });

  let initial = {
    userid: isLoggedIn ? userInfo.data[0].userid : "",
    userpwd: "",
    usernewpwd: "",
    confermpassword: "",
    pwdtype: "CHANGE_PWD",
  };

  const onSubmit = async (values, { resetForm }) => {
    delete values.confermpassword;
    const Info = api.post("/appuser/managepassword/", values);
    toast
      .promise(Info, {
        loading: "Authenticating member.",
        success: (Info) => {
          return Info.data.message;
        },
        error: (err) => {
          return (
            err?.response?.data?.errors ??
            err?.response?.data?.message ??
            err?.message ??
            "OOPs something went wrong."
          );
        },
      })
      .then((data) => {
        if (!isLoggedIn && data.data.status === true) {
          navigate("/", { replace: true });
        }
      });
  };


  return (
    <>
      <section className="d-flex align-items-center">
        <div className="col-6 ml-12" style={{ marginTop: "-30px" }}>
          <div className="wrapper">
            <div className="log">
              <Formik
                initialValues={initial}
                validationSchema={validate}
                onSubmit={onSubmit}
              >
                {(formik) => (
                  <div style={changPass?.cardstyle}>
                    <h2>{changPass?.title}</h2>
                    <Form>
                      {!isLoggedIn ? (
                        <TextField
                          data-toggle="tooltip"
                          title={changPass?.userid?.title}
                          label={changPass?.userid?.label}
                          name={changPass?.userid.name}
                          placeholder={changPass?.userid?.placeholder}
                          type={changPass?.userid?.type}
                        />
                      ) : (
                        ""
                      )}
                      <TextField

                        title={changPass?.oldpassword?.title}
                        label={changPass?.oldpassword?.label}
                        name={changPass?.oldpassword?.name}
                        placeholder={changPass?.oldpassword?.placeholder}
                        type={changPass?.oldpassword?.type}
                        data-toggle="tooltip"
                      />
                      <TextField
                        data-toggle="tooltip"
                        title={changPass?.newpassword?.title}
                        label={changPass?.newpassword?.label}
                        name={changPass?.newpassword?.name}
                        placeholder={changPass?.newpassword?.placeholder}
                        type={changPass?.newpassword?.type}
                      />
                      <TextField
                        data-toggle="tooltip"
                        title={changPass?.confermpassword?.title}
                        label={changPass?.confermpassword?.label}
                        name={changPass?.confermpassword?.name}
                        placeholder={changPass?.confermpassword?.placeholder}
                        type={changPass?.confermpassword?.type}
                      />
                      <div className="mt-4">
                        <button
                          data-toggle="tooltip"
                          type={changPass?.ChangePasswordbtn?.type}
                          className="btn btn-primary text-white "
                          style={changPass?.ChangePasswordbtn?.style}
                          title={changPass?.ChangePasswordbtn?.title}
                        >
                          {changPass?.ChangePasswordbtn?.value}
                        </button>
                        {!isLoggedIn ? (
                          <Link
                            to="/"
                            data-toggle="tooltip"
                            title={changPass?.backlogingbtn?.title}
                            style={changPass?.ChangePasswordbtn?.style}
                            className="btn mt-3 btn-block btn-primary text-white"
                            onClick={(e) => {
                              navigate("/");
                            }}
                          >
                            {changPass?.backlogingbtn?.label}
                          </Link>
                        ) : (
                          ""
                        )}
                      </div>
                    </Form>
                  </div>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
