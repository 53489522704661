// src/textToSpeech.js
export const speak = (text, options = {}) => {
    if ('speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(text);
      
      // Set default values for speech options
      const {
        lang = 'en-US', // Language
        pitch = 1, // Pitch (0 to 2)
        rate = 1, // Speed rate (0.1 to 10)
        volume = 1, // Volume (0 to 1)
        voice = null // Specific voice (if available)
      } = options;
  
      // Apply the options to the utterance
      utterance.lang = lang;
      utterance.pitch = pitch;
      utterance.rate = rate;
      utterance.volume = volume;
  
      // Set the specific voice if provided
      if (voice) {
        const voices = window.speechSynthesis.getVoices();
        const selectedVoice = voices.find(v => v.name === voice);
        if (selectedVoice) {
          utterance.voice = selectedVoice;
        }
      }
  
      window.speechSynthesis.speak(utterance);
    } else {
      console.error('Text-to-speech not supported in this browser.');
    }
  };
  