import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useState, useEffect } from "react";
import PolicyInfo from "../../components/CrmServices/PolicyInfo/PolicyInfo";

function PolicyInfoDND(props) {
  const { tableData } = props;
  const [activeKey, setActiveKey] = useState(0);

  useEffect(() => {
    if (tableData && tableData?.length > 0) {
      setActiveKey(0);
    }
  }, []);

  return (
    <Tabs
      activeKey={activeKey}
      id="uncontrolled-tab-example"
      className="mb-1 mt-0"
      onSelect={(key) => setActiveKey(key)}
    >
      {tableData &&
        tableData?.map((data, index) => (
          <Tab
            eventKey={index}
            title={
              Object.entries(data)[0].join("").charAt(0).toUpperCase() +
              Object.entries(data)[0].join(" ").slice(1)
            }
            key={index}
          >
            <PolicyInfo productInfo={data} />
          </Tab>
        ))}
    </Tabs>
  );
}

export default PolicyInfoDND;
