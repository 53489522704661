import React, { useEffect, useState } from "react";
import CallMergeIcon from "@mui/icons-material/CallMerge";
import DaynmicApicall from "../../utils/function";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import CallIcon from "@mui/icons-material/Call";
import socket from "../../utils/socket";
import { setCrmWebphone } from "../../redux/dialerStates";
import DialpadIcon from "@mui/icons-material/Dialpad";
import ContactsIcon from "@mui/icons-material/Contacts";

const ConferenceCall = ({ conf, setCallUnhold }) => {
  const { userInfo } = useSelector((state) => state?.user?.value);
  const { UserToken, userid, usergroup, dlrAgentId } = userInfo.data[0];
  let { crmsaveandexit } = useSelector((state) => state?.dialer);
  const { campaignInfo } = useSelector((state) => state.callsession);

  let { crmwebphone } = useSelector((state) => state?.webphoneStatus);
  const dispatch = useDispatch();
  const [datacall, setcalltransfer] = useState("");
  const [reportinglist, setReportinglist] = useState([]);
  const [ecternalReportingList, setExternalReportinglist] = useState([]);
  const [callholdUnhold, setCallholdUnhold] = useState(false);
  const [dialeCall, setdialeCall] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [dialedNumber, setDialedNumber] = useState("");
  const [buttonvisible, setButtonvisible] = useState({
    internal: true,
    external: true,
    dialPaid: true,
  });

  function handelButtonVisibility(event) {
    // setButtonvisible({
    //     internal: event === "internal" ? true : false,
    //     external: event === "external" ? true : false,
    //     dialPaid: event === "dialPaid" ? true : false,
    // })
  }
  const handleDial = (number) => {
    setDialedNumber(dialedNumber + number);
  };

  const handleCall = () => {
    if (dialedNumber.length == 10) {
      dial_call_tranfer(`0${dialedNumber}`);
    }
  };

  const handleBackspace = () => {
    setDialedNumber(dialedNumber.slice(0, -1));
  };
  useEffect(() => {
    getreportingmemberList();
    if (campaignInfo?.keypointer) {
      getExternalList();
    }
    if (crmwebphone.confStatus) {
      getcalltransfer();
    }
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function getreportingmemberList() {
    await DaynmicApicall(
      `appuser/getmasterdata/vw_reporting_ids/*/1=1/user_id/`,
      "",
      "get",
      userInfo.data[0].UserToken
    )
      .then(async (res) => {
        setReportinglist(res);
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        toast.error(
          error.response.data.message ??
            error.message ??
            "OOPs, Something went wrong."
        );
      });
  }

  const getExternalList = async () => {
    try {
      await DaynmicApicall(
        `prm/getParamdata/ALL/EXTERNAL-DIRECTORY/${campaignInfo?.keypointer}`,
        "",
        "get",
        userInfo.data[0].UserToken
      ).then(async (res) => {
        setExternalReportinglist(res);
      });
    } catch (error) {
      console.log("ERROR", error.message);
      setExternalReportinglist([]);
    }
  };

  async function dial_call_tranfer(number) {
    callholdAndUnhold();
    const dial_call = {
      agent_token: dlrAgentId,
      action: "dial_call",
      event_data: {
        dial_number: `${number}`, // Number which needs to dial
      },
    };
    socket.emit("crmdialerdata", dial_call);
    socket.on("respfromdialer", async (data) => {
      if (
        data.action === "call_state" &&
        data.event_data.caller_number == number &&
        data.event_data.call_status === "answered"
      ) {
        await setCallUnhold(false);
        setdialeCall(data.event_data);
        setButtonvisible({
          internal: false,
          external: false,
          dialPaid: false,
        });
        getcalltransfer("112");
        dispatch(
          setCrmWebphone({
            setdialeCall: data.event_data,
          })
        );
        setCallholdUnhold(true);
      }
    });
  }

  async function callholdAndUnhold() {
    let holdUnhold = {
      agent_token: dlrAgentId,
      action: "toggle_hold",
      event_data: {
        call_session_id: crmsaveandexit?.dialer_session_id, // CALL UNIQUE SESSION ID OR UUID
        hold_action: callholdUnhold === true ? "unhold" : "hold", // value "hold" or "unhold" will be based on control action
      },
    };
    socket.emit("crmdialerdata", holdUnhold);
  }

  const getcalltransfer = () => {
    return setcalltransfer(!datacall);
  };

  async function call_conference() {
    const callinfo = {
      agent_token: dlrAgentId,
      action: "create_conference",
      event_data: {
        call_session_id: dialeCall.call_session_id, // Transfer To Number
        sup_call_session_id: crmsaveandexit?.dialer_session_id, // CALL UNIQUE SESSION ID OR UUID
      },
    };
    socket.emit("crmdialerdata", callinfo);
  }

  const buttons = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "<-", "0", "*"];

  return (
    <div
      className="text-center"
      style={{ color: "#262B40", fontWeight: "bold", fontSize: "20px" }}
    >
      <div className="">
        <a
          className="btn "
          data-toggle="collapse"
          title="Conference call"
          href="#conference"
          role="button"
          aria-expanded="false"
          aria-controls="conference"
        >
          <CallMergeIcon />
        </a>
      </div>

      {/* Conference call */}
      <div className="collapse" id="conference">
        <div
          className="card"
          style={{ height: "100px", width: "200px", marginLeft: "-75px" }}
        >
          <div className="d-flex justify-content-center">
            {buttonvisible.internal && (
              <div className="mt-3 mx-3 dropdown" title="Internal Directory">
                <PermContactCalendarIcon
                  className="dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                />
                <ul
                  className="dropdown-menu mt-2"
                  style={{ width: "auto", marginLeft: "-25px" }}
                >
                  {reportinglist &&
                    reportinglist.map((data, index) => (
                      <button
                        key={index}
                        className="dropdown-item"
                        onClick={(e) => {
                          dial_call_tranfer(data.dlr_agent_id);
                          handelButtonVisibility("internal");
                        }}
                      >
                        {data.username}
                      </button>
                    ))}
                </ul>
              </div>
            )}

            {buttonvisible.external && (
              <div className="mt-3 mx-3 dropdown" title="External Directory">
                <ContactsIcon
                  className="dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                />
                <ul
                  className="dropdown-menu mt-2"
                  style={{ width: "auto", marginLeft: "-50px" }}
                >
                  {ecternalReportingList &&
                    ecternalReportingList.map((data, index) => (
                      <button
                        key={index}
                        class="dropdown-item"
                        onClick={(e) => {
                          dial_call_tranfer(data?.paracode);
                          handelButtonVisibility("external");
                        }}
                      >
                        {data?.paradesc}
                      </button>
                    ))}
                </ul>
              </div>
            )}
            {buttonvisible.dialPaid && (
              <div className="mt-3 mx-3" title="Dial Paid">
                <DialpadIcon onClick={handleClickOpen} />
              </div>
            )}
            <Dialog
              open={open}
              onClose={handleClose}
              style={{
                padding: 0,
                margin: 0,
                height: "500px",
              }}
            >
              <DialogTitle>Dial Call</DialogTitle>
              <DialogContent
                style={{
                  width: "250px",
                }}
              >
                <input
                  type="text"
                  className="form-control"
                  // readOnly
                  value={dialedNumber}
                  onChange={(e) => setDialedNumber(e.target.value)}
                />
                <div className="container mt-2">
                  {
                    <div className="row mb-2">
                      {buttons.map((number, index) => (
                        <div
                          key={index}
                          class="col-sm-6 col-md-4 col-lg-4 col-xl-4 m-0"
                        >
                          <div class="card mb-2 border-right-4 secondary m-0 border-2 border-secondary">
                            <div
                              class="text-center"
                              style={{
                                color: "#262B40",
                                fontWeight: "bold",
                                fontSize: "20px",
                              }}
                            >
                              {number !== "<-" || number === "Call" ? (
                                <span
                                  class=""
                                  onClick={() => handleDial(number)}
                                >
                                  {number}
                                </span>
                              ) : (
                                <span
                                  class=""
                                  onClick={() =>
                                    number === "<-"
                                      ? handleBackspace()
                                      : handleDial(number)
                                  }
                                >
                                  {number}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  }
                  <button
                    className="btn btn-success m-0 p-0 ml-2"
                    type="button"
                    onClick={() => {
                      handleCall(dialedNumber);
                      handelButtonVisibility("dialPaid");
                    }}
                  >
                    {" "}
                    <CallIcon />
                  </button>
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Close</Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
      {crmwebphone?.confStatus?.event_data?.message_text !==
        "CONF_CREATION_SUCCESS" &&
        datacall && (
          <div className="button-container">
            <button
              className="btn btn-sm mt-3 conference-button"
              title="Click for call conference"
              onClick={async (e) => {
                await call_conference();
                await setCallUnhold(false);
              }}
            >
              Conference Call
            </button>
          </div>
        )}
    </div>
  );
};

export default ConferenceCall;
