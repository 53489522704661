import axios from "axios";
import { BASE_URL } from "./constent";


const api = axios.create({
  // baseURL:'https://uatpzcapi.p3z.in/api/',
  baseURL:BASE_URL,
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
});


export default api;
