import React from "react";
import TabModel from "./Multimedia/TabModel";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import UpdateInformation from "./UpdateInformation";
import api from "../../utils/api";
import DynamicTab from "./DynamicTabs/DynamicTab";
import AgentScoreModal from "./AgentScoreModal";
import "./Style.css";

const AgentAction = (props) => {
  const { startTimer, stopTimer, timerDuration } = props;
  const { userInfo } = useSelector((state) => state?.user?.value);
  const { callScore } = useSelector((state) => state.callsession);
  const [pageData, setpageData] = useState("");
  const [score, setScore] = useState("");
  const [showAgentScore, setShowAgentScore] = useState(false);
  const [timeLeft, setTimeLeft] = useState({ minutes: 0, seconds: 0 });
  const timerRef = useRef(null);

  async function pageInfo() {
    api
      .get(`appuser/getcomponetbyid/${props?.data?.campinfo?.keypointer}/42`, {
        headers: {
          Authorization: userInfo.data[0].UserToken,
        },
      })
      .then(async (data) => {
        await setpageData(data.data.data[0].DATA);
      })
      .catch((err) => {
        console.log("ERROR", err);
      });
  }

  useEffect(() => {
    const fetchPageInfo = async () => {
      if (props?.data?.campinfo?.keypointer) {
        await pageInfo();
      }
    };
    fetchPageInfo();
  }, [props?.data?.campinfo?.keypointer]);

  useEffect(() => {
    setScore(callScore);
  }, [callScore]);

  useEffect(() => {
    if (timerDuration) {
      const minutes = Math.floor(timerDuration / 60);
      const seconds = timerDuration % 60;
      setTimeLeft({ minutes, seconds });
    }
  }, [timerDuration]);

  useEffect(() => {
    if (startTimer) {
      startCountdown();
    } else if (stopTimer) {
      stopCountdown();
    }
    return () => clearInterval(timerRef.current);
  }, [startTimer, stopTimer, timerDuration]);

  const startCountdown = () => {
    if (timerRef.current) clearInterval(timerRef.current); // Clear any existing timer

    // Reset the time to the latest timerDuration
    const minutes = Math.floor(timerDuration / 60);
    const seconds = timerDuration % 60;
    setTimeLeft({ minutes, seconds });

    // Start countdown
    timerRef.current = setInterval(() => {
      setTimeLeft((prevTime) => {
        const totalSeconds = prevTime.minutes * 60 + prevTime.seconds - 1;
        if (totalSeconds <= 0) {
          clearInterval(timerRef.current);
          return { minutes: 0, seconds: 0 };
        } else {
          const minutes = Math.floor(totalSeconds / 60);
          const seconds = totalSeconds % 60;
          return { minutes, seconds };
        }
      });
    }, 1000);
  };

  const stopCountdown = () => {
    if (timerRef.current) clearInterval(timerRef.current); // Stop the countdown

    // Reset timer to 0:00
    setTimeLeft({ minutes: 0, seconds: 0 });
  };
  return (
    <>
      {Object.keys(pageData)?.length > 0 ? (
        <div
          className="mt-2 card d-flex flex-row ml-1 mb-2"
          style={{ justifyContent: "space-between" }}
        >
          <div className="d-flex flex-row">
            <div className="pt-2">
              <button
                type="button"
                className="btn btn-primary-sm mb-3"
                data-bs-toggle="modal"
                data-bs-target="#modalMultimedia"
                title={pageData.button3}
                data-toggle="tooltip"
              >
                {pageData.button3}
              </button>
            </div>
            <div className="pt-2">
              <button
                type="button"
                className="btn btn-primary-sm mb-3"
                data-bs-toggle="modal"
                data-bs-target="#modalUpdate"
                title={pageData.button4}
                data-toggle="tooltip"
              >
                {pageData.button4}
              </button>
            </div>
            <div>
              <a
                className="btn btn-primary-sm mb-3"
                data-bs-toggle="modal"
                data-bs-target="#Pdf script"
                title="Tool"
                data-toggle="tooltip"
                role="button"
              >
                <div className="dropdown dropup">
                  <button
                    className="btn dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {pageData.button5}
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    {pageData?.toolsOptions.map((value, index) => {
                      return (
                        <a
                          key={index}
                          className="dropdown-item"
                          onClick={() => window.open(value.href, "_blank")}
                        >
                          {value.name}
                        </a>
                      );
                    })}
                  </div>
                </div>
              </a>
            </div>
            <div
              className="pt-2"
              style={{
                display:
                  `${pageData?.customerForm?.visible}` === "true" ? "" : "none",
              }}
            >
              <button
                type="button"
                className="btn btn-primary-sm mb-3"
                data-bs-toggle="modal"
                data-bs-target="#modalCustomerform"
                title={pageData.button6}
                data-toggle="tooltip"
              >
                {pageData.button6}
              </button>
            </div>
          </div>

          <div className="d-flex flex-row">
            <div className="pt-2">
              <div className="flip-box">
                <span>{String(timeLeft.seconds).padStart(2, "0")}</span>
              </div>
            </div>
            <div className="pt-2">
              <button
                className="btn-sm btn-primary ml-3 m-2 "
                type="button"
                title="Open Agent Score"
                style={{ fontSize: "15px" }}
                onClick={() => setShowAgentScore(true)}
              >
                Current Score : {score}
              </button>
            </div>
          </div>

          {/* Modal Code Starts */}
          <div
            className="modal fade"
            id="modalUpdate"
            tabIndex="-1"
            role="dialog"
            data-bs-backdrop="static"
            aria-labelledby="modalTitleNotify"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered ml-1"
              role="document"
            >
              <div className="modal-content " style={{ marginTop: "250px" }}>
                <div className="modal-header">
                  <p className="modal-title" id="modalTitleNotify">
                    {pageData.updateuserinfo?.header}
                  </p>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body" style={{ padding: " 0.6rem" }}>
                  <UpdateInformation userInfo={props} />
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            data-bs-backdrop="static"
            id="modalMultimedia"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="modalTitleNotify"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered ml-1"
              role="document"
            >
              <div className="modal-content" style={{ marginTop: "200px" }}>
                <div className="modal-header">
                  <p className="modal-title" id="modalTitleNotify">
                    {pageData.Multimedia?.header}
                  </p>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <TabModel callDetails={props} />
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            data-bs-backdrop="static"
            id="modalCustomerform"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="modalTitleNotify"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered ml-1"
              role="document"
            >
              <div className="modal-content" style={{ marginTop: "250px" }}>
                <div className="modal-header">
                  <p className="modal-title" id="modalTitleNotify">
                    {pageData?.button6}
                  </p>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <DynamicTab campinfo={props?.data?.campinfo} />
                </div>
              </div>
            </div>
          </div>

          <AgentScoreModal
            open={showAgentScore}
            handleClose={() => setShowAgentScore(false)}
            data={props?.scoreData}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default AgentAction;
