import React, { useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { getFormData } from "../../helpers/helpers";
import api from "../../utils/api";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { Stack } from "react-bootstrap";
import { Chip, gridClasses } from "@mui/material";
import DaynmicApicall from "../../utils/function";
import socket from "../../utils/socket";

const ResetPassword = (props) => {
  const { isLoggedIn, userInfo } = useSelector((state) => state?.user?.value);
  const [pageData, setpageData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [getUserPhoneList, setuserphone] = useState([]);
  const [filterColumn, setFilterColumn] = useState("");
  const [filterValue, setFilterValue] = useState("");

  function resetPassword(e) {
    e.preventDefault();
    const formData = getFormData(e.target);
    const signinRes = api.post("appuser/managepassword/", formData, {
      headers: {
        Authorization: userInfo.data[0].UserToken, //the token is a variable which holds the token
      },
    });
    toast.promise(signinRes, {
      loading: "Authenticating member.",
      success: (data) => {
        return `User Reset Password Successfully .`;
      },
      error: (err) => {
        return (
          err?.response?.data?.errors ??
          err?.response?.data?.message ??
          err?.message ??
          "OOPs something went wrong."
        );
      },
    });
  }
  async function getCampaign(e) {
    api
      .get(`appuser/getcamp/ALL`, {
        headers: {
          Authorization: userInfo.data[0].UserToken, //the token is a variable which holds the token
        },
      })
      .then((res) => {
        let unique = [
          ...new Map(
            res?.data?.data?.map((item) => [item["campname"], item])
          ).values(),
        ];
        setTableData(unique);
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        toast.error(
          error.response.data.message ??
            error.message ??
            "OOPs, Something went wrong."
        );
      });
  }
  async function getUserNAme(campData) {
    api
      .get(`appuser/userlist/${campData}/ALL`, {
        headers: {
          Authorization: userInfo.data[0].UserToken, //the token is a variable which holds the token
        },
      })

      .then((res) => {
        setpageData(res.data.data);
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        toast.error(
          error.response.data.message ??
            error.message ??
            "OOPs, Something went wrong."
        );
      });
  }

  useEffect(async () => {
    await getCampaign();
    await pageInfo();
  }, []);

  const resetPass = {
    title: "Reset Password",
    cardstyle: {
      backgroundColor: " ",
    },
    campaign: {
      label: "Campaign Name",
      placeholder: "Select User campaign",
      tooltip: "Select User campaign",
      name: "campaign",
      type: "text",
    },
    UserName: {
      label: "User Name",
      placeholder: "Select User",
      tooltip: "Select User ",
      name: "UserName",
      type: "text",
    },

    resetbtn: {
      value: "Reset Password",
      style: {
        backgroundColor: "#639",
      },
      tooltip: "Click here For Reset Password",
    },
  };

  async function pageInfo() {
    await DaynmicApicall(
      `appuser/getuserphonelist/`,
      "",
      "get",
      userInfo.data[0].UserToken
    ).then((data) => {
      setuserphone(data);
    });
  }

  const columns = [
    { field: "userid", headerName: "User Id", width: 150 },
    { field: "username", headerName: "User Name", width: 300 },
    { field: "dlragentid", headerName: "Dialer Agent ID", width: 200 },
    { field: "phone_extension", headerName: "Phone Extension", width: 200 },
    {
      field: "Action",
      type: "actions",
      width: 80,
      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem
          label="Call Listen"
          onClick={(e) => {
            calltransfer("11", params.row?.phone_extension);
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Call Whisper"
          onClick={(e) => {
            calltransfer("12", params.row?.phone_extension);
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Call Barge"
          onClick={(e) => {
            calltransfer("13", params.row?.phone_extension);
          }}
          showInMenu
        />,
      ],
    },
  ];

  async function calltransfer(sipcode, phone_extension) {
    const calltranfer = {
      agent_token: userInfo.data[0]?.dlrAgentId,
      action: "dial_call",
      event_data: {
        dial_number: `*${sipcode + phone_extension}`, // 11 is code , 2010 agent extenction
      },
    };
    socket.emit("crmdialerdata", calltranfer);
  }

  function CustomToolbar() {
    return (
      <Stack direction="row" justifyContent="flex-end">
        <GridToolbarContainer className={gridClasses.toolbarContainer}>
          <GridToolbarExport />
          <GridToolbarFilterButton />
        </GridToolbarContainer>
      </Stack>
    );
  }

  const onFilterChange = React.useCallback(async (filterModel) => {
    if (
      filterModel?.items?.[0]?.value &&
      filterModel?.items?.[0]?.value.length > 0
    ) {
      setFilterColumn(filterModel?.items?.[0]?.columnField);
      setFilterValue(filterModel?.items?.[0]?.value);
    }
  }, []);

  return (
    <>
      <section className="vh-lg-90 mb-5  d-flex align-items-center">
        <div className="col-6 ml-8">
          <div className="col">
            <div className="col-sm  ml-9">
              <div className="wrapper">
                <div className="log">
                  <div className="nav-wrapper position-relative mb-2">
                    <h1 className="h3 mb-4">{resetPass?.title}</h1>
                    <form onSubmit={resetPassword}>
                      <div className="mb-4">
                        <label>{resetPass.campaign.label}</label>
                        <select
                          className="form-select"
                          name="keypointer"
                          aria-label="Default select example"
                          onChange={async (e) => {
                            await getUserNAme(e.target.value);
                          }}
                        >
                          {tableData?.map((data, index) => (
                            <option key={data.value} value={data.keypointer}>
                              {data.campname}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="mb-4">
                        <label>{resetPass?.UserName?.label}</label>
                        <select
                          className="form-select"
                          name="userid"
                          aria-label="Default select example"
                        >
                          <option selected>
                            {resetPass?.UserName?.placeholder}
                          </option>
                          {pageData?.map((data) => (
                            <option key={data.value} value={data.userid}>
                              {data.username}
                            </option>
                          ))}
                        </select>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-block btn-primary text-white"
                        title={resetPass?.resetbtn?.tooltip}
                      >
                        {resetPass?.resetbtn?.value}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* call transfer events */}
      <div className="row">
        {getUserPhoneList?.length > 0 && (
          <div className="table-settings mb-4">
            <div className="my-2">
              <DataGrid
                //loading={loadingData}
                getRowId={(r) => r.userid}
                rows={getUserPhoneList}
                columns={columns}
                //rowCount={tableData?.length}
                pageSize={10}
                rowsPerPageOptions={[10, 25, 25, 50, 100]}
                onFilterModelChange={onFilterChange}
                autoHeight={true}
                className="bg-white"
                components={{
                  Toolbar: CustomToolbar,
                }}
                density="compact"
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ResetPassword;
