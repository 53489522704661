import * as React from "react";
import toast from "react-hot-toast";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DaynmicApicall from "../../utils/function";
import "./style.css";
import Dialer from "./Dialer";
import PhoneForwardedOutlinedIcon from "@mui/icons-material/PhoneForwardedOutlined";
import { setCrmWebphone } from "../../redux/dialerStates";
import { ManageEventLog } from "../../utils/function";
import { removeCurrentCall } from "../../redux/Dialer";
import { setGlobalLanguage } from "../../redux/Campaign";
import { lbRedialCall } from "../../components/DespositionComponents/comonentFunctions";
import BlinkingInfo from "../BlinkingInfo";

export default function DialerSidebar(props) {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  let { crmsaveandexit } = useSelector((state) => state?.dialer);
  const { userInfo } = useSelector((state) => state?.user?.value);
  const { crmwebphone } = useSelector((state) => state?.webphoneStatus);

  //   let dialerCrmData = localStorage?.currentcall?.crm_data
  //     ? JSON.parse(JSON.parse(localStorage?.currentcall)?.crm_data)
  //     : "";
  const Header = { headers: { Authorization: userInfo.data[0].UserToken } };
  const [callPtp, setCallPtp] = useState("");
  const [agentstatus, setagentstatus] = useState("");
  const [dialerCrmData, setDialerCrmData] = useState("");

  useEffect(() => {
    setDialerCrmData(localStorage.currentcall);
  }, [localStorage.currentcall]);

  useEffect(async () => {
    await DaynmicApicall(
      `/dialer/getagentstatus/${userInfo.data[0].dlrAgentId}`,
      "",
      "get"
    )
      .then((res) => {
        setagentstatus(res);
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        toast.error(
          error.response.message ??
            error.message ??
            "OOPs, Something went wrong."
        );
      });
  }, [crmwebphone.queueStatus, crmwebphone.dialerHangupStatus]);

  const handleAgentStatusClick = async () => {
    await DaynmicApicall(
      `/dialer/getagentstatus/${userInfo.data[0].dlrAgentId}`,
      "",
      "get"
    )
      .then((res) => {
        setagentstatus(res);
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        toast.error(
          error.response.message ??
            error.message ??
            "OOPs, Something went wrong."
        );
      });
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  async function getCallPtp(agentid, currenthour) {
    await DaynmicApicall(
      `mcrmdlr/getagentcallptptargetdata/${userInfo.data[0].userid}/${parseInt(
        new Date().getHours() + "00"
      )}`,
      "",
      "get",
      userInfo.data[0].UserToken
    )
      .then(async (res) => {
        await setCallPtp(res[0]);
      })
      .catch((error) => {
        console.log("ERROR", error);
        toast.error(
          error.response.data.message ??
            error.message ??
            "OOPs, Something went wrong."
        );
      });
  }

  const callRedial = async () => {
    try {
      let getcalldisconnected = await DaynmicApicall(
        `dialer/getcallsiconnectevent/${
          JSON.parse(JSON.parse(dialerCrmData)?.crm_data)?.dialeruuid
        }`,
        "",
        "get",
        userInfo.data[0].UserToken
      );
      if (getcalldisconnected.CNT === 0) {
        toast("You can redial after call disconnect", {
          icon: "😎",
          style: {
            borderRadius: "10px",
            background: "#45abdb",
            color: "#fff",
          },
        });
        return false;
      }
      const payload = {
        ...crmsaveandexit,
        resp_code: "REDIAL",
        sub_resp_code: "REDIAL",
        script_language: "ENGLISH",
      };

      let Info = await DaynmicApicall(
        `mcrmdlr/managecallsave`,
        payload,
        "post",
        userInfo.data[0].UserToken
      );
      if (Info.status === true) {
        const actionlog = {
          Rowed: "NEW",
          custid: props?.data?.custinfo?.cust_id,
          campid: props?.data?.campinfo?.campid,
          eventname: `OnClick`,
          actionname: `final call save`,
          createdby: userInfo.data[0].userid,
        };
        ManageEventLog(actionlog);
        await lbRedialCall(
          {
            agentid: userInfo?.data[0]?.AgentDidNo.toString(),
            custid: `${payload?.campid}${payload?.cust_id}`,
            phone: [payload?.dialer_connected_ph],
            campid: payload?.campid,
          },
          userInfo.data[0].UserToken
        );
        const hangupdata = {
          crmuuid: JSON.parse(JSON.parse(dialerCrmData)?.crm_data)?.dialeruuid,
          external_hangup: "1",
          external_status: `${userInfo?.data[0]?.userid}${payload.resp_code}CallFromDialerPage`,
          agentid: `${userInfo.data[0].AgentDidNo}`,
        };
        await DaynmicApicall(
          `dialer/dialerhangup`,
          hangupdata,
          "post",
          userInfo.data[0].UserToken
        ).then((data) => {
          dispatch(removeCurrentCall());
          dispatch(
            setGlobalLanguage({
              data: "",
            })
          );
          dispatch(
            setCrmWebphone({
              dialerHangupCuid: "",
            })
          );
          dispatch(
            setCrmWebphone({
              dialerHangupStatus: true,
            })
          );
          dispatch(
            setCrmWebphone({
              callStartTime: "",
            })
          );
          dispatch(
            setCrmWebphone({
              callEndTime: "",
            })
          );
          localStorage.removeItem("currentcall");
          localStorage.removeItem("callLogs");
          localStorage.removeItem("currentListening");
          props.aftersave("");
        });
      }
      toast.success(Info.message);
    } catch (error) {
      console.log("ERROR", error.message);
    }
  };

  return (
    <div className="container">
      <div className="row">
        {/* <div id="ptpcall" className="col-md card mt-2 ml-2 mr-3 mb-1">
          <div className="row m-0">            
            <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3 m-0 p-0">
              
                <div>
                  <button
                    className="btn"
                    type="button"
                    data-toggle="tooltip"
                    title="ReDial"
                    onClick={(e) => {
                      callRedial();
                    }}
                  >
                    <PhoneForwardedOutlinedIcon />
                  </button>
                </div>
            
            </div>
          </div>
        </div> */}
        <div className="container mt-3 mb-3">
          <div className="row" style={{ width: "100%" }}>
            <BlinkingInfo />
          </div>
        </div>
        <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
          <div
            className="phoneView"
            style={{ overflowY: "scroll", overflowX: "hidden" }}
          >
            <Dialer propsdata={props} dialerCrmData={dialerCrmData} />
          </div>
        </div>
      </div>
    </div>
  );
}
