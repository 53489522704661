import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { Stack, LinearProgress, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import "./record.css";
import { MultiSelect } from "../../components/formValidation/MultiSelect";
import { Form, Formik } from "formik";
import PageHeader from "../../components/PageHeader";
import DaynmicApicall from "../../utils/function";
import { TextField } from "../../components/formValidation/TextField";
import SaveIcon from "@mui/icons-material/Save";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { ErrorMessage } from "formik";
import Select from "react-select";
import axios from "axios";
import * as Yup from "yup";
import { BASE_URL } from "../../utils/constent";
import { Button } from "react-bootstrap";
import PolicyInfoModel from "../../components/CrmServices/PolicyInfo/PolicyInfoModel";

const CustomMultiSelect = ({
  label,
  name,
  formik,
  onChange,
  isMulti,
  options,
  value,
  format,
  ...rest
}) => {
  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { isDisabled }) => {
      return {
        ...styles,
        cursor: isDisabled ? "not-allowed" : "pointer",
      };
    },
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#727cf5",
        borderColor: "#727cf5",
        borderRadius: "3px",
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: "white",
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: "#ffffff",
      cursor: "pointer",
      ":hover": {
        backgroundColor: "red",
        color: "white",
      },
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Set a high zIndex value
  };

  return (
    <div className="col-md-12 mb-3">
      <div className="form-control-sm row">
        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 pt-2">
          <label htmlFor={name}>{label}</label>
        </div>
        <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8">
          <Select
            name={name}
            styles={colourStyles}
            value={value}
            options={options}
            onChange={onChange}
            isMulti={isMulti}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            {...rest}
          />
          <ErrorMessage
            component="div"
            name={name}
            className="error text-danger"
          />
        </div>
      </div>
    </div>
  );
};

export default function ManageCallRecording(props) {
  let allCamps = [];
  const { userInfo } = useSelector((state) => state?.user?.value);
  const [campList, setCampList] = useState([]);
  const [searchdata, setSearchdata] = useState([]);
  const { campdetail } = useSelector((state) => state.campaign);
  const [campKey, setCampKey] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [customerInfoOpen, setCustomerInfoOpen] = useState(false);
  const [pageData, setpageData] = useState("");
  const [selectData, setSelectData] = useState([]);
  const [options, setOptions] = useState([]);
  const [agentoptions, setAgentOptions] = useState([]);
  const [pageSize, setPageSize] = useState(25);
  const [playingRows, setPlayingRows] = useState([]);
  const [currentlyPlayingAudio, setCurrentlyPlayingAudio] = useState(null);
  const [customerInfo, setcustomerInfo] = useState("");

  useEffect(async () => {
    await getCampaign();
    await pageInfo();
  }, []);

  const userCampaignIds = JSON.parse(userInfo.data[0].campaignid);
  const filteredCamps = campdetail.filter((camp) =>
    userCampaignIds.includes(parseInt(camp.campid))
  );

  allCamps = filteredCamps.map((item) => ({
    value: item.campid,
    label: item.campname,
  }));

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickCustomerInfo = () => {
    setCustomerInfoOpen(true);
  };

  const handleCloseCustomerInfo = () => {
    setCustomerInfoOpen(false);
  };

  const onSave = (selectedRows) => {
    handleSave(selectedRows);
  };

  const handleSave = async (selectedRows) => {
    if (selectedRows && selectedRows.length > 0) {
      setShowProgress(true);

      try {
        for (const row of selectedRows) {
          let fileName = "";
          options.forEach((option) => {
            fileName += row[option.value === null ? "" : option.value] + "_";
          });
          fileName = fileName.slice(0, -1);
          fileName += ".wav";
          await downloadFile(row.recording_file, fileName);
        }
      } finally {
        setShowProgress(false);
      }
    }
  };

  const downloadFile = async (url, fileName) => {
    const payload = {
      url: url,
      fileName: fileName,
    };

    try {
      const response = await axios.post(
        `${BASE_URL}dialer/audio`,
        { payload },
        {
          responseType: "blob",
          onDownloadProgress: (progressEvent) => {
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            setDownloadProgress(progress);
          },
        }
      );

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setDownloadProgress(0);
      setSelectedRows([]);
    } catch (error) {
      console.error("Axios Error:", error);
    }
  };

  var onSearch = async (values, { resetForm }) => {
    setPlayingRows([]);
    setLoading(true);
    const payload = {
      ...values,
      camp_id: values.camp_id !== "NA" ? values.camp_id.value : "NA",
      entry_date: {
        start: values.entry_date_from,
        end: values.entry_date_to,
      },
      phone_no: values.phone_no !== "NA" ? values.phone_no : "NA",
      agent_id:
        values.agent_id !== "NA" ? values.agent_id.value.toString() : "NA",
    };
    try {
      const Info = await DaynmicApicall(
        `mcrmdlr/getrecordingdata/`,
        payload,
        "post",
        userInfo.data[0].UserToken
      );
      Info?.data?.finalresult
        ? setSearchdata(Info?.data?.finalresult)
        : setSearchdata([]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSaveClick = () => {
    onSave(selectedRows);
  };

  const getRowClassName = (params) => {
    return playingRows.includes(params.row.id) ? "playing-row" : "";
  };

  const handleAudioPlay = async (params) => {
    const { id, recording_file } = params.row;

    if (currentlyPlayingAudio && currentlyPlayingAudio !== id) {
      const previousAudio = document.getElementById(currentlyPlayingAudio);
      if (previousAudio) {
        await previousAudio.pause();
        setCurrentlyPlayingAudio(null);
      }
    }
    const audioElement = document.getElementById(id);
    if (audioElement) {
      await audioElement.play();
      setCurrentlyPlayingAudio(id);
    }
  };

  const handleAudioPause = async (id) => {
    const audioElement = document.getElementById(id);

    if (audioElement) {
      await audioElement.pause();
      setCurrentlyPlayingAudio(null);
    }
  };

  const maskPhone = (phone) => {
    return phone ? phone.replace(/\d(?=\d{4})/g, "*") : "";
  };

  const columns = [
    {
      field: "Action",
      type: "actions",
      headerName: "Action",
      minWidth: 250,
      flex: 1,
      getActions: (params) => [
        <audio
          id={params.row.id}
          controls
          controlsList={
            userInfo.data[0].usergroup === "GUEST" ? "nodownload" : ""
          }
          src={params.row.recording_file}
          style={{
            width: "250px",
            padding: "15px 5px",
          }}
          onPlay={() => {
            setPlayingRows((prevRows) => [...prevRows, params.row.id]);
            handleAudioPlay(params);
          }}
          onPause={() => {
            handleAudioPause(params.row.id);
          }}
        ></audio>,
      ],
    },
    {
      field: "cuid",
      headerName: "Cust Id",
      minWidth: 130,
      flex: 1,
      disableExport: true,
      hide: true,
    },
    {
      field: "phone_no",
      headerName: "Phone Number",
      minWidth: 135,
      flex: 1,
      disableExport: true,
      renderCell: (params) => {
        const object = {
          data: {
            custinfo: {
              cuid: `${params?.row?.cuid}`,
            },
            campinfo: { keypointer: campKey },
          },
        };
        return (
          <span
            style={{
              color: "#1976d2",
              cursor: "pointer",
            }}
            onClick={async (e) => {
              await setcustomerInfo(object);
              await handleClickCustomerInfo();
            }}
          >
            {maskPhone(params?.row?.phone_no)}
          </span>
        );
      },
    },
    {
      field: "policynumber",
      headerName: "Loan/Policy",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "resp_code",
      headerName: "Resp Code",
      minWidth: 105,
      flex: 1,
    },
    {
      field: "username",
      headerName: "Agent Name",
      minWidth: 120,
      flex: 1,
    },
    { field: "campname", headerName: "Camp Name", minWidth: 120, flex: 1 },
    {
      field: "call_date",
      headerName: "Call Date",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        const date = params.value.split("T")[0];
        return date;
      },
    },
    {
      field: "call_end_time",
      headerName: "Call End Time",
      minWidth: 125,
      flex: 1,
      renderCell: (params) => {
        const utcDate = new Date(params.value);
        const localTime = utcDate.toLocaleString("en-US", {
          timeZone: "Asia/Kolkata",
          hour12: false,
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        });
        return localTime;
      },
    },
    {
      field: "recording_file",
      headerName: "File",
      minWidth: 50,
      flex: 1,
      renderCell: (params) => {
        return params.value ? "Yes" : "NO";
      },
    },
  ];

  async function getAgentData(camp) {
    await DaynmicApicall(
      `mcrmdlr/getAgentExtId/${camp}`,
      "",
      "get",
      userInfo.data[0].UserToken
    )
      .then((res) => {
        const Data = res.map((item) => {
          return {
            value: item.dlragentid,
            label: item.username,
          };
        });
        setAgentOptions(Data);
      })
      .catch((err) => {
        console.log("ERROR", err);
      });
  }

  async function pageInfo() {
    await DaynmicApicall(
      `/appuser/getcomponetbyid/${props.activemenu.keypointer}/151`,
      "",
      "get",
      userInfo.data[0].UserToken
    )
      .then((data) => {
        setpageData(data[0]?.DATA);
        setSelectData(data[0]?.DATA?.optoins);
      })
      .catch((err) => {
        console.log("ERROR", err);
      });
  }

  async function getCampaign(e) {
    await DaynmicApicall(
      `appuser/getcamp/ALL`,
      "",
      "get",
      userInfo.data[0].UserToken
    ).then((res) => {
      let uniqueCamp = [
        ...new Map(res?.map((item) => [item["campname"], item])).values(),
      ];
      let camp = uniqueCamp.map((item) => {
        return {
          value: item.campid,
          label: item.campname,
          ...item,
        };
      });
      setCampList(camp);
    });
  }

  const calculateMaxDate = (selectedDate, days) => {
    const maxDate = new Date(selectedDate);
    maxDate.setDate(maxDate.getDate() + days);
    return maxDate.toISOString().split("T")[0];
  };

  const CustomToolbar = React.memo(() => {
    return (
      <Stack direction="row" justifyContent="flex-end">
        <GridToolbarContainer className={gridClasses.toolbarContainer}>
          {userInfo.data[0].usergroup !== "GUEST" && (
            <>
              <button
                className="MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall MuiButtonBase-root  css-1knaqv7-MuiButtonBase-root-MuiButton-root"
                tabIndex="0"
                type="button"
                onClick={handleClickOpen}
                style={{ display: `${selectedRows.length}` > 0 ? " " : "none" }}
              >
                <span className="MuiButton-startIcon MuiButton-iconSizeSmall css-y6rp3m-MuiButton-startIcon">
                  <SaveIcon />
                </span>
                Save
              </button>
              <GridToolbarExport />
            </>
          )}

          <GridToolbarFilterButton />
        </GridToolbarContainer>
      </Stack>
    );
  });

  let initial = {
    camp_id: "NA",
    entry_date: "NA",
    entry_date_from: "",
    entry_date_to: "NA",
    phone_no: "NA",
    recording_length: "NA",
    agent_id: "NA",
    last_resp_code: "NA",
  };

  const validate = Yup.object({
    camp_id: Yup.object().required("Campaign Name is required"),
    entry_date_from: Yup.string().required("Call Date From is required"),
  });

  return (
    <>
      {pageData && (
        <>
          <PageHeader heading={pageData.mainHeader} />
          <div className="card pl-2 pr-2">
            <Formik
              initialValues={initial}
              validationSchema={validate}
              onSubmit={onSearch}
            >
              {(formik) => (
                <Form>
                  <div className="row mt-3">
                    <MultiSelect
                      label={pageData.form.campaign.label}
                      value={formik.values.camp_id}
                      name={pageData.form.campaign.name}
                      isMulti={false}
                      formik={formik}
                      options={allCamps}
                      onChange={(value) => {
                        formik.setFieldValue("camp_id", value);
                        getAgentData(value.value);
                        const filteredCamps = campList?.filter(
                          (v) => v.campid == value.value
                        );
                        setCampKey(
                          filteredCamps?.length
                            ? filteredCamps[0].keypointer
                            : null
                        );
                      }}
                    />
                    <TextField
                      title={pageData.form.callDateFrom.title}
                      data-toggle="tooltip"
                      label={pageData.form.callDateFrom.label}
                      type="date"
                      name={pageData.form.callDateFrom.name}
                      placeholder={pageData.form.callDateFrom.placeholder}
                    />
                    <TextField
                      title={pageData.form.callDateTo.title}
                      data-toggle="tooltip"
                      label={pageData.form.callDateTo.label}
                      type="date"
                      name={pageData.form.callDateTo.name}
                      placeholder={pageData.form.callDateTo.placeholder}
                      max={
                        formik.values[pageData.form.callDateFrom.name] !== ""
                          ? calculateMaxDate(
                              formik.values[pageData.form.callDateFrom.name],
                              pageData.form.callDateTo.maxdate
                            )
                          : ""
                      }
                      min={
                        formik.values[pageData.form.callDateFrom.name] !== ""
                          ? formik.values[pageData.form.callDateFrom.name]
                          : ""
                      }
                      disabled={
                        formik.values[pageData.form.callDateFrom.name] === ""
                      }
                    />
                    <TextField
                      title={pageData.form.phoneNumber.title}
                      data-toggle="tooltip"
                      label={pageData.form.phoneNumber.label}
                      type="text"
                      name={pageData.form.phoneNumber.name}
                      placeholder={pageData.form.phoneNumber.placeholder}
                    />
                    {/* <TextField
                      title={pageData.form.recordingLength.title}
                      data-toggle="tooltip"
                      label={pageData.form.recordingLength.label}
                      type="text"
                      name={pageData.form.recordingLength.name}
                      placeholder={pageData.form.recordingLength.placeholder}
                    /> */}
                    <MultiSelect
                      label={pageData.form.agentId.label}
                      value={formik.values.agent_id}
                      name={pageData.form.agentId.name}
                      isMulti={false}
                      formik={formik}
                      options={agentoptions}
                      onChange={(value) =>
                        formik.setFieldValue("agent_id", value)
                      }
                    />

                    <TextField
                      title={pageData.form.responseCode.title}
                      data-toggle="tooltip"
                      label={pageData.form.responseCode.label}
                      type="text"
                      name={pageData.form.responseCode.name}
                      placeholder={pageData.form.responseCode.placeholder}
                    />
                  </div>

                  <div className="d-flex justify-content-end w-100">
                    <button
                      type="submit"
                      className="btn btn-primary btn-sm m-2"
                    >
                      {pageData.form.button.label1}
                    </button>

                    <button type="reset" className="btn btn-primary btn-sm m-2">
                      {pageData.form.button.label2}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
            <div className="table-settings mb-4">
              <div className="my-2">
                {loading ? (
                  <div style={{ textAlignLast: "center", color: "#1acc61" }}>
                    <span>Fetching Data...</span>
                  </div>
                ) : (
                  <div>
                    <div className="card card-body border-light shadow">
                      <div className="table-settings mb-4">
                        {showProgress && (
                          <div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                variant="body2"
                                fontWeight="bold"
                                color="text.primary"
                              >
                                DOWNLOADING...
                              </Typography>
                            </div>
                            <LinearProgress value={downloadProgress} />
                          </div>
                        )}
                        <div className="my-2">
                          {searchdata && (
                            <DataGrid
                              style={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                              title="gstadfggg"
                              data-toggle="tooltip"
                              getRowId={(row) => row.id}
                              rows={searchdata}
                              getRowClassName={getRowClassName}
                              onSelectionModelChange={(ids) => {
                                const selectedIDs = new Set(ids);
                                const selectedRowData = searchdata.filter(
                                  (row) => {
                                    return selectedIDs.has(row.id);
                                  }
                                );
                                setSelectedRows(selectedRowData);
                              }}
                              columns={columns}
                              pageSize={pageSize}
                              onPageSizeChange={(newPageSize) =>
                                setPageSize(newPageSize)
                              }
                              rowsPerPageOptions={[25, 50, 75, 100]}
                              colSpan={3}
                              slotProps={{
                                select: {
                                  "aria-label": "rows per page",
                                },
                                actions: {
                                  showFirstButton: true,
                                  showLastButton: true,
                                },
                              }}
                              autoHeight={true}
                              className="bg-white"
                              components={{
                                Toolbar: CustomToolbar,
                              }}
                              density="compact"
                              checkboxSelection
                            />
                          )}
                          <Dialog
                            onClose={handleClose}
                            aria-labelledby="customized-dialog-title"
                            open={open}
                          >
                            <DialogTitle
                              sx={{ m: 0, p: 2 }}
                              id="customized-dialog-title"
                            >
                              {pageData.headerName}
                            </DialogTitle>
                            <IconButton
                              aria-label="close"
                              onClick={handleClose}
                              sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                            <DialogContent dividers>
                              <Formik>
                                {(formik) => (
                                  <Form>
                                    <div className="row">
                                      <CustomMultiSelect
                                        label={pageData.label}
                                        name={pageData.name}
                                        isMulti={true}
                                        formik={formik}
                                        options={selectData}
                                        onChange={(value) => setOptions(value)}
                                      />
                                    </div>
                                  </Form>
                                )}
                              </Formik>
                            </DialogContent>
                            <DialogActions>
                              <button
                                type="submit"
                                className="btn btn-primary btn-sm m-2"
                                onClick={() => {
                                  return handleSaveClick(), handleClose();
                                }}
                              >
                                {pageData.button}
                              </button>
                            </DialogActions>
                          </Dialog>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Dialog
            fullWidth={true}
            maxWidth="lg"
            open={customerInfoOpen}
            onClose={handleCloseCustomerInfo}
          >
            <DialogTitle>Customer Information</DialogTitle>
            <DialogContent>
              <PolicyInfoModel userData={customerInfo} />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseCustomerInfo}>Close</Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}
