import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import { TextField } from "../../formValidation/TextField";
import { DataGrid } from "@mui/x-data-grid";
import DaynmicApicall from "../../../utils/function";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import api from "../../../utils/api";
import toast from "react-hot-toast";
import { removeCurrentCall, setdialerInfo } from "../../../redux/Dialer";
import { removeCrmWebphone, setCrmWebphone } from "../../../redux/dialerStates";
import { removeCallSession } from "../../../redux/CurrentCallSessionDetails";

export default function CustomerSearchPage(props) {
  const dispatch = useDispatch();
  const {
    parentTOChields: { campinfo },
  } = props;
  const { userInfo } = useSelector((state) => state?.user?.value);
  const [tableData, setTableData] = useState("");
  let DialerCrmData;

  if (localStorage.currentcall) {
    DialerCrmData = JSON.parse(JSON.parse(localStorage.currentcall).crm_data);
  }

  useEffect(() => {
    const fetchData = async () => {
      if (localStorage.currentcall) {
        try {
          const data = JSON.parse(localStorage.currentcall);
          await insertSearchData(data);
        } catch (error) {
          console.error("Error parsing JSON:", error);
        }
      } else {
        console.error("localStorage.currentcall is undefined or null");
      }
    };

    fetchData();
  }, []);

  const handleChildToProp = (event, cellValues) => {
    let searchdata = props.dialerdata;
    searchdata.event_data.crm_data.custid = cellValues.row.cuid;
    props?.searchTOCrm(searchdata);
  };
  const columns = [
    {
      field: "Action",
      width: 200,
      renderCell: (cellValues) => {
        return (
          <Link
            to="/dashboard"
            variant="contained"
            color="info"
            onClick={async (event) => {
              let searchdata = props.dialerdata;
              searchdata.event_data.crm_data.custid = cellValues.row.cuid;
              searchdata.event_data.call_status = "searchpage";
              props?.searchTOCrm(searchdata);
            }}
            style={{ color: "red" }}
          >
            Go To Home Page
          </Link>
        );
      },
    },
    { field: "cuid", headerName: "Customer Id", width: 100 },
    { field: "policy_no", headerName: "Policy No", width: 200 },
    { field: "productname", headerName: "Product Name", width: 200 },
    { field: "policy_owner", headerName: "Customer Name", width: 200 },
    { field: "email_id", headerName: "Email", width: 200 },
    { field: "contact_no", headerName: "Phone Number", width: 180 },
    { field: "agent_broker_name", headerName: "Agent Name", width: 150 },
  ];
  const initial = {
    cuid: "",
    campid: "",
    dob: "",
    policyno: "",
    custid: "111V0654837",
    campid: "111",
    agentid: "",
    phone: "",
    dialerrespose: "incoming",
    clientid: "",
    callmode: "",
    sessionid: "pxd2pwhdr",
    notifyurl: "http://172.16.1.207:2008/dashboard",
    call_status: "answered",
    call_start: 1672230936401,
    call_stop: "",
    event_data: {
      caller_number: "+918267809214",
      caller_name: "+918267809214",
      call_session_id: "pxd2pwhdr",
      call_uuid: "sip:+918267809214@dialer01;user=phone",
      call_direction: "incoming",
      call_status: "answered",
      call_start: 1672230936401,
      call_stop: "",
      crm_data: {
        custid: "111V0654837",
        campid: "41",
        agentid: "",
        phone: "8267809214",
        dialerresponse: "",
        clientid: "",
        callmode: "",
        sessionid: "",
        pass_on_time: "",
      },
      agent_token: "2046",
    },
  };

  const onSubmit = async (values, { resetForm }) => {
    let customer_name = values.cuid ? values.cuid : "NA";
    let phone = values.phone ? values.phone : "NA";
    let volunteer = values.volunteer ? values.volunteer : "NA";
    let volunteername = await DaynmicApicall(
      `mcrmdlr/getcustomersearch/${customer_name}/${phone}/${volunteer}/NA/${campinfo.keypointer}`,
      "",
      "get",
      userInfo.data[0].UserToken
    )
      .then((res) => {
        setTableData(res);
      })
      .catch((err) => {
        console.log("ERROR", err);
      });
  };
  const sendData = async (values) => {
    await DaynmicApicall(
      `mcrmdlr/getuatdialerdata/`,
      "",
      "get",
      userInfo.data[0].UserToken
    ).then((res) => {});
  };

  const pageData = {
    customerid: {
      title: "Please Enter Customer Name",
      label: "Customer Name",
      placeholder: "Enter Coustomer Name",
      type: "text",
      name: "cuid",
    },
    phonenum: {
      title: "Please Enter Customer Phone Number",
      label: "Phone Number",
      placeholder: "Enter Phone Number",
      type: "text",
      name: "phone",
    },
    dodate: {
      title: "Please Enter Customer DOB",
      label: "Date of Birth",
      placeholder: "Enter Date of birth",
      type: "date",
      name: "dob",
    },
    policynumber: {
      title: "Please enter volunteer number",
      label: "Policy Number",
      placeholder: "Enter Policy Number",
      type: "text",
      name: "volunteer",
    },
    agentidnum: {
      title: "Please Enter Agent Id",
      label: "Agent Id",
      placeholder: "Enter Agent Id",
      type: "text",
      name: "agentid",
    },
    submitbtn: {
      label: "Submit",
    },
    sendbtn: {
      label: "Send",
    },
  };

  async function callerhangup() {
    try {
      const parameter = {
        extensionid: DialerCrmData.inboundcallerchannelid,
        crmuuid: DialerCrmData.dialeruuid,
        remarks: `${userInfo?.data[0]?.userid}CallFromSearchPage`,
      };
      const Info = api.post("dialer/callerhangup", parameter, {
        headers: { Authorization: userInfo.data[0].UserToken },
      });
    } catch (error) {
      dispatch(setdialerInfo(""));
    }
  }

  async function dialerhangup() {
    const hangupdata = {
      crmuuid: DialerCrmData.dialeruuid,
      external_hangup: "1",
      external_status: `${userInfo?.data[0]?.userid}CallFromSearchPage`,
      agentid: `${userInfo.data[0].AgentDidNo}`,
    };
    const Info = api.post("dialer/dialerhangup", hangupdata, {
      headers: { Authorization: userInfo.data[0].UserToken },
    });
    toast
      .promise(Info, {
        success: (Info) => {
          return Info.data.message;
        },
        error: (err) => {
          return (
            err?.response?.data?.errors ??
            "Please contact to dialer admin & Save the call" ??
            err?.message ??
            "OOPs something went wrong."
          );
        },
      })
      .then(async (data) => {
        dispatch(removeCurrentCall());
        dispatch(removeCallSession());
        dispatch(removeCrmWebphone());
        dispatch(setCrmWebphone({ dialerHangupStatus: true }));
        localStorage.removeItem("currentcall");
        localStorage.removeItem("callLogs");
        localStorage.removeItem("currentListening");

        props.aftersave("");
        dispatch(setdialerInfo(""));
      })
      .catch((error) => {
        dispatch(removeCurrentCall());
        localStorage.removeItem("currentcall");
        localStorage.removeItem("callLogs");
        localStorage.removeItem("currentListening");
        dispatch(
          setCrmWebphone({
            dialerHangupCuid: "",
          })
        );
        dispatch(
          setCrmWebphone({
            dialerHangupStatus: true,
          })
        );
        dispatch(
          setCrmWebphone({
            callStartTime: "",
          })
        );
        dispatch(
          setCrmWebphone({
            callEndTime: "",
          })
        );
        props.aftersave("");
        dispatch(setdialerInfo(""));
      });
  }

  async function f_getConnectedCamp(campid, userid) {
    const camp = await api
      .get(`appuser/getcampbycampid/${campid}/${userid}`, {
        headers: {
          Authorization: userInfo.data[0].UserToken,
        },
      })
      .catch((error) => {
        console.log("Error from: ", error);
      });
    return camp.data.data;
  }

  async function insertSearchData(data) {
    let getcamp;
    let dialerresp = JSON.parse(data?.crm_data);
    if (
      data?.agent_token == userInfo.data[0]?.dlrAgentId &&
      dialerresp.custid &&
      dialerresp.phone
    ) {
      let logdata = {
        custid: dialerresp.custid,
        campid:
          dialerresp.custid?.length > 0
            ? dialerresp.custid?.slice(0, 3)
            : dialerresp.campid,
        agentid: data.agent_token,
        phone: dialerresp.phone,
        dialerrespose: data.call_direction,
        clientid: dialerresp.clientid,
        callmode: dialerresp.callmode,
        // callmode: data.call_direction,
        sessionid: data.call_session_id,
        notifyurl: window.location.origin + window.location.pathname,
        call_status: data.call_status,
        call_start: data.call_start,
        call_stop: data.call_stop,
        event_data: data,
        dialeruuid: dialerresp.dialeruuid, // ****************
        recordingfile: dialerresp.recordingfile, // ****************
        active: "Y",
        action_name: "INSERT",
      };

      if (logdata.campid) {
        getcamp = await f_getConnectedCamp(
          logdata.campid,
          userInfo.data[0].userid
        );
      }
      if (!getcamp[0]) {
        await dialerscrmlog(
          logdata,
          getcamp[0] ? getcamp[0].keypointer : "u001dlr"
        );
      }
    }
  }

  async function dialerscrmlog(data, keypointer) {
    if (data.call_status === "answered") {
      await api
        .post(`/mcrmdlr/managedialercrminput/${keypointer}`, data, {
          headers: {
            Authorization: userInfo.data[0].UserToken,
          },
        })
        .then(async (res) => {});
    }
  }

  return (
    <div>
      <div className="card card-body mt-2">
        <Formik initialValues={initial} onSubmit={onSubmit}>
          {(formik) => (
            <Form>
              <div className="row mt-2">
                <TextField
                  data-toggle="tooltip"
                  title={pageData.customerid.title}
                  label={pageData.customerid.label}
                  placeholder={pageData.customerid.placeholder}
                  type={pageData.customerid.type}
                  name={pageData.customerid.name}
                />
                <TextField
                  data-toggle="tooltip"
                  title={pageData.phonenum.title}
                  label={pageData.phonenum.label}
                  placeholder={pageData.phonenum.placeholder}
                  type={pageData.phonenum.type}
                  name={pageData.phonenum.name}
                />
                <TextField
                  data-toggle="tooltip"
                  title={pageData.policynumber.title}
                  label={pageData.policynumber.label}
                  placeholder={pageData.policynumber.placeholder}
                  type={pageData.policynumber.type}
                  name={pageData.policynumber.name}
                />
                <div>
                  <button type="submit" className="btn btn-primary p-2 m-2">
                    {pageData.submitbtn.label}
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary p-2 m-2"
                    onClick={() => {
                      callerhangup();
                      dialerhangup();
                    }}
                  >
                    Call Hangup
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <div className="card card-body mt-3 mb-2">
        <div className="table-settings mb-4">
          <div className="my-2">
            <DataGrid
              getRowId={(r) => r.cuid}
              rows={tableData}
              columns={columns}
              autoHeight={true}
              className="bg-white"
            />
            <div className="d-flex justify-content-end">
              <button
                type="submit"
                className="btn btn-primary btn-sm m-2"
                onClick={sendData}
              >
                {pageData.sendbtn.label}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
