import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { Chip, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../utils/api";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { setParems } from "../../redux/Campaign";
import DaynmicApicall from "../../utils/function";

export default function HolidayCalender(props) {
  const navigate = useNavigate();
  const { isLoggedIn, userInfo } = useSelector((state) => state?.user?.value);
  const [tableData, setTableData] = useState([]);
  const [filterColumn, setFilterColumn] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [pageData, setpageData] = useState([]);
  const dispatch = useDispatch();
  const Header = { headers: { Authorization: userInfo.data[0].UserToken } };

  async function getHolidayCaleder() {
    await DaynmicApicall(
      `/dialer/getdialerqueuecalender/`,
      "",
      "get",
      userInfo.data[0].UserToken
    ).then((res) => {
      setTableData(res);
    });
  }
  async function deleteComponent(e, compInfo) {
    e.preventDefault();
    compInfo.mode = "DELETE";
    const updatePromise = await DaynmicApicall(
      `appuser/managemenumaster/`,
      Header,
      "post"
    );
    toast.success(updatePromise.message);
    getHolidayCaleder();
  }
  const columns = [
    { field: "sno", headerName: "S No.", minWidth: 50, flex: 1 },
    { field: "queueid", headerName: "Queue Id", minWidth: 150, flex: 1 },
    {
      field: "holiday_name",
      headerName: "Holiday Name",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "workinghour",
      headerName: " Working Hours",
      minWidth: 150,
      flex: 1,
    },
    // { field: "end_workinghrs", headerName: "End Working Date", minWidth: 150, flex: 1 },
    { field: "status", headerName: "Status", minWidth: 100, flex: 1 },
    {
      field: "Action",
      type: "actions",
      headerName: "Action",
      width: 80,
      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem
          label="Edit"
          onClick={(e) => {
            dispatch(
              setParems({
                data: {
                  CAMP_ID: params.row.CAMP_ID,
                  action: "UPDATE",
                  url: "addeditholiday",
                  comp: params.row,
                },
              })
            );
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Delete"
          onClick={(e) => {
            deleteComponent(e, params.row);
          }}
          showInMenu
        />,
      ],
    },
  ];
  function CustomToolbar() {
    return (
      <Stack direction="row" justifyContent="flex-end">
        <GridToolbarContainer className={gridClasses.toolbarContainer}>
          <GridToolbarExport />
          <GridToolbarFilterButton />
        </GridToolbarContainer>
      </Stack>
    );
  }
  const onFilterChange = React.useCallback(async (filterModel) => {
    if (
      filterModel?.items?.[0]?.value &&
      filterModel?.items?.[0]?.value.length > 0
    ) {
      setFilterColumn(filterModel?.items?.[0]?.columnField);
      setFilterValue(filterModel?.items?.[0]?.value);
    }
  }, []);
  useEffect(async () => {
    await getHolidayCaleder();
  }, []);

  return (
    <>
      {pageData && (
        <>
          <div className="container-fluid py-4 bg ">
            <div className="row">
              <div className="card col-xl-12 bg-dark">
                <div className="row row-cols-md-2 m-2 p-2 row-cols-lg-2 row-cols-xl-2 font-weight-bold">
                  <span className="text-left text-light ">
                    Manage Inbound Call
                  </span>
                  <span className="text-right">
                    <button
                      type="btn"
                      className="btn btn-success btn-sm float-right"
                      onClick={() => {
                        dispatch(
                          setParems({
                            data: { action: "INSERT", url: "addeditholiday" },
                          })
                        );
                      }}
                    >
                      Manage Inbound Call
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-body border-light shadow">
            <div className="table-settings mb-4">
              <div className="my-2">
                <DataGrid
                  QuickFilterIcon
                  //loading={loadingData}
                  getRowId={(r) => r.sno}
                  rows={tableData}
                  columns={columns}
                  // onEditRowsModelChange
                  onRowEditCommit
                  //rowCount={totalUsers}
                  pageSize={10}
                  rowsPerPageOptions={[10, 25, 25, 50, 100]}
                  onFilterModelChange={onFilterChange}
                  // onPageChange={handlePageChange}
                  autoHeight={true}
                  className="bg-white"
                  components={{
                    Toolbar: CustomToolbar,
                  }}
                  density="compact"
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
