



import React from 'react'
import { Form, Formik } from 'formik';
import { MultiSelect } from '../../components/formValidation/MultiSelect';
import { TextField } from '../../components/formValidation/TextField';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import DaynmicApicall from '../../utils/function';
import toast from 'react-hot-toast';
import { useDispatch } from "react-redux";
import { setParems } from "../../redux/Campaign";
import * as Yup from "yup";

export default function AddEditHoliday(props) {
    const { isLoggedIn, userInfo } = useSelector((state) => state?.user?.value);
    const dispatch = useDispatch();
    const Header = { headers: { Authorization: userInfo.data[0].UserToken } };
    const [botscript, setBotScript] = useState("")
    const [queue, setQueue] = useState([]);
    const [pageData, setpageData] = useState();
    async function pageInfo() {
        await DaynmicApicall(`/appuser/getcomponetbyid/${props.data.activeMenu.keypointer}/${props.data.activeMenu.subcompid}`, '', 'get', userInfo.data[0].UserToken).then((data) => {
            setpageData(data[0].DATA);
        })
    }
    async function getQueue() {
        await DaynmicApicall(`dialer/getdialerqueue`, '', 'get', userInfo.data[0].UserToken).then(async (res) => {

            let bData = [];
            bData = res?.map((item) => {
                return {
                    label: item.queue_name,
                    value: item.name
                };
            });
            setQueue(bData);
        })
    }
    useEffect(async() => {
        await pageInfo();
       await getcalenderlist();
       await getQueue();
    }, [])
    const getcalenderlist = async () => {
        let Info = await DaynmicApicall(`/dialer/getdialerqueuecalender/`, " ", 'get', userInfo.data[0].UserToken)

        setBotScript(Info)
    };
    let Statuscheck = [
        { value: "open", label: "Open" },
        { value: "close", label: "Close" },
    ]
    let DaysName = [
        { value: "1", label: "Sun" },
        { value: "2", label: "Mon" },
        { value: "3", label: "Tue" },
        { value: "4", label: "Wed" },
        { value: "5", label: "Thurs" },
        { value: "6", label: "Fri" },
        { value: "7", label: "Sat" },
    ]
    let DaysName2 = [
        { value: "1", label: "Sun" },
        { value: "2", label: "Mon" },
        { value: "3", label: "Tue" },
        { value: "4", label: "Wed" },
        { value: "5", label: "Thurs" },
        { value: "6", label: "Fri" },
        { value: "7", label: "Sat" },
    ]
    let action = props?.data?.paremsData.action === "UPDATE";
    const initial = {
        sno: "",
        queueid: action ? queue?.find((v) => v.label === props.data.paremsData.comp?.queueid) : "",
        startworkinghrs: action ? props?.data?.paremsData.comp.startworkinghrs : "",
        end_workinghrs: action ? props?.data?.paremsData.comp.end_workinghrs : "",
        workinghour: "",
        closedate: "",
        updatedate: action ? props?.data?.paremsData.comp.workinghour : "",
        dayname: action ? DaysName?.find((v) => v.label === props.data.paremsData.comp?.dayname) : "",
        dayname1: action ? DaysName2?.find((v) => v.label === props.data.paremsData.comp?.dayname1) : "",
        holiday_name: action ? props?.data?.paremsData.comp.holiday_name : "",
        status: action ? Statuscheck?.find((v) => v.label === props.data.paremsData.comp?.status) : [],
        action_name: action ? "UPDATE" : "INSERT",
        createdby: userInfo.data[0].userid,
    }
    const getHolidaylist = async (values, { resetForm }) => {


        values.sno = props.data.paremsData.comp?.sno;
        let camp = values.queueid;
        values.queueid = camp.label;
        if (props?.data?.paremsData.action === "INSERT") {
            if (values.status.label == 'Open') {
                values.status = 'Open'
                values.workinghour = `${parseInt(values.startworkinghrs.split("T")[1].split(":")[0])}:00-${parseInt(values.end_workinghrs.split("T")[1].split(":")[0])}:00,${values.dayname.label}-${values.dayname1.label},*,*`;
            }
            if (values.status.label == 'Close') {
                values.status = 'Close'
                let months = { '01': 'Jan', '02': 'Feb', '03': 'Mar', '04': 'Apr', '05': 'May', '06': 'June', '07': 'July', '08': 'Aug', '09': 'Sep', '10': 'Oct', '11': 'Nov', '12': 'Dec' }
                let month = months[values.closedate.slice(5, 7)];
                values.workinghour = '*' + ',' + '*' + ',' + values.closedate.slice(8, 10) + ',' + month;
            }
            delete values.dayname; delete values.dayname1; delete values.startworkinghrs; delete values.end_workinghrs; delete values.createdby;

            let Info = await DaynmicApicall(`dialer/createcalender/`, values, 'post', userInfo.data[0].UserToken)
            toast.success(Info.message)
            setBotScript(Info)
            getcalenderlist();
        }
        if (props?.data?.paremsData.action === "UPDATE") {

            values.status = values.status.label;
            let Info = await DaynmicApicall(`dialer/updatecalender/`, values, 'post', userInfo.data[0].UserToken)
            toast.success(Info.message)
            setBotScript(Info)
            getcalenderlist();
        }
    }
    const validate = Yup.object({
        startworkinghrs: Yup.string().required("start Date Required"),
        end_workinghrs: Yup.string().required("End Date Required"),
        dayname: Yup.string().required("Start day name Required"),
        dayname1: Yup.string().required("End day name Required"),

    });
    return (
        <>
        {pageData && (
            <>
            <div className="card card-body border-light shadow-sm mb-4">
                <div className="col-xl-12">
                    <div className="row row-cols-md-2 row-cols-lg-2 row-cols-xl-2 font-weight-bold">
                        <span className="text-left text-dark ">
                            {props?.data?.paremsData?.action === "INSERT"
                                ? pageData.title
                                : pageData.updatetitle}
                        </span>
                    </div>
                </div>
                <Formik
                    initialValues={initial}
                    //  validationSchema={validate}
                    onSubmit={getHolidaylist} >
                    {(formik) => (
                        <div>
                            <Form>
                                <div className="row mt-2">
                                    <MultiSelect
                                        label={pageData.queueiddata.label}
                                        placeholder={pageData.queueiddata.placeholder}
                                        type="multi"
                                        name={pageData.queueiddata.name}
                                        value={formik.values.queueid}
                                        isMulti={false}
                                        options={queue}
                                        onChange={(value) =>
                                            formik.setFieldValue('queueid', value)
                                        }
                                    />

                                    <TextField
                                     title="Please Enter Holiday Name"
                                     data-toggle="tooltip"
                                        label={pageData.holiname.name}
                                        placeholder={pageData.holiname.placeholder}
                                        type={pageData.holiname.type}
                                        name={pageData.holiname.name}
                                    />

                                    <MultiSelect
                                        label={pageData.statuschek.label}
                                        placeholder={pageData.statuschek.placeholder}
                                        type="multi"
                                        name={pageData.statuschek.name}
                                        value={formik.values.status}
                                        isMulti={false}
                                        options={Statuscheck}
                                        // tooltip="{data.tooltip}"
                                        onChange={(value) => {
                                            formik.setFieldValue("status", value)
                                            value.map((data) => {
                                                formik.setFieldValue(`${data.value}`, data)
                                            })
                                        }
                                        }
                                    />
                                    {props?.data?.paremsData?.action == 'UPDATE' && (
                                        <>
                                            <TextField
                                            title="Please Enter Update Date"
                                            data-toggle="tooltip"
                                                label={pageData.dateupdate.label}
                                                placeholder={pageData.dateupdate.placeholder}
                                                type={pageData.dateupdate.type}
                                                name={pageData.dateupdate.name}

                                            />

                                        </>
                                    )}

                                    {props?.data?.paremsData?.action == 'INSERT' && formik.values.status.value == 'open' && (
                                        <>
                                            <TextField
                                            title="Please Enter Start Date"
                                            data-toggle="tooltip"
                                                label={pageData.strdate.label}
                                                placeholder={pageData.strdate.placeholder}
                                                type={pageData.strdate.type}
                                                name={pageData.strdate.name}
                                            />
                                            <TextField
                                            title="Please Enter End Date"
                                            data-toggle="tooltip"
                                                label={pageData.enddate.label}
                                                placeholder={pageData.enddate.placeholder}
                                                type={pageData.enddate.type}
                                                name={pageData.enddate.name}
                                            />
                                            <MultiSelect
                                                label={pageData.strday.label}
                                                placeholder={pageData.strday.placeholder}
                                                type="multi"
                                                name={pageData.strday.name}
                                                value={formik.values.dayname}
                                                isMulti={false}
                                                options={DaysName}
                                                onChange={(value) =>
                                                    formik.setFieldValue('dayname', value)
                                                }
                                            />
                                            <MultiSelect
                                                label={pageData.endday.label}
                                                placeholder={pageData.endday.placeholder}
                                                type="multi"
                                                name={pageData.endday.name}
                                                value={formik.values.dayname1}
                                                isMulti={false}
                                                options={DaysName2}
                                                onChange={(value) =>
                                                    formik.setFieldValue('dayname1', value)
                                                }
                                            />
                                        </>
                                    )}

                                    {props?.data?.paremsData?.action == 'INSERT' && props?.data?.paremsData?.action == 'INSERT' && formik.values.status.value == 'close' && (
                                        <>
                                            <TextField
                                            title="Please Enter Date"
                                            data-toggle="tooltip"
                                                label={pageData.clsedate.label}
                                                type={pageData.clsedate.type}
                                                placeholder={pageData.clsedate.placeholder}
                                                name={pageData.clsedate.name}
                                            />
                                        </>
                                    )
                                    }
                                </div>
                                <div className="d-flex justify-content-end">
                                    <button
                                        type="submit"
                                        className="btn btn-primary p-2 m-2"
                                    >
                                      {pageData.savebtn}
                                    </button>
                                    <Link
                                        to="MNU_HOLI_CALENDER"
                                        className="btn btn-primary p-2 m-2 text-white"
                                        onClick={(e) => {
                                            dispatch(setParems({ data: "MNU_HOLI_CALENDER" }));
                                        }}
                                    >
                                       {pageData.canclebtn}
                                    </Link>
                                </div>
                            </Form>
                        </div>
                    )}
                </Formik>
            </div>
            </>
        )}
        </>
    )
}



