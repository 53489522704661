import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import DaynmicApicall from "../../../utils/function";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { Stack } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import TableDetails from "./TableDetails";

export default function CallHistory(props) {
  const { userInfo } = useSelector((state) => state?.user?.value);
  const {
    userInfo: { userInfo: callHistoryDetails },
  } = props;
  const [tableData, setTableData] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [filterColumn, setFilterColumn] = useState("");
  const [pageSize, setPageSize] = useState(10);
  async function CallHistoryData() {
    await DaynmicApicall(
      `mcrmdlr/getEngagementhistory/${callHistoryDetails?.data?.custinfo?.cust_id}/${callHistoryDetails?.data?.campinfo?.keypointer}`,
      "",
      "get",
      userInfo.data[0].UserToken
    )
      .then((data) => {
        setTableData(data);
      })
      .catch((error) => {
        console.lof("ERROR", error.message);
        setTableData([]);
      });
  }

  const columns = [
    {
      field: "contactdate",
      headerName: "Contact Date",
      minWidth: 130,
      flex: 1,
    },
    { field: "customerid", headerName: "Customer Id", minWidth: 150, flex: 1 },
    {
      field: "customer_name",
      headerName: "Customer Name",
      minWidth: 150,
      flex: 1,
    },
    { field: "contactby", headerName: "Contact By", minWidth: 110, flex: 1 },
    {
      field: "mobile_no",
      headerName: "Contact Number",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "responsecode",
      headerName: "Response Code",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "subresponsecode",
      headerName: "Sub Response Code",
      minWidth: 170,
      flex: 1,
    },
    { field: "revertdate", headerName: "Revert Date", minWidth: 130, flex: 1 },
    { field: "reverttime", headerName: "Revert Time", minWidth: 130, flex: 1 },
    { field: "callid", headerName: "Call Id", minWidth: 90, flex: 1 },
    {
      field: "followupdate",
      headerName: "Followup Date ",
      minWidth: 130,
      flex: 1,
    },
    {
      field: "dialer_session_id",
      headerName: "Dialer Session Id",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "component_data",
      headerName: "Show Details",
      minWidth: 150,
      flex: 1,
      headerClassName: "table-header",
      renderCell: (params) => {
        const value =
          params.value && params.value[0] && params.value[0].response_component;
        let content;
        switch (value && value.resp_code) {
          case "PTP":
            const { ptpdate, ptpAmount, remarks, sessionid } = value || {};
            const selectedData = { ptpdate, ptpAmount, remarks, sessionid };
            content = (
              <Tooltip
                title={<TableDetails data={selectedData} category="PTP" />}
                color="inherit"
                placement="left-start"
                arrow
                style={{ backgroundColor: "#ffffff", maxWidth: "300px" }}
              >
                <span
                  style={{
                    color: "#1976d2",
                    cursor: "pointer",
                  }}
                >
                  Show PTP Details
                </span>
              </Tooltip>
            );
            break;
          case "CMI":
            const { surveyData } = value || [];
            const selectedCMIData = { surveyData };
            content = (
              <Tooltip
                title={<TableDetails data={selectedCMIData} category="CMI" />}
                color="inherit"
                placement="left-start"
                arrow
                style={{
                  backgroundColor: "#ffffff",
                  maxWidth: "1000",
                }}
              >
                <span
                  style={{
                    color: "#1976d2",
                    cursor: "pointer",
                  }}
                >
                  Show CMI Details
                </span>
              </Tooltip>
            );
            break;
          default:
            const remark = value?.remarks ? value?.remarks : [];
            const remarksData = { remark };
            content = (
              <Tooltip
                title={<TableDetails data={remarksData} category="DEFAULT" />}
                color="inherit"
                placement="left-start"
                arrow
                style={{
                  backgroundColor: "#ffffff",
                  maxWidth: "1000",
                }}
              >
                <span
                  style={{
                    color: "#1976d2",
                    cursor: "pointer",
                  }}
                >
                  Show Remarks
                </span>
              </Tooltip>
            );
        }

        return content;
      },
    },
  ];

  function CustomToolbar() {
    return (
      <Stack direction="row" justifyContent="flex-end">
        <GridToolbarContainer className={gridClasses.toolbarContainer}>
          {/* <GridToolbarExport /> */}
          <GridToolbarFilterButton />
        </GridToolbarContainer>
      </Stack>
    );
  }

  const onFilterChange = React.useCallback(async (filterModel) => {
    if (
      filterModel?.items?.[0]?.value &&
      filterModel?.items?.[0]?.value.length > 0
    ) {
      setFilterColumn(filterModel?.items?.[0]?.columnField);
      setFilterValue(filterModel?.items?.[0]?.value);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (callHistoryDetails?.data?.custinfo?.cust_id) await CallHistoryData();
    })();
  }, [callHistoryDetails?.data?.custinfo?.cust_id]);

  return (
    <>
      <div className="card card-body border-light shadow">
        <div className="table-settings mb-4">
          <div className="my-2">
            <DataGrid
              getRowId={(r) => r.dialer_session_id}
              rows={tableData.length > 0 ? tableData : []}
              columns={columns}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 20, 50, 100]}
              onFilterModelChange={onFilterChange}
              autoHeight={true}
              className="bg-white"
              components={{
                Toolbar: CustomToolbar,
              }}
              density="compact"
            />
          </div>
        </div>
      </div>
    </>
  );
}
