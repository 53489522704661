import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./FloatingSidebar.css";
import DaynmicApicall from "../../utils/function";
import {
  Stack,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TablePagination,
} from "@mui/material";

const FloatingNotificationbar = ({ isOpen }) => {
  const { userInfo } = useSelector((state) => state?.user?.value);
  const [animationClass, setAnimationClass] = useState("");
  const [loading, setLoading] = useState(false);
  const [Data, setData] = useState([]);
  const [filterColumn, setFilterColumn] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [currentlyPlayingAudio, setCurrentlyPlayingAudio] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const dispatch = useDispatch();

  const isTextFieldEnabled = Boolean(filterColumn);

  useEffect(() => {
    const handleAnimation = async () => {
      if (isOpen) {
        setAnimationClass("slide-in");
        await fetchDialerData();
      } else {
        setAnimationClass("slide-out");
      }
    };
    handleAnimation();
  }, [isOpen]);

  const fetchDialerData = async () => {
    try {
      setLoading(true);
      await DaynmicApicall(
        `/mcrmdlr/getesclationnotify/${userInfo.data[0].userid}`,
        "",
        "get",
        userInfo.data[0].UserToken
      )
        .then((res) => {
          setData(res);
          setLoading(false);
        })
        .catch((err) => {
          console.log("ERROR", err);
          setData([]);
          setLoading(false);
        });
    } catch (error) {
      console.log("ERROR", error);
      setData([]);
      setLoading(false);
    }
  };

  const handleAudioPlay = async (id) => {
    if (currentlyPlayingAudio && currentlyPlayingAudio !== id) {
      const previousAudio = document.getElementById(currentlyPlayingAudio);
      if (previousAudio) {
        await previousAudio.pause();
        setCurrentlyPlayingAudio(null);
      }
    }
    const audioElement = document.getElementById(id);
    if (audioElement) {
      await audioElement.play();
      setCurrentlyPlayingAudio(id);
    }
  };

  const handleAudioPause = async (id) => {
    const audioElement = document.getElementById(id);
    if (audioElement) {
      await audioElement.pause();
      setCurrentlyPlayingAudio(null);
    }
  };

  const filteredData =
    Data?.length > 0 &&
    Data?.filter((row) => {
      if (!filterValue) return true;
      return row[filterColumn]
        ?.toString()
        .toLowerCase()
        .includes(filterValue.toLowerCase());
    });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedData =
    filteredData?.length > 0 &&
    filteredData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <div
      className={`floating-sidebar2 ${animationClass}`}
      onAnimationEnd={() => {
        if (!isOpen) {
          setAnimationClass("");
        }
      }}
      style={{ display: isOpen ? "" : "none" }}
    >
      {isOpen && (
        <div className="popup2 tabStyling">
          <div className="table-settings mb-4">
            <Stack direction="row" spacing={2} alignItems="center">
              <div>
                <strong>
                  <span>Upcoming Escalation Calls</span>
                </strong>
              </div>
              <div className="custom-filter">
                <FormControl
                  variant="outlined"
                  size="small"
                  sx={{
                    width: "100%",
                    marginRight: "5px",
                    color: "white",
                    "& .MuiOutlinedInput-root": {
                      width: "100%",
                      "& .MuiSelect-select": {
                        color: "white",
                      },
                      "& .MuiSelect-icon": {
                        color: "white",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "white",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "white",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "white",
                      },
                      "& .MuiSelect-nativeInput": {
                        color: "white",
                      },
                    },
                  }}
                >
                  <InputLabel>Filter Column</InputLabel>
                  <Select
                    value={filterColumn}
                    onChange={(e) => setFilterColumn(e.target.value)}
                    label="Filter Column"
                  >
                    <MenuItem value="cuid">Cuid</MenuItem>
                    <MenuItem value="cust_name">Customer Name</MenuItem>
                    <MenuItem value="campname">Camp Name</MenuItem>
                    <MenuItem value="agent_id">Agent Id</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  label="Filter Value"
                  variant="outlined"
                  size="small"
                  value={filterValue}
                  onChange={(e) => setFilterValue(e.target.value)}
                  disabled={!isTextFieldEnabled}
                  sx={{
                    width: "100%",
                    color: "white",
                    "& .MuiOutlinedInput-root": {
                      width: "100%",
                      "& .MuiOutlinedInput-input": {
                        color: "white",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "white",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "white",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "white",
                      },
                    },
                  }}
                />
              </div>
            </Stack>
            <div className="my-2">
              {loading ? (
                <div style={{ "text-align-last": "center" }}>Loading...</div>
              ) : (
                <>
                  <table className="custom-table">
                    <thead>
                      <tr>
                        <th>Action</th>
                        <th>Cuid</th>
                        <th>Customer Name</th>
                        <th>Agent Id</th>
                        <th>Camp Name</th>
                        <th>Call Back Date</th>
                        <th>Call Back Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedData?.length > 0 ? (
                        paginatedData.map((row) => {
                          return (
                            <tr key={row.log_id} className="custom-tr">
                              <td>
                                <audio
                                  id={row.log_id}
                                  controls
                                  src={row.recording_file}
                                  style={{
                                    width: "250px",
                                    padding: "15px 5px",
                                  }}
                                  onPlay={() => handleAudioPlay(row.log_id)}
                                  onPause={() => handleAudioPause(row.log_id)}
                                ></audio>
                              </td>
                              <td>{row.cuid}</td>
                              <td>{row.cust_name}</td>
                              <td>{row.agent_id}</td>
                              <td>{row.campname}</td>
                              <td>{row.callback_date.split("T")[0]}</td>
                              <td>{row.callback_time}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="7" style={{ textAlign: "center" }}>
                            No Data Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <TablePagination
                    component="div"
                    count={filteredData?.length > 0 ? filteredData?.length : 0}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[4]}
                    style={{ color: "white", paddingTop: "5px" }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FloatingNotificationbar;
