import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import React from "react";
import DaynmicApicall from "../../utils/function";
import { MultiSelect } from "../../components/formValidation/MultiSelect";
import { Form, Formik } from "formik";
import { TextField } from "../../components/formValidation/TextField";
import { Link } from "react-router-dom";
import api from "../../utils/api";

export default function Reporting(props) {
  const { userInfo } = useSelector((state) => state?.user?.value);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filterColumn, setFilterColumn] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [pageData, setPageData] = useState("");
  const [columns, setColumns] = useState([]);
  const [getReportingType, setReportingType] = useState([]);
  const [getReportName, setReportName] = useState("");

  async function reportingTyprList() {
    await DaynmicApicall(
      `prm/getParamdata/ALL/reporting_parameter/${props.activemenu.keypointer}`,
      "",
      "get",
      userInfo.data[0].UserToken
    )
      .then(async (res) => {
        let bData = [];
        bData = await res.map((item) => {
          return {
            value: item.paracode,
            label: item.paravalue,
          };
        });
        setReportingType(bData);
      })
      .catch((error) => {
        toast.error(
          error.response.message ??
            error.message ??
            "OOPs, something went wrong."
        );
      });
  }

  function CustomToolbar() {
    return (
      <Stack direction="row" justifyContent="flex-end">
        <GridToolbarContainer className={gridClasses.toolbarContainer}>
          <GridToolbarExport
            csvOptions={{
              fileName: getReportName !== "" ? getReportName : "Report",
            }}
          />{" "}
          <GridToolbarFilterButton />
        </GridToolbarContainer>
      </Stack>
    );
  }

  const onFilterChange = React.useCallback(async (filterModel) => {
    if (
      filterModel?.items?.[0]?.value &&
      filterModel?.items?.[0]?.value.length > 0
    ) {
      setFilterColumn(filterModel?.items?.[0]?.columnField);
      setFilterValue(filterModel?.items?.[0]?.value);
    }
  }, []);

  useEffect(() => {
    reportingTyprList();
  }, []);

  const onSubmit = async (values, { resetForm }) => {
    setTableData([]);
    setReportName(values.reportingType.label);
    const Info = api.get(
      `appuser/getcalldata/${values.reportingType.value}/${
        values.startdate ? values.startdate : "NA"
      }/${values.enddate ? values.enddate : "NA"}`,
      { headers: { Authorization: userInfo.data[0].UserToken } }
    );
    toast
      .promise(Info, {
        // loading: "",
        success: (Info) => {
          return Info.data.message;
        },
        error: (err) => {
          return (
            err?.response?.data?.errors ??
            err?.response?.data?.message ??
            err?.message ??
            "OOPs something went wrong."
          );
        },
      })
      .then((res) => {
        let col = Object.keys(res.data.data[0]).map((item) => {
          return {
            field: item,
            headerName: item.toUpperCase(),
            editable: true,
            minWidth: item == "date" ? 200 : 100,
            flex: 1,
          };
        });
        setColumns(col);
        setTableData(res.data.data);
      })
      .catch((error) => {
        setReportName("");
        console.log(error.message);
      });
  };
  function generateRandom() {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  const calculateMaxDate = (selectedDate, days) => {
    if (selectedDate !== "NA" && days !== "NA") {
      const maxDate = new Date(selectedDate);
      maxDate.setDate(maxDate.getDate() + days);
      return maxDate.toISOString().split("T")[0];
    }
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const validate = Yup.object({
    reportingType: Yup.object().required("Please select report type*"),
  });

  return (
    <>
      <div className="container-fluid py-4 bg ">
        <div className="row">
          <div className="card col-xl-12 bg-dark">
            <div className="row row-cols-md-2 m-2 p-2 row-cols-lg-2 row-cols-xl-2 font-weight-bold">
              <span
                className="text-left text-light "
                title="Manage User"
                data-toggle="tooltip"
              >
                Reporting Details
              </span>
              <span className="text-right"></span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Formik
          initialValues={{
            reportingType: "",
            startdate: "NA",
            enddate: "NA",
          }}
          validationSchema={validate}
          onSubmit={onSubmit}
        >
          {(formik) => (
            <div
              className="card card-body border-light shadow mb-4"
              style={pageData?.style}
            >
              <Form>
                <div className="row mt-3">
                  <MultiSelect
                    label="Reporting Type"
                    name="reportingType"
                    isMulti={false}
                    formik={formik}
                    options={getReportingType}
                    placeholder="Select Reporting Type"
                    type="multi"
                    value={formik.values.reportingType}
                    onChange={(value) =>
                      formik.setFieldValue("reportingType", value)
                    }
                  />
                  <TextField label="Start Date" name="startdate" type="date" />
                  <TextField
                    label="End Date"
                    name="enddate"
                    type="date"
                    max={
                      formik.values["startdate"] !== "NA"
                        ? calculateMaxDate(formik.values["startdate"], 15)
                        : ""
                    }
                    min={
                      formik.values["startdate"] !== "NA"
                        ? formik.values["startdate"]
                        : ""
                    }
                    disabled={formik.values["startdate"] === "NA"}
                  />
                </div>
                <div className="d-flex justify-content-end mt-1">
                  <button type="submit" className="btn btn-primary btn-sm m-2">
                    Submit
                  </button>
                  <Link
                    to="MNU_CRUD_MENU"
                    className="btn btn-primary btn-sm m-2"
                    onClick={(e) => {
                      formik.resetForm();
                      setTableData([]);
                    }}
                  >
                    Cancel
                  </Link>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </div>
      <div className="card card-body border-light shadow">
        <div className="table-settings mb-4">
          <div className="my-2">
            <DataGrid
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
              title="gstadfggg"
              data-toggle="tooltip"
              getRowId={(row) => generateRandom()}
              rows={tableData}
              columns={columns}
              rowsPerPageOptions={[10, 25, 50, 100]}
              onFilterModelChange={onFilterChange}
              colSpan={3}
              count={tableData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              slotProps={{
                select: {
                  "aria-label": "rows per page",
                },
                actions: {
                  showFirstButton: true,
                  showLastButton: true,
                },
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              autoHeight={true}
              className="bg-white"
              components={{
                Toolbar: CustomToolbar,
              }}
              density="compact"
            />
          </div>
        </div>
      </div>
    </>
  );
}
