import { Form, Formik } from "formik";
import React, { useEffect, useState, useRef } from "react";
import * as Yup from "yup";
import { MultiSelect } from "../formValidation/MultiSelect";
import { TextField } from "../formValidation/TextField";
import DaynmicApicall from "../../utils/function";
import { useSelector } from "react-redux";
import { Toggle } from "../formValidation/Toggle";
import toast from "react-hot-toast";
import api from "../../utils/api";

function DespositionCompo(props) {
  const { userInfo } = useSelector((state) => state?.user?.value);
  const { UserToken, userid, usergroup, dlrAgentId } = userInfo.data[0];
  let { crmsaveandexit } = useSelector((state) => state?.dialer);
  const [pageData, setpageData] = useState([]);
  const [options, setoptions] = useState([]);

  async function pageInfo() {
    await DaynmicApicall(
      `/appuser/getcomponetbyid/${props.campinfo.keypointer}/${props?.componentid}`,
      "",
      "get",
      userInfo.data[0].UserToken
    )
      .then((data) => {
        setpageData(data[0] ? data[0]?.DATA : []);
      })
      .catch((err) => {
        console.log("ERROR", err);
        setpageData([]);
      });
  }

  async function fetchSelectOptions(field) {
    if (field.search === "API") {
      setoptions([]);
      try {
        await DaynmicApicall(
          `/mcrmdlr/getlanguageagent/${props.campinfo.campid}`,
          "",
          "get"
        ).then(async (data) => {
          let oData = [];
          oData = await data.map((item) => {
            return {
              value: item.user_id,
              label: item.agentid,
            };
          });
          setoptions(oData);
        });

        setpageData((prevPageData) => {
          const updatedPageData = [...prevPageData];
          const fieldToUpdate = updatedPageData.find(
            (item) => item.name === field.name
          );
          if (fieldToUpdate) {
            fieldToUpdate.options = options;
          }
          return updatedPageData;
        });
      } catch (error) {
        console.error("Error fetching select options:", error);
      }
    }
    if (field.search === "API1") {
      setoptions([]);
      try {
        await DaynmicApicall(
          `/mcrmdlr/getlanguagetl/${props.campinfo.campid}`,
          "",
          "get"
        ).then(async (data) => {
          let oData = [];
          oData = await data.map((item) => {
            return {
              value: item.user_id,
              label: item.agentid,
            };
          });
          setoptions(oData);
        });

        setpageData((prevPageData) => {
          const updatedPageData = [...prevPageData];
          const fieldToUpdate = updatedPageData.find(
            (item) => item.name === field.name
          );
          if (fieldToUpdate) {
            fieldToUpdate.options = options;
          }
          return updatedPageData;
        });
      } catch (error) {
        console.error("Error fetching select options:", error);
      }
    }
  }

  useEffect(async () => {
    await pageInfo();
  }, []);

  useEffect(() => {
    const selectAPIFields = pageData?.filter(
      (field) => field.type === "select" && field.search === "API"
    );

    const shouldRefreshData = selectAPIFields?.some((field) => {
      return field.options.length === 0;
    });

    const selectAPIFields1 = pageData?.filter(
      (field) => field.type === "select" && field.search === "API1"
    );

    const shouldRefreshData1 = selectAPIFields1?.some((field) => {
      return field.options.length === 0;
    });

    if (shouldRefreshData) {
      selectAPIFields.forEach(fetchSelectOptions);
    }
    if (shouldRefreshData1) {
      selectAPIFields1.forEach(fetchSelectOptions);
    }
  }, [pageData]);

  // Define validation schema dynamically based on field type
  const validationSchema = Yup.object().shape(
    pageData?.reduce((schema, field) => {
      if (field.type === "text") {
        schema[field.name] = field.required
          ? Yup.string()
              .required(`${field.label} is required`)
              .matches(
                /^[a-zA-Z0-9\s]+$/,
                "* This field cannot contain white space and special character"
              )
          : Yup.string().matches(
              /^[a-zA-Z0-9\s]+$/,
              "* This field cannot contain special character"
            );
      } else if (field.type === "number") {
        schema[field.name] = field.required
          ? Yup.number()
              .typeError(`${field.label} must be a number`)
              .required(`${field.label} is required`)
              .min(0, `${field.label} cannot be negative`)
              .test(
                "no-spaces",
                "* This field cannot contain blank spaces",
                (value) => !/\s/.test(value)
              )
          : Yup.number()
              .typeError(`${field.label} must be a number`)
              .min(0, `${field.label} cannot be negative`)
              .test(
                "no-spaces",
                "* This field cannot contain blank spaces",
                (value) => !/\s/.test(value)
              );
      } else if (field.type === "select") {
        schema[field.name] = field.required
          ? Yup.object().required(`*${field.label} is required`)
          : Yup.array();
      } else if (field.type === "date") {
        const dateFormatValidation = Yup.string().matches(
          /^\d{4}-\d{2}-\d{2}$/,
          "* Date format should be DD-MM-YYYY"
        );

        if (field.previous === false) {
          const minDate = new Date();
          minDate.setDate(minDate.getDate() - 1);

          schema[field.name] = field.required
            ? dateFormatValidation
                .test(
                  "isValid",
                  "*Date cannot be in the past",
                  function (value) {
                    const inputDate = new Date(value);
                    return inputDate >= minDate;
                  }
                )
                .required(`${field.label} is required`)
            : dateFormatValidation;
        } else {
          schema[field.name] = field.required
            ? dateFormatValidation.required(`${field.label} is required`)
            : dateFormatValidation;
        }
      }

      return schema;
    }, {})
  );

  // Create initial values object dynamically
  const initialValues = pageData?.reduce((acc, field) => {
    acc[field.name] = field.type === "select" && field.isMulti ? [] : "";

    return acc;
  }, {});

  const handleTextChange = (field, formik) => (event) => {
    formik.setFieldValue(field.name, event.target.value);
  };

  const handleDateChange = (field, formik) => (event) => {
    formik.setFieldValue(field.name, event.target.value);
  };

  const handleSelectChange = (field, formik) => (selectedOptions) => {
    let values = selectedOptions;
    formik.setFieldValue(field.name, values);
  };

  const handleToggleChange = (field, formik) => (event) => {
    formik.setFieldValue(field.name, event.target.checked);
  };

  const fieldComponents = {
    text: TextField,
    number: TextField,
    date: TextField,
    switch: Toggle,
    select: MultiSelect,
    // Add more field types and corresponding components here
  };

  const formSubmit = async (values, { resetForm }) => {
    Object.entries(values).map((data) => {
      if (typeof data[1] === "object") {
        values[data[0]] = data[1].value;
      }
    });

    let defaultValue = {
      custid: props.customer.custid,
      call_date: new Date().toISOString(),
      agentid: userInfo.data[0]?.userid,
      resp_code: crmsaveandexit.resp_code
        ? crmsaveandexit.resp_code
        : props.customer.resp_code,
      sub_resp_code:
        crmsaveandexit.sub_resp_code === "NA"
          ? props?.customer?.resp_code
          : crmsaveandexit.sub_resp_code,
      campid: props?.campinfo?.campid,
      compid: props?.componentid,
      pol_loan_no: props.customer.policy,
      keypointer: props.campinfo.keypointer,
      call_id: 0,
      sessionid: crmsaveandexit?.dialer_session_id,
      action_name: "INSERT",
    };

    await api
      .post(
        "/mcrmdlr/managedispcomp",
        { ...values, ...defaultValue },
        { headers: { Authorization: userInfo.data[0].UserToken } }
      )
      .then(async (Info) => {
        props.onClose();
        toast.success(Info.data.message);
        switch (defaultValue.resp_code) {
          case "LB":
            props.setLBagent(values.regionalEmployee);
            break;
          case "ESCALATION":
            props.setTLId(values.tlmanagerescalation);
            break;
          case "LEAD":
          // console.log("LEAD defaultValue", defaultValue);
          default:
        }
      })
      .catch((error) => {
        console.log("Error", error.message);
        toast.error(error.response?.data?.errors);
      });
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={formSubmit}
      >
        {(formik) => (
          <div className="card card-body border-light shadow mb-4">
            <Form>
              <div
                className="row mt-3 m-1 p-1"
                style={{ textAlign: "justify" }}
              >
                {pageData &&
                  pageData.map((field) => {
                    const FieldComponent = fieldComponents[field.type];
                    const handleChange =
                      field.type === "text"
                        ? handleTextChange(field, formik)
                        : field.type === "number"
                        ? handleTextChange(field, formik)
                        : field.type === "select"
                        ? handleSelectChange(field, formik)
                        : field.type === "switch"
                        ? handleToggleChange(field, formik)
                        : field.type === "date"
                        ? handleDateChange(field, formik)
                        : null;

                    return (
                      <FieldComponent
                        key={field.name}
                        data-toggle="tooltip"
                        title={field.title}
                        label={field.label}
                        name={field.name}
                        placeholder={field.placeholder}
                        type={field.type}
                        options={field.options}
                        ismulti={field.isMulti}
                        defaultChecked={formik.values[field.name]}
                        value={formik.values[field.name]}
                        onChange={handleChange}
                      />
                    );
                  })}
                <div className="d-flex justify-content-end mt-1">
                  <button type="submit" className="btn btn-primary btn-sm m-2">
                    Submit
                  </button>
                </div>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default DespositionCompo;
