import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { Stack, LinearProgress, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { MultiSelect } from "../../../components/formValidation/MultiSelect";
import { Form, Formik } from "formik";
import PageHeader from "../../../components/PageHeader";
import DaynmicApicall from "../../../utils/function";
import { TextField } from "../../../components/formValidation/TextField";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import SaveIcon from "@mui/icons-material/Save";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { ErrorMessage } from "formik";
import Select from "react-select";
import axios from "axios";
import api from "../../../utils/api";
import { BASE_URL } from "../../../utils/constent";

const CustomMultiSelect = ({
  label,
  name,
  formik,
  onChange,
  isMulti,
  options,
  value,
  format,
  ...rest
}) => {
  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { isDisabled }) => {
      return {
        ...styles,
        cursor: isDisabled ? "not-allowed" : "pointer",
      };
    },
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#727cf5",
        borderColor: "#727cf5",
        borderRadius: "3px",
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: "white",
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: "#ffffff",
      cursor: "pointer",
      ":hover": {
        backgroundColor: "red",
        color: "white",
      },
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Set a high zIndex value
  };

  return (
    <div className="col-md-12 mb-3">
      <div className="form-control-sm row">
        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 pt-2">
          <label htmlFor={name}>{label}</label>
        </div>
        <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8">
          <Select
            name={name}
            styles={colourStyles}
            value={value}
            options={options}
            onChange={onChange}
            isMulti={isMulti}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            {...rest}
          />
          <ErrorMessage
            component="div"
            name={name}
            className="error text-danger"
          />
        </div>
      </div>
    </div>
  );
};

export default function CutomerCallRecording(props) {
  const { ActiveMenu, InitialTableData } = props;
  const { userInfo } = useSelector((state) => state?.user?.value);
  const [campList, setCampList] = useState([]);
  const [searchfount, setSearchfount] = useState(false);
  const [searchdata, setSearchdata] = useState([]);
  const { campdetail } = useSelector((state) => state.campaign);
  const [tableData, setTableData] = useState([]);
  const [filterColumn, setFilterColumn] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [open, setOpen] = useState(false);
  const [pageData, setpageData] = useState("");
  const [selectData, setSelectData] = useState([]);
  const [options, setOptions] = useState([]);
  const [pageSize, setPageSize] = useState(25);
  const [playingRows, setPlayingRows] = useState([]);
  const [currentlyPlayingAudio, setCurrentlyPlayingAudio] = useState(null);

  useEffect(async () => {
    await pageInfo();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onSave = (selectedRows) => {
    handleSave(selectedRows);
  };

  const handleSave = async (selectedRows) => {
    if (selectedRows && selectedRows.length > 0) {
      setShowProgress(true);

      try {
        for (const row of selectedRows) {
          let fileName = "";
          options.forEach((option) => {
            fileName += row[option.value === null ? "" : option.value] + "_";
          });
          fileName = fileName.slice(0, -1);
          fileName += ".wav";
          await downloadFile(row.recording_file, fileName);
        }
      } finally {
        setShowProgress(false);
      }
    }
  };

  const downloadFile = async (url, fileName) => {
    const payload = {
      url: url,
      fileName: fileName,
    };

    try {
      const response = await axios.post(
        `${BASE_URL}dialer/audio`,
        { payload },
        {
          responseType: "blob",
          onDownloadProgress: (progressEvent) => {
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            setDownloadProgress(progress);
          },
        }
      );

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setDownloadProgress(0);
      setSelectedRows([]);
    } catch (error) {
      console.error("Axios Error:", error);
    }
  };

  const handleSaveClick = () => {
    onSave(selectedRows);
  };

  const getRowClassName = (params) => {
    return playingRows.includes(params.row.id) ? "playing-row" : "";
  };

  const handleAudioPlay = async (params) => {
    const { id, recording_file } = params.row;

    if (currentlyPlayingAudio && currentlyPlayingAudio !== id) {
      const previousAudio = document.getElementById(currentlyPlayingAudio);
      if (previousAudio) {
        await previousAudio.pause();
        setCurrentlyPlayingAudio(null);
      }
    }
    const audioElement = document.getElementById(id);
    if (audioElement) {
      await audioElement.play();
      setCurrentlyPlayingAudio(id);
    }
  };

  const handleAudioPause = async (id) => {
    const audioElement = document.getElementById(id);

    if (audioElement) {
      await audioElement.pause();
      setCurrentlyPlayingAudio(null);
    }
  };

  const columns = [
    {
      field: "Action",
      type: "actions",
      headerName: "Action",
      minWidth: 250,
      flex: 1,
      getActions: (params) => [
        <audio
          id={params.row.id}
          controls
          src={params.row.recording_file}
          style={{
            width: "250px",
            padding: "15px 5px",
          }}
          onPlay={() => {
            setPlayingRows((prevRows) => [...prevRows, params.row.id]);
            handleAudioPlay(params);
          }}
          onPause={() => {
            handleAudioPause(params.row.id);
          }}
        ></audio>,
      ],
    },
    {
      field: "phone_no",
      headerName: "Phone Number",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "policynumber",
      headerName: "Loan/Policy",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "resp_code",
      headerName: "Resp Code",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "username",
      headerName: "Agent Name",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "agentid",
      headerName: "Agent ID",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "id",
      headerName: "Recording Id",
      minWidth: 150,
      flex: 1,
      hide: true,
    },
    { field: "campname", headerName: "Camp Name", minWidth: 150, flex: 1 },
    {
      field: "customer_connect_wtih_agent",
      headerName: "Start Time",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "customer_disconnected_at",
      headerName: "End Time",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "recording_file",
      headerName: "Recording File",
      minWidth: 150,
      flex: 1,
    },
  ];

  async function pageInfo() {
    await DaynmicApicall(
      `/appuser/getcomponetbyid/${ActiveMenu}/151`,
      "",
      "get",
      userInfo.data[0].UserToken
    )
      .then((data) => {
        setpageData(data[0]?.DATA);
        setSelectData(data[0]?.DATA?.optoins);
      })
      .catch((err) => {
        console.log("ERROR", err);
      });
  }

  const CustomToolbar = React.memo(() => {
    return (
      <Stack direction="row" justifyContent="flex-end">
        <GridToolbarContainer className={gridClasses.toolbarContainer}>
          <button
            className="MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall MuiButtonBase-root  css-1knaqv7-MuiButtonBase-root-MuiButton-root"
            tabIndex="0"
            type="button"
            onClick={handleClickOpen}
            style={{ display: `${selectedRows.length}` > 0 ? " " : "none" }}
          >
            <span className="MuiButton-startIcon MuiButton-iconSizeSmall css-y6rp3m-MuiButton-startIcon">
              <SaveIcon />
            </span>
            Save
          </button>
          <GridToolbarFilterButton />
        </GridToolbarContainer>
      </Stack>
    );
  });

  const onFilterChange = React.useCallback(async (filterModel) => {
    if (
      filterModel?.items?.[0]?.value &&
      filterModel?.items?.[0]?.value.length > 0
    ) {
      setFilterColumn(filterModel?.items?.[0]?.columnField);
      setFilterValue(filterModel?.items?.[0]?.value);
    }
  }, []);

  return (
    <>
      {pageData && (
        <>
          {showProgress && (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  color="text.primary"
                >
                  DOWNLOADING...
                </Typography>
              </div>
              <LinearProgress value={downloadProgress} />
            </div>
          )}
          <div className="my-2">
            {searchdata && (
              <DataGrid
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
                title="gstadfggg"
                data-toggle="tooltip"
                getRowId={(row) => row.id}
                rows={InitialTableData ? InitialTableData : []}
                getRowClassName={getRowClassName}
                onSelectionModelChange={(ids) => {
                  const selectedIDs = new Set(ids);
                  const selectedRowData = InitialTableData.filter((row) => {
                    return selectedIDs.has(row.id);
                  });
                  setSelectedRows(selectedRowData);
                }}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[25, 50, 75, 100]}
                onFilterModelChange={onFilterChange}
                colSpan={3}
                slotProps={{
                  select: {
                    "aria-label": "rows per page",
                  },
                  actions: {
                    showFirstButton: true,
                    showLastButton: true,
                  },
                }}
                autoHeight={true}
                className="bg-white"
                components={{
                  Toolbar: CustomToolbar,
                }}
                density="compact"
                checkboxSelection
              />
            )}
            <Dialog
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={open}
            >
              <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                {pageData.headerName}
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              <DialogContent dividers>
                <Formik>
                  {(formik) => (
                    <Form>
                      <div className="row">
                        <CustomMultiSelect
                          label={pageData.label}
                          name={pageData.name}
                          isMulti={true}
                          formik={formik}
                          options={selectData}
                          onChange={(value) => setOptions(value)}
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              </DialogContent>
              <DialogActions>
                <button
                  type="submit"
                  className="btn btn-primary btn-sm m-2"
                  onClick={() => {
                    return handleSaveClick(), handleClose();
                  }}
                >
                  {pageData.button}
                </button>
              </DialogActions>
            </Dialog>
          </div>
        </>
      )}
    </>
  );
}
