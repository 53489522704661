import React, { useState, useEffect } from "react";
import DaynmicApicall from "../utils/function";
import { useSelector } from "react-redux";
import api from "../utils/api";
import toast from "react-hot-toast";
import UpdateIcon from "@mui/icons-material/Update";
import { getDialerServer } from "../utils/socket";
// import { getDialerServer } from '../utils/socket';

const BlinkingInfo = (props) => {
  const { userInfo } = useSelector((state) => state?.user?.value);
  let { crmwebphone } = useSelector((state) => state?.webphoneStatus);

  const [isBlinking, setIsBlinking] = useState(true);
  const [queueStatus, setQueueStatus] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const controller = new AbortController();
      const { signal } = controller;

      try {
        await DaynmicApicall(
          `/dialer/getagentstatus/${userInfo.data[0]?.dlrAgentId}`,
          "",
          "get",
          userInfo.data[0].UserToken,
          { signal }
        )
          .then((res) => {
            setQueueStatus(res);
          })
          .catch((error) => {
            console.log("API ERROR", error.message);
          });
      } catch (error) {
        if (error.name === "AbortError") {
          console.log("API call aborted");
        } else {
          console.error("Error fetching data:", error);
        }
      }
    };
    fetchData();
  }, [crmwebphone]);

  const f_getConnectedCamp = async (campid, userid) => {
    const camp = await api.get(`appuser/getcampbycampid/${campid}/${userid}`, {
      headers: {
        Authorization: userInfo.data[0].UserToken,
      },
    });
    return camp.data.data;
  };

  const styles = {
    blinkingText: {
      transition: "opacity 0.5s ease-in-out",
      opacity: isBlinking ? 1 : 0,
    },
    tableContainer: {
      maxHeight: "200px",
      overflow: "hidden",
    },
  };

  async function fetchAgentStatus() {
    try {
      await DaynmicApicall(
        `/dialer/getagentstatus/${userInfo.data[0]?.dlrAgentId}`,
        "",
        "get",
        userInfo.data[0].UserToken
      )
        .then((res) => {
          setQueueStatus(res);
        })
        .catch((error) => {
          console.log("API ERROR", error.message);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  return (
    <>
      {queueStatus?.length > 0 ? (
        <div className="card" style={{ ...styles.card }}>
          <div className="card-body p-0 px-0 m-0">
            <div>
              <div className="">
                <ul className="list-unstyled list-inline font-small m-0">
                  {queueStatus &&
                    queueStatus.map((data, index) => (
                      <li
                        key={index}
                        className="list-inline-item d-flex align-items-center white-text"
                      >
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <span
                            className="col-9 text-left"
                            style={{
                              fontSize: "10px",
                              fontWeight: "bolder",
                              color:
                                data.status === "UNAVAILABLE" ? "red" : "green",
                            }}
                          >
                            {data.campaign} {data.status}
                          </span>
                          <span className="col-3 text-right">
                            {data.status && (
                              <UpdateIcon
                                className=""
                                style={{ cursor: "pointer" }}
                                onClick={async (e) => {
                                  await fetchAgentStatus();
                                }}
                              />
                            )}
                          </span>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default BlinkingInfo;
