import { React, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeCurrentCall, setSaveandExit } from "../../redux/Dialer";
import {
  checkValidateJSON,
  getdetails,
  pageInfoJson,
  scoreUpload,
} from "../../utils/function";
import AgentScript from "../../components/CrmServices/AgentScript";
import CustomerSearchPage from "./searchpage/CustomerSearchPage";
import AgetDashboard from "../../pages/dashboard/AgetDashboard";
import DailerSidebar from "../Dialer/DailerSidebar";
import DaynmicApicall from "../../utils/function";
import CardDetails from "./CardDetails";
import AgentAction from "./AgentAction";
import socket from "../../utils/socket";
import api from "../../utils/api";
import "./Style.css";
import AgentDashboardNav from "../../pages/dashboard/AgentDashboardNav";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { Tooltip } from "@mui/material";
import { setGlobalLanguage } from "../../redux/Campaign";
import { removeCrmWebphone, setCrmWebphone } from "../../redux/dialerStates";
import { setCustomerInfo } from "../../redux/Dialer";
import {
  removeCallSession,
  setCampaignInfo,
} from "../../redux/CurrentCallSessionDetails";
import { error } from "jquery";

export default function CrmHomePage(props) {
  let { userInfo } = useSelector((state) => state?.user?.value);
  let { crmsaveandexit } = useSelector((state) => state?.dialer);
  let { crmwebphone } = useSelector((state) => state?.webphoneStatus);
  const { callScore } = useSelector((state) => state.callsession);
  let { callStartTime, dialerHangupStatus, dialerHangupCuid, callEndTime } =
    crmwebphone;
  const { dlrAgentId } = userInfo?.data[0];
  const [customerDetail, setCustomerDetail] = useState("");
  const [customerSearch, setCustomerSearch] = useState("");
  const [connectedCamp, setConnectedCamp] = useState("");
  const [pageData, setpageData] = useState([]);
  const [pageTimeoutData, setPageTimeoutData] = useState([]);
  const [dialerdata, setDialerdata] = useState("");
  const [dispalyTimer, setDispalyTimer] = useState(false);
  const [dispalyAutoSaveTimer, setDispalyAutoSaveTimer] = useState(false);
  const [getLBagent, setLBagent] = useState("");
  const [getTLId, setTLId] = useState("");
  const [scoreData, setScoreData] = useState("");
  const [startTimer, setStartTimer] = useState(false);
  const [stopTimer, setStopTimer] = useState(false);
  const [timer, settimer] = useState("");
  const [score, setScore] = useState("");
  let DialerCrmData = localStorage.getItem("currentcall")
    ? JSON.parse(JSON.parse(localStorage.currentcall)?.crm_data)
    : "";
  const dispatch = useDispatch();

  useEffect(() => {
    setScore(callScore);
  }, [callScore]);

  useEffect(() => {
    setScore(callScore);
  }, [callScore]);

  useEffect(() => {
    console.log(
      "AUTO RNR",
      dialerHangupCuid,
      dialerHangupStatus,
      callStartTime
    );
    if (
      DialerCrmData &&
      dialerHangupCuid == DialerCrmData.dialeruuid &&
      dialerHangupStatus == false &&
      callStartTime
    ) {
      const calculateTimeDifference = () => {
        const startTime = new Date(parseInt(callStartTime));
        const endTime = new Date();
        const timeDifference = endTime - startTime;
        console.log("AUTO RNR timeDifference", timeDifference);
        if (timeDifference < pageTimeoutData?.difference) {
          return true;
        } else {
          return false;
        }
      };

      setDispalyTimer(calculateTimeDifference());
    }
  }, [callStartTime, dialerHangupStatus, pageTimeoutData]);

  useEffect(() => {
    console.log("AUTO CALL SAVE", dialerHangupStatus);
    const hangupTime = new Date();
    let timer = null;
    let timeExceeded = false;

    timer = setInterval(() => {
      if (
        DialerCrmData &&
        dialerHangupCuid == DialerCrmData.dialeruuid &&
        dialerHangupStatus == false &&
        !timeExceeded
      ) {
        const currentTime = new Date();
        const timeDifference = currentTime - hangupTime;

        if (timeDifference > pageTimeoutData?.autoSaveDifference) {
          console.log("AUTO CALL SAVE timeDifference", timeDifference);
          timeExceeded = true;
          clearInterval(timer);
          setDispalyAutoSaveTimer(true);
        } else {
          setDispalyAutoSaveTimer(false);
        }
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [dialerHangupStatus, pageTimeoutData]);

  const heandelDialerCustomer = (customer) => setCustomerSearch(customer);
  const heandelDialerdata = (dialer) => {
    setDialerdata(dialer);
    if (!dialer) {
      setCustomerDetail("");
      setConnectedCamp("");
    }
  };

  const f_getConnectedCamp = async (campid, userid) => {
    const camp = await api
      .get(`appuser/getcampbycampid/${campid}/${userid}`, {
        headers: {
          Authorization: userInfo.data[0].UserToken,
        },
      })
      .catch((error) => {
        console.log("Error from f_getConnectedCamp: ", error);
      });
    dispatch(setCampaignInfo(camp.data.data[0]));
    return camp.data.data;
  };

  async function getCustomer(custid, keypointer) {
    if (custid) {
      const customer = await api.get(
        `mcrmdlr/getcustomer/${custid}/${keypointer}`,
        {
          headers: {
            Authorization: userInfo.data[0].UserToken,
          },
        }
      );
      dispatch(
        setSaveandExit({
          contact_no: customer.data?.data[0]?.contact_no,
        })
      );
      dispatch(setCustomerInfo(customer.data?.data[0]));
      await setCustomerDetail(customer.data?.data[0]);
      return customer.data.data[0];
    }
  }
  async function dialerscrmlog(data, keypointer) {
    if (data.call_status === "answered") {
      await api
        .post(`/mcrmdlr/managedialercrminput/${keypointer}`, data, {
          headers: {
            Authorization: userInfo.data[0].UserToken,
          },
        })
        .then(async (res) => {});
    }
  }

  async function getCrmdata() {
    await api
      .get(`/dialer/getcalldatapg/${dlrAgentId}`, {
        headers: {
          Authorization: userInfo.data[0].UserToken,
        },
      })
      .then(async (data) => {
        let dialerData = data.data.data;
        await localStorage.setItem(
          "currentcall",
          JSON.stringify(dialerData.event_data)
        );
        const parsedCrmData = JSON.parse(dialerData?.event_data?.crm_data);
        const newData = {
          ...dialerData,
          event_data: {
            ...dialerData.event_data,
            crm_data: parsedCrmData,
          },
        };
        await crmhomepageprerequisite(newData);
      })
      .catch((error) => {
        console.log("Api failed ", error);
      });
  }

  useEffect(() => {
    socket.on("respfromdialer", async (data) => {
      switch (data.action) {
        case "call_state":
          let checkvalidjson = await checkValidateJSON(
            data?.event_data?.crm_data
          ).catch((error) => {
            console.log("Error on responsefrom dialer", error.message);
          });
          if (
            data?.event_data?.crm_data &&
            data?.event_data?.call_status === "answered"
          ) {
            const parsedCrmData = JSON.parse(data?.event_data?.crm_data);
            // data.event_data.crm_data = parsedCrmData;
            // await crmhomepageprerequisite(data);
            const newData = {
              ...data,
              event_data: {
                ...data.event_data,
                crm_data: parsedCrmData,
              },
            };

            await crmhomepageprerequisite(newData);
          }
          break;
        default:
      }
    });
    return () => {
      socket.disconnect();
      socket.off();
    };
  }, []);

  useEffect(() => {
    if (customerSearch) {
      crmhomepageprerequisite(customerSearch);
    }
  }, [customerSearch]);

  async function gettlno(logdata) {
    await DaynmicApicall(
      `appuser/getprocesstlid/${logdata.campid}`,
      "",
      "get",
      userInfo.data[0].UserToken
    )
      .then((data) => {
        let val = {
          // cust_id: logdata?.custid?.slice(3),
          // campid: logdata?.custid.slice(0, 3),
          // contact_by: userInfo?.data[0]?.userid,
          // dialer_connected_ph: logdata?.phone,
          tlno: data?.tlid,
          tl: data?.tlname,
          // call_mode: logdata?.callmode,
          // dialer_session_id: logdata?.sessionid,
        };
        dispatch(setSaveandExit({ ...val }));
        // Object.entries(val).map((data, index) => {
        //   let aa = `{"${data[0]}": "${data[1]}"}`;
        //   dispatch(setSaveandExit(JSON.parse(aa)));
        // });
      })
      .catch((err) => {
        console.log("API failed", err);
      });
  }
  async function crmhomepageprerequisite(data) {
    let getcamp;
    let dialerresp = data?.event_data?.crm_data;
    if (
      data.event_data.agent_token == userInfo.data[0]?.dlrAgentId &&
      dialerresp.custid &&
      dialerresp.phone
    ) {
      let logdata = {
        custid: dialerresp.custid,
        campid:
          dialerresp.custid?.length > 0
            ? dialerresp.custid?.slice(0, 3)
            : dialerresp.campid,
        agentid: data.event_data.agent_token,
        phone: dialerresp.phone,
        dialerrespose: data.event_data.call_direction,
        clientid: dialerresp.clientid,
        callmode: dialerresp.callmode,
        // callmode: data.call_direction,
        sessionid: data.event_data.call_session_id,
        notifyurl: window.location.origin + window.location.pathname,
        call_status: data.event_data.call_status,
        call_start: data.event_data.call_start,
        call_stop: data.event_data.call_stop,
        event_data: data.event_data,
        dialeruuid: dialerresp.dialeruuid, // ****************
        recordingfile: dialerresp.recordingfile, // ****************
        active: "Y",
        action_name: "INSERT",
      };
      setDialerdata(data);
      if (logdata.campid) {
        getcamp = await f_getConnectedCamp(
          logdata.campid,
          userInfo.data[0].userid
        );
      }
      dialerscrmlog(logdata, getcamp[0].keypointer);
      dispatch(
        setSaveandExit({
          dialer_session_id: logdata?.sessionid,
          cust_id: logdata?.custid?.slice(3),
          campid: logdata.campid || logdata?.custid.slice(0, 3),
          contact_by: userInfo?.data[0]?.userid,
          dialer_connected_ph: logdata?.phone,
          call_mode: logdata?.callmode,
        })
      );
      await setConnectedCamp(logdata.campid ? getcamp[0] : "");
      logdata.userid = userInfo.data[0]?.userid;
      await getCustomer(logdata.custid, getcamp[0].keypointer);
      await gettlno(logdata);
      logdata.keypointer = getcamp.keypointer;
      await getdetails(logdata, getcamp[0].keypointer);
      await pageInfoJson(
        getcamp[0].keypointer,
        25,
        userInfo.data[0].UserToken
      ).then((data) => {
        setpageData(data);
      });
    }
  }

  useEffect(async () => {
    await dispatch(
      setSaveandExit({
        keypointer: connectedCamp.keypointer,
      })
    );

    await DaynmicApicall(
      `appuser/getcomponetbyid/${connectedCamp?.keypointer}/157`,
      "",
      "get",
      userInfo.data[0].UserToken
    )
      .then((res) => {
        setPageTimeoutData(res[0]?.DATA);
      })
      .catch((err) => {
        console.log("API failed", err);
      });
  }, [connectedCamp]);

  let parentTOChields = { custinfo: customerDetail, campinfo: connectedCamp };

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return <div className="timer">{pageTimeoutData.renderTime.text1}</div>;
    }

    return (
      <div className="timer">
        <div className="value">{remainingTime}</div>
        <div className="text">{pageTimeoutData.renderTime.text2}</div>
      </div>
    );
  };

  const onComplete = async () => {
    const payload = {
      crmsaveandexit: crmsaveandexit,
      pol_loan_no: customerDetail?.policy_no,
    };
    const logpayload = {
      sessionid: crmsaveandexit?.dialer_session_id,
      cuid: `${crmsaveandexit?.campid}${crmsaveandexit?.cust_id}`,
      agentid: crmsaveandexit?.contact_by,
      message_text: "Inbound Caller Hangup",
    };
    await DaynmicApicall(`/mcrmdlr/callsavelog`, logpayload, "post").then(
      (res) => {}
    );
    await DaynmicApicall(
      `mcrmdlr/autornrcallsave`,
      payload,
      "post",
      userInfo.data[0].UserToken
    )
      .then(async (apiresult) => {
        if (apiresult.message === "success") {
          const confirmHangup = window.confirm(`${pageTimeoutData.alert}`);
          if (confirmHangup) {
            const apiBody = {
              cuid: customerDetail?.cuid,
              action_event: "Agent Auto Quality",
              action_flag: "",
              sessionid: crmsaveandexit?.dialer_session_id,
              crmuuid: DialerCrmData.dialeruuid,
              campid: connectedCamp?.campid,
              agentid: userInfo.data[0].userid,
              score_param: "",
              call_remarks: "Auto RNR call save",
              score_param: "",
              final_score: score,
              keypointer: connectedCamp?.keypointer,
            };

            await scoreUpload(apiBody, userInfo.data[0].UserToken)
              .then(async (data) => {})
              .catch((err) => {
                console.log("ERROR", err);
              });
            const hangupdata = {
              crmuuid: DialerCrmData.dialeruuid,
              external_hangup: "1",
              external_status: `${userInfo?.data[0]?.userid}${crmsaveandexit.resp_code}CallFromAgentPage`,
              agentid: `${userInfo.data[0].AgentDidNo}`,
            };
            await DaynmicApicall(
              `dialer/dialerhangup`,
              hangupdata,
              "post",
              userInfo.data[0].UserToken
            )
              .then(async (data) => {
                if (crmwebphone.confStatus) {
                  await DaynmicApicall(
                    `ami/endCallConference`,
                    {
                      agent: dlrAgentId,
                      crmuuid: DialerCrmData.dialeruuid,
                    },
                    "post",
                    userInfo.data[0].UserToken
                  );
                }
                setDispalyTimer(false);
                dispatch(removeCurrentCall());
                dispatch(removeCallSession());
                dispatch(removeCrmWebphone());
                dispatch(setCrmWebphone({ dialerHangupStatus: true }));
                dispatch(
                  setGlobalLanguage({
                    data: "",
                  })
                );

                localStorage.removeItem("currentcall");
                localStorage.removeItem("callLogs");
                localStorage.removeItem("currentListening");
                heandelDialerdata("");
              })
              .catch(async (error) => {
                if (crmwebphone.confStatus) {
                  await DaynmicApicall(
                    `ami/endCallConference`,
                    {
                      agent: dlrAgentId,
                      crmuuid: DialerCrmData.dialeruuid,
                    },
                    "post",
                    userInfo.data[0].UserToken
                  );
                }
                setDispalyTimer(false);
                dispatch(removeCurrentCall());
                dispatch(removeCallSession());
                dispatch(removeCrmWebphone());
                dispatch(setCrmWebphone({ dialerHangupStatus: true }));
                dispatch(
                  setGlobalLanguage({
                    data: "",
                  })
                );
                localStorage.removeItem("currentcall");
                localStorage.removeItem("callLogs");
                localStorage.removeItem("currentListening");
                heandelDialerdata("");
              });
          }
        } else {
          setDispalyTimer(false);
        }
      })
      .catch((error) => {
        console.log("Error in auto rnr", error);
        setDispalyTimer(false);
      });
  };

  const onCompleteAutoSave = async () => {
    const payload = {
      crmsaveandexit: crmsaveandexit,
      pol_loan_no: customerDetail?.policy_no,
    };
    const logpayload = {
      sessionid: crmsaveandexit?.dialer_session_id,
      cuid: `${crmsaveandexit?.campid}${crmsaveandexit?.cust_id}`,
      agentid: crmsaveandexit?.contact_by,
      message_text: "Inbound Caller Hangup",
    };
    await DaynmicApicall(`/mcrmdlr/callsavelog`, logpayload, "post").then(
      (res) => {}
    );
    await DaynmicApicall(
      `mcrmdlr/autocallsave`,
      payload,
      "post",
      userInfo.data[0].UserToken
    )
      .then(async (apiresult) => {
        if (apiresult.message === "success") {
          const confirmHangup = window.confirm(`${pageTimeoutData.alert}`);
          if (confirmHangup) {
            const apiBody = {
              cuid: customerDetail?.cuid,
              action_event: "Agent Auto Quality",
              action_flag: "",
              sessionid: crmsaveandexit?.dialer_session_id,
              crmuuid: DialerCrmData.dialeruuid,
              campid: connectedCamp?.campid,
              agentid: userInfo.data[0].userid,
              score_param: "",
              call_remarks: "Auto Wrapup call save",
              score_param: "",
              final_score: score,
              keypointer: connectedCamp?.keypointer,
            };

            await scoreUpload(apiBody, userInfo.data[0].UserToken)
              .then(async (data) => {})
              .catch((err) => {
                console.log("ERROR", err);
              });
            const hangupdata = {
              crmuuid: DialerCrmData.dialeruuid,
              external_hangup: "1",
              external_status: `${userInfo?.data[0]?.userid}${crmsaveandexit.resp_code}CallFromAgentPage`,
              agentid: `${userInfo.data[0].AgentDidNo}`,
            };
            await DaynmicApicall(
              `dialer/dialerhangup`,
              hangupdata,
              "post",
              userInfo.data[0].UserToken
            )
              .then(async (data) => {
                if (crmwebphone.confStatus) {
                  await DaynmicApicall(
                    `ami/endCallConference`,
                    {
                      agent: dlrAgentId,
                      crmuuid: DialerCrmData.dialeruuid,
                    },
                    "post",
                    userInfo.data[0].UserToken
                  );
                }
                setDispalyAutoSaveTimer(false);
                dispatch(removeCurrentCall());
                dispatch(removeCallSession());
                dispatch(removeCrmWebphone());
                dispatch(setCrmWebphone({ dialerHangupStatus: true }));
                dispatch(
                  setGlobalLanguage({
                    data: "",
                  })
                );
                localStorage.removeItem("currentcall");
                localStorage.removeItem("callLogs");
                localStorage.removeItem("currentListening");
                heandelDialerdata("");
              })
              .catch(async (error) => {
                console.log("ERROR IN AUTO CALL SAVE", error);
                if (crmwebphone.confStatus) {
                  await DaynmicApicall(
                    `ami/endCallConference`,
                    {
                      agent: dlrAgentId,
                      crmuuid: DialerCrmData.dialeruuid,
                    },
                    "post",
                    userInfo.data[0].UserToken
                  );
                }
                setDispalyAutoSaveTimer(false);
                dispatch(removeCurrentCall());
                dispatch(removeCallSession());
                dispatch(removeCrmWebphone());
                dispatch(setCrmWebphone({ dialerHangupStatus: true }));
                dispatch(
                  setGlobalLanguage({
                    data: "",
                  })
                );
                localStorage.removeItem("currentcall");
                localStorage.removeItem("callLogs");
                localStorage.removeItem("currentListening");
                heandelDialerdata("");
              });
          }

          // alert(`${pageTimeoutData.alert}`);
        } else {
          setDispalyAutoSaveTimer(false);
        }
      })
      .catch((err) => {
        console.log("Auto Call Save Error", err);
        setDispalyAutoSaveTimer(false);
      });
  };

  const getUpdatedScore = async () => {
    const payload = {
      custid: `${crmsaveandexit?.campid}${crmsaveandexit?.cust_id}`,
      sessionid: crmsaveandexit?.dialer_session_id,
      keypointer: connectedCamp.keypointer,
    };
    await DaynmicApicall(`/mcrmdlr/getagentcallscore`, payload, "post")
      .then((res) => {
        setScoreData(res);
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const handleStart = (time) => {
    const newTimmer = time / 1000;
    settimer(newTimmer);
    setStartTimer(true);
    setStopTimer(false);
  };

  const handleStop = () => {
    settimer("");
    setStartTimer(false);
    setStopTimer(true);
  };

  return (
    <>
      {pageData && (
        <div className="container-fulid">
          <>
            <div
              className="row bg-dark mt-1 font-weight-bold rounded"
              style={{ color: "white" }}
            >
              {/* Header Section */}
              <span
                className="mt-3 col-xs-12 col-sm-5 col-md-4  col-lg-2"
                id={`${connectedCamp ? "header" : "dashboard"}`}
              >
                {connectedCamp ? connectedCamp?.campname : "Agent Dashboard"}
              </span>
              <span
                id="wlcmtext1"
                className="mt-3 col-xs-12 col-sm-7 col-md-8 col-lg-5"
              >
                Welcome {userInfo.data[0].username}
                <span className="text-success ml-1" onClick={getCrmdata}>
                  ({userInfo.data[0].userid})
                </span>
              </span>
              <span
                id="wlcmtext"
                className="mt-3 col-xs-12 col-sm-10 col-md-10  col-lg-4"
              >
                {new Date().toLocaleDateString(undefined, {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </span>
              <div
                id="profileimg"
                className="mt-2 col-xs-12 col-sm-2 col-md-2 col-lg-1 col-xl-1"
              >
                <AgentDashboardNav Value={""} />
              </div>
            </div>
            <div className="row">
              {dialerdata ? (
                dialerdata &&
                (connectedCamp && customerDetail ? (
                  connectedCamp && (
                    <div
                      className="col-sm-12 col-md-12 col-lg-9"
                      style={{
                        pointerEvents:
                          dispalyTimer || dispalyAutoSaveTimer
                            ? "none"
                            : "auto",
                      }}
                    >
                      <div className="row">
                        <AgentScript
                          data={parentTOChields}
                          setLBagent={setLBagent}
                          setTLId={setTLId}
                          getUpdatedScore={getUpdatedScore}
                          handleStart={handleStart}
                          handleStop={handleStop}
                        />
                        <CardDetails
                          data={parentTOChields}
                          aftersave={heandelDialerdata}
                          setDispalyTimer={setDispalyTimer}
                          getLBagent={getLBagent}
                          getTLId={getTLId}
                        />
                        <AgentAction
                          data={parentTOChields}
                          scoreData={scoreData}
                          startTimer={startTimer}
                          stopTimer={stopTimer}
                          timerDuration={timer}
                        />
                      </div>
                    </div>
                  )
                ) : (
                  <div className="col-sm-12 col-md-12 col-lg-9">
                    <CustomerSearchPage
                      searchTOCrm={heandelDialerCustomer}
                      aftersave={heandelDialerdata}
                      dialerdata={dialerdata}
                      parentTOChields={parentTOChields}
                    />
                  </div>
                ))
              ) : (
                <div className="col-sm-12 col-md-12 col-lg-9">
                  <AgetDashboard />
                </div>
              )}
              <div className="col-sm-12 col-md-12 col-lg-3">
                {userInfo.data[0]?.usergroup === "AGENT" && (
                  <DailerSidebar aftersave={heandelDialerdata} />
                )}
                {dispalyTimer === true && pageTimeoutData ? (
                  <div className="countdown">
                    <Tooltip
                      title={
                        <strong>{pageTimeoutData.renderTime.tooltip}</strong>
                      }
                      arrow
                    >
                      <div className="timer-wrapper">
                        <CountdownCircleTimer
                          isPlaying
                          duration={pageTimeoutData.duartion}
                          size={110}
                          strokeWidth={10}
                          colors={pageTimeoutData.colors}
                          colorsTime={pageTimeoutData.colorsTime}
                          onComplete={() => {
                            onComplete();
                          }}
                        >
                          {renderTime}
                        </CountdownCircleTimer>
                      </div>
                    </Tooltip>
                  </div>
                ) : dispalyAutoSaveTimer === true && pageTimeoutData ? (
                  <div className="countdown">
                    <Tooltip
                      title={
                        <strong>{pageTimeoutData.renderTime.tooltip}</strong>
                      }
                      arrow
                    >
                      <div className="timer-wrapper">
                        <CountdownCircleTimer
                          isPlaying
                          duration={pageTimeoutData.autoSaveDuartion}
                          size={110}
                          strokeWidth={10}
                          colors={pageTimeoutData.colors}
                          colorsTime={pageTimeoutData.colorsTime}
                          onComplete={() => {
                            onCompleteAutoSave();
                          }}
                        >
                          {renderTime}
                        </CountdownCircleTimer>
                      </div>
                    </Tooltip>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            {/*</div> */}
          </>
        </div>
      )}
    </>
  );
}
