import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../redux/User";
import api from "../../utils/api";
import { useEffect, useRef, useState } from "react";

export default function DashboardAreaNav(props) {
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const { campdetail } = useSelector((state) => state.campaign);
  const [timerId, setTimerId] = useState(false);
  const renderCount = useRef(1);
  const { userInfo } = useSelector((state) => state?.user?.value);
  let { crmsaveandexit } = useSelector((state) => state?.dialer);
  let { crmwebphone } = useSelector((state) => state?.webphoneStatus);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  async function userLogout() {
    await api
      .put("/appuser/lgout/", { userid: userInfo.data[0].userid })
      .then((data) => {
        localStorage.clear();
      });
  }
  useDispatch(async () => {
    await DashboardAreaNav();
  }, []);

  const f_getConnectedCamp = async (campid, userid) => {
    const camp = await api.get(`appuser/getcampbycampid/${campid}/${userid}`, {
      headers: {
        Authorization: userInfo.data[0].UserToken,
      },
    });
    return camp.data.data?.map((item) => item.queue_name);
  };

  useEffect(() => {
    const autoLogout = () => {
      if (document.visibilityState === "hidden") {
        const t = 10; // timeout in hours
        const timeoutInMilliseconds = t * 3600 * 1000;

        // set timer to log user out
        const id = window.setTimeout(
          () => setIsLoggedOut(true),
          timeoutInMilliseconds
        );
        setTimerId(id);
      } else {
        // clear existing timer
        window.clearTimeout(timerId);
      }
    };

    document.addEventListener("visibilitychange", autoLogout);

    return () => {
      document.removeEventListener("visibilitychange", autoLogout);
      // clear existing timer on component unmount
      window.clearTimeout(timerId);
    };
  }, [timerId, setIsLoggedOut]);

  useEffect(() => {
    if (isLoggedOut) {
      queuelogout();
    }
  }, [isLoggedOut]);

  async function queuelogout() {
    // if (userInfo.data[0].usergroup === "AGENT") {
    const queuse = await f_getConnectedCamp("ALL", userInfo.data[0].userid);
    await api
      .post(
        "/ami/queue/logout",
        {
          actionid: "crmuuid",
          queues: queuse,
          interface: userInfo.data[0].dlrAgentId,
          membername: userInfo.data[0].dlrAgentId,
        },
        userInfo
      )
      .catch((error) => {
        console.log("Error from queue/logout::: ", error);
      });
    // }

    const l1 = document.getElementById("logout");
    l1?.click();
    // if (crmwebphone.queueStatus === "registered") {
    //   if (!crmsaveandexit.dialer_session_id) {
    //     let lgout = {
    //       agent_token: userInfo.data[0]?.dlrAgentId,
    //       action: "queue_logout",
    //     };
    //     socket.emit("crmdialerdata", lgout);
    //     socket.on("respfromdialer", async (data) => {
    //       if (
    //         data.action === "message_info" &&
    //         parseInt(data.agent_token) === userInfo.data[0].dlrAgentId &&
    //         data.event_data.message_text.includes("Remove")
    //       ) {
    //         const l1 = document.getElementById("logout");
    //         l1?.click();
    //       }
    //     });
    //   } else {
    //     toast("You can only logout after running call save", {
    //       icon: "👏",
    //       style: {
    //         borderRadius: "10px",
    //         background: "#45abdb",
    //         color: "#fff",
    //       },
    //     });
    //   }
    // } else {
    //   const l1 = document.getElementById("logout");
    //   l1?.click();
    // }
  }

  const PageData = {
    text1: "My Profile",
    text2: " Logout",
    style: {
      color: "#333",
    },
  };

  return (
    <nav className="navbar navbar-top navbar-expand navbar-dashboard pl-0 pr-2 pb-0 pt-0">
      <div className="container-fluid px-0">
        <div
          className="d-flex justify-content-end w-100"
          id="navbarSupportedContent"
        >
          <ul
            className="navbar-nav align-items-center"
            // title="User Profile"
            data-toggle="tooltip"
          >
            <li className="nav-item dropdown">
              <a
                className="nav-link pt-1 px-0"
                // href="#"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="media d-flex align-items-center">
                  <img
                    className="user-avatar md-avatar rounded-circle"
                    alt="placeholder"
                    src="https://cdnlib.a10s.in/cdndata/user.png"
                  />
                  <div className="media-body ml-2 text-dark align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small font-weight-bold">
                      {userInfo?.user?.email}
                    </span>
                  </div>
                </div>
              </a>
              <div className="dropdown-menu dashboard-dropdown dropdown-menu-right mt-2">
                <Link className="dropdown-item font-weight-bold" to="profile">
                  <span className="far fa-user-circle"></span>
                  {PageData.text1}
                </Link>
                <div role="separator" className="dropdown-divider"></div>
                <button
                  hidden
                  id="logout"
                  className="dropdown-item font-weight-bold"
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    userLogout();
                    localStorage.removeItem("support_user");
                    dispatch(logout());
                    navigate("/", { replace: true });
                    window.history.go();
                  }}
                >
                  <span className="fas fa-sign-out-alt text-danger"></span>
                  {PageData.text2}
                </button>
                <button
                  className="dropdown-item font-weight-bold"
                  to="#"
                  onClick={(e) => {
                    queuelogout();
                  }}
                >
                  <span className="fas fa-sign-out-alt text-danger"></span>
                  logout
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <hr style={PageData.color} />
    </nav>
  );
}
