import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Grid, TextField } from "@mui/material";
import Select from "react-select";
import DaynmicApicall from "../../../utils/function";

export default function VocTab({ tabInfo, submitfunction, initialValues }) {
  const { userInfo } = useSelector((state) => state?.user?.value);
  const { UserToken } = userInfo.data[0];
  const [pageData, setPageData] = useState([]);
  const [options, setOptions] = useState([]);
  const [initial, setInitial] = useState(null);
  const [options1, setOptions1] = useState([]);
  const [options2, setOptions2] = useState([]);

  useEffect(() => {
    async function fetchPageInfo() {
      try {
        const data = await DaynmicApicall(
          `/appuser/getcomponetbyid/${tabInfo.camp_id}/${tabInfo.comp_id}`,
          "",
          "get",
          UserToken
        );
        setPageData(data[0]?.DATA || []);
      } catch (err) {
        console.error("ERROR", err);
        setPageData([]);
      }
    }

    fetchPageInfo();
  }, [tabInfo.camp_id, tabInfo.comp_id, UserToken]);

  useEffect(() => {
    if (pageData?.length > 0 && !initial) {
      const init = pageData[1]?.reduce((acc, field) => {
        acc[field.name] = field.type === "select" && field.isMulti ? [] : "";
        return acc;
      }, {});

      setInitial({
        ...init,
        reasonL1: "",
        descriptionL2: "",
        remarksL3: "",
        action_name: "INSERT",
      });
    }
  }, [pageData, initial]);

  const validationSchema = Yup.object().shape(
    pageData[1]?.reduce(
      (acc, field) => {
        if (field.required) {
          if (field.type === "text") {
            acc[field.name] = Yup.string().required(
              `${field.name} field is required`
            );
          } else if (field.type === "select") {
            acc[field.name] = Yup.string().required(
              `${field.name} field is required`
            );
          }
        }
        return acc;
      },
      {
        reasonL1: Yup.string().required("reasonL1 field is required"),
        descriptionL2: Yup.string().required("descriptionL2 field is required"),
        remarksL3: Yup.string().required("remarksL3 field is required"),
      }
    )
  );

  const formik = useFormik({
    initialValues: initial || {},
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      submitfunction(values);
    },
  });

  async function getOptions(group, level) {
    try {
      const payload = {
        code: "ALL",
        group,
        keypointer: "u001dlr",
      };

      const data = await DaynmicApicall(
        `/prm/getParameterdata`,
        payload,
        "post",
        UserToken
      );

      const newOptions =
        data?.data.map((opt) => ({
          value: opt?.paracode,
          label: opt?.paravalue,
        })) || [];

      if (level === 1) setOptions(newOptions);
      else if (level === 2) setOptions1(newOptions);
      else setOptions2(newOptions);
    } catch (error) {
      console.error("ERROR", error);
      if (level === 1) setOptions([]);
      else if (level === 2) setOptions1([]);
      else setOptions2([]);
    }
  }

  useEffect(() => {
    async function setUpdateValues() {
      if (
        initialValues &&
        initialValues.action_name === "UPDATE" &&
        pageData[1]
      ) {
        const updatedValues = pageData[1].reduce((acc, field) => {
          acc[field.name] = initialValues[field.name] || "";
          return acc;
        }, {});

        if (initialValues[pageData[0]?.l1?.name]) {
          await getOptions(initialValues[pageData[0].l1.name], 1);
          await getOptions(initialValues[pageData[0].l2.name], 2);
        }

        formik.setValues((values) => ({
          ...values,
          ...initialValues,
          ...updatedValues,
        }));
      }
    }

    setUpdateValues();
  }, [initialValues, pageData, formik.setValues]);

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  const renderField = (field) => {
    const commonProps = {
      fullWidth: true,
      size: "small",
      id: `outlined-${field.name}`,
      label: field.label,
      value: formik.values[field.name] || "",
      onChange: (event) => formik.setFieldValue(field.name, event.target.value),
      onBlur: () => formik.setFieldTouched(field.name, true),
      error: formik.touched[field.name] && !!formik.errors[field.name],
      helperText: formik.touched[field.name] && formik.errors[field.name],
    };

    switch (field.type) {
      case "date":
        return (
          <Grid item xs={12} sm={4} key={field.name}>
            <TextField type="date" {...commonProps} />
          </Grid>
        );
      case "text":
        return (
          <Grid item xs={12} sm={4} key={field.name}>
            <TextField type="text" {...commonProps} />
          </Grid>
        );
      case "select":
        return (
          <Grid item xs={12} sm={4} key={field.name}>
            <Select
              styles={{
                ...customStyles,
                control: (provided, state) => ({
                  ...provided,
                  borderColor:
                    formik.touched[field.name] && formik.errors[field.name]
                      ? "#d32f2f"
                      : "#ccc",
                  "&:hover": {
                    borderColor:
                      formik.touched[field.name] && formik.errors[field.name]
                        ? "#d32f2f"
                        : "#ccc",
                  },
                }),
              }}
              classNamePrefix="react-select"
              options={field.options}
              value={field.options.find(
                (option) => option.value === formik.values[field.name]
              )}
              onChange={(selectedOption) => {
                formik.setFieldValue(field.name, selectedOption.value);
                formik.setFieldTouched(field.name, true);
              }}
              placeholder={field.placeholder}
              onBlur={() => formik.setFieldTouched(field.name, true)}
              isSearchable={false}
            />
            {formik.touched[field.name] && formik.errors[field.name] && (
              <div
                className="error"
                style={{
                  fontSize: "12px",
                  paddingLeft: "13px",
                  color: "#d32f2f",
                }}
              >
                {formik.errors[field.name]}
              </div>
            )}
          </Grid>
        );
      default:
        return null;
    }
  };

  const handleReset = () => {
    formik.resetForm({
      values: {
        ...initial,
        reasonL1: "",
        descriptionL2: "",
        remarksL3: "",
        action_name: "INSERT",
      },
      touched: {},
      errors: {},
    });

    setOptions([]);
    setOptions1([]);
    setOptions2([]);
  };

  return (
    <div className="card p-4">
      {pageData?.length > 0 && (
        <form className="p-2" onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Select
                styles={{
                  ...customStyles,
                  control: (provided, state) => ({
                    ...provided,
                    borderColor:
                      formik.touched[pageData[0].l1.name] &&
                      formik.errors[pageData[0].l1.name]
                        ? "#d32f2f"
                        : "#ccc",
                    "&:hover": {
                      borderColor:
                        formik.touched[pageData[0].l1.name] &&
                        formik.errors[pageData[0].l1.name]
                          ? "#d32f2f"
                          : "#ccc",
                    },
                  }),
                }}
                classNamePrefix="react-select"
                options={pageData[0].l1.options}
                value={pageData[0].l1.options.find(
                  (option) =>
                    option.value === formik.values[pageData[0].l1.name]
                )}
                onBlur={() => formik.setFieldTouched(pageData[0].l1.name, true)}
                isSearchable={false}
                onChange={async (selectedOption) => {
                  formik.setFieldValue(
                    pageData[0].l1.name,
                    selectedOption.value
                  );
                  formik.setFieldTouched(pageData[0].l1.name, true);
                  await getOptions(selectedOption.value, 1);
                }}
                placeholder={pageData[0].l1.placeholder}
              />
              {formik.touched.reasonL1 && formik.errors.reasonL1 && (
                <div
                  className="error"
                  style={{
                    fontSize: "12px",
                    paddingLeft: "13px",
                    color: "#d32f2f",
                  }}
                >
                  {formik.errors.reasonL1}
                </div>
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              <Select
                styles={{
                  ...customStyles,
                  control: (provided, state) => ({
                    ...provided,
                    borderColor:
                      formik.touched[pageData[0].l2.name] &&
                      formik.errors[pageData[0].l2.name]
                        ? "#d32f2f"
                        : "#ccc",
                    "&:hover": {
                      borderColor:
                        formik.touched[pageData[0].l2.name] &&
                        formik.errors[pageData[0].l2.name]
                          ? "#d32f2f"
                          : "#ccc",
                    },
                  }),
                }}
                classNamePrefix="react-select"
                options={options}
                value={options.find(
                  (option) =>
                    option.value === formik.values[pageData[0].l2.name]
                )}
                onBlur={() => formik.setFieldTouched(pageData[0].l2.name, true)}
                isSearchable={false}
                onChange={async (selectedOption) => {
                  formik.setFieldValue(
                    pageData[0].l2.name,
                    selectedOption.value
                  );
                  formik.setFieldTouched(pageData[0].l2.name, true);
                  await getOptions(selectedOption.value, 2);
                }}
                placeholder={pageData[0].l2.placeholder}
              />
              {formik.touched.descriptionL2 && formik.errors.descriptionL2 && (
                <div
                  className="error"
                  style={{
                    fontSize: "12px",
                    paddingLeft: "13px",
                    color: "#d32f2f",
                  }}
                >
                  {formik.errors.descriptionL2}
                </div>
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              <Select
                styles={{
                  ...customStyles,
                  control: (provided, state) => ({
                    ...provided,
                    borderColor:
                      formik.touched[pageData[0].l3.name] &&
                      formik.errors[pageData[0].l3.name]
                        ? "#d32f2f"
                        : "#ccc",
                    "&:hover": {
                      borderColor:
                        formik.touched[pageData[0].l3.name] &&
                        formik.errors[pageData[0].l3.name]
                          ? "#d32f2f"
                          : "#ccc",
                    },
                  }),
                }}
                classNamePrefix="react-select"
                options={options1}
                value={options1.find(
                  (option) =>
                    option.value === formik.values[pageData[0].l3.name]
                )}
                onBlur={() => formik.setFieldTouched(pageData[0].l3.name, true)}
                isSearchable={false}
                onChange={(selectedOption) => {
                  formik.setFieldValue(
                    pageData[0].l3.name,
                    selectedOption.value
                  );
                  formik.setFieldTouched(pageData[0].l3.name, true);
                }}
                placeholder={pageData[0].l3.placeholder}
              />
              {formik.touched.remarksL3 && formik.errors.remarksL3 && (
                <div
                  className="error"
                  style={{
                    fontSize: "12px",
                    paddingLeft: "13px",
                    color: "#d32f2f",
                  }}
                >
                  {formik.errors.remarksL3}
                </div>
              )}
            </Grid>
            {pageData[1]?.map((field) => renderField(field))}
          </Grid>
          <div className="d-flex justify-content-end w-100 pt-2">
            <button type="submit" className="btn btn-primary btn-sm m-2">
              {pageData[0].Submitbutton.label}
            </button>
            <button
              type="reset"
              className="btn btn-primary btn-sm m-2"
              onClick={handleReset}
            >
              {pageData[0].Cancelbutton.label}
            </button>
          </div>
        </form>
      )}
    </div>
  );
}
