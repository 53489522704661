import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { logout } from "../redux/User";
import api from "../utils/api";
import SidebarMenu from "../pages/dashboard/SidebarMenu";
// import socketIOClient from "socket.io-client";
import toast from "react-hot-toast";
import socket from "../utils/socket";
import { useEffect, useRef, useState } from "react";
import { Navbar, Offcanvas, Stack } from "react-bootstrap";
import DaynmicApicall, { ManageEventLog } from "../utils/function";
import { AnimatePresence, motion } from "framer-motion";

const DashboardNav = ({ children, props }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [sideNavbarMenus, setSideNavbarMenus] = useState("");
  const { userInfo } = useSelector((state) => state?.user?.value);
  let { crmsaveandexit } = useSelector((state) => state?.dialer);
  let { crmwebphone } = useSelector((state) => state?.webphoneStatus);
  const localData = JSON.parse(localStorage.getItem("support_user"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  async function getSideManuInfo() {
    await DaynmicApicall(
      `/appuser/menus/${userInfo.data[0].userid}/${userInfo.data[0].usergroup}`,
      "",
      "get",
      userInfo.data[0].UserToken
    )
      .then((res) => {
        setSideNavbarMenus(res);
      })
      .catch((error) => {
        toast?.error(
          error?.response?.message ??
            error?.message ??
            "OOPs, Something went wrong."
        );
      });
  }

  useEffect(async () => {
    await getSideManuInfo();
  }, []);
  async function userLogout() {
    await api
      .put("/appuser/lgout/", { userid: userInfo.data[0].userid })
      .then((data) => {
        localStorage.clear();
      });
  }
  useDispatch(async () => {
    await DashboardNav();
  }, []);

  async function queuelogout() {
    if (crmwebphone.queueStatus === "registered") {
      if (!crmsaveandexit.dialer_session_id) {
        let lgout = {
          agent_token: userInfo.data[0]?.dlrAgentId,
          action: "queue_logout",
        };
        socket.emit("crmdialerdata", lgout);
        socket.on("respfromdialer", async (data) => {
          if (
            data.action === "message_info" &&
            parseInt(data.agent_token) === userInfo.data[0].dlrAgentId &&
            data.event_data.message_text.includes("Remove")
          ) {
            const l1 = document.getElementById("logout");
            l1?.click();
          }
        });
      } else {
        toast("You can only logout after running call save", {
          icon: "👏",
          style: {
            borderRadius: "10px",
            background: "#45abdb",
            color: "#fff",
          },
        });
      }
    } else {
      const l1 = document.getElementById("logout");
      l1?.click();
    }
  }

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };

  const PageData = {
    text1: "My Profile",
    text2: " Logout",
    style: {
      color: "#333",
    },
  };
  return (
    <Navbar
      expand="md"
      variant="dark"
      className="navbarhide navbar navbar-dark navbar-theme-primary px-4 col-12 d-md-none mb-2"
      style={{
        borderRadius: "10px",
        width: "100%",
        height: "60px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "5px",
      }}
    >
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Offcanvas
        id="offcanvasNavbar"
        aria-labelledby="offcanvasNavbarLabel"
        placement="end"
        style={{
          backgroundColor: "#ffffff",
          backgroundImage: "linear-gradient(#044056, #262b40)",
        }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title id="offcanvasNavbarLabel">
            <img
              className="rounded"
              src={localData.logo}
              // src="https://cdnlib.a10s.in/cdndata/ATS_LOGO1.png"
              alt=""
              style={{ height: "70px" }}
            />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <section className="routes">
            {sideNavbarMenus
              ? sideNavbarMenus?.map((route, index) => {
                  if (route.submenu) {
                    return (
                      <SidebarMenu
                        key={index}
                        setIsOpen={setIsOpen}
                        route={route}
                        showAnimation={showAnimation}
                        isOpen={isOpen}
                      />
                    );
                  }
                  return (
                    <NavLink key={index}>
                      <div className="icon">
                        {/* <img src={route.imageurl} alt="" /> */}
                      </div>
                      <AnimatePresence>
                        {isOpen && (
                          <motion.div
                            variants={showAnimation}
                            initial="hidden"
                            animate="show"
                            exit="hidden"
                            className="link_text"
                          >
                            {route?.campname}
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </NavLink>
                  );
                })
              : ""}
          </section>
        </Offcanvas.Body>
      </Navbar.Offcanvas>

      <div className="d-flex align-items-center">
        <a
          className="nav-link pt-1 px-0"
          // href="#"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div className="media d-flex align-items-center">
            <img
              className="user-avatar md-avatar rounded-circle"
              alt="placeholder"
              src="https://cdnlib.a10s.in/cdndata/user.png"
            />
            <div className="media-body ml-2 text-dark align-items-center d-none d-lg-block">
              <span className="mb-0 font-small font-weight-bold">
                {userInfo?.user?.email}
              </span>
            </div>
          </div>
        </a>
        <div className="dropdown-menu dashboard-dropdown dropdown-menu-right mt-2">
          <Link className="dropdown-item font-weight-bold" to="profile">
            <span className="far fa-user-circle"></span>
            {PageData.text1}
          </Link>
          <div role="separator" className="dropdown-divider"></div>
          <button
            hidden
            id="logout"
            className="dropdown-item font-weight-bold"
            to="#"
            onClick={(e) => {
              e.preventDefault();
              userLogout();
              localStorage.removeItem("support_user");
              localStorage.removeItem("currentcall");
              localStorage.removeItem("callLogs");
              localStorage.removeItem("currentListening");
              dispatch(logout());
              navigate("/", { replace: true });
              window.history.go();
            }}
          >
            <span className="fas fa-sign-out-alt text-danger"></span>
            {PageData.text2}
          </button>
          <button
            className="dropdown-item font-weight-bold"
            to="#"
            onClick={(e) => {
              queuelogout();
            }}
          >
            <span className="fas fa-sign-out-alt text-danger"></span>
            logout
          </button>
        </div>
      </div>
    </Navbar>

    //  </>
  );
};

export default DashboardNav;
