/*
'-------------------------------------------------------------------------------------------------------------------- 
'Module Name		  :  CRM AGENT SCORE      |   Purpose          :  Manage Agent calls score by activity      |    
'Created By		    :  Shivam Bhardwaj      |   Change Request No:  ---                                       |
'Creation Date 	  :  20-Aug-2024		      |   Description      :  To show the agent score in call           |
'Changed By		    :  Shivam Bhardwaj	    |   Change Date 	   :  21-Aug-2024                               |
'Revision History	:  V1                   |                                                                 |
'Review By				:  Shreskar Kumar       |   Review Date      :  24-Aug-2024                               |                  
'----------------------------------------------------------------------------------------------------------------------			
'*/

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
} from "@mui/material";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CloseIcon from "@mui/icons-material/Close";
import "./Style.css";

const AgentScoreDialog = ({ open, handleClose }) => {
  const [score, setScore] = useState(0);
  const { callScore } = useSelector((state) => state.callsession);

  const scoreData = localStorage.getItem("callLogs")
    ? JSON.parse(localStorage.getItem("callLogs"))
    : [];

  // To get the core item and structure in desired format
  const scoreItems = scoreData.map((item) => ({
    text: item.action_event_name,
    icon:
      item.action_flag === "INC" ? (
        <CheckBoxOutlinedIcon style={{ height: "20px", width: "20px" }} />
      ) : (
        <CancelOutlinedIcon style={{ height: "20px", width: "20px" }} />
      ),
    score: item.score_param,
  }));

  // To get updated score
  useEffect(() => {
    setScore(callScore);
  }, [callScore]);

  return (
    <Dialog
      className="ScoreDialog"
      open={open}
      onClose={handleClose}
      aria-labelledby="agent-score-dialog-title"
      aria-describedby="agent-score-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="agent-score-dialog-title">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <strong>Agent Score</strong>
          <IconButton onClick={handleClose}>
            <CloseIcon style={{ color: "black" }} />
          </IconButton>
        </div>
        <Divider sx={{ width: "130px", mt: 1, padding: 0, margin: 0 }} />
        <Typography id="agent-score-dialog-description" sx={{ mt: 2 }}>
          It is calculated on the basis of your interaction with CRM.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <List sx={{ maxHeight: "150px", overflow: "auto" }}>
          {scoreItems.length > 0 ? (
            scoreItems.map((item, index) => (
              <React.Fragment key={index}>
                <ListItem>
                  <ListItemIcon>
                    <div className="ScoreIcons">{item.icon}</div>
                  </ListItemIcon>
                  <ListItemText sx={{ fontSize: "0.8rem" }} disableTypography>
                    {item.text}
                  </ListItemText>
                  <Typography variant="body2" color="textSecondary">
                    {item.score}
                  </Typography>
                </ListItem>
                {index !== scoreItems.length - 1 && <Divider />}
              </React.Fragment>
            ))
          ) : (
            <div style={{ textAlign: "center" }}>
              <span>No Data Found</span>
            </div>
          )}
        </List>
        <div className="ScoreTotal">
          <span
            className="btn-sm btn-primary ml-3 m-2"
            title="Total Score"
            style={{ fontSize: "15px" }}
          >
            Total Score: {score}
          </span>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AgentScoreDialog;
