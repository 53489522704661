import { Form, Formik } from "formik";
import React from "react";
import { MultiSelect } from "../../components/formValidation/MultiSelect";
import { TextField } from "../../components/formValidation/TextField";
import { Link } from "react-router-dom";
import { Toggle } from "../../components/formValidation/Toggle";
import { TextArea } from "../../components/formValidation/TextArea";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import DaynmicApicall from "../../utils/function";
import api from "../../utils/api";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setParems } from "../../redux/Campaign";
import * as Yup from "yup";

export default function ManageBotScript(props) {
  const { campdetail, component } = useSelector((state) => state.campaign);
  const { isLoggedIn, userInfo } = useSelector((state) => state?.user?.value);
  const dispatch = useDispatch();
  const [language, setLanguage] = useState();
  const [voice_code_list, setvoicecode] = useState("");
  const Header = { headers: { Authorization: userInfo.data[0].UserToken } };
  const [campList, setCampList] = useState("");
  const [Voice_Code, setVoicecode] = useState();
  const [dgllist, setDglList] = useState("");
  // const { campdetail } = useSelector((state) => state.campaign);
  const [botscript, setBotScript] = useState("");
  const [Dtata, setpageData] = useState();
  let allCamps = [];
  var loading = false;
  if (campdetail && campdetail.length > 0) {
    allCamps = campdetail.map((item) => {
      return {
        value: item.campid,
        label: item.campname,
      };
    });
  }
  async function pageInfo() {
    await DaynmicApicall(
      `/appuser/getcomponetbyid/${props.data.activeMenu.keypointer}/${props.data.activeMenu.subcompid}`,
      "",
      "get",
      userInfo.data[0].UserToken
    ).then((data) => {
      setpageData(data[0].DATA);
    });
  }
  const getLanguage = () => {
    api
      .get(
        `prm/getParamdata/ALL/LANGUAGE_CODE/${props.data.activeMenu.keypointer}`,
        {
          headers: {
            Authorization: userInfo.data[0].UserToken, //the token is a variable which holds the token
          },
        }
      )
      .then(async (res) => {
        let bData = [];
        bData = await res.data.data.map((item) => {
          return {
            value: item.paracode,
            label: item.paravalue,
          };
        });
        setLanguage(bData);
      })
      .catch((error) => {
        toast.error(
          error.response.data.message ??
            error.message ??
            "OOPs, something went wrong."
        );
      });
  };
  const getDGLList = () => {
    api
      .get(
        `prm/getParamdata/ALL/DGL_LIST/${props.data.activeMenu.keypointer}`,
        {
          headers: {
            Authorization: userInfo.data[0].UserToken, //the token is a variable which holds the token
          },
        }
      )
      .then(async (res) => {
        let bData = [];
        bData = await res.data.data.map((item) => {
          return {
            value: item.paracode,
            label: item.paravalue,
          };
        });
        setDglList(bData);
      })
      .catch((error) => {
        toast.error(
          error.response.data.message ??
            error.message ??
            "OOPs, something went wrong."
        );
      });
  };

  const getvoicecode = () => {
    api
      .get(
        `prm/getParamdata/ALL/VOICE_CODE/${props.data.activeMenu.keypointer}`,
        {
          headers: {
            Authorization: userInfo.data[0].UserToken, //the token is a variable which holds the token
          },
        }
      )
      .then(async (res) => {
        let bData = [];
        bData = await res.data.data.map((item) => {
          return {
            value: item.paracode,
            label: item.paravalue,
          };
        });
        setVoicecode(bData);
      })
      .catch((error) => {
        toast.error(
          error.response.data.message ??
            error.message ??
            "OOPs, something went wrong."
        );
      });
  };
  useEffect(async () => {
    await getvoicecode();
    await getLanguage();
    await pageInfo();
    await getDGLList();
    await getbotScript();
  }, []);
  const getbotScript = async () => {
    let Info = await DaynmicApicall(
      `/mcrmdlr/getbotscriptmaster/`,
      " ",
      "get",
      userInfo.data[0].UserToken
    );
    setBotScript(Info);
  };

  let action = props?.data?.paremsData.action === "UPDATE";

  const initial = {
    script_id: action ? props?.data?.paremsData.comp.script_id : "NEW",
    campid: action
      ? allCamps?.find((v) => v?.value == props?.data?.paremsData.comp.camp_id)
      : "",
    campname: action ? props?.data?.paremsData.comp.camp_name : "",
    sub_camp_id: action ? props?.data?.paremsData.comp.sub_camp_id : "0",
    dgl: action ? props?.data?.paremsData.comp.dgltext : "",
    voicecode: action
      ? Voice_Code?.find(
          (v) => v.label === props.data.paremsData.comp?.voicecode
        )
      : "",
    language: action
      ? language?.find((v) => v.label === props.data.paremsData.comp?.lan)
      : "",
    voice_txt: action ? props?.data?.paremsData.comp.voice_txt : "",
    greet_active: action
      ? props?.data?.paremsData.comp.greet_active == 1
        ? true
        : false
      : "",
    greet_dgl: action ? props?.data?.paremsData.comp.greet_dgl : "",
    greet_script: action ? props?.data?.paremsData.comp.greet_script : "",
    textvariables: action ? props?.data?.paremsData.comp.variablesarr : [],
    greet_variables: action ? props?.data?.paremsData.comp.greet_variables : "",
    action_name: action ? "UPDATE" : "INSERT",
    createdby: userInfo.data[0].userid,
    active: action
      ? props?.data?.paremsData.comp.active == 1
        ? true
        : false
      : "",
    keypointer: props.data.activeMenu.keypointer,
  };

  const manageBotscript = async (values, { resetForm }) => {
    let camp = values.campid;
    values.campid = camp.value;
    values.campname = camp.label;
    let lan = values.language;
    values.script_language = lan.label;
    let voice_code = values.voicecode;
    values.voicecode = voice_code.label;
    values.remarks = "";
    // let dgldata ;
    // if (props?.data?.paremsData.action === "UPDATE"){
    // dgldata= values.sub_camp_id
    // values.sub_camp_id=dgldata;}
    values.active = values.active === true ? 1 : 0;
    values.greet_active = values.greet_active === true ? 1 : 0;

    if (props?.data?.paremsData.action === "INSERT") {
      delete values.language;
      delete values.sub_camp_id;
    }

    let Info = await DaynmicApicall(
      `mcrmdlr/managebotscript/`,
      values,
      "post",
      userInfo.data[0].UserToken
    );
    toast.success(Info.message);
    dispatch(setParems({ data: "" }));
    setBotScript(Info);
    getbotScript();
  };
  const pageData = {
    header: {},
    fields: [
      {
        label: "Voice Code",
        placeholder: "Enter Voice Code",
        type: "multi",
        name: "voicecode",
        title: "Please Enter Voice Code",
        isMulti: "false",
        options: voice_code_list,
      },
      // { label: "Active", placeholder: "Active", type: "toggle", name: "active", value: "", defaultChecked: true },
      // { label: "Voice Code", placeholder: "Enter Voice Code", type: "multi", name: "voicecode", title: "Please Enter Voice Code", isMulti: "false", options: voice_code_list },
      // { label: "Voice Text", placeholder: "script", type: "textarea", name: "voice_txt", },
      // { label: "Active", placeholder: "Active", type: "toggle", name: "active", value: "", defaultChecked: true },
    ],
  };
  const validate = Yup.object({
    dgl: Yup.string().required("Please enter Capital Letter"),
  });
  return (
    <>
      {Dtata && (
        <div className="card card-body border-light shadow-sm mb-4">
          <div className="col-xl-12">
            <div className="row row-cols-md-2 row-cols-lg-2 row-cols-xl-2 font-weight-bold">
              <span className="text-left text-dark ">
                {props?.data?.paremsData?.action === "INSERT"
                  ? "Insert Bot Script"
                  : "Update  Bot Script"}
              </span>
            </div>
          </div>
          <Formik
            initialValues={initial}
            validationSchema={validate}
            onSubmit={manageBotscript}
          >
            {(formik) => (
              <div>
                <Form>
                  <div className="row mt-2">
                    <MultiSelect
                      label={Dtata.cmapaignname.label}
                      placeholder={Dtata.cmapaignname.placeholder}
                      type={Dtata.cmapaignname.type}
                      name={Dtata.cmapaignname.name}
                      value={formik.values.campid}
                      isMulti={false}
                      options={allCamps}
                      //  tooltip="{data.tooltip}"
                      onChange={(value) =>
                        formik.setFieldValue("campid", value)
                      }
                    />
                    <TextField
                      label={Dtata.dgldata.label}
                      placeholder={Dtata.dgldata.placeholder}
                      type={Dtata.dgldata.type}
                      name={Dtata.dgldata.name}
                    />
                    <MultiSelect
                      label={Dtata.language.label}
                      placeholder={Dtata.language.placeholder}
                      type={Dtata.language.type}
                      name={Dtata.language.name}
                      value={formik.values.language}
                      isMulti={false}
                      options={language}
                      //  tooltip="{data.tooltip}"
                      onChange={(value) =>
                        formik.setFieldValue("language", value)
                      }
                    />
                    <MultiSelect
                      label={Dtata.voicecodedata.label}
                      placeholder={Dtata.voicecodedata.placeholder}
                      type={Dtata.voicecodedata.type}
                      name={Dtata.voicecodedata.name}
                      value={formik.values.voicecode}
                      isMulti={false}
                      options={Voice_Code}
                      //  tooltip="{data.tooltip}"
                      onChange={(value) =>
                        formik.setFieldValue("voicecode", value)
                      }
                    />
                    <TextField
                      label={Dtata.variblename.label}
                      placeholder={Dtata.variblename.name}
                      type={Dtata.variblename.name}
                      name="textvariables"
                    />
                    <TextArea
                      rows="7"
                      label={Dtata.voicetextare.label}
                      placeholder={Dtata.voicetextare.placeholder}
                      type={Dtata.voicetextare.type}
                      name={Dtata.voicetextare.name}
                    />
                    <Toggle
                      label={Dtata.greetactive.label}
                      name={Dtata.greetactive.name}
                      type={Dtata.greetactive.type}
                      defaultChecked={formik.values.greet_active}
                      onChange={(e) =>
                        formik.setFieldValue("greet_active", e.target.checked)
                      }
                    />
                    <Toggle
                      label={Dtata.activetoggle.label}
                      type={Dtata.activetoggle.type}
                      name={Dtata.activetoggle.name}
                      defaultChecked={formik.values.active}
                      onChange={(e) =>
                        formik.setFieldValue("active", e.target.checked)
                      }
                    />
                    {formik.values.greet_active == true && (
                      <>
                        <div className="d-flex" style={{}}>
                          <TextField
                            label={Dtata.dglgreet.label}
                            placeholder={Dtata.dglgreet.placeholder}
                            type={Dtata.dglgreet.type}
                            name={Dtata.dglgreet.name}
                          />
                          <TextField
                            label={Dtata.greetvariable.label}
                            placeholder={Dtata.greetvariable.placeholder}
                            type={Dtata.greetvariable.type}
                            name="greet_variables"
                          />
                        </div>
                        <TextArea
                          rows="7"
                          label={Dtata.greetscript.label}
                          placeholder={Dtata.greetscript.placeholder}
                          type={Dtata.greetscript.type}
                          name={Dtata.greetscript.name}
                        />
                      </>
                    )}
                    {pageData &&
                      pageData.fields.map((data) => (
                        <>
                          {data.type === "text" && (
                            <TextField
                              label={data.label}
                              placeholder={data.placeholder}
                              type={data.type}
                              name={data.name}
                              tooltip={data.tooltip}
                            />
                          )}
                          {data.type === "textarea" && (
                            <TextArea
                              rows="7"
                              label={data.label}
                              default={data.value}
                              name={data.name}
                              type={data.type}
                              tooltip={data.tooltip}
                              placeholder={data.placeholder}
                            />
                          )}
                          {data.type === "toggle" && (
                            <Toggle
                              label={data.label}
                              name={data.name}
                              type={data.type}
                              tooltip={data.tooltip}
                              defaultChecked={data.active}
                              onChange={(e) =>
                                formik.setFieldValue(
                                  data.name,
                                  e.target.checked
                                )
                              }
                            />
                          )}
                        </>
                      ))}
                  </div>

                  <div className="d-flex justify-content-end">
                    <button type="submit" className="btn btn-primary p-2 m-2">
                      {Dtata.savebtn}
                    </button>

                    <Link
                      to="MNU_BOT_SCRIPT"
                      className="btn btn-primary p-2 m-2 text-white"
                      onClick={(e) => {
                        dispatch(setParems({ data: "MNU_BOT_SCRIPT" }));
                      }}
                    >
                      {Dtata.canbtn}
                    </Link>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      )}
    </>
  );
}
