import DaynmicApicall from "../../utils/function";
// { "preferredLanguage": "Telugu", "regionalEmployee": "P017013~4007", "custid": "111V1034797", "call_date": "2023-12-28T10:02:10.793Z", "agentid": "P016978", "resp_code": "LB", "sub_resp_code": "", "campid": "111", "compid": "98", "pol_loan_no": "T01784190920123338", "keypointer": "lntasd123tmc", "call_id": 0, "sessionid": "lcxem3fau", "action_name": "INSERT" }

export async function lbRedialCall(values, UserToken) {
  try {
    let dlrApiBody = {
      agentid: values.agentid,
      custid: values.custid,
      phone: values.phone,
      campid: values.campid,
      escalation: 0,
    };
    await DaynmicApicall(
      `dialer/lbposttodlr`,
      dlrApiBody,
      "post",
      UserToken
    ).then((data) => {});
  } catch (error) {
    console.log(
      "Unable to send data to dialer lbRedialCall fail",
      error.message
    );
  }
}

export async function escalationCall(values, UserToken) {
  try {
    await DaynmicApicall(`/mcrmdlr/manageesclation`, values, "post", UserToken)
      .then(async (res) => {
        let dlrApiBody = {
          agentid: values.tlextension,
          custid: values.custcuid,
          phone: values.phone,
          campid: values.campid,
          escalation: 1,
        };
        await DaynmicApicall(
          `dialer/lbposttodlr`,
          dlrApiBody,
          "post",
          UserToken
        );
      })
      .catch((err) => {
        console.log("ERROR", err);
      });
  } catch (error) {
    console.log("Unable to escalate the call Call", error.message);
  }
}
