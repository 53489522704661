import { Formik } from "formik";
import toast from "react-hot-toast";
import { Typography, Box, Paper, Avatar } from "@mui/material";
import { React, useState, useEffect, useRef } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Toggle } from "../formValidation/Toggle";
import { setSaveandExit } from "../../redux/Dialer";
import { setGlobalLanguage } from "../../redux/Campaign";
import Cardpopup from "../formValidation/Cardpopup";
import DaynmicApicall, {
  ManageEventLog,
  scoreUpload,
} from "../../utils/function";
import api from "../../utils/api";
import { ErrorMessage } from "formik";
import Select from "react-select";
import "./Style.css";
import { setCallScore } from "../../redux/CurrentCallSessionDetails";
import CircleNotificationsOutlinedIcon from "@mui/icons-material/CircleNotificationsOutlined";

const MultiSelect = ({
  label,
  name,
  formik,
  onChange,
  isMulti,
  options,
  value,
  format,
  ...rest
}) => {
  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { isDisabled }) => {
      return {
        ...styles,
        cursor: isDisabled ? "not-allowed" : "pointer",
      };
    },
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#727cf5",
        borderColor: "#727cf5",
        borderRadius: "3px",
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: "white",
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: "#ffffff",
      cursor: "pointer",
      ":hover": {
        backgroundColor: "red",
        color: "white",
      },
    }),
  };
  return (
    <div>
      <Select
        name={name}
        styles={colourStyles}
        value={value}
        options={options}
        onChange={onChange}
        isMulti={isMulti}
        {...rest}
      />
      <ErrorMessage component="div" name={name} className="error text-danger" />
    </div>
  );
};

const AgentScript = (props) => {
  let { userInfo } = useSelector((state) => state?.user?.value);
  let { crmsaveandexit } = useSelector((state) => state?.dialer);
  let {
    data: { campinfo },
  } = props;
  const {
    radioCheck,
    scriptCheck,
    dispositionCheck,
    subDispositionCheck,
    callScore,
  } = useSelector((state) => state.callsession);
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const [cresponse, setCresponse] = useState("");
  const [nextScript, setNextScript] = useState("");
  const [dispositon, setdispositon] = useState("");
  const [subDispositon, setsubDispositon] = useState("");
  const cresponseRef = useRef(cresponse);
  const nextScriptRef = useRef(nextScript);
  const timeoutExecutedRef = useRef(false);
  const scoreRef = useRef(callScore);
  const [activeStep, setActiveStep] = useState(0);
  const [yesNoResponse, setYesnoResponse] = useState("");
  const [globalScript, setGlobalScript] = useState([]);
  const [language, setLanguage] = useState([]);
  const [responsemaster, setResponsemaster] = useState([]);
  const [pageData, setPageData] = useState("");
  const [getPhoneDetails, setPhoneDetails] = useState("");
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);

  let DialerCrmData = localStorage.getItem("currentcall")
    ? JSON.parse(JSON.parse(localStorage.currentcall).crm_data)
    : "";

  let actionlog = {
    eventname: "",
    actionname: "",
    actioncate: "",
  };

  //----For auto quality code start-----
  useEffect(() => {
    nextScriptRef.current = nextScript;
  }, [nextScript]);

  useEffect(() => {
    cresponseRef.current = cresponse;
  }, [cresponse]);

  useEffect(() => {
    scoreRef.current = callScore;
  }, [callScore]);

  // Initial effect for checking radio and script in the first 30 seconds
  useEffect(() => {
    if (!radioCheck || !pageData || timeoutExecutedRef.current) return;

    const timer = pageData?.radioCheck?.Timer || 10000;

    const initialCheckTimeout = setTimeout(async () => {
      let newScore = callScore;

      const scriptToggle = nextScriptRef.current ? true : false;
      const switchToggle = cresponseRef.current ? true : false;

      const radioScore = pageData?.radioCheck?.Score ?? 0;
      const scriptScore = pageData?.scriptCheck?.Score ?? 0;

      if (!switchToggle) {
        newScore -= radioScore;
        logToLocalStorage(pageData?.radioCheck?.Name, radioScore, "DEC");
      }
      if (!scriptToggle) {
        newScore -= scriptScore;
        logToLocalStorage(pageData?.scriptCheck?.Name, scriptScore, "DEC");
      }

      newScore = Math.max(0, Math.min(newScore, 100));
      dispatch(setCallScore(newScore));

      // Call API to send status
      await sendApiResponse(
        switchToggle,
        pageData?.radioCheck?.Name,
        radioScore,
        "0"
      );

      timeoutExecutedRef.current = true; // Mark timeout as executed
    }, timer);

    return () => {
      clearTimeout(initialCheckTimeout);
    };
  }, [radioCheck, scriptCheck, pageData]);

  // Set up interval to check disposition and subDisposition every 30 seconds
  useEffect(() => {
    const checkInterval = setInterval(async () => {
      if (dispositionCheck && subDispositionCheck && pageData) {
        await props.handleStop();
        await props.handleStart(pageData?.dispositionCheck?.Timer);

        let newScore = scoreRef.current;
        const dispositionToggle = dispositon ? true : false;
        const subdispositionToggle = subDispositon ? true : false;

        const dispositionScore = pageData?.dispositionCheck?.Score ?? 0;
        const subdispositionScore = pageData?.subDispositionCheck?.Score ?? 0;

        if (!dispositionToggle) {
          newScore -= dispositionScore;

          logToLocalStorage(
            pageData?.dispositionCheck?.Name,
            dispositionScore,
            "DEC"
          );
        }
        if (!subdispositionToggle) {
          newScore -= subdispositionScore;
          logToLocalStorage(
            pageData?.subDispositionCheck?.Name,
            subdispositionScore,
            "DEC"
          );
        }

        newScore = Math.max(0, Math.min(newScore, 100));

        if (newScore === 0) {
          clearInterval(checkInterval);
          dispatch(setCallScore(newScore));
          await props.handleStop();
          return;
        }

        if (newScore !== scoreRef.current) {
          dispatch(setCallScore(newScore));
          scoreRef.current = newScore;
        }

        setdispositon(false);
        setsubDispositon(false);
      }
    }, pageData?.dispositionCheck?.Timer);

    return () => clearInterval(checkInterval);
  }, [
    dispositionCheck,
    subDispositionCheck,
    pageData,
    dispatch,
    dispositon,
    subDispositon,
  ]);

  const sendApiResponse = async (
    userClicked,
    action,
    scoreParam,
    totalScore
  ) => {
    const actionFlag = userClicked ? "INC" : "DEC";
    const apiBody = {
      cuid: props?.data?.custinfo?.cuid,
      action_event: action,
      action_flag: actionFlag,
      sessionid: crmsaveandexit?.dialer_session_id,
      crmuuid: DialerCrmData.dialeruuid,
      campid: props?.data?.campinfo?.campid,
      agentid: userInfo.data[0].userid,
      score_param: "",
      call_remarks: "",
      score_param: userClicked ? `+${scoreParam}` : `-${scoreParam}`,
      final_score: totalScore,
      keypointer: props?.data?.campinfo?.keypointer,
    };

    await scoreUpload(apiBody, userInfo.data[0].UserToken)
      .then(async (data) => {
        await props.getUpdatedScore();
      })
      .catch((err) => {
        console.log("ERROR", err);
      });
  };

  const logToLocalStorage = (actionEventName, scoreParam, action_flag) => {
    const logEntry = {
      sno: Date.now(),
      action_event_name: actionEventName,
      action_flag: action_flag,
      score_param: `-${scoreParam}`,
    };

    const existingLogs = JSON.parse(localStorage.getItem("callLogs")) || [];
    existingLogs.push(logEntry);

    localStorage.setItem("callLogs", JSON.stringify(existingLogs));
  };
  //----For auto quality code end-----

  async function getScript(campid, language) {
    await DaynmicApicall(
      `/mcrmdlr/getmasterscriptall/${campid}/${
        language && language !== "NA"
          ? language?.split(" ")[0]?.toUpperCase()
          : "ENGLISH"
      }/${campinfo?.keypointer}`,
      "",
      "get",
      userInfo.data[0].UserToken
    )
      .then((data) => {
        let gScript =
          data?.length > 0
            ? data?.filter((e) => e?.script_code?.split("-")[0] === "G")
            : "";
        setGlobalScript(gScript);
      })
      .catch((err) => {
        console.log("API failed", err);
      });
  }

  async function getcamprespmapmast(campid, language) {
    await DaynmicApicall(
      `/mcrmdlr/getresponsescript/${campid}/${
        language ? language?.split(" ")[0]?.toUpperCase() : "ENGLISH"
      }/${campinfo.keypointer}`,
      "",
      "get",
      userInfo.data[0].UserToken
    )
      .then((data) => {
        setResponsemaster(data);
      })
      .catch((err) => {
        console.log("ERROR", err);
      });
  }

  async function pageInfo() {
    await DaynmicApicall(
      `/appuser/getcomponetbyid/${campinfo?.keypointer}/41`,
      "",
      "get",
      userInfo.data[0].UserToken
    )
      .then((data) => {
        setPageData(data[0]?.DATA);
      })
      .catch((err) => {
        console.log("ERROR", err);
      });
  }

  async function fetchNotifications() {
    const payload = {
      custid: props.data.custinfo.cuid,
    };
    await DaynmicApicall(
      `/crmora/otherinfo`,
      payload,
      "post",
      userInfo.data[0].UserToken
    )
      .then((data) => {
        setNotifications(data?.data);
      })
      .catch((err) => {
        setNotifications([]);
        console.log("ERROR", err);
      });
  }

  async function getLanguage() {
    api
      .get(`prm/getParamdata/ALL/LANGUAGE/${campinfo?.keypointer}`, {
        headers: {
          Authorization: userInfo.data[0].UserToken, //the token is a variable which holds the token
        },
      })
      .then(async (res) => {
        let bData = [];
        bData = await res?.data?.data?.map((item) => {
          return {
            value: item.paracode,
            label: item.paravalue,
          };
        });
        setLanguage(bData);
      })
      .catch((error) => {
        toast.error(
          error.response.data.message ??
            error.message ??
            "OOPs, something went wrong."
        );
      });
  }

  async function yesnoresponse(val) {
    if (val) {
      actionlog.eventname = `OnChange`;
      actionlog.actionname = "Global Script call Yes";
      actionlog.actioncat = "PRI-G-Y";
      ManageEventLog(actionlog);
      setYesnoResponse(
        globalScript[activeStep].subscript.find(
          (e) =>
            e.script_code.split("-")[e.script_code.split("-")?.length - 1] ===
            "N"
        )
      );
    } else if (val === false) {
      actionlog.eventname = `OnChange`;
      actionlog.actionname = "Global Script call No";
      actionlog.actioncat = "PRI-G-N";
      ManageEventLog(actionlog);
      setYesnoResponse(
        globalScript[activeStep].subscript.find(
          (e) =>
            e.script_code.split("-")[e.script_code.split("-").length - 1] ===
            "Y"
        )
      );
    }
  }

  const maxSteps = globalScript ? globalScript?.length : "";
  const handleNext = () => {
    actionlog.eventname = `handleNext`;
    actionlog.actionname = "Next Global script Call";
    actionlog.actioncat = "PRI-G";
    ManageEventLog(actionlog);
    setYesnoResponse("");
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setNextScript(true);
  };

  const handleBack = () => {
    actionlog.eventname = `handleBack`;
    actionlog.actionname = "Previous Global script Call";
    ManageEventLog(actionlog);
    setYesnoResponse("");
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleData = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    (async () => {
      await props.getUpdatedScore();
      await getcamprespmapmast(
        props.data.campinfo.campid,
        props.data.custinfo.cust_language
      );
      await pageInfo();
      await getLanguage();
      await getScript(
        props.data.campinfo.campid,
        props.data.custinfo.cust_language
      );
      await fetchNotifications();
    })();
  }, [props.data.custinfo]);

  useEffect(() => {
    getconnectPhoneDetails();
  }, [crmsaveandexit?.dialer_connected_ph]);

  async function getconnectPhoneDetails() {
    if (crmsaveandexit?.dialer_connected_ph) {
      await DaynmicApicall(
        `/mcrmdlr/getconnectedphone/${crmsaveandexit.dialer_connected_ph}/${crmsaveandexit.campid}${crmsaveandexit.cust_id}/${campinfo?.keypointer}`,
        "",
        "get",
        userInfo.data[0].UserToken
      )
        .then((data) => {
          setPhoneDetails(data);
        })
        .catch((err) => {
          console.log("ERROR", err);
        });
    }
  }

  const initial = {
    consent: "",
    scripttype: "",
  };

  var ReplaceObjeWithScript = {
    Customer_Surname: props.data?.custinfo?.policy_owner,
    policy_name: "",
    Agent_Name: userInfo.data[0]?.username,
    AGENT_NAME: userInfo.data[0]?.username,
    CONTACT_NO: props?.data?.custinfo?.contact_no,
  };

  const hasNotifications = notifications?.length > 0;

  return (
    <>
      {pageData && (
        <div className="card ml-1 mt-2 pt-1">
          <div className="row mt-1">
            <div className="col-sm-2 col-md-1 col-lg-1 col-xl-1 pt-1 pl-1 pr-0">
              <span style={{ fontSize: "1.1rem" }}>
                <strong>{pageData?.title}</strong>
              </span>
            </div>
            <div className="col-sm-10 col-md-7 col-lg-7 col-xl-7 p-0">
              <Formik initialValues={{ consent: "", scriptyesno: "" }}>
                {(formik) => (
                  <div className="row" style={{ width: "100%" }}>
                    <div className="col-3 col-sm-3 col-md-2 col-lg-2 col-xl-2 p-0">
                      <Toggle
                        label={cresponse === false ? " NO " : "Yes"}
                        name={pageData?.toggle?.name}
                        key={pageData?.toggle?.key}
                        type={pageData?.toggle?.type}
                        onChange={async (e) => {
                          formik.setFieldValue("consent", e.target.checked);
                          setCresponse(e.target.checked);
                          await yesnoresponse(e.target.checked);
                        }}
                      />
                    </div>
                    <div className="col-9 col-sm-9 col-md-10 col-lg-10 col-xl-10 p-0 ">
                      <div className="row d-flex justify-content-end">
                        <div
                          id="language"
                          className="col-4 col-sm-6 col-md-4 col-lg-4 col-xl-4"
                        >
                          <MultiSelect
                            name="selectlanguage"
                            placeholder="Language"
                            isMulti={false}
                            options={language}
                            onChange={(value) => {
                              getScript(
                                props.data?.campinfo?.campid,
                                value?.value
                              );
                              getcamprespmapmast(
                                props.data?.campinfo?.campid,
                                value?.value
                              );
                              dispatch(
                                setGlobalLanguage({
                                  data: value.value ? value.value : "ENGLISH",
                                })
                              );
                            }}
                          />
                        </div>
                        <div
                          id="person"
                          className="col-6 col-sm-6 col-md-5 col-lg-5 col-xl-5"
                          style={{
                            display: pageData?.contact_person
                              ? pageData?.contact_person?.visible === false
                                ? "none"
                                : ""
                              : "none",
                          }}
                        >
                          <MultiSelect
                            name="contact_person"
                            placeholder="Contact Person"
                            isMulti={false}
                            options={pageData?.contact_person?.options}
                            onChange={(value) => {
                              dispatch(
                                setSaveandExit({
                                  contact_person: value?.value,
                                })
                              );
                            }}
                            format="true"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Formik>
            </div>
            <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <div
                id="language"
                className="d-flex justify-content-end mt-1 ml-8"
              >
                <button
                  title="Back"
                  data-toggle="tooltip"
                  className="btn-sm "
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  Back
                </button>
                <button
                  className="btn-sm  ml-3"
                  title="Next"
                  // data-toggle="tooltip"
                  data-bs-original-title="tooltip"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                >
                  Next
                </button>
              </div>
            </div>
            <div className="col-sm-12 col-md-1 col-lg-1 col-xl-1">
              <div style={{ position: "relative" }}>
                <div
                  style={{
                    textAlign: "center",
                    padding: "10px 0px",
                    cursor: "pointer",
                  }}
                >
                  <CircleNotificationsOutlinedIcon
                    fontSize="large"
                    style={{
                      color: hasNotifications ? "red" : "#ccc",
                      cursor: hasNotifications ? "pointer" : "not-allowed",
                    }}
                    onClick={() =>
                      hasNotifications && setShowNotifications((prev) => !prev)
                    }
                  />
                </div>

                {showNotifications && hasNotifications && (
                  <Paper
                    elevation={3}
                    style={{
                      position: "absolute",
                      right: 0,
                      top: "50px",
                      width: "300px",
                      maxHeight: "400px",
                      overflowY: "auto",
                      zIndex: 1000,
                      borderRadius: "5px",
                      border: "1px solid black",
                    }}
                  >
                    <Box
                      p={1}
                      borderBottom="1px solid #ddd"
                      textAlign={"center"}
                    >
                      <Typography variant="h7" align="center">
                        Notifications
                      </Typography>
                    </Box>

                    {notifications.map((notif, index) => (
                      <Box
                        key={index}
                        display="flex"
                        p={1}
                        borderBottom="1px solid #ddd"
                      >
                        <Avatar
                          sx={{
                            backgroundColor: "#262B40",
                            width: 24,
                            height: 24,
                            fontSize: "0.8rem",
                            marginRight: "10px",
                          }}
                        >
                          {index + 1}
                        </Avatar>
                        <Box>
                          <Typography
                            variant="body2"
                            fontWeight="bold"
                            sx={{ fontSize: "0.9rem" }}
                          >
                            {notif.EVENT}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            sx={{ fontSize: "0.75rem", color: "gray" }}
                          >
                            {notif.DATETIME.split("T")[0]},{" "}
                            {notif.DATETIME.split("T")[1].split(".")[0]}
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </Paper>
                )}
              </div>
            </div>
            <div>
              <Typography className="mb-2">
                {yesNoResponse
                  ? yesNoResponse?.script_temp_body
                  : globalScript[activeStep]?.script_temp_body?.replace(
                      /Customer_Surname|Policy_Name|Agent_Name|AGENT_NAME|CONTACT_NO/gi,
                      function (matched) {
                        return ReplaceObjeWithScript[matched];
                      }
                    )}
                <strong className="text-bold">
                  {" "}
                  {getPhoneDetails && getPhoneDetails}
                </strong>
              </Typography>
            </div>
          </div>
        </div>
      )}
      <Container className="card mt-2 ml-1">
        <Row className="p-2 mb-0" style={{ width: "100%" }}>
          {responsemaster &&
            responsemaster?.map((data, index) => {
              return (
                <Col
                  xs={6}
                  sm={3}
                  md={2}
                  lg={2}
                  xl={2}
                  className="p-1 m-0"
                  key={index}
                >
                  <Card
                    className="p-0 m-0"
                    id="scriptbtn"
                    style={{ width: "9rem" }}
                  >
                    <Button
                      id={`scriptresponse${index}`}
                      className={`bg-${data?.resp_color} text-white fw-bold`}
                      title={data?.script_name}
                      data-toggle="tooltip"
                      data-bs-toggle="modal"
                      data-bs-target={`#cardtoggel${data?.sno}`}
                      size="sm"
                      variant="white"
                      onClick={async (e) => {
                        if (!data.subscript.length > 0) {
                          toast("No script found for this disposition !!!", {
                            style: {
                              border: "1px solid black",
                              backgroundColor: "#ffcc00",
                            },
                          });
                        }
                        setdispositon(true);
                        props.handleStop();
                        dispatch(
                          setSaveandExit({
                            resp_code: data.script_code,
                            sub_resp_code: "NA",
                          })
                        );
                        actionlog.eventname = `OnClick`;
                        actionlog.actionname = `${
                          data.script_code + "-" + data.script_name
                        } Disposition call`;
                        actionlog.actioncate = "PRI-D";
                        actionlog.resp_code = data.script_code;
                        actionlog.sub_resp_code = data.script_code;
                        await ManageEventLog(actionlog);
                      }}
                      style={{
                        width: "100%",
                        height: "30px",
                        borderRadius: "10px",
                        fontSize: 10,
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {data.script_name}
                    </Button>
                    {data.subscript.length > 0 && (
                      <Cardpopup
                        data={data}
                        customer={{
                          policy: props.data.custinfo.policy_no,
                          custid: props.data.custinfo.cuid,
                          policy_owner: props.data.custinfo.policy_owner,
                        }}
                        campinfo={campinfo}
                        setLBagent={props.setLBagent}
                        setTLId={props.setTLId}
                        setsubDispositon={setsubDispositon}
                      />
                    )}
                  </Card>
                </Col>
              );
            })}
        </Row>
      </Container>
    </>
  );
};

export default AgentScript;
