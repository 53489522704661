import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "react-bootstrap";
import "./style.css";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import RefreshIcon from "@mui/icons-material/Refresh";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import toast from "react-hot-toast";
import DaynmicApicall, { ManageEventLog } from "../../utils/function";
import socket, { queueLogin } from "../../utils/socket";
import TtyIcon from "@mui/icons-material/Tty";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import PhonePausedIcon from "@mui/icons-material/PhonePaused";
import { removeCrmWebphone, setCrmWebphone } from "../../redux/dialerStates";
import EmojiFoodBeverageIcon from "@mui/icons-material/EmojiFoodBeverage";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import CircleIcon from "@mui/icons-material/Circle";
import MicOffIcon from "@mui/icons-material/MicOff";
import MicIcon from "@mui/icons-material/Mic";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import CallEndIcon from "@mui/icons-material/CallEnd";
import IconButton from "@material-ui/core/IconButton";
import api from "../../utils/api";
import { WEBPHONE_URL } from "../../utils/constent";
import ConferenceCall from "./ConferenceCall";
import { speak } from "../../utils/textToSpeech";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { lbRedialCall } from "../DespositionComponents/comonentFunctions";
import { removeCurrentCall } from "../../redux/Dialer";
import { setGlobalLanguage } from "../../redux/Campaign";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import {
  removeCallSession,
  setDispositionCheck,
  setQueueInfo,
  setRadioCheck,
  setScriptCheck,
  setsubDispositionCheck,
} from "../../redux/CurrentCallSessionDetails";
import { Switch } from "@mui/material";

export default function Dialer(props) {
  const { campaignInfo, queueStatus } = useSelector(
    (state) => state.callsession
  );
  const { userInfo } = useSelector((state) => state?.user?.value);
  const { UserToken, userid, usergroup, dlrAgentId } = userInfo.data[0];
  let { crmwebphone } = useSelector((state) => state?.webphoneStatus);
  const confStatusRef = useRef(crmwebphone?.confStatus);
  const dispatch = useDispatch();
  let { crmsaveandexit } = useSelector((state) => state?.dialer);
  const [lucnhbreak, setLucnhbreak] = useState(false);
  const [sortbreak, setSortbreak] = useState(false);
  const [callholdUnhold, setCallholdUnhold] = useState(false);
  const [getQueue, setQueue] = useState([]);
  const [callmuteUnmute, setcallmuteUnmute] = useState(false);
  const [getConferenceInfo, SetConferenceInfo] = useState("");
  const [getConferenceTo, SetConferenceTo] = useState("");
  const [wepphoneconnect, setWebphoneconnect] = useState("");
  const [getRegister, setRegister] = useState("Not Registered");
  const [datacall, setcalltransfer] = useState("");
  const [dialeCall, setdialeCall] = useState("");
  const [reportinglist, setReportinglist] = useState("");
  const [getEnd, setEnd] = useState([]);
  const [getfollowupdata, setfollowupdata] = useState([]);
  const [getpreviewfollowupdata, setpreviewfollowupdata] = useState([]);
  const [pageData, setPageData] = useState("");
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  const TIME = new Date()
    .toLocaleTimeString("en-gb", { hour12: false })
    .split(":");

  const DialerCrmData =
    localStorage["currentcall"] && userInfo.data[0].usergroup === "AGENT"
      ? JSON.parse(JSON.parse(localStorage.currentcall).crm_data)
      : "";

  const pageInfo = async () => {
    await DaynmicApicall(
      `/appuser/getcomponetbyid/${campaignInfo?.keypointer}/124`,
      "",
      "get",
      userInfo.data[0].UserToken
    )
      .then((data) => {
        setPageData(data[0]?.DATA);
      })
      .catch((err) => {
        console.log("ERROR", err);
        setPageData("");
      });
  };

  const f_getConnectedCamp = async () => {
    const camp = await DaynmicApicall(
      `appuser/getcampbycampid/ALL/${userid}`,
      "",
      "get",
      UserToken
    );
    const queue = camp?.map((data) => {
      return {
        queue_name: data.campname, // queue
        queue_number: data.queue_name,
      };
    });
    setQueue(queue);
  };

  const fngetdndstatus = async () => {
    const payload = {
      exten: `${dlrAgentId}`,
      dnd: "status",
    };
    const dndStatus = await DaynmicApicall(`/ami/dnd`, payload, "post");
    if (dndStatus?.response === "DND is activated") {
      setChecked(true);
    } else {
      setChecked(false);
    }
  };

  const getfollowup = async () => {
    const Info = await DaynmicApicall(
      `mcrmdlr/gettodayfollowup/${userid}`,
      "",
      "get",
      UserToken
    );
    setfollowupdata(Info ? Info : []);
  };

  const getpreviewfollowup = async () => {
    const payload = {
      campid: userInfo?.data[0]?.campaignid,
      agentid: userInfo?.data[0]?.userid,
    };
    const Info = await DaynmicApicall(
      `mcrmdlr/agentpreviewcase`,
      payload,
      "post",
      UserToken
    );
    setpreviewfollowupdata(Info?.data?.length > 0 ? Info?.data : []);
  };

  const getcalltransfer = () => {
    return setcalltransfer(!datacall);
  };

  useEffect(() => {
    confStatusRef.current = crmwebphone?.confStatus;
  }, [crmwebphone?.confStatus]);

  useEffect(() => {
    if (campaignInfo?.keypointer) {
      pageInfo();
    }
  }, [campaignInfo]);

  useEffect(() => {
    if (usergroup === "AGENT") {
      getfollowup();
      getpreviewfollowup();
    }
    if (usergroup === "TEAM-LEAD" || usergroup === "MANAGER") {
      getfollowup();
      getpreviewfollowup();
    }
    // fngetdndstatus();
    f_getConnectedCamp();
    getreportingmemberList();
  }, []);

  useEffect(() => {
    confStatusRef.current = getConferenceInfo;
    if (getRegister === "Not Registered") {
      dispatch(
        setCrmWebphone({
          queueStatus: false,
        })
      );
    }
    var Connection = socket.connect("");

    window.addEventListener("offline", (event) => {
      setRegister("Not Registered");
      dispatch(
        setCrmWebphone({
          queueStatus: false,
        })
      );
    });

    if (Connection.connected === false) {
      setRegister("Not Registered");
      dispatch(
        setCrmWebphone({
          queueStatus: false,
        })
      );
    }

    socket.on("respfromdialer", async (data) => {
      switch (data.action) {
        case "message_info":
          dispatch(
            setCrmWebphone({
              message_text: data.event_data.message_text,
            })
          );
          toast.success(data.event_data.message_text);
          if (data.event_data.message_text.includes("Inbound Caller Hangup")) {
            speak("Inbound Caller Hangup");
            const callEndCuid = data?.event_data?.message_text
              ?.split("-")[1]
              .trim();
            console.log("Inbound Caller Hangup cuid", callEndCuid);
            dispatch(
              setCrmWebphone({
                dialerHangupCuid: callEndCuid,
              })
            );
            await callAutoWrapupTriggerConfNo();
          } else if (
            data.event_data.message_text.includes("conf end completely")
          ) {
            await callAutoWrapupTriggerConfYes();
          } else {
            speak(data.event_data.message_text);
          }
          break;
        case "get_call_transfer":
          const l1 = document.getElementById("calltransfer");
          l1.click();
          break;
        case "register_state":
          setRegister(data.event_data.register_state.toUpperCase());
          dispatch(
            setCrmWebphone({
              queueStatus: data.event_data.register_state,
            })
          );
          if (
            data.event_data.register_state === "registered" &&
            usergroup === "AGENT"
          ) {
            await queueLogin(
              userInfo.data[0]?.dlrAgentId,
              userInfo.data[0]?.userid
            ).then((data) => {
              dispatch(setQueueInfo(true));
              dispatch(
                setCrmWebphone({
                  message_text: "added",
                })
              );
            });
          }
          break;
        case "call_state":
          setWebphoneconnect(data.event_data.call_status);
          if (
            data.event_data.crm_data &&
            data?.event_data?.call_status === "answered"
          ) {
            dispatch(
              setCrmWebphone({
                dialerCallHangup: true,
              })
            );
            dispatch(setRadioCheck(true));
            dispatch(setScriptCheck(true));
            dispatch(setDispositionCheck(true));
            dispatch(setsubDispositionCheck(true));
          }

          // Update call status in CRM webphone
          dispatch(
            setCrmWebphone({
              callStatus: data?.event_data?.call_status,
              callStartTime:
                data?.event_data?.call_start && data.event_data.crm_data
                  ? data.event_data.call_start
                  : undefined,
              callEndTime:
                data?.event_data?.call_stop && data.event_data.crm_data
                  ? data.event_data.call_stop
                  : undefined,
            })
          );
          break;
        case "toggle_mute":
          setCallholdUnhold(!callholdUnhold);

          break;
        case "attx_xfer":
          break;
        case "toggle_hold":
          break;
        case "send_dtmf":
          break;
        case "conference_info":
          // SetConferenceInfo(data)
          setEnd([
            // { label: "Self Disconnect", value: dlrAgentId },
            {
              label: "Customer Disconnect",
              value: localStorage["currentcall"]
                ? JSON.parse(localStorage.currentcall).caller_number
                : "",
            },
            { label: "TL Disconnect", value: getConferenceTo },
            // { label: "Call Disconnect", value: "" },
          ]);
          SetConferenceInfo(data);
          dispatch(
            setCrmWebphone({
              confStatus: data,
            })
          );
        default:
        // cccccccc
      }
    });
    socket.on("crmdialerdata", async (data) => {});
  }, []);

  async function callAutoWrapupTriggerConfNo() {
    if (Object.keys(confStatusRef.current || {}).length === 0) {
      dispatch(
        setCrmWebphone({
          dialerHangupStatus: false,
          dialerCallHangup: false,
        })
      );
    }
  }

  async function callAutoWrapupTriggerConfYes() {
    if (Object.keys(confStatusRef.current || {}).length > 0) {
      dispatch(
        setCrmWebphone({
          dialerHangupStatus: false,
          dialerCallHangup: false,
        })
      );
    }
  }

  async function callholdAndUnhold(event) {
    let holdUnhold = {
      agent_token: userInfo.data[0]?.dlrAgentId,
      action: "toggle_hold",
      event_data: {
        call_session_id: crmsaveandexit?.dialer_session_id, // CALL UNIQUE SESSION ID OR UUID
        hold_action: callholdUnhold === true ? "unhold" : "hold", // value "hold" or "unhold" will be based on control action
      },
    };
    socket.emit("crmdialerdata", holdUnhold);
  }
  async function callMuteAndUnmute() {
    setcallmuteUnmute(callmuteUnmute === false ? true : false);
    let session;
    if (usergroup !== "AGENT") {
      session = JSON.parse(localStorage.currentcall).call_session_id;
    }
    let muteunmute = {
      agent_token: userInfo.data[0]?.dlrAgentId,
      action: "toggle_mute",
      event_data: {
        call_session_id: session ? session : crmsaveandexit?.dialer_session_id, // CALL UNIQUE SESSION ID OR UUID
        mute_action: callmuteUnmute === true ? "unmute" : "mute", // value "mute" or "unmute" will be based on control action
      },
    };
    socket.emit("crmdialerdata", muteunmute);
  }

  async function auditTrails(taskName, errMessage, errRemarks) {
    const parameter = {
      sNo: "404",
      taskName: taskName,
      errMessage: errMessage,
      errRemarks: errRemarks,
    };
    const Info = await DaynmicApicall(
      `err/setError/`,
      parameter,
      "post",
      userInfo.data[0].UserToken
    );
  }
  async function callend(extensionid) {
    let parameter;

    if (usergroup !== "AGENT") {
      let endcall = {
        agent_token: dlrAgentId,
        action: "end_call",
        event_data: {
          call_session_id: JSON.parse(localStorage.currentcallend)
            .call_session_id, // Call disconnect
        },
      };
      socket.emit("crmdialerdata", endcall);
    } else {
      const DialerCrmData = localStorage["currentcall"]
        ? JSON.parse(JSON.parse(localStorage.currentcall).crm_data)
        : "";
      parameter = {
        agent: dlrAgentId,
        crmuuid: DialerCrmData.dialeruuid,
      };
      if (extensionid) {
        let conferencesCallDetails = await getcallconfstatus(
          `${dlrAgentId}`,
          DialerCrmData.dialeruuid
        );

        if (extensionid?.label === "TL Disconnect") {
          parameter = {
            channelid: conferencesCallDetails.Message[0]?.thirdparty_channel,
            crmuuid: conferencesCallDetails.Message[0]?.dialeruuid,
          };
        }

        if (extensionid?.label === "Self Disconnect") {
          parameter = {
            channelid: conferencesCallDetails.Message[0]?.agent_channel,
            crmuuid: conferencesCallDetails.Message[0]?.dialeruuid,
          };
        }

        if (extensionid?.label === "Customer Disconnect") {
          parameter = {
            channelid: conferencesCallDetails.Message[0]?.customer_real_channel,
            crmuuid: conferencesCallDetails.Message[0]?.dialeruuid,
          };
        }

        if (extensionid?.label === "Call Disconnect") {
          let Info = api.post(
            "ami/endCallCOnference",
            {
              agent: dlrAgentId,
              crmuuid: DialerCrmData.dialeruuid,
            },
            {
              headers: { Authorization: UserToken },
            }
          );
          toast
            .promise(Info, {
              success: (Info) => {
                return Info.data.message;
              },
              error: (err) => {
                return (
                  err?.response?.data?.errors ??
                  "Please contact to dialer admin & Save the call" ??
                  err?.message ??
                  "OOPs something went wrong."
                );
              },
            })
            .then((data) => {
              // let endcall = {
              //   agent_token: dlrAgentId,
              //   action: "end_call",
              //   event_data: {
              //     call_session_id: getConferenceInfo.event_data?.conf_id, // Call disconnect
              //   },
              // };
              // socket.emit("crmdialerdata", endcall);
              SetConferenceInfo("");
              let actionlog = {
                Rowed: "NEW",
                custid: crmsaveandexit.cust_id,
                campid: crmsaveandexit.campid,
                eventname: `OnClick`,
                actionname: "",
                createdby: userid,
              };
              ManageEventLog(actionlog);
              dispatch(
                setCrmWebphone({
                  dialerCallHangup: false,
                  message_text: "",
                })
              );
              setWebphoneconnect("");
            })
            .catch((error) => {
              dispatch(
                setCrmWebphone({
                  dialerCallHangup: false,
                  message_text: "",
                })
              );
              auditTrails(
                "callerhangup",
                error.response?.data?.message,
                error.response?.request?.responseURL
              );
            });
        }
      }
      const Info = api.post("dialer/callerhangup", parameter, {
        headers: { Authorization: UserToken },
      });

      toast
        .promise(Info, {
          success: (Info) => {
            return Info.data.message;
          },
          error: (err) => {
            return (
              err?.response?.data?.errors ??
              "Please contact to dialer admin & Save the call" ??
              err?.message ??
              "OOPs something went wrong."
            );
          },
        })
        .then((data) => {
          // let endcall = {
          //   agent_token: dlrAgentId,
          //   action: "end_call",
          //   event_data: {
          //     call_session_id: getConferenceInfo.event_data?.conf_id, // Call disconnect
          //   },
          // };
          // socket.emit("crmdialerdata", endcall);
          let actionlog = {
            Rowed: "NEW",
            custid: crmsaveandexit.cust_id,
            campid: crmsaveandexit.campid,
            eventname: `OnClick`,
            actionname: "",
            createdby: userid,
          };
          ManageEventLog(actionlog);
          dispatch(
            setCrmWebphone({
              dialerCallHangup: false,
              message_text: "",
            })
          );
          setWebphoneconnect("");
        })
        .catch((error) => {
          dispatch(
            setCrmWebphone({
              dialerCallHangup: false,
              message_text: Info.data.message,
            })
          );
          auditTrails(
            "callerhangup",
            error.response?.data?.message,
            error.response?.request?.responseURL
          );
        });
    }
  }

  async function getcallconfstatus(agentid, crmuuid) {
    return await DaynmicApicall(
      `ami/getcallconfstatus`,
      {
        agent: agentid,
        crmuuid: crmuuid,
      },
      "post",
      userInfo.data[0].UserToken
    )
      .then(async (res) => {
        return res;
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        toast.error(
          error.response.data.message ??
            error.message ??
            "OOPs, Something went wrong."
        );
      });
  }
  async function dial_call_tranfer(number) {
    // callholdAndUnhold()
    // const dial_call = {
    //   agent_token: userInfo.data[0]?.dlrAgentId,
    //   action: "dial_call",
    //   event_data: {
    //     dial_number: `${number}` // Number which needs to dial
    //   }
    // }
    // socket.emit("crmdialerdata", dial_call);
    // socket.on("respfromdialer", async (data) => {
    //   if (data.action === "call_state" && data.event_data.caller_number == number)
    //     setdialeCall(data.event_data)
    //   dispatch(setCrmWebphone({
    //     setdialeCall: data.event_data,
    //   }))
    // })
  }
  async function attandcalltransfer() {
    const callinfo = {
      agent_token: userInfo.data[0]?.dlrAgentId,
      action: "attx_xfer",
      event_data: {
        xfer_call_session_id: dialeCall.call_session_id, // Transfer To Number
        call_session_id: crmsaveandexit?.dialer_session_id, // CALL UNIQUE SESSION ID OR UUID
      },
    };
    socket.emit("crmdialerdata", callinfo);
  }
  async function fn_lunchbreak() {
    const queueNames = getQueue.map((item) => item.queue_number);
    await api
      .post(
        "/ami/agent/pause",
        {
          actionid: "crmuuid",
          queues: queueNames,
          interface: userInfo.data[0].dlrAgentId.toString(),
          paused: lucnhbreak,
          reason: "Lunch-Break",
        },
        userInfo
      )
      .then((response) => {
        speak(response.data.Message);
        dispatch(
          setCrmWebphone({
            message_text: response.data.Message,
          })
        );
        toast.success(`${response.data.Message}`);
        // })
        setLucnhbreak(!lucnhbreak);
      })
      .catch((error) => {
        console.log("Error from queue/logout::: ", error.response);
        toast.error(error.response.data.message);
      });
  }
  async function fn_sortbreak() {
    const queueNames = getQueue.map((item) => item.queue_number);
    await api
      .post(
        "/ami/agent/pause",
        {
          actionid: "crmuuid",
          queues: queueNames,
          interface: userInfo.data[0].dlrAgentId.toString(),
          paused: sortbreak,
          reason: "Sort-Break",
        },
        userInfo
      )
      .then((response) => {
        // response.data.results.map((resp) => {
        speak(response.data.Message);
        dispatch(
          setCrmWebphone({
            message_text: response.data.Message,
          })
        );
        toast.success(`${response.data.Message}`);
        setSortbreak(!sortbreak);
      })
      .catch((error) => {
        console.log("Error from queue/logout::: ", error.response);
        toast.error(error.response.data.message);
      });
  }

  async function getreportingmemberList() {
    await DaynmicApicall(
      `appuser/getmasterdata/vw_reporting_ids/*/1=1/user_id/`,
      "",
      "get",
      userInfo.data[0].UserToken
    )
      .then(async (res) => {
        setReportinglist(res);
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        toast.error(
          error.response.data.message ??
            error.message ??
            "OOPs, Something went wrong."
        );
      });
  }
  const callRedial = async () => {
    try {
      setLoading(true);
      let getcalldisconnected = await DaynmicApicall(
        `dialer/getcallsiconnectevent/${
          JSON.parse(JSON.parse(props?.dialerCrmData)?.crm_data)?.dialeruuid
        }`,
        "",
        "get",
        userInfo.data[0].UserToken
      );
      if (getcalldisconnected.CNT === 0) {
        toast("You can redial after call disconnect", {
          icon: "😎",
          style: {
            borderRadius: "10px",
            background: "#45abdb",
            color: "#fff",
          },
        });
        setLoading(false);
        return false;
      }
      const payload = {
        ...crmsaveandexit,
        resp_code: "REDIAL",
        sub_resp_code: "REDIAL",
        script_language: "ENGLISH",
      };

      let Info = await DaynmicApicall(
        `mcrmdlr/managecallsave`,
        payload,
        "post",
        userInfo.data[0].UserToken
      );
      if (Info.status === true) {
        const actionlog = {
          Rowed: "NEW",
          custid: props?.propsdata?.data?.custinfo?.cust_id,
          campid: props?.propsdata?.data?.campinfo?.campid,
          eventname: `OnClick`,
          actionname: `final call save`,
          createdby: userInfo.data[0].userid,
        };
        ManageEventLog(actionlog);
        await lbRedialCall(
          {
            agentid: userInfo?.data[0]?.AgentDidNo.toString(),
            custid: `${payload?.campid}${payload?.cust_id}`,
            phone: [payload?.dialer_connected_ph],
            campid: payload?.campid,
          },
          userInfo.data[0].UserToken
        );
        const hangupdata = {
          crmuuid: JSON.parse(JSON.parse(props?.dialerCrmData)?.crm_data)
            ?.dialeruuid,
          external_hangup: "1",
          external_status: `${userInfo?.data[0]?.userid}${userInfo?.data[0]?.usergroup}${payload.resp_code}FromWebPhone`,
          agentid: `${userInfo.data[0].AgentDidNo}`,
        };
        await DaynmicApicall(
          `dialer/dialerhangup`,
          hangupdata,
          "post",
          userInfo.data[0].UserToken
        )
          .then(async (data) => {
            dispatch(removeCurrentCall());
            dispatch(removeCallSession());
            dispatch(removeCrmWebphone());
            dispatch(
              setCrmWebphone({
                confStatus: "",
              })
            );
            localStorage.removeItem("currentcall");
            localStorage.removeItem("callLogs");
            localStorage.removeItem("currentListening");
            if (crmwebphone.confStatus) {
              await DaynmicApicall(
                `ami/endCallCOnference`,
                {
                  agent: dlrAgentId,
                  crmuuid: DialerCrmData.dialeruuid,
                },
                "post",
                UserToken
              );
            }
            props.propsdata.aftersave("");
            setLoading(false);
            dispatch(removeCurrentCall());
            localStorage.removeItem("currentcall");
            localStorage.removeItem("callLogs");
            localStorage.removeItem("currentListening");
          })
          .catch((error) => {
            dispatch(removeCurrentCall());
            dispatch(removeCallSession());
            dispatch(removeCrmWebphone());
            dispatch(
              setCrmWebphone({
                confStatus: "",
              })
            );
            localStorage.removeItem("currentcall");
            localStorage.removeItem("callLogs");
            localStorage.removeItem("currentListening");
            props.propsdata.aftersave("");
            setLoading(false);
            toast.error(error.message);
          });
      }
      setLoading(false);
      toast.success(Info.message);
    } catch (error) {
      setLoading(false);
      console.log("ERROR", error.message);
    }
  };
  async function queuelogout() {
    // if (userInfo.data[0].usergroup === "AGENT") {
    // const queuse = await f_getConnectedCamp("ALL", userInfo.data[0].userid);
    // console.log("queuse::::", queuse)
    // const queueNames = getQueue?.map((item) => item.queue_number);
    const queueNames = getQueue
      .filter((item) => item.queue_number !== "100078") // Filter out items with queue_name '100078'
      .map((item) => item.queue_number);
    const response = await api
      .post(
        "/ami/queue/logout",
        {
          actionid: Date.now(),
          queues: queueNames,
          interface: userInfo.data[0].dlrAgentId,
          membername: userInfo.data[0].dlrAgentId,
        },
        userInfo
      )
      .catch((error) => {
        console.log("Error from queue/logout::: ", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.Message
        ) {
          toast.error(error.response.data.Message);
        } else {
          toast.error("An error occurred");
        }
      });
    dispatch(setQueueInfo(false));
    speak(response.data.Message);
    toast.success(`${response.data.Message}`);
    return response.data;
  }

  const handleDND = async (value) => {
    setChecked(value);
    const response = await api
      .post("/ami/dnd", {
        exten: `${dlrAgentId}`,
        dnd: value ? "yes" : "no",
      })
      .catch((error) => {
        console.log("Error from dnd::: ", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.response
        ) {
          toast.error(error.response.data.response);
        } else {
          toast.error("An error occurred");
        }
      });
    toast.success(`${response.data.response}`);
  };

  return (
    <>
      <div className="container-fulid">
        <div className="bg-dark text-light rounded m-0 p-0">
          <p style={{ fontSize: "13px" }} className="text-center ">
            <SignalCellularAltIcon
              className={
                getRegister.includes("REGISTERED")
                  ? "text-success"
                  : "text-danger"
              }
              style={{ height: "17px" }}
            />
            {getRegister} ({wepphoneconnect})
          </p>
        </div>
        <div className="container">
          {/* Iframe */}
          <div className="">
            <iframe
              hidden
              title="webphone"
              src={`${WEBPHONE_URL}?${userInfo.data[0].dlrAgentId}`}
              allow="microphone"
            ></iframe>
          </div>
          {/* WebPhone */}
          <div className="">
            <div className="">
              <div className="card mt-4 border-2 border-secondary">
                <div className="row justify-content-center">
                  {crmsaveandexit.dialer_session_id && (
                    <div
                      className="col-sm-6 col-md-4 col-lg-4 col-xl-4 m-0"
                      style={{
                        display:
                          pageData?.Re_Dail?.visible === false ? "none" : "",
                      }}
                    >
                      <div
                        className="text-center"
                        style={{
                          color: "#262B40",
                          fontWeight: "bold",
                          fontSize: "20px",
                        }}
                      >
                        {loading ? (
                          <button
                            className="btn"
                            data-toggle="tooltip"
                            title="Re-Dail Progress"
                            disabled
                          >
                            <CircularProgress
                              style={{
                                height: "20px",
                                width: "20px",
                                color: "Black",
                              }}
                            />
                          </button>
                        ) : (
                          <button
                            className="btn"
                            data-toggle="tooltip"
                            title={`${pageData?.Re_Dail?.title}`}
                            onClick={(e) => {
                              callRedial();
                            }}
                          >
                            <TtyIcon />
                          </button>
                        )}
                      </div>
                    </div>
                  )}

                  {["ended", false].includes(crmwebphone.dialerCallHangup) && (
                    <>
                      <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 m-0">
                        <div
                          className="text-center"
                          style={{
                            color: "#262B40",
                            fontWeight: "bold",
                            fontSize: "20px",
                          }}
                        >
                          <button
                            disabled={lucnhbreak === true ? true : false}
                            className="btn"
                            type="button"
                            data-toggle="tooltip"
                            title="Short Break"
                            onClick={(e) => {
                              fn_sortbreak();
                            }}
                          >
                            {sortbreak === false ? (
                              <PauseCircleIcon
                                data-toggle="tooltip"
                                title="Short break start"
                              />
                            ) : (
                              <EmojiFoodBeverageIcon
                                data-toggle="tooltip"
                                title="Short break end"
                                style={{ color: "f75151" }}
                              />
                            )}
                          </button>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 m-0">
                        <div
                          className="text-center"
                          style={{
                            color: "#262B40",
                            fontWeight: "bold",
                            fontSize: "20px",
                          }}
                        >
                          <button
                            disabled={sortbreak === true ? true : false}
                            className="btn"
                            type="button"
                            data-toggle="tooltip"
                            title="Lunch Break"
                            onClick={(e) => {
                              fn_lunchbreak();
                            }}
                          >
                            {lucnhbreak === false ? (
                              <PhonePausedIcon
                                data-toggle="tooltip"
                                title="Call Pause"
                              />
                            ) : (
                              <FastfoodIcon
                                data-toggle="tooltip"
                                title="Lunch Break"
                                style={{ color: "f75151" }}
                              />
                            )}
                          </button>
                        </div>
                      </div>
                    </>
                  )}

                  {((crmsaveandexit.dialer_session_id &&
                    !["answered", false].includes(
                      crmwebphone.dialerCallHangup
                    )) ||
                    usergroup !== "AGENT") && (
                    <>
                      <div
                        className="col-sm-4 col-md-4 col-lg-4 col-xl-4 m-0"
                        style={{
                          display:
                            pageData?.Short_Break?.visible === false
                              ? "none"
                              : "",
                        }}
                      >
                        <div
                          className="text-center"
                          style={{
                            color: "#262B40",
                            fontWeight: "bold",
                            fontSize: "20px",
                          }}
                        >
                          <button
                            id="dialpad"
                            title={`${
                              callholdUnhold === true
                                ? `${pageData?.Call_Hold?.title1}`
                                : `${pageData?.Call_Hold?.title2}`
                            }`}
                            onClick={(e) => {
                              setCallholdUnhold(
                                callholdUnhold === false ? true : false
                              );
                              callholdAndUnhold();
                            }}
                            className={`${
                              callholdUnhold === true
                                ? "bg-success"
                                : "bg-white"
                            } btn rounded-5`}
                          >
                            {callholdUnhold ? (
                              <PlayCircleOutlineIcon />
                            ) : (
                              <PauseCircleOutlineIcon />
                            )}
                          </button>
                        </div>
                      </div>
                      <div
                        className="col-sm-4 col-md-4 col-lg-4 col-xl-4 m-0"
                        style={{
                          display:
                            pageData?.Call_Mute?.visible === false
                              ? "none"
                              : "",
                        }}
                      >
                        <div
                          className="text-center"
                          style={{
                            color: "#262B40",
                            fontWeight: "bold",
                            fontSize: "20px",
                          }}
                        >
                          <button
                            id="dialpad"
                            className={`${
                              callmuteUnmute === true
                                ? "bg-success"
                                : "bg-white"
                            } btn rounded-5`}
                            data-toggle="tooltip"
                            title={`${
                              callmuteUnmute === true
                                ? `${pageData?.Call_Mute?.title1}`
                                : `${pageData?.Call_Mute?.title2}`
                            }`}
                            onClick={callMuteAndUnmute}
                          >
                            {callmuteUnmute ? <MicOffIcon /> : <MicIcon />}
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                  {crmsaveandexit.dialer_session_id &&
                    !["answered", false].includes(
                      crmwebphone.dialerCallHangup
                    ) && (
                      <div
                        className="col-sm-4 col-md-4 col-lg-4 col-xl-4 m-0"
                        style={{
                          display:
                            pageData?.Call_Conference?.visible === false
                              ? "none"
                              : "",
                        }}
                      >
                        <ConferenceCall
                          conf={getConferenceInfo}
                          setCallUnhold={setCallholdUnhold}
                        />
                      </div>
                    )}
                </div>
              </div>
            </div>
            {(crmsaveandexit.dialer_session_id ||
              (userInfo.data[0].usergroup !== "AGENT" &&
                localStorage["currentcall"])) && (
              <div>
                {/* {Object.keys(getConferenceInfo)?.length > 0 ? ( */}
                {Object.keys(crmwebphone?.confStatus)?.length > 0 ? (
                  <div className="mt-3 dropdown">
                    <button
                      className="btn btn-xl border-3 border-secondary mt-1 ml-2  dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{
                        height: "40px",
                        width: "200px",
                        fontSize: "12px",
                        fontWeight: "50px",
                        background: "#dd0e0e",
                        color: "#fff",
                        padding: "0px",
                        margin: "0px",
                        display:
                          pageData?.Call_Conference_Hangup_call?.visible ===
                          false
                            ? "none"
                            : "",
                      }}
                    >
                      {pageData?.Call_Conference_Hangup_call?.label}
                    </button>
                    <ul
                      className="dropdown-menu mt-2"
                      style={{
                        width: "auto",
                      }}
                    >
                      {getEnd &&
                        getEnd.map((data, index) => {
                          return (
                            <button
                              key={index}
                              className="dropdown-item"
                              onClick={(e) => {
                                callend(data);
                              }}
                            >
                              {data.label}
                            </button>
                          );
                        })}
                    </ul>
                  </div>
                ) : (
                  <button
                    className="btn btn-xl border-3 border-secondary mt-1 ml-1"
                    style={{
                      height: "40px",
                      width: "205px",
                      fontSize: "15px",
                      fontWeight: "50px",
                      background: "#dd0e0e",
                      color: "#fff",
                      padding: "0px",
                      margin: "0px",
                      display:
                        pageData?.Hangup_call?.visible === false ? "none" : "",
                    }}
                    type="button"
                    data-toggle="tooltip"
                    title={`${pageData?.Hangup_call?.title}`}
                    onClick={(e) => {
                      callend();
                    }}
                  >
                    <CallEndIcon style={{ paddingRight: "5px" }} />
                    <span>{pageData?.Hangup_call?.label}</span>
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
        {usergroup !== "AGENT" && (
          <div style={{ textAlign: "center" }}>
            {" "}
            <button
              className="btn btn-xl border-3 border-secondary mt-1 ml-1"
              style={{
                height: "40px",
                width: "205px",
                fontSize: "15px",
                fontWeight: "50px",
                background: "#dd0e0e",
                color: "#fff",
                padding: "0px",
                margin: "0px",
                display: pageData?.Hangup_call?.visible === false ? "none" : "",
              }}
              type="button"
              data-toggle="tooltip"
              title={`${pageData?.Hangup_call?.title}`}
              onClick={(e) => {
                callend();
              }}
            >
              <CallEndIcon style={{ paddingRight: "5px" }} />
              <span>{pageData?.Hangup_call?.label}</span>
            </button>
          </div>
        )}
        {usergroup !== "AGENT" && (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              {!queueStatus ? (
                <button
                  className="btn btn-sm mt-2 bg-dark text-white ps-3"
                  style={{
                    marginLeft: "-10px",
                  }}
                  onClick={async () => {
                    await queueLogin(
                      userInfo.data[0]?.dlrAgentId,
                      userInfo.data[0]?.userid
                    ).then((data) => {
                      dispatch(setQueueInfo(true));
                      dispatch(
                        setCrmWebphone({
                          message_text: "added",
                        })
                      );
                    });
                  }}
                >
                  Take Call
                </button>
              ) : (
                <button
                  className="btn btn-sm mt-2 bg-danger text-white ps-3"
                  style={{
                    marginLeft: "-10px",
                  }}
                  onClick={queuelogout}
                >
                  Out From call
                </button>
              )}
            </div>
            {usergroup === "TEAM-LEAD" || usergroup === "MANAGER" ? (
              <div style={{ paddingTop: "5px" }}>
                <strong>DND</strong>
                <Switch
                  color="error"
                  checked={checked}
                  onChange={async (event) => {
                    // await handleDND(event.target.checked);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        )}
        {usergroup === "AGENT" && (
          <div className="row mt-2">
            <Tabs
              className="tabLI"
              defaultActiveKey="ATM"
              style={{
                marginLeft: "1.5rem",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Tab
                eventKey="ATM"
                title="ATM"
                style={{
                  color: "#262B40",
                  width: "100%",
                }}
              >
                <div className="my-2">
                  <div className="mt-2 mb-1">
                    <div className="row" style={{ height: "25px" }}>
                      <div className="col-10">
                        <span
                          className="font-weight-bold m-1"
                          style={{ fontSize: "smaller" }}
                        >
                          My Followup Calls(ATM)
                        </span>{" "}
                      </div>
                      <div className="col-2 pr-3">
                        <RefreshIcon
                          onClick={getfollowup}
                          className="text-success float-right rounded"
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </div>
                    <hr className="m-0" />
                  </div>
                  <div className="mb-1">
                    <Table className="table-borderless ">
                      <tr className="bg-dark text-light">
                        <td
                          className="font-weight-bold mx-0 px-1"
                          style={{ fontSize: "10px" }}
                        >
                          S.No.
                        </td>
                        <td
                          className="font-weight-bold mx-0 px-1"
                          style={{ fontSize: "10px" }}
                        >
                          Status
                        </td>
                        <td
                          className="font-weight-bold mx-0 px-1"
                          style={{ fontSize: "10px" }}
                        >
                          Name
                        </td>
                        <td
                          className="font-weight-bold mx-0 px-1"
                          style={{ fontSize: "10px" }}
                        >
                          Time
                        </td>
                        <td
                          className="font-weight-bold mx-0 px-1"
                          style={{ fontSize: "10px" }}
                        >
                          Phone No
                        </td>
                      </tr>
                      {getfollowupdata &&
                        getfollowupdata.map((data, index) => {
                          return (
                            <tr key={index} className="mt-1">
                              <td
                                className="text-bold px-1"
                                style={{ fontSize: "9px" }}
                              >
                                {index + 1}
                              </td>
                              <td className="px-0 px-1">
                                {" "}
                                <span>
                                  {" "}
                                  <Tooltip
                                    style={{ padding: 0, margin: 0 }}
                                    title={
                                      data.today_atm_called >= 1
                                        ? "Call Done"
                                        : data.today_atm_called === 0 &&
                                          data.revertto_time -
                                            (TIME[0] + TIME[1]) <=
                                            5 &&
                                          data.revertto_time -
                                            (TIME[0] + TIME[1]) >
                                            0
                                        ? "Up Coming Call"
                                        : data.today_atm_called === 0 &&
                                          data.revertto_time <=
                                            TIME[0] + TIME[1]
                                        ? "Didn't get Call"
                                        : "Followup Calls"
                                    }
                                    placement="top"
                                  >
                                    <IconButton
                                      style={{
                                        height: "5px",
                                        width: "5px",
                                        padding: 0,
                                        margin: 0,
                                        color:
                                          data.today_atm_called >= 1
                                            ? "green"
                                            : data.today_atm_called === 0 &&
                                              data.revertto_time -
                                                (TIME[0] + TIME[1]) <=
                                                5 &&
                                              data.revertto_time -
                                                (TIME[0] + TIME[1]) >
                                                0
                                            ? "orange"
                                            : data.today_atm_called === 0 &&
                                              data.revertto_time <=
                                                TIME[0] + TIME[1]
                                            ? "red"
                                            : "white",
                                      }}
                                    >
                                      <CircleIcon
                                        style={{
                                          height: "10px",
                                          width: "10px",
                                          border: "1px Black Solid",
                                          borderRadius: "5px",
                                        }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </span>{" "}
                              </td>
                              <td
                                className="text-bold px-1"
                                style={{ fontSize: "9px" }}
                              >
                                {data?.cust_name
                                  ? data?.cust_name.length > 13
                                    ? data?.cust_name.slice(0, 13) + "..."
                                    : data?.cust_name
                                  : ""}
                              </td>
                              <td
                                className="text-bold px-1"
                                style={{ fontSize: "9px" }}
                              >
                                {data.revertto_time}
                              </td>
                              <td
                                className="text-bold px-1"
                                style={{ fontSize: "9px" }}
                              >
                                xxxxxx{data.phone_no}
                              </td>
                            </tr>
                          );
                        })}
                    </Table>
                  </div>
                </div>
                {getfollowupdata?.length === 0 && (
                  <div className="text-center">
                    <span className="text-center" style={{ fontSize: "20px" }}>
                      No Data found
                    </span>
                  </div>
                )}
              </Tab>
              <Tab eventKey="ALLOC" title="ALLOC" style={{ color: "#262B40" }}>
                <div className="my-2">
                  <div className="mt-2 mb-1">
                    <div className="row" style={{ height: "25px" }}>
                      <div className="col-10">
                        <span
                          className="font-weight-bold m-1"
                          style={{ fontSize: "smaller" }}
                        >
                          My Preview Calls(ATM)
                        </span>{" "}
                      </div>
                      <div className="col-2 pr-3">
                        <RefreshIcon
                          onClick={getpreviewfollowup}
                          className="text-success float-right rounded"
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </div>
                    <hr className="m-0" />
                  </div>
                  <div className="mb-1">
                    <Table className="table-borderless ">
                      <tr className="bg-dark text-light">
                        <td
                          className="font-weight-bold mx-0 px-1"
                          style={{ fontSize: "10px" }}
                        >
                          S.No.
                        </td>
                        <td
                          className="font-weight-bold mx-0 px-2"
                          style={{ fontSize: "10px" }}
                        >
                          Cust Id.
                        </td>
                        <td
                          className="font-weight-bold mx-0 px-2"
                          style={{ fontSize: "10px" }}
                        >
                          Name
                        </td>
                        <td
                          className="font-weight-bold mx-0 px-2"
                          style={{ fontSize: "10px" }}
                        >
                          Date
                        </td>
                      </tr>
                      {getpreviewfollowupdata &&
                        getpreviewfollowupdata.map((data, index) => {
                          return (
                            <tr key={index} className="mt-1">
                              <td
                                className="text-bold px-2 "
                                style={{ fontSize: "10px" }}
                              >
                                {index + 1}
                              </td>
                              <td
                                className="text-bold px-1 "
                                style={{ fontSize: "10px" }}
                              >
                                {data.CUST_ID}
                              </td>
                              <td
                                className="text-bold px-1 "
                                style={{ fontSize: "10px" }}
                              >
                                <span
                                  style={{ padding: 0, fontWeight: "bold" }}
                                >
                                  ({data.DIALER_ATTEMPT})
                                </span>
                                &nbsp;
                                {data?.CUSTOMER_NAME
                                  ? data?.CUSTOMER_NAME.length > 8
                                    ? data?.CUSTOMER_NAME.slice(0, 8) + "..."
                                    : data?.CUSTOMER_NAME
                                  : ""}
                              </td>
                              <td
                                className="text-bold px-1 "
                                style={{ fontSize: "10px" }}
                              >
                                {data.REVERT_DATE.split("T")[0]}
                              </td>
                            </tr>
                          );
                        })}
                    </Table>
                  </div>
                </div>
                {getpreviewfollowupdata?.length === 0 && (
                  <div className="text-center">
                    <span className="text-center" style={{ fontSize: "20px" }}>
                      No Data found
                    </span>
                  </div>
                )}
              </Tab>
            </Tabs>
          </div>
        )}
      </div>
    </>
  );
}
