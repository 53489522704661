import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from "redux";
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import userReducer from "../User";
import campReducer from "../Campaign";
import dialerReducer from "../Dialer";
import dialerStates from '../dialerStates';
import callSessionReducer from '../CurrentCallSessionDetails';

const reducers = combineReducers({
    user: userReducer,
    campaign: campReducer,
    dialer: dialerReducer,
    webphoneStatus: dialerStates,
    callsession: callSessionReducer, // Corrected slice name
});

const persistConfig = {
    key: 'root',
    storage,
    // whitelist: ['callsession'], // Uncomment if you want to persist callsession state
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk],
});

export default store;