import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import api from "../../../utils/api";
import DaynmicApicall from "../../../utils/function";

const SurveyForm = (props) => {
  const { campinfo, componentid, customer } = props;
  const { userInfo } = useSelector((state) => state?.user?.value);
  let { crmsaveandexit } = useSelector((state) => state?.dialer);
  const [surveyData, setSurveyData] = useState([]);

  const fetchData = async () => {
    try {
      const data = await DaynmicApicall(
        `/appuser/getcomponetbyid/${campinfo?.keypointer}/${componentid}`,
        "",
        "get",
        userInfo.data[0].UserToken
      );

      if (data && data.length > 0 && data[0]?.DATA) {
        const questions = data[0].DATA;

        let rowData = questions.map((data, index) => ({
          disposition: data.disposition,
          id: index,
          question: data.question,
          status: "",
          remark: "",
          option: data.options,
        }));

        setSurveyData(rowData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const lastCmiDetails = async () => {
    try {
      const data = await DaynmicApicall(
        `/mcrmdlr/getlastcmidetails/${customer?.custid}/${campinfo?.keypointer}`,
        "",
        "get"
      );

      const cmiSurveyData = data[0]?.input_json?.surveyData || [];
      const updatedSurveyData = surveyData.map((question) => {
        const cmiQuestion = cmiSurveyData.find(
          (cmiQuestion) => cmiQuestion.id === question.id
        );
        if (cmiQuestion) {
          return {
            ...question,
            status: cmiQuestion.status,
            remark: cmiQuestion.remark,
          };
        } else {
          return question;
        }
      });

      setSurveyData(updatedSurveyData);
    } catch (error) {
      console.error("Error fetching lastCmiDetails:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [campinfo, componentid, userInfo.data]);

  useEffect(() => {
    const fetchDataAndCmiDetails = async () => {
      await fetchData();
      if (surveyData.length > 0) {
        await lastCmiDetails();
      }
    };

    fetchDataAndCmiDetails();
  }, [customer?.custid, surveyData.length]);

  const handleStatusChange = (id, value) => {
    setSurveyData((prevData) =>
      prevData.map((row) => (row.id === id ? { ...row, status: value } : row))
    );
  };

  const handleRemarkChange = (id, value) => {
    setSurveyData((prevData) =>
      prevData.map((row) => (row.id === id ? { ...row, remark: value } : row))
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let defaultValue = {
      custid: customer?.custid,
      call_date: new Date().toISOString(),
      agentid: userInfo?.data[0]?.userid,
      resp_code: props?.customer?.resp_code,
      sub_resp_code:
        crmsaveandexit.sub_resp_code === "NA"
          ? props?.customer?.resp_code
          : crmsaveandexit.sub_resp_code,
      campid: campinfo?.campid,
      compid: componentid,
      pol_loan_no: customer?.policy,
      keypointer: campinfo?.keypointer,
      call_id: 0,
      sessionid: crmsaveandexit?.dialer_session_id,
      action_name: "INSERT",
    };
    const combinedData = {
      ...defaultValue,
      surveyData,
    };
    await api
      .post("/mcrmdlr/managedispcomp", combinedData, {
        headers: { Authorization: userInfo.data[0].UserToken },
      })
      .then((Info) => {
        props.onClose();
        toast.success(Info.data.message);
      })
      .catch((error) => {
        toast.error(error.response?.data?.errors);
      });
  };

  return (
    <div className="container mt-4">
      {surveyData.length > 0 && (
        <form className="" onSubmit={handleSubmit}>
          <div className="table-responsive card">
            <table className="table">
              <thead
                style={{
                  background: "#262B40",
                  color: "#fff",
                  borderRadius: "12px",
                }}
              >
                <tr>
                  <th className="col-1 p-o m-0">S.No.</th>
                  <th className="col-6  p-o m-0">Survey Questions</th>
                  <th className="col-2 p-o m-0">Status</th>
                  <th className="col-3 p-o m-0">Remark</th>
                </tr>
              </thead>
              <tbody>
                {surveyData.map((row) => (
                  <tr key={row.id}>
                    <td className="col-1 p-o m-0">{row.id + 1}</td>
                    <td className="col-6 p-o m-0 text-left text-wrap">
                      {row.question}
                    </td>
                    <td className="col-2 p-o m-0" style={{ width: "20%" }}>
                      <select
                        className="form-control form-control-sm"
                        value={row.status}
                        onChange={(e) =>
                          handleStatusChange(row.id, e.target.value)
                        }
                      >
                        <option value="">Select Status</option>
                        {row.option.map((data) => (
                          <option value={data} key={data}>
                            {data}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td className="col-3 p-o m-0">
                      <input
                        className="form-control form-control-sm"
                        type="text"
                        value={row.remark}
                        onChange={(e) =>
                          handleRemarkChange(row.id, e.target.value)
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="d-flex justify-content-end mb-4 pt-4">
              {" "}
              <button type="submit" className="btn btn-primary">
                Submit Survey
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default SurveyForm;
