import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { MultiSelect } from "../../formValidation/MultiSelect";
import { useSelector } from "react-redux";
import api from "../../../utils/api";
import toast from "react-hot-toast";
import { Box, TextareaAutosize } from "@mui/material";
import DaynmicApicall from "../../../utils/function";

export default function Sms({ callDetails }) {
  let { userInfo } = useSelector((state) => state?.user?.value);
  const { UserToken, userid, usergroup, dlrAgentId } = userInfo.data[0];
  let { crmsaveandexit } = useSelector((state) => state?.dialer);
  let {
    data: { campinfo },
  } = callDetails;
  let {
    data: { custinfo },
  } = callDetails;
  const [templates, setTemplate] = useState([]);
  const [templateContent, setTemplateContent] = useState("");

  const pageData = {
    text1: {
      label: "Select Template",
      name: "smstemplate",
      placeholder: "Select Tamplate",
      tooltip: "Select Tamplate For User",
    },
    text2: {
      label: "Select Language",
      name: "sendlanguage",
      placeholder: "Select Language",
      tooltip: "Select Language For User",
    },
    text3: {
      row: "5",
      name: "arearow",
      placeholder: "Template Show",
      tooltip: "Show Selected Template",
      type: "text",
    },
    text4: {
      label: "Mobile Number",
      name: "mobil",
      placeholder: "Enter Mobile Number",
      tooltip: "Enter Mobile Number Here..",
      type: "text",
    },
    button: {
      label: "Send sms",
    },
    cancelButton: {
      label: "cancel",
    },
  };

  useEffect(async () => {
    await api
      .get(`prm/getsmstemplete/${campinfo?.campid}/LINKSMS`, {
        headers: {
          Authorization: userInfo.data[0].UserToken,
        },
      })
      .then((data) => {
        let temp = data?.data?.data?.map((item) => {
          return {
            value: item.para_code,
            label: item.para_value,
            ...item,
          };
        });
        setTemplate(temp);
      });
  }, []);

  const onSubmit = async (values) => {
    const apiData = {
      campid: campinfo.campid,
      custid: custinfo.cust_id,
      custcuid: custinfo.cuid,
      sessionid: crmsaveandexit.dialer_session_id,
      agentid: userid,
      respcode: values.smstemplate.value,
      subrespcode: "NA",
      phone: "91" + crmsaveandexit.dialer_connected_ph,
      pol_loan_no: custinfo?.loan_no ? custinfo?.loan_no : custinfo?.policy_no,
      keypointer: crmsaveandexit.keypointer,
      payment_link: custinfo.payment_link ? custinfo.payment_link : "",
    };

    const Info = api.post(`multimedia/smsCreation`, apiData, {
      headers: { Authorization: UserToken },
    });
    toast
      .promise(Info, {
        success: (Info) => {
          return Info.data.message;
        },
        error: (err) => {
          return (
            err?.response?.data?.errors ??
            err?.response?.data?.message ??
            err?.message ??
            "OOPs something went wrong."
          );
        },
      })
      .then((data) => {});
  };

  const getTemplate = async (value) => {
    await DaynmicApicall(
      `mcrmdlr/smstemplate/${value?.para_code}/${crmsaveandexit.keypointer}`,
      "",
      "get",
      userInfo.data[0].UserToken
    ).then((data) => {
      data ? setTemplateContent(data) : setTemplateContent("");
    });
  };
  return (
    <Formik
      initialValues={{
        smstemplate: "",
      }}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <div className="card card-body text-dark shadow-sm ">
          <Form>
            <div className="row">
              <MultiSelect
                label={pageData.text1.label}
                name={pageData.text1.name}
                tooltip={pageData.text1.tooltip}
                placeholder={pageData.text1.placeholder}
                value={formik.values.smstemplate}
                isMulti={false}
                formik={formik}
                options={templates}
                onChange={async (value) => {
                  formik.setFieldValue("smstemplate", value);
                  await getTemplate(value);
                }}
              />
              <div className="col-s-3 col-md-7 mt-3 pl-5">
                <Box>
                  <TextareaAutosize
                    style={{
                      width: "100%",
                      boxSizing: "border-box",
                      fontFamily: "'IBM Plex Sans', sans-serif",
                      fontSize: "0.875rem",
                      fontWeight: "400",
                      lineHeight: "1.5",
                      padding: "8px 12px",
                      borderRadius: "8px",
                    }}
                    aria-label="SMS Content"
                    minRows={4}
                    value={templateContent}
                    placeholder="SMS Content"
                  />
                </Box>
              </div>
              <div className="col-s-12 col-md-12 mt-3">
                <div className="form-control-sm">
                  <button type="submit" className="btn btn-primary p-2 m-2">
                    {pageData?.button?.label}
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
}
