import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Chip,
  Box,
  Divider,
  CircularProgress,
} from "@mui/material";
import "./FloatingSidebar.css";
import DaynmicApicall from "../../utils/function";

const FloatingSidebar = ({ isOpen }) => {
  const { userInfo } = useSelector((state) => state?.user?.value);
  const [animationClass, setAnimationClass] = useState("");
  const [value, setValue] = useState("Dialer");
  const [data, setData] = useState([]);
  const [botData, setBotData] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [botLoading, setBotLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchPageInfo = async () => {
      try {
        const data = await DaynmicApicall(
          `/appuser/getcomponetbyid/u001dlr/180`,
          "",
          "get",
          userInfo.data[0].UserToken
        );
        setPageData(data[0]?.DATA);
      } catch (err) {
        console.log("ERROR", err);
        setPageData([]);
      }
    };
    fetchPageInfo();
  }, [userInfo]);

  useEffect(() => {
    const handleAnimation = async () => {
      if (isOpen && pageData?.length > 0) {
        setAnimationClass("slide-in");
        setValue("Dialer");
        await fetchDialerData();
      } else {
        setAnimationClass("slide-out");
      }
    };
    handleAnimation();
  }, [isOpen, pageData]);

  const fetchData = useCallback(
    async (value) => {
      if (value === "Dialer") {
        await fetchDialerData();
      }
      if (value === "Bot") {
        await fetchBotData();
      }
    },
    [userInfo, pageData]
  );

  const fetchDialerData = async () => {
    try {
      setLoading(true);
      const payload = {
        datatype: pageData[0]?.dialer?.view,
        campid: userInfo?.data[0]?.campaignid,
      };
      await DaynmicApicall(
        `/external/getdialerData`,
        payload,
        "post",
        userInfo.data[0].UserToken
      )
        .then((res) => {
          setData(res?.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log("ERROR", err);
          setData([]);
          setLoading(false);
        });
    } catch (error) {
      console.log("ERROR", error);
      setData([]);
      setLoading(false);
    }
  };

  const fetchBotData = async () => {
    try {
      setBotLoading(true);
      const payload = {
        datatype: pageData[0]?.bot?.view,
        campid: userInfo?.data[0]?.campaignid,
      };
      await DaynmicApicall(
        `/external/getbotData`,
        payload,
        "post",
        userInfo.data[0].UserToken
      )
        .then((res) => {
          setBotData(res?.data);
          setBotLoading(false);
        })
        .catch((err) => {
          console.log("ERROR", err);
          setBotData([]);
          setBotLoading(false);
        });
    } catch (error) {
      console.log("ERROR", error);
      setBotData([]);
      setBotLoading(false);
    }
  };

  const handleChange = async (event, newValue) => {
    setValue(newValue);
    await fetchData(newValue);
  };

  return (
    <div
      className={`floating-sidebar ${animationClass}`}
      onAnimationEnd={() => {
        if (!isOpen) {
          setAnimationClass("");
        }
      }}
      style={{ display: isOpen ? "" : "none" }}
    >
      {isOpen && (
        <div className="popup tabStyling">
          <Tabs value={value} onChange={handleChange} aria-label="dialerbot">
            <Tab value="Dialer" label="Dialer" />
            <Tab value="Bot" label="Bot" />
          </Tabs>
          {value === "Dialer" && (
            <div className="scrollable-content">
              {loading ? (
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    textAlign: "center",
                    paddingTop: "50px",
                  }}
                >
                  <CircularProgress
                    style={{
                      height: "50px",
                      width: "50px",
                      color: "White",
                    }}
                  />
                </div>
              ) : (
                <Grid container spacing={2} pt={2}>
                  {data?.length > 0 ? (
                    data?.map((item) => (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        key={item.campaign_id}
                      >
                        <Card
                          sx={{
                            width: "100%",
                            borderRadius: 2,
                            padding: 1,
                            backgroundColor: "#FFFFFF",
                          }}
                        >
                          <CardContent>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Typography
                                variant="subtitle1"
                                component="div"
                                sx={{ mb: 1 }}
                              >
                                <Chip
                                  label={
                                    item?.campaign_name
                                      ? item.campaign_name.length > 18
                                        ? item.campaign_name.substring(0, 18) +
                                          "..."
                                        : item.campaign_name
                                      : ""
                                  }
                                  color="primary"
                                  size="small"
                                  variant="outlined"
                                />
                              </Typography>
                              <Typography variant="h5" component="div">
                                <strong>Total:</strong> {item.total_data}
                              </Typography>
                            </Box>
                            <Divider sx={{ my: 1 }} />
                            {Object.entries(item).map(
                              ([key, value]) =>
                                key !== "campaign_name" &&
                                key !== "campaign_id" &&
                                key !== "total_data" && (
                                  <Box
                                    key={key}
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    sx={{ mb: 0.5 }}
                                  >
                                    <Typography variant="body2">
                                      {key.charAt(0).toUpperCase() +
                                        key.slice(1).replace(/_/g, " ")}
                                    </Typography>
                                    <Typography variant="body2">
                                      {value}
                                    </Typography>
                                  </Box>
                                )
                            )}
                          </CardContent>
                        </Card>
                      </Grid>
                    ))
                  ) : (
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        textAlign: "center",
                        paddingTop: "50px",
                      }}
                    >
                      <span>
                        <strong>No Data Found!</strong>
                      </span>
                    </div>
                  )}
                </Grid>
              )}
            </div>
          )}
          {value === "Bot" && (
            <div className="scrollable-content">
              {botLoading ? (
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    textAlign: "center",
                    paddingTop: "50px",
                  }}
                >
                  <CircularProgress
                    style={{
                      height: "50px",
                      width: "50px",
                      color: "White",
                    }}
                  />
                </div>
              ) : (
                <Grid container spacing={2} pt={2}>
                  {botData?.length > 0 ? (
                    botData?.map((item) => (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        key={item?.dlr_id}
                      >
                        <Card
                          sx={{
                            width: "100%",
                            borderRadius: 2,
                            padding: 1,
                            backgroundColor: "#FFFFFF",
                          }}
                        >
                          <CardContent>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Typography
                                variant="subtitle1"
                                component="div"
                                sx={{ mb: 1 }}
                              >
                                <Chip
                                  label={
                                    item?.Campaign_name
                                      ? item.Campaign_name.length > 18
                                        ? item.Campaign_name.substring(0, 18) +
                                          "..."
                                        : item.Campaign_name
                                      : ""
                                  }
                                  color="primary"
                                  size="small"
                                  variant="outlined"
                                />
                              </Typography>
                              <Typography variant="h5" component="div">
                                <strong>Total:</strong> {item?.Total_data}
                              </Typography>
                            </Box>
                            <Divider sx={{ my: 1 }} />
                            {Object.entries(item).map(
                              ([key, value]) =>
                                key !== "Campaign_name" &&
                                key !== "Campaign_id" &&
                                key !== "Total_data" &&
                                key !== "dlr_id" && (
                                  <Box
                                    key={key}
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    sx={{ mb: 0.5 }}
                                  >
                                    <Typography variant="body2">
                                      {key.charAt(0).toUpperCase() +
                                        key.slice(1).replace(/_/g, " ")}
                                    </Typography>
                                    <Typography variant="body2">
                                      {value}
                                    </Typography>
                                  </Box>
                                )
                            )}
                          </CardContent>
                        </Card>
                      </Grid>
                    ))
                  ) : (
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        textAlign: "center",
                        paddingTop: "50px",
                      }}
                    >
                      <span>
                        <strong>No Data Found!</strong>
                      </span>
                    </div>
                  )}
                </Grid>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FloatingSidebar;
