import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import DaynmicApicall from "../../utils/function";
import Emitable from "./Emitable";
import { useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import Divider from "@mui/material/Divider";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import toast from "react-hot-toast";
import api from "../../utils/api";

function PtpdCompo(props) {
  const { campinfo, componentid, customer } = props;
  const { userInfo } = useSelector((state) => state?.user?.value);
  let { crmsaveandexit } = useSelector((state) => state?.dialer);
  const [pageData, setPageData] = useState("");
  const [emiData, setEmiData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [pendingAmount, setpendingAmount] = useState("");
  const [plan, setPlan] = useState("");

  async function pageInfo() {
    await DaynmicApicall(
      `/appuser/getcomponetbyid/${campinfo?.keypointer}/${componentid}`,
      "",
      "get",
      userInfo.data[0].UserToken
    )
      .then((data) => {
        setPageData(data[0]?.DATA);
      })
      .catch((error) => {
        console.log("error in getcomponetbyid", error);
        setPageData("");
      });
  }
  async function totalPaymentInfo() {
    await DaynmicApicall(
      `/mcrmdlr/getpaymentdetails/${campinfo?.campid}/${customer?.custid}/${campinfo?.keypointer}`,
      "",
      "get"
    )
      .then((data) => {
        setpendingAmount(data?.totalPendingAmount);
        setTableData(data?.emiDetails);
      })
      .catch((error) => {
        console.log("error in getpaymentdetails", error);
        setpendingAmount("");
        setTableData([]);
      });
  }
  async function lastPlanInfo() {
    await DaynmicApicall(
      `/mcrmdlr/getlastplandetails/${customer?.custid}/${campinfo?.keypointer}`,
      "",
      "get"
    )
      .then((data) => {
        setPlan(data[0]?.plancount);
        setEmiData(data[0]?.input_json?.planData);
      })
      .catch((error) => {
        console.log("error in getlastplandetails", error);
        setPlan("");
        setEmiData([]);
      });
  }

  useEffect(() => {
    pageInfo();
    totalPaymentInfo();
    lastPlanInfo();
  }, []);

  const handleSubmit = async (values) => {
    const isInvalid = formik.values.data.some(
      (row) => !row.partDate || !row.partAmount
    );

    if (isInvalid) {
      alert("Please fill in all fields for each row.");
    } else {
      let defaultValue = {
        custid: customer?.custid,
        call_date: new Date().toISOString(),
        agentid: userInfo?.data[0]?.userid,
        resp_code: props?.customer?.resp_code,
        sub_resp_code:
          crmsaveandexit.sub_resp_code === "NA"
            ? props?.customer?.resp_code
            : crmsaveandexit.sub_resp_code,
        campid: campinfo?.campid,
        compid: componentid,
        pol_loan_no: customer?.policy,
        keypointer: campinfo?.keypointer,
        call_id: 0,
        sessionid: crmsaveandexit?.dialer_session_id,
        action_name: "INSERT",
      };

      const combinedData = {
        ...defaultValue,
        planData: values.data,
      };
      await api
        .post("/mcrmdlr/managedispcomp", combinedData, {
          headers: { Authorization: userInfo.data[0].UserToken },
        })
        .then((Info) => {
          props.onClose();
          toast.success(Info.data.message);
          lastPlanInfo();
          formik.resetForm();
        })
        .catch((error) => {
          console.log("Error", error.message);
          toast.error(error.response?.data?.errors);
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      numberOfRows: 0,
      data: [],
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const updateRows = (newRowCount) => {
    const newRows = Array.from({ length: newRowCount }, (_, index) => ({
      id: index + 1,
      partDate: "",
      partAmount: "",
    }));

    formik.setValues({
      ...formik.values,
      numberOfRows: newRowCount,
      data: newRows,
    });
  };

  return (
    <>
      {pageData && (
        <div>
          <div style={{ marginTop: "10px", marginBottom: "20px" }}>
            <DataGrid
              getRowId={(row) => row?.id}
              rows={tableData}
              columns={pageData?.columns1}
              rowCount={tableData.length > 0 ? tableData.length : 0}
              rowHeight={40}
              pageSize={3}
              autoHeight={true}
              className="bg-white"
              density="compact"
              disableColumnMenu
            />
          </div>
          <Divider />
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <strong>
              {pageData?.heading1}: {plan}
            </strong>
            <strong>
              {pageData?.heading2}: ₹{pendingAmount}
            </strong>
          </div>
          <Divider />
          <Tabs defaultActiveKey="home" className="mb-1 mt-2" tabsize="2">
            <Tab
              eventKey="home"
              title={pageData?.tab1}
              style={{ borderRight: "none" }}
            >
              <div>
                <Emitable
                  columns={pageData?.columns}
                  formik={formik}
                  emiData={emiData}
                />
              </div>
            </Tab>
            <Tab
              eventKey="plan"
              title={pageData?.tab2}
              style={{ borderRight: "none" }}
            >
              <div>
                <form onSubmit={formik.handleSubmit}>
                  <label htmlFor="numberOfRows" style={{ color: "#4a5073" }}>
                    {pageData?.heading3}:
                  </label>

                  <input
                    type="number"
                    id="numberOfRows"
                    name="numberOfRows"
                    style={{
                      marginLeft: "5px",
                      paddingLeft: "5px",
                      width: "50px",
                    }}
                    min="0"
                    max={pageData?.maxParts}
                    value={formik.values.numberOfRows}
                    onChange={(e) => updateRows(e?.target?.value)}
                  />

                  <Emitable columns={pageData?.columns} formik={formik} />

                  <div className="d-flex justify-content-end mt-1">
                    <button
                      type="submit"
                      className="btn btn-primary btn-sm m-2"
                    >
                      {pageData?.button}
                    </button>
                  </div>
                </form>
              </div>
            </Tab>
          </Tabs>
        </div>
      )}
    </>
  );
}
export default PtpdCompo;
